export const LOAD_MYTECHIE_REPORT         = 'task/report/mytechie/load';
export const LOAD_MYTECHIE_REPORT_SUCCESS = 'task/report/mytechie/load/success';
export const LOAD_MYTECHIE_REPORT_FAILURE = 'task/report/mytechie/load/failure';


export const loadMyTechieReport = ({ interval }, onSuccess) => {
  const [ fromDate, toDate ] = interval || [];
  if (!fromDate || !toDate) {
    return {
      type: LOAD_MYTECHIE_REPORT_FAILURE,
      result: {
        message: 'Ungültiger Interval (von, bis)'
      }
    };
  }
  return {
    types: [
      LOAD_MYTECHIE_REPORT,
      LOAD_MYTECHIE_REPORT_SUCCESS,
      LOAD_MYTECHIE_REPORT_FAILURE
    ],
    promise: (client) => client.get(`/mytechie/download/reports?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`),
    onSuccess: () => {
      onSuccess && onSuccess();
    },
    interval
  };
};
