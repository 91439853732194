import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "primereact/checkbox";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import { DelphiConnectionPermissionOptions } from "../Utils";
// import { isContractor } from "../../../../components/Utils";
import { PermissionResource, Resources } from "../../../Permissions";

export default class DelphiConnectionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    kdlCompanyList: PropTypes.array,
    userCompany: PropTypes.object,
    readOnly: PropTypes.bool,
    requireDescription: PropTypes.bool,
    defaultDescription: PropTypes.string,
  };
  static defaultProps = {
    className: "",
    className: "",
    model: {
      parent_company_id: 0,
      username: "",
      permissions: "",
    },
    companyList: [],
    kdlCompanyList: [],
  };
  constructor(props) {
    super(props);
    this.state = this.generateState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.generateState(props));
  }
  generateState(props) {
    // console.log("DelphiConnectionForm.generateState()", props);
    const model = props.model || {};
    let permissions = "";
    if (model.permissions) {
      const tokens = model.permissions.split(", ");
      permissions = tokens[0];
    }
    return {
      parent_company_id: model.parent_company_id || 0,
      parentCompanyName: props.kdlCompanyList.length > 0 ? props.kdlCompanyList[0].name : "",
      username: model.username || "",
      password: model.password || "",
      permissions,
      error: {
        parent_company_id: null,
        permissions: null,
      },
    };
  }
  getEmptyModel = () => {
    return {
      parent_company_id: 0,
      parentCompanyName: "",
      username: "",
      password: "",
      permissions: [],
    };
  };
  findCompanyId = (companies, value) => {
    const found = companies.find((item) => item.name === value);
    return found ? found.id : null;
  };
  // mergePermissions = (oPermissions, newPermissions) => {
  //   const permissions = [];
  //   // add any old permisions
  //   const oldPermissions = oPermissions.split(",").map((item) => item.trim());
  //   oldPermissions.forEach((permission) => {
  //     const found = DelphiConnectionPermissionOptions.find(
  //       (item) => item.value === permission
  //     );
  //     if (!found) permissions.push(permission);
  //   });

  //   return permissions.concat(newPermissions).join(", ");
  // };
  validate = (initialize) => {
    // const {
    //   kdlCompanyList,
    // } = this.props;
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (error[key] !== undefined) {
        if (key === "permissions") {
          if (model[key].length === 0) {
            error[key] = true;
            isValid = false;
          }
        } else if (model[key] === "") {
          error[key] = true;
          isValid = false;
        }
      }
    });
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    if (initialize) {
      const state = this.getEmptyModel();
      this.setState(state);
    }
    if (model.permissions === this.props.model.permissions) {
      this.setState({ error: { permissions: true }});
    }
    const { id, index, status, status_id, parent_company_id } = this.props.model;
    return {
      ...model,
      id,
      index,
      status,
      status_id,
      oldStatusId: status_id,
      by_company_id: parent_company_id,
      company_id: parent_company_id,
      /* parent_company_id: this.findCompanyId(
        kdlCompanyList,
        model.parentCompanyName
      ), */
      // permissions: model.permissions.join(", "),
    };
  };
  getModel = (initialize = false) => {
    const model = this.validate(initialize);
    return model;
  };
  onPermissionsChange = (event) => {
    let permissions = [...this.state.permissions];
    if (event.checked) {
      permissions.push(event.value);
    } else {
      permissions.splice(permissions.indexOf(event.value), 1);
    }
    this.setState({
      permissions,
      error: {
        ...this.state.error,
        permissions: permissions.length === 0,
      },
    });
  };
  renderPermissions = (model, holderClassName = "", className = "") => {
    const { options, permissions } = model;
    const createCheckbox = (prefix) => (value, index) =>
      (
        <div key={index} className={className}>
          <div className="p-col-12">
            <Checkbox
              inputId={`${prefix}${index}`}
              value={value}
              onChange={this.onPermissionsChange}
              checked={permissions.includes(value)}
              disabled={this.props.readOnly}
            />
            <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">
              {value}
            </label>
          </div>
        </div>
      );
    const items = (options || [])
      .map((item) => item.value)
      .map(createCheckbox("delphiPermCb-"));
    return (
      <div className={holderClassName}>
        <Label>{`Rechte*`}</Label>
        <div
          className="w3-row neg-margin-lr"
          style={{ color: "#000 !important" }}
        >
          {items}
        </div>
      </div>
    );
  };
  setPermissions = (permissions) => {
    this.setState({
      permissions,
      error: {
        ...this.state.error,
        permissions: permissions === ""
      },
    });
  } 
  render() {
    const {
      className,
      kdlCompanyList,
      readOnly,
    } = this.props;
    const {
      parentCompanyName,
      username,
      password,
      permissions,
      error,
    } = this.state;
    const KDLCompanyOptions = kdlCompanyList
      .map((item) => ({ label: item.name, value: `${item.id}` }));

    return (
      <div className={className}>
        <div
          className={`w3-col- form-group-item ${
            error.parentCompanyName ? "error-group" : ""
          }`}
        >
          <Label htmlFor="parentCompanyName">{`Vertragspartner*`}</Label>
          <Dropdown
            id="parentCompanyName"
            className="w3-block w3-border"
            value={parentCompanyName}
            placeholder="Bitte auswählen"
            options={KDLCompanyOptions}
            readOnly
          />
        </div>
        <div
          className={`w3-col- form-group-item ${
            error.permissions ? "error-group" : ""
          }`}
        >
          <Label htmlFor="permissions">{`Rechte*`}</Label>
          <Dropdown
            id="permissions"
            className="w3-block w3-border"
            value={permissions}
            placeholder="Bitte auswählen"
            options={DelphiConnectionPermissionOptions}
            onChange={(event) => this.setPermissions(event.value)}
            readOnly={readOnly}
          />
        </div>
        {/* <div className="clearfix">
          {this.renderPermissions(
            { options: DelphiConnectionPermissionOptions, permissions },
            `pad-top ${error.permissions ? " error-group" : ""}`,
            "w3-col s4 pad-btm pad-lr"
          )}
        </div> */}
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label htmlFor="username">{`Kennung`}</Label>
            <InputText
              id="username"
              className="w3-block w3-border w3-round pad"
              value={username}
              disabled={true/* !isAdmin */}
              onChange={(event) =>
                this.setState({ username: event.target.value })
              }
              readonly={readOnly}
            />
          </div>
          <PermissionResource
            resource={Resources.Organisation.WorkerWizard.ConnectionPasswords}
          >
            <div className="w3-col s6 pad-lr pad-top form-group-item">
              <Label htmlFor="password">{`Passwort`}</Label>
              <InputText
                id="password"
                className="w3-block w3-border w3-round pad"
                value={password}
                disabled={true/* !isAdmin */}
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
                readonly={readOnly}
              />
            </div>
          </PermissionResource>
        </div>
      </div>
    );
  }
}
