import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { frontloadConnect } from "react-frontload";
import {
  isResourceReadable,
  loadResourceGroupPermissions,
} from "../actions";
import * as Resources from "../resources";

const frontload = async (props) => {
  await props.getResourceGroupPermissions("Tasks.UserManagement");
};

const mapStateToProps = (state) => {
  return {
    loading: state.permissions.loadingGroup["tasks.usermanagement"],
    permissions: state.permissions.permissionGroup["tasks.usermanagement"],
  };
};

const mapDispatchToProps = (dispatch) => ({
  getResourceGroupPermissions: (...args) =>
    dispatch(loadResourceGroupPermissions(...args)),
});


// User Task Manager Redirect
function UserTasksRedirect({
  loading,
  permissions,
}) {
  console.log("UserTasksRedirect()", loading, permissions);
  if (loading === undefined || loading === true) {
    return null;
  }
  const showContact = isResourceReadable(Resources.Tasks.UserManagement.Contact, permissions);
  const showEmailCompany = isResourceReadable(Resources.Tasks.UserManagement.CompanyEmails, permissions);
  const showEmailUser = isResourceReadable(Resources.Tasks.UserManagement.EmployeeEmails, permissions);
  const showCompanyChange = isResourceReadable(Resources.Tasks.UserManagement.EmployeeCompany, permissions);
  const showDeactivation = isResourceReadable(Resources.Tasks.UserManagement.EmployeeDeactivation, permissions);

  let targetUrl = "/aufgaben";
  if (showContact) {
    targetUrl += "/benutzer/aenderung-kontaktdaten";
  } else if (showEmailCompany) {
    targetUrl += "/benutzer/unversandte-emails-firmen";
  } else if (showEmailUser) {
    targetUrl += "/benutzer/unversandte-emails-benutzer";
  } else if (showCompanyChange) {
    targetUrl += "/benutzer/aenderung-subunternehmen";
  } else if (showDeactivation) {
    targetUrl += "/benutzer/deaktivierung-mitarbeiter";
  }
  return (<Redirect to={targetUrl}/>);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(UserTasksRedirect)
);