import React from "react";
import PropTypes from "prop-types";
import {
  COMPANY_STATUS_ACTIONS,
  COMPANY_STATUS_IDS,
  COMPANY_STATUS_LABELS,
  PageHeightAuto,
  isCompanyActivated,
  notifyError,
} from "../../../../components/Utils";
import BasicDataForm from "./BasicDataForm";
import StatusForm from "../../../../components/StatusForm";

export default class ContractorForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    types: PropTypes.array,
    userCompany: PropTypes.object,
    getCompany: PropTypes.func,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    companies: [],
  };
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      name: model.name || "",
      error: {
        name: null,
      },
    };
  }
  validate = (noValidate) => {
    const statusData = this.statusData.getModel();
    let basicData = this.basicData.getModel(noValidate);
    if (!noValidate && !basicData) {
      notifyError({
        summary: "Subunternehmen verwalten",
        detail: `Das Subunternehmen konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`,
      });
      return window.scrollTo(0, this.basicData.offsetTop);
    }
    basicData = basicData || {
      name: "",
      type: "",
      address: {},
      contractGivers: [],
    };
    const { name, type_id, address } = basicData;
    const { id, index, activeEmployees, totalEmployees } = this.props.model;
    const model = {
      id,
      index,
      activeEmployees,
      totalEmployees,
      name,
      type_id,
      address,
      // groups,
      // newsRecipients,
      status_id: statusData.status_id,
    };
    const company = this.props.getCompany ? this.props.getCompany() : null;
    console.log(">> Parent Company:", company);
    if (company && company.id) {
      model.parentId = company.id;
    }
    return model;
  };
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  };
  getData = () => {
    return this.getModel(true);
  };
  render() {
    const { className, model, types, disabled, userCompany } = this.props;
    // console.log('ContractorForm.render()', this.props);
    return (
      <div className={`${className}`} style={{ minHeight: "100px" }}>
        <PageHeightAuto
          id="basisdaten"
          className="w3-border margin-top pad-big"
        >
          <div className="w3-row neg-margin-lr">
            <div className="w3-col s6 pad-lr">
              <h3 className="no-margin-top">Allgemeine Daten</h3>
            </div>
            <div className="w3-col s6 pad-lr">
              <StatusForm
                ref={(ref) => (this.statusData = ref)}
                className="status-form w3-right-"
                model={{ status_id: model.status_id }}
                statusIds={COMPANY_STATUS_IDS}
                statusLabels={COMPANY_STATUS_LABELS}
                statusActions={COMPANY_STATUS_ACTIONS}
                objectTitle="das Subunternehmen"
                disabled={
                  disabled ||
                  (model.activeEmployees > 0 && isCompanyActivated(model))
                }
                namePrefix="contractor-"
              />
            </div>
            <BasicDataForm
              ref={(ref) => (this.basicData = ref)}
              model={model}
              types={types}
              userCompany={userCompany}
              onCompanyTypeChanged={this.onCompanyTypeChanged}
              disabled={disabled}
              namePrefix="contractor-"
            />
          </div>
        </PageHeightAuto>
      </div>
    );
  }
}
