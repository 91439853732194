import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { frontloadConnect } from "react-frontload";
import {
  isResourceReadable,
  loadResourcePermissions,
} from "../actions";

const frontload = async (props) => {
  if (props.fetch && props.resource) {
    await props.getResourcePermission(props.resource);
  }
};

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissionMap,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getResourcePermission: (...args) =>
    dispatch(loadResourcePermissions(...args)),
});

const PermissionResourceList = ({
  permissions,
  resources,
  children,
  redirectUrl,
  isVisible,
}) => {
  if (!resources || !Array.isArray(resources) || resources.length === 0) {
    return <>{children}</>;
  }
  let isReadable = isVisible;
  for (const resource of resources) {
    isReadable = isReadable || isResourceReadable(resource, permissions);
    if (isReadable) {
      break;
    }
  }
  if (!isReadable) {
    if (redirectUrl && redirectUrl !== "") {
      return <Redirect to={redirectUrl} />;
    }
    return null;
  }
  return <>{children}</>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(PermissionResourceList)
);
