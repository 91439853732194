import React from "react";
import PropTypes from "prop-types";
import { NavLink, HashRouter } from "react-router-dom";

import { ConnectionTypes } from "../../components/Utils";

import { CancelButtonLink } from "../../../../components/Buttons";
import Dashboard from "../../../../components/Dashboard";
import ImportForm from "../../../../components/ImportForm";
import {
  WizardTitle,
  notifyError,
  PageHeightAuto,
  notifyInfo,
} from "../../../../components/Utils";
// import RecordsTable from "../components/RecordsTable";

class Wizard extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([
      ConnectionTypes.IT_CONNECTION,
      ConnectionTypes.PT_CONNECTION,
      ConnectionTypes.AD_CONNECTION,
      ConnectionTypes.DELPHI_CONNECTION,
    ]).isRequired,
    history: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {
      imported: [],
      rejected: [],
      importSuccess: false,
    };
  }

  componentDidUpdate() {
    if (this.state.importSuccess) {
      const summary = `${this.props.type}-Zugangsimport`;
      const name = `${this.props.type}-Zugänge`;
      setTimeout(() => {
        notifyInfo({
          summary, //: `${this.props.type}-Zugangsimport`,
          detail: `Die ${name} wurden erfolgreich importiert.`,
        });
        this.handleCancel();
      }, 2000);
    }
  }

  handleError = (event) => {
    const error = event.xhr.response;
    const errorMessage = error && error.message;
    notifyError({
      summary: "Importfehler",
      detail: errorMessage,
    });
    this.setState({ imported: [], rejected: [] });
  };

  handleSuccess = (event) => {
    console.log("Import succeeded:", event);
    //===================================//
    // temporary solution
    this.setState({ importSuccess: true });
    //===================================//
    /* const { imported, rejected } = event.xhr.response;
    this.setState({
      imported,
      // to keep the `rejected` structure similar to the `imported` structure
      rejected: rejected.map(
        ({ record, ...other }) => ({ ...record, ...other})
      )
    }); */
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/aufgaben/it-zugaenge");
  };

  renderMenu() {
    const { imported, rejected } = this.state;
    let rejectedLink;
    if (rejected.length !== 0) {
      rejectedLink = (
        <li>
          <NavLink to={`/abgelehnt`} activeClassName="active">
            {"Abgelehnte Zugänge"}
          </NavLink>
        </li>
      );
    }
    let importedLink;
    if (imported.length !== 0) {
      importedLink = (
        <li>
          <NavLink to={`/importiert`} activeClassName="active">
            {"Importierte Zugänge"}
          </NavLink>
        </li>
      );
    }
    return (
      <HashRouter>
        <div className="under-wizard-title">
          <div className="margin-big-top nav-menu-vertical">
            <ul className="w3-ul w3-border w3-round- w3-large">
              <li>
                <NavLink to={`/upload`} activeClassName="active">
                  {"CSV hochladen"}
                </NavLink>
              </li>
              {rejectedLink}
              {importedLink}
            </ul>
          </div>
        </div>
      </HashRouter>
    );
  }

  render() {
    const { type } = this.props;
    const { imported, rejected } = this.state;
    console.log(
      "ConnectionImportWizard.render() \nprops",
      this.props,
      "\nstate",
      this.state
    );
    const Menu = this.renderMenu();

    let rejectedSection;
    if (rejected.length !== 0) {
      rejectedSection = (
        <PageHeightAuto
          id="/abgelehnt"
          className="w3-border margin-big-top pad-big"
        >
          <h3 className="no-margin-top">Abgelehnte Zugänge</h3>
          {/* <RecordsTable records={rejected} type={type} showReason /> */}
        </PageHeightAuto>
      );
    }

    let importedSection;
    if (imported.length !== 0) {
      importedSection = (
        <PageHeightAuto
          id="/importiert"
          className="w3-border margin-big-top pad-big"
        >
          <h3 className="no-margin-top">Importierte Zugänge</h3>
          {/* <RecordsTable records={imported} type={type} /> */}
        </PageHeightAuto>
      );
    }

    return (
      <div className="clearfix">
        <WizardTitle
          type={`connection-import-${type.toLowerCase()}`}
          menu={
            <div className="pad-big-top">
              <CancelButtonLink onCancel={this.handleCancel} />
            </div>
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeightAuto
              id="/upload"
              className="w3-border margin-big-top pad-big"
            >
              <h3 className="no-margin-top">CSV hochladen</h3>
              <ImportForm
                url={`/data-api/companies/connections/csv/upload/${type}`}
                onError={this.handleError}
                onUpload={this.handleSuccess}
              />
            </PageHeightAuto>
            {rejectedSection}
            {importedSection}
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default Wizard;
