import * as actionTypes from '../actions/keys';
import { LOGOUT_SUCCESS } from '../../../redux/actions/auth';
import { notifySuccess, notifyError, openFile, PRODUCT_STATUS_IDS, isProductActivated } from '../../../components/Utils';
import { isArray } from 'lodash';
import { sortProductsByName } from '../components';
// import isArray from 'lodash/isArray';

const initialState = {
  loading: false,
  keys: [],
  catalogKeys: [],
  key: null,
  keyOrders: [],
  keyOrder: null,
  addresses: [],
  keyWizardPage: 0,
  keyOrderWizardPage: 0,
  avialableKeys: [],
  dependentKeyOrders: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load keys
    case actionTypes.LOAD_KEYS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_KEYS_SUCCESS: {
      const keys = action.result.map(key => {
        if (key.image_paths) {
          try {
            key.files = JSON.parse(key.image_paths);
          } catch(error) {
            key.files = [];
            console.error(error);
          }
        }
        return key;
      });
      const activeKeys = sortProductsByName(keys.filter(key => isProductActivated(key)));
      return {
        ...state,
        loading: false,
        keys: sortProductsByName(keys),
        catalogKeys: [ ...activeKeys ],
        avialableKeys: [ ...activeKeys ],
      };
    }
    case actionTypes.LOAD_KEYS_FAILURE:
      return {
        ...state,
        loading: false,
        keys: [],
        catalogKeys: [],
        error: action.result
      };
    // load a key
    case actionTypes.LOAD_KEY:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_KEY_SUCCESS: {
      const key = isArray(action.result) ? action.result[0] : action.result;
      if (key.image_paths) {
        try {
          key.files = JSON.parse(key.image_paths);
        } catch(error) {
          console.error(error);
        }
        // key.files = [{ path: key.image_path }];
      }
      console.log('REDUX REDUCER - LOAD_KEY_SUCCESS', key);
      return {
        ...state,
        loading: false,
        key
      };
    }
    case actionTypes.LOAD_KEY_FAILURE:
      return {
        ...state,
        loading: false,
        key: null,
        error: action.result
      };
    // save a key
    case actionTypes.SAVE_KEY:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SAVE_KEY_SUCCESS:
      notifySuccess({
        summary: `Schließmittel`,
        detail: `Das Schließmittel ${action.key.name} wurde erfolgreich ${action.key.id ? 'aktualisiert' : 'gespeichert'}!`
      });
      return {
        ...state,
        loading: false,
        key: null
      };
    case actionTypes.SAVE_KEY_FAILURE:
      notifyError({
        summary: `Schließmittel`,
        detail: `Das Schließmittel ${action.key.name} konnte nicht ${action.key.id ? 'aktualisiert' : 'gespeichert'} werden!`
      });
      return {
        ...state,
        loading: false,
        key: null,
        error: action.result
    };
    // cancel key
    case actionTypes.CANCEL_KEY:
      return {
        ...state,
        key: null,
        keyWizardPage: 0
      };
    // delete a key
    case actionTypes.DELETE_KEY:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_KEY_SUCCESS:
      notifySuccess({
        summary: `Schließmittel`,
        detail: `Das Schließmittel ${action.key.name} wurde erfolgreich gelöscht!`
      });
      return {
        ...state,
        loading: false,
        selectedKey: null,
        selectionPurpose: null
      };
    case actionTypes.DELETE_KEY_FAILURE:
      notifyError({
        summary: `Schließmittel`,
        detail: `Das Schließmittel ${action.key.name} konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result,
        selectedKey: null,
        selectionPurpose: null
      };
    // select a key
    case actionTypes.SELECT_KEY:
      return {
        ...state,
        selectedKey: action.key,
        selectionPurpose: action.purpose
      };
    // cancel a key selection
    case actionTypes.CANCEL_KEY_SELECTION:
      return {
        ...state,
        selectedKey: null,
        selectionPurpose: null
      };
    // check key usage
    case actionTypes.CHECK_USAGE:
      return {
        ...state,
        loading: true,
        dependentKeyOrders: null
      };
    case actionTypes.CHECK_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dependentKeyOrders: action.result
      };
    case actionTypes.CHECK_USAGE_FAILURE:
      return {
        ...state,
        loading: false,
        dependentKeyOrders: null,
        error: action.error || action.result
      };
    // change a key's status
    case actionTypes.CHANGE_STATUS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CHANGE_STATUS_SUCCESS:
      notifySuccess({
        summary: `Schließmittel ${action.key.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Das Schließmittel ${action.key.name} wurde erfolgreich ${action.key.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert!`
      });
      return {
        ...state,
        loading: false,
        key: null,
        selectedKey: null,
        selectionPurpose: null
      };
    case actionTypes.CHANGE_STATUS_FAILURE:
      notifyError({
        summary: `Schließmittel ${action.key.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Das Schließmittel ${action.key.name} konnte nicht ${action.key.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert werden!`
      });
      return {
        ...state,
        loading: false,
        key: null,
        error: action.result,
        selectedKey: null,
        selectionPurpose: null
      };
    
    // load key orders
    case actionTypes.LOAD_KEY_ORDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_KEY_ORDERS_SUCCESS: {
      const keyOrders = action.result.map(keyOrder => {
        if (keyOrder.avis_paths) {
          try {
            keyOrder.files = JSON.parse(keyOrder.avis_paths);
          } catch(error) {
            keyOrder.files = {};
            console.error(error);
          }
        }
        return keyOrder;
      });
      return {
        ...state,
        loading: false,
        keyOrders
      };
    }
    case actionTypes.LOAD_KEY_ORDERS_FAILURE:{
      const error = action.result || action.error;
      console.log("Key Orders Fetch ERROR:", action);
      notifyError({
        summary: `Schließmittel`,
        detail: `Die Schließmittel konnten nicht geladen werden${(error.code === 403) ? ': NICHT ERLAUBT' : ''}!`
      });
      return {
        ...state,
        loading: false,
        keyOrders: [],
        error
      };}
    // load key order
    case actionTypes.LOAD_KEY_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_KEY_ORDER_SUCCESS: {
      const keyOrder = isArray(action.result) ? action.result[0] : action.result;
      keyOrder.person = (keyOrder.address || {}).contact_person_name;
      keyOrder.address_id = (keyOrder.address || {}).id;
      keyOrder.items = keyOrder.items.map(item => {
        const key = (state.keys || []).find(key => key.id === item.product_id);
        item.files = key ? key.files : [];
        return item;
      });
      if (keyOrder.avis_paths) {
        try {
          keyOrder.files = JSON.parse(keyOrder.avis_paths);
        } catch(error) {
          keyOrder.files = {};
          console.error(error);
        }
      }
      return {
        ...state,
        loading: false,
        keyOrder
      };
    }
    case actionTypes.LOAD_KEY_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        keyOrders: null,
        error: action.result
      };
    // load order address
    case actionTypes.LOAD_ADDRESSES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: action.result
      };
    case actionTypes.LOAD_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        addresses: [],
        error: action.result
      };
    // save order catalog
    case actionTypes.SAVE_KEY_ORDER_CATALOG: {
      const keyOrder = state.keyOrder || {};
      keyOrder.items = action.result;
      // console.log('REDUX_REDUCER saveOrderCatalog()', action);
      return {
        ...state,
        keyOrder
      };
    }
    // save order address
    case actionTypes.SAVE_KEY_ORDER_ADDRESS: {
      const keyOrder = state.keyOrder || {};
      return {
        ...state,
        keyOrder: { ...keyOrder, ...action.result }
      };
    }
    // save key order
    case actionTypes.SAVE_KEY_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SAVE_KEY_ORDER_SUCCESS:
      notifySuccess({
        summary: `Schließmittel bestellen`,
        detail: `Ihre Bestellung wurde erfolgreich ${action.keyOrder.id ? 'aktualisiert' : 'abgegeben'}!`
      });
      return {
        ...state,
        loading: false,
        keyOrder: null
      };
    case actionTypes.SAVE_KEY_ORDER_FAILURE:
      notifyError({
        summary: `Schließmittel bestellen`,
        detail: `Ihre Bestellung konnte nicht ${action.keyOrder.id ? 'aktualisiert' : 'abgegeben'} werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // cancel key order
    case actionTypes.CANCEL_KEY_ORDER:
      return {
        ...state,
        keyOrder: null,
        keyOrderWizardPage: 0
      }
    // avis file
    case actionTypes.LOAD_ORDER_AVIS_FILE_SUCCESS: {
      if (action.avis_path) {
        notifySuccess({
          summary: 'AVIS-Datei herunterladen',
          detail: `Die AVIS-Datei der Bestellung ${action.orderNumber} kann heruntergeladen werden!`
        });
        openFile(action.avis_path);
      }
      // openFile(action.result.filePath);
      return {
        ...state,
        loading: false
      }
    }

    // load avialable keys
    case actionTypes.LOAD_AVIALABLE_KEYS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_AVIALABLE_KEYS_SUCCESS:
      return {
        ...state,
        avialableKeys: action.result,
        loading: false
      };
    case actionTypes.LOAD_AVIALABLE_KEYS_FAILURE:
      return {
        ...state,
        loading: false,
        avialableKeys: null,
        error: action.result
      };    

    // set wizard page
    case actionTypes.SET_WIZARD_PAGE: 
    return {
      ...state,
      [`${action.wizardType}WizardPage`]: action.page
    };

    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
