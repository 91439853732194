import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, ButtonLink } from "../../../../components/Buttons";

import {
  W3Dropdown,
  W3DropdownToggler,
  W3DropdownContent,
} from "../../../../components/W3Dropdown";
import ITTaskDialog from "./ITTaskDialog";
import ActionFilter from "../ActionFilter";

export default class ADConnectionTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedADConnections: PropTypes.array,
    connectionTaskType: PropTypes.string,
    exportADConnections: PropTypes.func.isRequired,
    filterConnections: PropTypes.func.isRequired,
    items: PropTypes.array,
    history: PropTypes.object,
  };
  static defaultProps = {
    className: "",
    actions: {},
    items: [],
  };
  onExport = (event) => {
    if (event) event.preventDefault();
    const type = "AD";
    const { selectedADConnections, exportADConnections } = this.props;
    let counter = 0;
    if (selectedADConnections && selectedADConnections.length > 0) {
      counter += selectedADConnections.length;
    }
    this.taskDialog.show(
      true,
      {
        title: `AD-Zugänge exportieren`,
        text: `
          Alle ${counter} ausgewählte AD-Zugänge werden für die Weiterverarbeitung vorbereitet und als CSV exportiert.  
          Wollen Sie fortfahren?
        `,
        type: type.toUpperCase(),
        showValidity: true,
        taskType: this.props.connectionTaskType,
        showTaskType: false,
      },
      (data) => exportADConnections(data)
    );
  };
  render() {
    // console.log('ADConnectionTaskMenu.render() connectionTaskType:', this.props.connectionTaskType);
    const {
      className,
      selectedADConnections,
      filterConnections,
      connectionTaskType,
      items,
    } = this.props;
    const isADExportable =
      selectedADConnections && selectedADConnections.length > 0;
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle pad-rht">
            <ActionFilter
              type="AD"
              taskType={connectionTaskType}
              setTaskType={filterConnections}
              itemsToCount={items}
              className="display-inline-block"
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-lft">
            <W3Dropdown className="modems dropdown-list-menu">
              <W3DropdownToggler
                as={PrimaryButton}
                size=""
              >{`Optionen`}</W3DropdownToggler>
              <W3DropdownContent
                className="w3-bar-block w3-border"
                style={{ right: "0", width: "320px" }}
              >
                <ButtonLink
                  to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onExport}
                  disabled={!isADExportable}
                >
                  {`AD-Zugänge exportieren (CSV)`}
                </ButtonLink>
                <ButtonLink
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  to={`/wizard/aufgabe/import/ad-zugang`}
                  disabled={false}
                >
                  {`AD-Zugänge importieren (CSV)`}
                </ButtonLink>
              </W3DropdownContent>
            </W3Dropdown>
          </div>
        </div>
        <ITTaskDialog ref={(ref) => (this.taskDialog = ref)} />
      </div>
    );
  }
}
