import React from "react";

const BasicDataFormHeader = ({ title, disabled, renderFootPrint, renderStatus }) => {
  return (
    <div className="w3-row neg-margin-lr">
      <div className="w3-col s6 pad-lr">
        <h3 className="no-margin-top">{title}</h3>
      </div>
      <div className="w3-col s6 pad-lr pad-btm">
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s7 pad-lr">
            {renderFootPrint && renderFootPrint(disabled)}
          </div>
          <div className="w3-col s5 pad-lr">
            {renderStatus && renderStatus()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDataFormHeader;
