import React from "react";
import { connect } from "react-redux";
import { AdminSalesMenu } from "../components";
import { toggleFullScreen } from "../actions/reporting";
import { PageHeight40, PageTitle } from "../../../components/Utils";
import { isResourceReadable } from "../../Permissions/actions";
import { Resources } from "../../Permissions";

const mapStateToProps = (state) => {
  return {
    isFullScreen: state.report.isFullScreen,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setFullScreen: (...args) => dispatch(toggleFullScreen(...args)),
});

function Menu({ isFullScreen, setFullScreen, disabled, permissions }) {
  const canImport = isResourceReadable(
    Resources.Sales.Reporting.Import,
    permissions
  );
  return (
    <PageTitle noMarginTop={isFullScreen}>
      <div className={"w3-right"}>
        <AdminSalesMenu
          isFullScreen={isFullScreen}
          setFullScreen={setFullScreen}
          reportDisabled={disabled}
          importDisabled={!canImport}
        />
      </div>
      <h3 className="no-margin">Reporting-Portal</h3>
    </PageTitle>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);

const mapStateToPropsSTH = (state) => {
  return {
    isFullScreen: state.report.isFullScreen,
  };
};
const SalesTableHolderModule = ({ isFullScreen, children }) => {
  console.log("SalesTableHolderModule.render()", isFullScreen);
  const style = !isFullScreen
    ? {}
    : {
        position: "fixed",
        top: "72px",
        left: "0",
        zIndex: 2,
        width: "100%",
        height: "calc(100vh - 72px)",
        // overflowY: 'scroll',
        background: "#fff",
      };
  return (
    <div style={style}>
      <PageHeight40>{children}</PageHeight40>
    </div>
  );
};
export const SalesTableHolder = connect(
  mapStateToPropsSTH,
  null
)(SalesTableHolderModule);
