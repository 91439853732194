import { loadCompanies } from "../../Company/actions";
import { loadCurrentWorker } from "../../Worker/actions";
import { encodeObjectToBase64 } from "../../../components/Utils";
import { canReadAdministrativeData } from "../../Permissions/actions";

export const LOAD_SALES = "sales/all/load";
export const LOAD_SALES_SUCCESS = "sales/all/load/success";
export const LOAD_SALES_FAILURE = "sales/all/load/failure";

export const LOAD_SALES_OPTIONS = "sales/options/load";
export const LOAD_SALES_OPTIONS_SUCCESS = "sales/options/load/success";
export const LOAD_SALES_OPTIONS_FAILURE = "sales/options/load/failure";

export const LOAD_SALES_PARTNERS = "sales/kdls/load";
export const LOAD_SALES_PARTNERS_SUCCESS = "sales/kdls/load/success";
export const LOAD_SALES_PARTNERS_FAILURE = "sales/kdls/load/failure";

export const LOAD_SALE = "sales/load";
export const LOAD_SALE_SUCCESS = "sales/load/success";
export const LOAD_SALE_FAILURE = "sales/load/failure";

export const SAVE_SALE = "sales/save";
export const SAVE_SALE_SUCCESS = "sales/save/success";
export const SAVE_SALE_FAILURE = "sales/save/failure";

export const UPDATE_SALE = "sales/update";
export const UPDATE_SALE_SUCCESS = "sales/update/success";
export const UPDATE_SALE_FAILURE = "sales/update/failure";

export const SET_SALES_COLUMNS = "sales/columns/set";
export const CLEAR_SALES = "sales/clear";
export const CANCEL_SALE = "sales/cancel";

export const DELETE_SALE = "sales/delete";
export const DELETE_SALE_SUCCESS = "sales/delete/success";
export const DELETE_SALE_FAILURE = "sales/delete/failure";

export const IMPORT_PRODUCTS = "sales/products/import";
export const IMPORT_VPKN = "sales/vpkn/import";

export const LOAD_SALES_WORKERS = "sales/workers/load";
export const LOAD_SALES_WORKERS_SUCCESS = "sales/workers/load/success";
export const LOAD_SALES_WORKERS_FAILURE = "sales/workers/load/failure";

export const TOGGLE_FULLSCREEN = "sales/fullscreen/toggle";

export const loadSales = (filter, props = {}) => {
  console.log(`REDUX-ACTION: loadSales() filter :`, filter);
  return (dispatch, getState) => {
    const fetchSales = () => {
      if (!filter) {
        return console.log("<< NO FILTER => aborting !");
      }
      let filterJsonStringBase64 = null;
      if (filter) {
        filterJsonStringBase64 = encodeObjectToBase64(filter);
      }
      const { salesFilter } = getState().report;
      if (salesFilter === filterJsonStringBase64) {
        return console.log("<< filter did not change => abort!");
      }
      dispatch({
        types: [LOAD_SALES, LOAD_SALES_SUCCESS, LOAD_SALES_FAILURE],
        promise: (client) =>
          client.get(`/sales?filter=${filterJsonStringBase64}`),
        filter: filterJsonStringBase64,
      });
    };
    if (props.options) {
      console.log(">> prefetching sales options ...");
      dispatch(loadSalesOptions(fetchSales));
    } else {
      fetchSales();
    }
  };
};

export const loadSalesOptions = (onSuccess) => {
  console.log(`REDUX-ACTION: loadSalesOptions()`);
  return {
    types: [
      LOAD_SALES_OPTIONS,
      LOAD_SALES_OPTIONS_SUCCESS,
      LOAD_SALES_OPTIONS_FAILURE,
    ],
    promise: (client) => client.get(`/sales/groupedValues/dataType/null`),
    onSuccess: () => onSuccess && onSuccess(),
  };
};

export const loadSalesPartners = (onSuccess) => {
  console.log(`REDUX-ACTION: loadSalesPartners()`);
  return {
    types: [
      LOAD_SALES_PARTNERS,
      LOAD_SALES_PARTNERS_SUCCESS,
      LOAD_SALES_PARTNERS_FAILURE,
    ],
    promise: (client) => client.get(`/sales/groupedValues/dataType/partners`),
    onSuccess: () => onSuccess && onSuccess(),
  };
};

export const clearSales = () => {
  console.log(`REDUX-ACTION: clearSales()`);
  return { type: CLEAR_SALES };
};

export const loadSale = ({ id }) => {
  console.log(`REDUX-ACTION: loadSale(${id})`);
  return (dispatch, getState) => {
    const currentWorker = getState().worker.currentWorker;
    if (!currentWorker) {
      dispatch(loadCurrentWorker());
    }
    const fetch = () => {
      if (id === "neu") {
        const sale = {
          weborder_id: "",
          project_name: "",
          smb_promo: false,
          salesagent_username: "",
          tippgeber_username: "",
          delphi_order_nr: "",
        };
        return dispatch({ type: LOAD_SALE_SUCCESS, result: sale });
      }
      dispatch({
        types: [LOAD_SALE, LOAD_SALE_SUCCESS, LOAD_SALE_FAILURE],
        promise: (client) => client.get(`/sales/${id}`),
      });
    };
    // dispatch(loadCompanies(fetch));
    dispatch(loadSalesPartners(fetch));
  };
};

/**
 * Save a sale
 * @param {Object} param0 Object containing the sale to be saved
 * @param {Function} onSuccess optional callback for a successful execution
 */
export const saveSale = ({ sale }, onSuccess) => {
  console.log(`REDUX-ACTION: saveSale()`, sale);
  return (dispatch /* , getState */) => {
    dispatch({
      types: [SAVE_SALE, SAVE_SALE_SUCCESS, SAVE_SALE_FAILURE],
      promise: (client) => client.post(`/sales/absatzMelden`, { data: sale }),
      onSuccess: () => onSuccess && onSuccess(),
    });
  };
};

/**
 * Cancel the creation of a sale
 */
export const cancelSale = () => {
  return { type: CANCEL_SALE };
};

/**
 * Update a sale
 * @param {Object} param0 Object containing the sale to be updated
 * @param {Function} onSuccess optional callback for a successful execution
 */
export const updateSale = ({ sale }, onSuccess) => {
  console.log(`REDUX-ACTION: updateSale()`, sale);
  return {
    types: [UPDATE_SALE, UPDATE_SALE_SUCCESS, UPDATE_SALE_FAILURE],
    promise: (client) => client.patch(`/sales/${sale.id}`, { data: sale }),
    onSuccess: (dispatch) => {
      dispatch(loadSales());
      onSuccess && onSuccess();
    },
  };
};

/**
 * Delete a sale
 * @param {Object} param0 Object containing the sale to be deleted
 * @param {Function} onSuccess optional callback for a successful execution
 */
export const deleteSale = ({ sale }, onSuccess) => {
  console.log(`REDUX-ACTION: deleteSale()`, sale);
  return {
    types: [DELETE_SALE, DELETE_SALE_SUCCESS, DELETE_SALE_FAILURE],
    promise: (client) =>
      client.del(`/sales/partner_meldung/${sale.weborder_id}`),
    onSuccess: (dispatch) => {
      dispatch(loadSales());
      onSuccess && onSuccess();
    },
  };
};

/**
 * Set the selected columns of the sales table
 * @param {Array} columns the selected columns
 */
export const setSalesColumns = (columns) => {
  console.log("REDUX-ACTION: setSalesColumns()", columns);
  return { type: SET_SALES_COLUMNS, columns };
};

/**
 * Get Sales workers
 * @param {Object} props optional props
 * @param {Function} onSuccess optional callback for a successful execution
 */
export const loadSalesWorkers = (props = {}, onSuccess) => {
  console.log("REDUX-ACTION: Reporting - loadSalesWorkers() ...");
  return (dispatch, getState) => {
    const { user } = getState().auth;
    let companyId = props.companyId;
    if (!companyId) {
      companyId = canReadAdministrativeData(getState()) ? 0 : user.companyId;
    }
    dispatch({
      types: [
        LOAD_SALES_WORKERS,
        LOAD_SALES_WORKERS_SUCCESS,
        LOAD_SALES_WORKERS_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `/companies/${companyId}/employees/summaries/forSales/grouped?related=true`
        ),
      onSuccess: (dispatch) => onSuccess && onSuccess(dispatch),
      currentUser: user,
    });
  };
};

/**
 * Toggle fullscreen mode
 * @param {Objec} param0
 */
export const toggleFullScreen = ({ isFullScreen }) => {
  console.log(
    "REDUX-ACTION: Reporting - toggleFullScreen(",
    isFullScreen,
    ") ..."
  );
  return { type: TOGGLE_FULLSCREEN, isFullScreen };
};
