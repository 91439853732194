import { Resources } from "../../Permissions";

const specialChildren = [
  // {
  //   label: "Reporting-Portal",
  //   url: "/sales/reporting-portal",
  //   component: "reporting",
  //   resource: Resources.Sales.Reporting.Page,
  // },
  // {
  //   label: "Meine Leistungen",
  //   url: "/sales/leistungen",
  //   component: "achievements",
  //   resource: Resources.Sales.Achievements.Page,
  //   children: [
  //     {
  //       label: "Meine Erfolge",
  //       url: "/sales/leistungen/meine-erfolge",
  //       component: "success",
  //       resource: Resources.Sales.Achievements.Successes,
  //     },
  //     {
  //       label: "Absatz je Mitarbeiter",
  //       url: "/sales/leistungen/absatz-je-mitarbeiter",
  //       component: "workerSales",
  //       resource: Resources.Sales.Achievements.WorkerSales,
  //     },
  //     {
  //       label: "MNV-Tip",
  //       url: "/sales/leistungen/mnv-tip",
  //       component: "mnvTip",
  //       resource: Resources.Sales.Achievements.MNVTip,
  //     },
  //   ],
  // },
  {
    label: "Produktverwaltung",
    url: "/sales/produktverwaltung",
    component: "products",
    // isVisibleForFlag: "isSalesImporter",
    resource: Resources.Sales.ProductManagement,
  },
  // {
  //   label: "Buchungsanforderung",
  //   url: "/sales/buchungsanforderung",
  //   component: "booking",
  //   // isVisibleForFlag: "isSalesBookingViewer",
  //   resource: Resources.Sales.Booking,
  // },
];

export const buildSalesTree = (tree) => {
  const salesTree = JSON.parse(JSON.stringify(tree));
  console.log("buildSalesTree()", salesTree.children);
  for (let index = 0; index < salesTree.children.length; index++) {
    const { url } = salesTree.children[index];
    switch (url) {
      case "/sales/arbeitshilfen":
        salesTree.children[index].resource = Resources.Sales.Arbeitshilfen;
        break;
      case "/sales/archiv":
        salesTree.children[index].resource = Resources.Sales.Archive;
        break;
      case "/sales/faq":
        salesTree.children[index].resource = Resources.Sales.FAQ;
        break;
      case "/sales/news-feed":
        salesTree.children[index].resource = Resources.Sales.NewsFeed;
        break;
      case "/sales/produkte":
        salesTree.children[index].resource = Resources.Sales.CurrentProducts;
        break;
      default:
        break;
    }
  }
  salesTree.children.splice(1, 0, ...specialChildren);
  // console.log("buildSalesTree() result", salesTree);
  return salesTree;
};
