import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputText, Label, Dropdown } from '../../../components/Inputs';
// import isString from 'lodash/isString';

export default class SaleForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    companies: PropTypes.array,
    salesAgents: PropTypes.array,
    tipGivers: PropTypes.array,
    currentUser: PropTypes.object,
    getSalesWorkers: PropTypes.func,
    getTipGivers: PropTypes.func,
    // hasAdminAccess: PropTypes.shape({}),
  }
  static defaultProps = {
    className: '',
    model: {
      weborder_id: '',
      project_name: '',
      smb_promo: false,
      salesagent_username: '',
      tippgeber_username: '',
      delphi_order_nr: ''
    },
    companies: [],
    salesAgents: [],
    tipGivers: []
  }
  constructor(props) {
    super(props);
    const { model, salesAgents, tipGivers, currentUser, companies } = props;
    let agentName = model.salesagent_username || '';
    if (agentName === '' && salesAgents.length === 1) {
      agentName = salesAgents[0].username;
    }
    let companyName = model.companyName || '';

    const companyOptions = companies.map(company => ({ label: company.name, value: company.name }));

    this.state = {
      companyOptions,
      salesAgents,
      tipGivers,

      companyName,
      weborder_id: model.weborder_id || '',
      project_name: model.project_name || 'Standard',
      smb_promo: model.smb_promo || false,
      salesagent_username: agentName,
      tippgeber_username: model.tippgeber_username || '',
      delphi_order_nr: model.delphi_order_nr || '',
      error: {
        weborder_id: null,
        // project_name: null,
        salesagent_username: null,
        // tippgeber_username: null,
        // delphi_order_nr: null,
      }
    };
  }
  componentDidMount() {
    if (this.state.companyOptions.length === 1) {
      this.setCompany(this.state.companyOptions[0].value);
    }
  }
  componentWillReceiveProps(props) {
    const { salesAgents, tipGivers } = props;
    this.setState({ salesAgents, tipGivers });
  }
  validate = () => {
    const { error, companyOptions, salesAgents, tipGivers, ...otherProps } = this.state;
    let model = { ...otherProps };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    // weborder id
    const weborder_id = parseInt(model.weborder_id);
    if (isNaN(weborder_id)) {
      error.weborder_id = 'Bitte eine Zahl eingeben';
      isValid = false;
    } else {
      model.weborder_id = weborder_id;
    }
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  setCompany = (value) => {
    const { getSalesWorkers } = this.props;
    this.setState({ companyName: value });
    getSalesWorkers({ companyName: value });
  }
  render() {
    console.log('SaleForm.render()', this.props, '\nstate', this.state);
    const { className } = this.props;
    const {
      companyOptions,
      salesAgents,
      tipGivers,

      companyName,
      weborder_id,
      project_name,
      smb_promo,
      salesagent_username,
      tippgeber_username,
      delphi_order_nr,
      error
    } = this.state;

    const companyMarkup = (
      <Dropdown
        id="company"
        className="w3-block w3-border"
        value={companyName}
        placeholder="Bitte auswählen"
        options={companyOptions}
        onChange={(event) => this.setCompany(event.value)}
        showClear
      />
    );
    
    const filterUsers = (item) => {
      // if (!this.props.hasAdminAccess) {
      //   return true;
      // }
      // check contractGivers
      for (const index in item.contractGivers) {
        if (item.contractGivers[index].name === companyName) {
          return true;
        }
      }
      return false;
    };
    const SalesAgentOptions = salesAgents.filter(filterUsers).map(item => ({
      label: `${item.lastname} ${item.firstname} (${item.username})`,
      value: item.username
    }));
    const TipGiverOptions = tipGivers.filter(filterUsers).map(item => ({
      label: `${item.lastname} ${item.firstname} (${item.username})`,
      value: item.username
    }));
    const ProjectOptions = [{ label: 'Standard', value: 'Standard' }];
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className={`pad-top form-group-item ${error.company && 'error-group'}`}>
              <Label htmlFor="company">{`KDL`}</Label>
              {companyMarkup}
            </div>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.weborder_id && 'error-group'}`}>
            <Label htmlFor="weborder_id">{`IAV Nummer/Weborder-ID*`}</Label>
            <InputText
              id="weborder_id"
              className="w3-block w3-border"
              value={weborder_id}
              onChange={(event) => this.setState({weborder_id: event.target.value})}
            />
          </div>
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.delphi_order_nr && 'error-group'}`}>
            <Label htmlFor="delphi_order_nr">{`Auftragsnummer`}</Label>
            <InputText
              id="delphi_order_nr"
              className="w3-block w3-border"
              value={delphi_order_nr}
              onChange={(event) => this.setState({delphi_order_nr: event.target.value})}
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.salesagent_username && 'error-group'}`}>
            <Label htmlFor="salesagent_username">{`SalesAgent*`}</Label>
            <Dropdown
              id="salesagent_username"
              className="w3-block w3-border"
              value={salesagent_username}
              placeholder="Bitte auswählen"
              options={SalesAgentOptions}
              onChange={(event) => this.setState({salesagent_username: event.value })}
              showClear
            />
          </div>
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.tippgeber_username && 'error-group'}`}>
            <Label htmlFor="tippgeber_username">{`Tippgeber`}</Label>
            <Dropdown
              id="tippgeber_username"
              className="w3-block w3-border"
              value={tippgeber_username}
              placeholder="Bitte auswählen"
              options={TipGiverOptions}
              onChange={(event) => this.setState({tippgeber_username: event.value})}
              showClear
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.project_name && 'error-group'}`}>
            <Label htmlFor="project_name">{`Projekt`}</Label>
            <Dropdown
              id="project_name"
              className="w3-block w3-border"
              value={project_name}
              placeholder="Bitte auswählen"
              options={ProjectOptions}
              onChange={(event) => this.setState({project_name: event.value })}
              showClear
            />
            {/* <InputText id="project_name" className="w3-block w3-border" value={project_name}
              onChange={(event) => this.setState({project_name: event.target.value})}/> */}
          </div>
          <div className={`w3-col s6 pad-lr pad-top form-group-item ${error.smb_promo && 'error-group'}`}>
            <div className="p-col-12">
              <Label htmlFor="smb_promo"><span style={{color: 'transparent'}}>{`_`}</span></Label>
              <Checkbox
                inputId={`smb_promo`}
                value={'SMB Promo'}
                checked={smb_promo === true}
                onChange={(event) => this.setState({smb_promo: event.checked})}
              />
              <label htmlFor={`smb_promo`} className="p-checkbox-label">{'SMB Promo'}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
