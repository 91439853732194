import React from "react";
import PropTypes from 'prop-types';
import { Dropdown, Label } from "./Inputs";
import ConfirmationDialog from "./ConfirmationDialog";

class StatusForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      status_id: PropTypes.number,
    }),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    statusIds: PropTypes.shape({}),
    statusLabels: PropTypes.shape({}),
    statusActions: PropTypes.shape({}),
    objectTitle: PropTypes.string,
    namePrefix: PropTypes.string,
    isDisabled: PropTypes.bool,
    iseditable: PropTypes.string,
  }
  static defaultProps = {
    className: '',
    label: 'Status',
    model: {
      status_id: 2
    },
    objectTitle: "Servicepartner",
    namePrefix: "",
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    return ({
      status_id: model.status_id || 2,
      error: {}
    });
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (error[key] !== undefined && model[key] === null) {
        error[key] = true;
        isValid = false;
      }
    });
    this.setState({ error });
    if (!noValidate && !isValid) {
      return null;
    }
    return { ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  onChange = (status_id) => {
    const { objectTitle, statusActions, namePrefix } = this.props;
    const action = statusActions[`${status_id}`];
    const tokens = objectTitle.split(" ");
    let objectName = objectTitle;
    if (tokens.length > 1) {
      objectName = tokens.slice(1).join(" ");
    }
    this.dialog.show(
      true,
      {
        title: `${objectName} ${action}`,
        text: `Wollen Sie ${objectTitle} wirklich ${action}?`
      },
      () => this.setState({
        status_id,
        error: {
          ...this.state.error,
          status_id: null
        }
      })
    );
  }
  render() {
    const { className, disabled, label, statusIds, statusLabels, namePrefix, iseditable } = this.props
    const { status_id } = this.state;
    const options = Object.keys(statusIds).map(key => ({
      label: statusLabels[`${statusIds[key]}`],
      value: statusIds[key],
    }));
    console.log("StatusForm()", options)
    const isDisabled = disabled || this.props.isDisabled || (iseditable !== "true");
    return (
      <div className={className}>
        <Label
          htmlFor={`${namePrefix}status_id`}
          noMargin
        >
          {label}
        </Label>
        <Dropdown
          id={`${namePrefix}status_id`}
          className="w3-block w3-border"
          value={status_id}
          placeholder="Status auswählen"
          options={options}
          onChange={(event) => this.onChange(event.value)}
          disabled={isDisabled}
          // appendTo={document.getElementById(`${namePrefix}status_id`)}
          // showClearz
        />
        <ConfirmationDialog ref={ref => this.dialog = ref} />
      </div>
    );
  }
}

export default StatusForm;
