import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ViewIconNavButton } from "../../../components/Buttons";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadModems, loadModemOrderOverview } from "../actions";
import { scrollToTop, TableEmptyMessage } from "../../../components/Utils";
// import ModemCatalogItem from '../components/ModemCatalogItem';
import SalesTablePageReport from "../../Reporting/components/SalesTablePageReport";

const frontload = async (props) => {
  await props.getModems();
  await props.getModemOrderOverview();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.modem.loading,
    orderOverview: state.modem.modemOrderOverview,
    modems: state.modem.catalogModems,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModems: (...args) => dispatch(loadModems(...args)),
  getModemOrderOverview: (...args) => dispatch(loadModemOrderOverview(...args)),
});

class ModemOrderOverviewTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    orderOverview: PropTypes.array,
    modems: PropTypes.array,
    getModems: PropTypes.func,
    getWeeklyModemOrders: PropTypes.func,
    rows: PropTypes.number,
    className: PropTypes.string,
  };
  static defaultProps = {
    rows: 12,
    className: "",
  };
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  };
  onShowDetails = (modem, history) => {
    // console.log('ModemOrderOverviewTable.onShowDetails()', modem);
    history.replace(
      `/shop/modems/uebersicht/jahr/${modem.year}/woche/${modem.week}`
    );
  };
  actionTemplate = (rowData /* , column */) => {
    return (
      <div>
        <ViewIconNavButton
          onClick={(history) => this.onShowDetails(rowData, history)}
        />
      </div>
    );
  };
  render() {
    const { className, isLoading, orderOverview, modems, rows } = this.props;
    if (!orderOverview || !modems) {
      return null;
    }
    const formatFieldName = (value) =>
      value.split(".").join("").split(" ").join("-");
    const data = orderOverview.map((item, index) => {
      const model = {
        index,
        calendarWeek: `${item.year}/${item.week < 10 ? "0" : ""}${item.week}`,
      };
      for (const key in item) {
        model[formatFieldName(key)] = item[key];
      }
      return model;
    });
    const columns = modems.map((modem, index) => {
      // console.log('<< Column for', modem);
      return (
        <Column
          key={index}
          field={formatFieldName(modem.name)}
          header={modem.name}
          sortable={data.length > 1}
          filter={data.length > rows}
          filterMatchMode="contains"
        />
      );
    });
    return (
      <div className={`modem-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Modembestellungen"
              itemName=""
              itemNamePlural="Modembestellungswochen"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={
            isLoading ? null : (
              <TableEmptyMessage itemNamePlural="Modembestellungen" />
            )
          }
        >
          <Column
            field="calendarWeek"
            header="Jahr/KW"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{ textAlign: "center" }}
          />
          {columns}
          <Column
            body={this.actionTemplate}
            style={{ textAlign: "center", width: "1em !important" }}
          />
        </DataTable>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(ModemOrderOverviewTable)
);
