import React from "react";
import PropTypes from "prop-types";
import { PrimaryButtonLink, PrimaryButton } from "../../../components/Buttons";
import { connect } from "react-redux";
import { exportUserDevices } from "../actions";

export const DeviceListMenu = ({ className, disabled }) => {
  if (disabled) {
    return null;
  }
  return (
    <div className={`device list-menu ${className}`}>
      <PrimaryButtonLink
        id="addDeviceBtn"
        to={`/wizard/organisation/messgeraet/admin/neu`}
      >{`Referenzmessgerät hinzufügen`}</PrimaryButtonLink>
    </div>
  );
};


const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getUserDevicesAsCSV: (...args) => dispatch(exportUserDevices(...args)),
});
class UserDeviceListMenuClass extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    getUserDevicesAsCSV: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
  };
  onExportUserDevices = (event) => {
    if (event) event.preventDefault();
    this.props.getUserDevicesAsCSV();
  };
  render() {
    const { className, disabled } = this.props;
    // if (disabled) {
    //   return null;
    // }
    return (
      <div className={`userDevice list-menu ${className}`}>
        {!disabled && (
          <PrimaryButtonLink
            to={`/wizard/organisation/messgeraet/mitarbeiter/neu`}
            name="addUserDeviceBtn"
          >
            {`Messgerät hinzufügen`}
          </PrimaryButtonLink>
        )}
        <PrimaryButton
          className="margin-lft"
          name="exportUserDeviceBtn"
          onClick={this.onExportUserDevices}
        >
          {"CSV Exportieren"}
        </PrimaryButton>
      </div>
    );
  }
}
export const UserDeviceListMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDeviceListMenuClass);
