import { createSortByAttr, encodeObjectToBase64 } from "../../../components/Utils";
import { loadCompanies } from "../../Company/actions";
import { DisplayConfig, getChildDisplay } from '../components/DisplayUtils';
import { loadSalesPartners, loadSalesWorkers } from '../../Reporting/actions/reporting';

export const LOAD_FORM_DATA = 'form/data/load';
export const LOAD_FORM_DATA_SUCCESS = 'form/data/load/success';
export const LOAD_FORM_DATA_FAILURE = 'form/data/load/failure';

export const LOAD_DELPHI_NRS = 'sales/delhiNrs/load';
export const LOAD_DELPHI_NRS_SUCCESS = 'sales/delhiNrs/load/success';
export const LOAD_DELPHI_NRS_FAILURE = 'sales/delhiNrs/load/failure';

export const LOAD_RESULT_COLUMNS = 'sales/resultCols/load';
export const LOAD_RESULT_COLUMNS_SUCCESS = 'sales/resultCols/load/success';
export const LOAD_RESULT_COLUMNS_FAILURE = 'sales/resultCols/load/failure';

export const SET_FILTER_TYPE = 'sales/filterType/set';
export const UNSET_WORKER = 'sales/worker/unset';
export const UNSET_FILTER_TYPE = 'sales/filterType/unset';
export const SHOW_DISPLAY = 'sales/display/show';

export const LOAD_SALES = 'sales/load';
export const LOAD_SALES_SUCCESS = 'sales/load/success';
export const LOAD_SALES_FAILURE = 'sales/load/failure';

export const LOAD_PRODUCT_GROUPS = 'sales/productGroups/load';
export const LOAD_PRODUCT_GROUPS_SUCCESS = 'sales/productGroups/load/success';
export const LOAD_PRODUCT_GROUPS_FAILURE = 'sales/productGroups/load/failure';

export const LOAD_PRODUCT_SALES = 'sales/productSales/load';
export const LOAD_PRODUCT_SALES_SUCCESS = 'sales/productSales/load/success';
export const LOAD_PRODUCT_SALES_FAILURE = 'sales/productSales/load/failure';

export const CLEAR_PRODUCT_SALES = 'sales/productSales/clear';
export const SET_TIME_RANGE = 'sales/timerRange/set';


const getDelphiNumbers = (username, onSuccess) => {
  return {
    types: [
      LOAD_DELPHI_NRS,
      LOAD_DELPHI_NRS_SUCCESS,
      LOAD_DELPHI_NRS_FAILURE
    ],
    promise: (client) => client.get(`/sales/achievements/usernames/${username}/filterType/delphiNumber`),
    onSuccess: (dis, getS, result) => onSuccess && onSuccess(result)
  };
};

const getSaleStates = (data, onSuccess) => {
  return {
    types: [
      LOAD_RESULT_COLUMNS,
      LOAD_RESULT_COLUMNS_SUCCESS,
      LOAD_RESULT_COLUMNS_FAILURE
    ],
    promise: (client) => client.get(`/sales/groupedValues/dataType/stateFinal`),
    onSuccess: (dis, getS, result) => onSuccess && onSuccess(result, data),
  };
};

export const getFormData = ({ username, companies, admin }, onSuccess) => {
  console.log(`REDUX-ACTION: getFormData(${username || ''})`);
  return (dispatch, getState) => {
    if (companies) {
      // dispatch(loadCompanies());
      dispatch(loadSalesPartners());
      if (admin) return;
    }
    const targetUser = admin ? username : getState().auth.user.username;
    if (!targetUser) {
      console.error('ERROR: missing username');
      return dispatch({
        type: LOAD_FORM_DATA_FAILURE,
        result: { message: 'Fehlende Eingaben' }
      });
    }
    // 2. callback
    const onSaleStatesFetched = (saleStates, delphiNrs) => {
      const states = (saleStates || []).sort(createSortByAttr('state_final'));
      dispatch({
        type: LOAD_FORM_DATA_SUCCESS,
        result: {
          delphiNrOptions: delphiNrs.map(item => item.delphi_order_nr),
          saleStates: states.map(item => item.state_final)
        },
        username: targetUser
      });
      return onSuccess && onSuccess();
    };
    // 1. callback
    const onDelphiNrsFetchted = (delphiNrs) => {
      return dispatch(getSaleStates(delphiNrs, onSaleStatesFetched));
    };
    return dispatch(getDelphiNumbers(targetUser, onDelphiNrsFetchted));
  };
};

export const loadWorkers = (...args) => {
  return (dispatch) => {
    dispatch({ type: UNSET_WORKER });
    return dispatch(loadSalesWorkers(...args));
  }
}

export const setFilterType = (filterType) => {
  return { type: SET_FILTER_TYPE, filterType };
};

export const cancelFilter = () => {
  return { type: UNSET_FILTER_TYPE };
};

export const showDisplay = (filterConfig, worker = {}) => {
  // return { type: SHOW_DISPLAY, filterConfig };
  return (dispatch, getState) => {
    const { topLevelDisplay } = getState().achievement;
    if (topLevelDisplay === filterConfig.display/*  && filterConfig.display === 'delphi' */) {
      console.log('Display already rendered(' + topLevelDisplay + ') => so fetch data actively');
      dispatch(loadUserSales({ filterConfig/* , userId: worker.username */ }));
    }
    dispatch({ type: SHOW_DISPLAY, filterConfig, worker });
  }
};


export const loadUserSales = ({ filterConfig, item }, onSuccess) => {
  console.log(`REDUX-ACTION: loadUserSales()`, filterConfig, item);
  return (dispatch, getState) => {
    let filter = { ...filterConfig };
    if (item) {
      const key = (DisplayConfig[filter.display] || {}).key;
      if (item[key] !== undefined) {
        filter[key] = item[key];
      }
      filter.display = getChildDisplay(filter.display);
    }
    filter = encodeObjectToBase64(filter);
    const { filterType, username } = getState().achievement;
    console.log(`>>>>>> getState().achievement`, getState().achievement);
    const targetUser = username || /* 'null'; //  */getState().auth.user.username;
    return dispatch({
      types: [
        LOAD_SALES,
        LOAD_SALES_SUCCESS,
        LOAD_SALES_FAILURE
      ],
      promise: (client) => client.get(`/sales/achievements/usernames/${targetUser}/filterType/${filterType}?filter=${filter}`),
      onSuccess: () => onSuccess && onSuccess(),
      filterConfig,
      item,
    });
  };
};


export const loadProductGroups = ({ filterConfig, username }, onSuccess) => {
  console.log(`REDUX-ACTION: loadProductGroups(${username || ''})`);
  return (dispatch, getState) => {
    let filter = { ...filterConfig };
    filter = encodeObjectToBase64(filter);
    const { username } = getState().achievement;
    const targetUser = username || getState().auth.user.username;
    return dispatch({
      types: [
        LOAD_PRODUCT_GROUPS,
        LOAD_PRODUCT_GROUPS_SUCCESS,
        LOAD_PRODUCT_GROUPS_FAILURE
      ],
      promise: (client) => client.get(`/sales/groupedValues/dataType/product`),
      onSuccess: () => onSuccess && onSuccess()
    });
  };
};


export const loadProductSales = ({ filterConfig }, onSuccess) => {
  console.log(`REDUX-ACTION: loadProductSales()`, filterConfig);
  return (dispatch, getState) => {
    if (filterConfig && (!filterConfig.productGroups || filterConfig.productGroups.length === 0)) {
      return dispatch({
        type: LOAD_PRODUCT_SALES_SUCCESS,
        result: null,
        filterConfig
      })
    }
    const filter = encodeObjectToBase64(filterConfig);
    const { username } = getState().achievement;
    const targetUser = username || getState().auth.user.username;
    return dispatch({
      types: [
        LOAD_PRODUCT_SALES,
        LOAD_PRODUCT_SALES_SUCCESS,
        LOAD_PRODUCT_SALES_FAILURE
      ],
      promise: (client) => client.get(`/sales/achievements/usernames/${targetUser}/filterType/productGroup?filter=${filter}`),
      onSuccess: () => onSuccess && onSuccess(),
      filterConfig,
    });
  };
};

export const clearProductSales = () => {
  return { type: CLEAR_PRODUCT_SALES };
};


export const setTimeRange = (interval) => {
  return { type: SET_TIME_RANGE, interval };
};