import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { frontloadConnect } from "react-frontload";
import {
  isResourceReadable,
  isResourceEditable,
  loadResourcePermissions,
} from "../actions";

const frontload = async (props) => {
  if (props.fetch && props.resource) {
    await props.getResourcePermission(props.resource);
  }
};

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissionMap,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getResourcePermission: (...args) =>
    dispatch(loadResourcePermissions(...args)),
});

const PermissionResource = ({
  permissions,
  resource,
  children,
  redirectUrl,
  isVisible,
  showOnlyIfEditable,
  hideDisabledFlag,
}) => {
  if (!resource) {
    return <>{children}</>;
  }
  const isReadable = isVisible || isResourceReadable(resource, permissions);
  const iseditable = isResourceEditable(resource, permissions);
  if (!isReadable) {
    if (redirectUrl && redirectUrl !== "") {
      return <Redirect to={redirectUrl} />;
    }
    return null;
  } else if (showOnlyIfEditable && !iseditable) {
    return null;
  }
  const props = { iseditable: `${iseditable}` };
  if (!hideDisabledFlag) {
    props.disabled = !iseditable;
  }
  return (
    <>
      {React.Children.map(children, (child) => {
        return child ? React.cloneElement(child, props) : child;
      })}
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(PermissionResource)
);
