import React from "react";
import PropTypes from "prop-types";
import ModelCatalogItem from "../ModemCatalogItem";
import { WizardButtonPanel } from "../../../../components/Buttons";

export default class TaskModemCatalogContent extends React.Component {
  static propTypes = {
    // state
    canReadAdminData: PropTypes.bool,
    loadingDebitor: PropTypes.bool,
    orderDebitorDetails: PropTypes.object,
    selectedDebitor: PropTypes.object,
    newModems: PropTypes.array,
    // actions
    saveOrderDebitorChanges: PropTypes.func.isRequired,
    // rest
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
  };
  openUpdateOverview = () => {
    // getting changes
    const { orderDebitorDetails } = this.props;
    const items = [];
    for (const index in orderDebitorDetails.modems) {
      if (this[`catalogItem${index}`]) {
        const item = this[`catalogItem${index}`].getModel();
        if (item) {
          items.push(item);
        }
      }
    }
    // saving changes
    if (items.length !== orderDebitorDetails.modems.length) {
      console.log("<< Correct errors ...");
      return;
    }
    this.props.saveOrderDebitorChanges(items);
    // navigate to overview page
    const { history, match } = this.props;
    const id = match.params.id;
    const debitorId = match.params.debitorId;
    history.replace(
      `/wizard/aufgabe/bestellung/modem/${id}/debitor/${debitorId}/uebersicht`
    );
  };
  render() {
    const {
      className,
      loadingDebitor,
      orderDebitorDetails,
      selectedDebitor,
      newModems,
      modems,
      canReadAdminData,
      cancelOrder,
    } = this.props;
    if (
      loadingDebitor ||
      (!orderDebitorDetails && !newModems) ||
      !selectedDebitor ||
      !modems
    ) {
      return null;
    }
    console.log(
      "TaskModemCatalog.renderOrder()",
      newModems,
      orderDebitorDetails,
      selectedDebitor,
      modems
    );
    // title
    const title = `Debitor (${selectedDebitor.debitorKey})`;
    // modems
    const modemMarkup = (newModems || orderDebitorDetails.modems).map(
      (modem, index) => {
        const bgColor = index % 2 === 0 ? "w3-light-grey" : "";
        const refModem = modems.find(
          (item) => item.name === (modem.productName || modem.product_name)
        );
        if (refModem && refModem.models) {
          modem.models = [...refModem.models];
        }
        return (
          <ModelCatalogItem
            key={index}
            ref={(ref) => (this[`catalogItem${index}`] = ref)}
            className={`pad-big-lr pad-tb w3-border-top ${bgColor}`}
            item={modem}
            model={modem}
            isEditing
            canReadAdminData={canReadAdminData}
            isTask
          />
        );
      }
    );
    return (
      <div className={`modem-catalog`}>
        <div className={`${className} w3-border`}>
          <div className="pad">
            <h3 className="no-margin pad-sm-top pad-lr">{title}</h3>
          </div>
          {modemMarkup}
        </div>
        <WizardButtonPanel
          className="margin-big-top w3-border pad-big w3-light-grey"
          onPrevious={null /* this.openDebitors */}
          onNext={this.openUpdateOverview}
          onCancel={cancelOrder}
          nextLabel={`Übersicht`}
          disableIfAbsent
        />
      </div>
    );
  }
}
