import React from "react";
import PropTypes from "prop-types";
import { InputText, Label } from "../../../../components/Inputs";
import { validateEmail } from "../../../../components/Utils";

export default class EmailForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      email: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      email: "",
    },
    disabled: false,
  };
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const model = props.model || {};
    return {
      email: model.email || "",
      error: {
        email: null,
      },
    };
  };
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    // email
    model.email = model.email.trim();
    console.log(
      ">> validating email ...",
      model.email,
      validateEmail(model.email)
    );
    if (!validateEmail(model.email)) {
      error.email = "ungültige E-Mail";
      isValid = false;
    }
    !noValidate && this.setState({ error });
    if (!noValidate && !isValid) {
      return null;
    }
    return model;
  };
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  };
  render() {
    const { className, disabled, readOnly } = this.props;
    const { email, error } = this.state;
    const label = `Vodafone E-Mail Adresse`;
    if (readOnly) {
      return (
        <div className={`${className} email-form`}>
          <Label>{label}</Label>
          <InputText className="w3-block" value={email} readonly />
        </div>
      );
    }
    return (
      <div className={`${className} email-form`}>
        <Label htmlFor="email">
          {label}
          <span>*</span>
        </Label>
        <InputText
          id="email"
          className={`w3-block w3-border`}
          value={email}
          type="email"
          onChange={(event) => this.setState({ email: event.target.value })}
          disabled={disabled}
        />
        {error.email && <div className="pad-sm-top">{error.email}</div>}
      </div>
    );
  }
}
