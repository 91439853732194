import React from "react";
import { PRODUCT_ORDER_STATUS_IDS } from "../../../../components/Utils";
import { PrimaryButtonLink } from "../../../../components/Buttons";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  keyOrders: state.key.keyOrders,
});
const mapDispatchToProps = null;

function KeyOrderMenu({ className = "", disabled, keyOrders }) {
  const found = (keyOrders || []).find(
    (item) => item.status_id !== PRODUCT_ORDER_STATUS_IDS.FINISHED
  );
  const readOnly = disabled || (found !== null && found !== undefined);
  return (
    <div className={`cards list-menu ${className}`}>
      <PrimaryButtonLink
        id="newKeyOrderButton"
        to={`/wizard/shop/bestellung/schliessmittel/neu`}
        disabled={readOnly}
      >
        {`zum Schließmittel-Shop`}
      </PrimaryButtonLink>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyOrderMenu);
