import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DeviceFormMenu } from "../../../../components/Buttons";
import ItemListManager from "../../../../components/ItemListManager";
import DelphiConnectionForm from "./DelphiConnectionForm";
import {
  STATUS_IDS,
  STATUS_LABELS,
  ACTION_IDS,
  TableEmptyMessage,
  isContractor,
  notifyError,
} from "../../../../components/Utils";
import SalesTablePageReport from "../../../Reporting/components/SalesTablePageReport";

export default class Connection extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    kdlCompanyList: PropTypes.array,
    userCompany: PropTypes.object,
    disableDelphi: PropTypes.bool,
    canReadAdminData: PropTypes.bool,
    rows: PropTypes.number,
    disabled: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      items: (props.model || []).map((item, index) => ({ ...item, index })),
      addDisabled: props.disableDelphi,
      first: 0,
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ addDisabled: props.disableDelphi });
  }
  getModel = () => {
    const { items } = this.state;
    // return items;
    const model = items.map((item) => {
      const {
        companyName,
        company_id,
        byCompanyName,
        by_company_id,
        parentCompanyName,
        parent_company_id,
        username,
        permissions,
        regions,
        id,
        status_id,
        action_id,
        description,
        oldStatusId,
      } = item;
      return {
        companyName,
        company_id,
        byCompanyName,
        by_company_id,
        parentCompanyName,
        parent_company_id,
        username,
        permissions,
        regions,
        id,
        status_id,
        action_id,
        description,
        oldStatusId,
      };
    });
    return model;
  };
  onSave = (onHide, updating) => {
    const model = this.form.getModel();
    if (!model) return;
    model.status_id = updating ? STATUS_IDS.UPDATING : STATUS_IDS.NEW;
    model.action_id = updating ? ACTION_IDS.UPDATE : ACTION_IDS.CREATE;
    // console.log('>> saving delphi connection ...', model);
    const { items } = this.state;
    if (model.index !== undefined && items[model.index] !== undefined) {
      if (updating && items[model.index].permissions === model.permissions) {
        return notifyError({
          summary: "Delphi-Zugang ändern",
          detail: "Für eine Zugangsänderung müssen Sie die Rechte ändern.",
        });
      } else {
        items[model.index] = model;
      }
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    onHide && onHide();
  };
  onDelete = (selected) => {
    if (selected.id) {
      const { items } = this.state;
      if (items[selected.index]) {
        items[selected.index].status_id = STATUS_IDS.DELETING;
        items[selected.index].action_id = ACTION_IDS.DELETE;
        items[selected.index].status = null; // [STATUS_IDS.DELETING];
        this.setState({ items });
        this.props.onChange && this.props.onChange(items);
      } else {
        console.error(
          "<< delphi connection to be deleted was not found",
          this.state.items
        );
      }
    } else {
      // console.log('>> deleting Delphi Connection from list', selected);
      const items = [];
      for (const index in this.state.items) {
        if (index !== selected.index) {
          items.push(this.state.items[index]);
        }
      }
      this.setState({ items });
      this.props.onChange && this.props.onChange(items);
    }
  };
  render() {
    console.log("Connection.render() props", this.props);
    const {
      className,
      kdlCompanyList,
      userCompany,
      // canReadAdminData,
      rows,
      disabled,
    } = this.props;
    const { first } = this.state;
    const onPage = (event) => this.setState({ first: event.first });
    // const isConnectionEditable = (connection) => {
    //   const isNotCreatorCompany =
    //     userCompany &&
    //     connection.id &&
    //     userCompany.id !==
    //       connection.by_company_id; /*  || userCompany.id !== connection.parent_company_id */
    //   // console.log(
    //   //   "canReadAdminData:",
    //   //   canReadAdminData,
    //   //   "isNotCreatorCompany:",
    //   //   isNotCreatorCompany
    //   // );
    //   if (
    //     !canReadAdminData &&
    //     !isContractor(userCompany) &&
    //     isNotCreatorCompany
    //   ) {
    //     return false;
    //   }
    //   return true;
    // };

    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => ({
        ...item,
        index,
        status: STATUS_LABELS[`${item.status_id}`] || item.status || "",
      }));
      return (
        <DataTable
          value={data}
          rows={rows}
          paginator={data.length > rows}
          first={first}
          onPage={onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={first}
              rows={rows}
              emptyText="Keine Zugänge"
              itemName="Zugang"
              itemNamePlural="Zugänge"
            />
          }
          emptyMessage={<TableEmptyMessage itemNamePlural="IT-Zugänge" min />}
        >
          <Column
            field="parentCompanyName"
            header="Vertragspartner"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="permissions"
            header="Rechte"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="username"
            header="Kennung"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="status"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            body={actionTemplate}
            style={{ textAlign: "center", width: "8em" }}
          />
        </DataTable>
      );
    };

    const renderItemForm = (item, onHide) => {
      const connection = item || {
        parent_company_id: kdlCompanyList.length > 0 ? kdlCompanyList[0].id : 0,
        username: "",
        permissions: "",
        status_id: STATUS_IDS.NEW,
      };
      // const requireDescription =
      //   this.state.items.length >= 1 &&
      //   !connection.index &&
      //   connection.index !== 0;
      // const defaultDescription = `${
      //   this.state.items.length + 1
      // }. ${DEFAULT_DELPHI_DESCRIPTION}`;
      // if (requireDescription) {
      //   connection.description = defaultDescription;
      // }
      const saveDisabled =
        connection.status_id !== STATUS_IDS.NEW/*  ||
        !isConnectionEditable(connection) */;
      const updateDisabled =
        connection.status_id !== STATUS_IDS.FINISHED/*  ||
        !isConnectionEditable(connection) */;
      return (
        <div>
          <DelphiConnectionForm
            ref={(ref) => (this.form = ref)}
            model={connection}
            kdlCompanyList={kdlCompanyList}
            userCompany={userCompany}
            // requireDescription={requireDescription}
            // defaultDescription={defaultDescription}
            readOnly={disabled || (updateDisabled && saveDisabled)}
            key={Math.random()}
          />
          <DeviceFormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey"
            style={{
              marginLeft: "-1em",
              marginRight: "-1em",
              marginBottom: "-1em",
            }}
            onSave={() => this.onSave(onHide)}
            onUpdate={() => this.onSave(onHide, true)}
            onCancel={onHide}
            saveDisabled={saveDisabled}
            updateDisabled={updateDisabled}
            saveHidden={disabled || saveDisabled}
            updateHidden={disabled || updateDisabled}
            saveBtnLabel="Beantragen"
            updateBtnLabel="Ändern"
            cancelBtnLabel={disabled ? "Schließen" : undefined}
            saveBtnName="saveUserDelphiAccBtn"
            updateBtnName="updateUserDelphiAcceBtn"
            cancelBtnName="cancelUserDelphiAccBtn"
          />
        </div>
      );
    };
    const getItemFormTitle = (connection) => {
      let title = "Delphi-Zugang bearbeiten";
      if (!connection) title = "Delphi-Zugang anlegen";
      return <h3 className="no-margin">{title}</h3>;
    };
    const isDeleteDisabled = (item) =>
      (item.id && item.status_id !== STATUS_IDS.FINISHED) || disabled;
    let missingRegions = false;
    return (
      <ItemListManager
        key={Math.random()}
        itemPanelStyle={{ width: "640px" }}
        className={className}
        title={<h4 className="no-margin-top" style={{height: "36px"}}>{"Delphi-Zugänge"}</h4>}
        addBtnLabel="Hinzufügen"
        addBtnName="addWorkerDelphiConnectionBtn"
        addBtnDisabled={this.state.addDisabled || missingRegions}
        addBtnHidden={disabled}
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="delphiConnection"
        isEditDisabled={isDeleteDisabled}
        itemDeleteable={!disabled}
        hideDisabledDelete
        isDeleteDisabled={isDeleteDisabled}
        deleteTitle="Delphi-Zugang löschen"
        getDeleteText={(item) =>
          `Wollen Sie den Delphi-Zugang (Kennung: '${item.username}', Vertragspartner: ${item.parentCompanyName}) unwiderruflich löschen?`
        }
        onDelete={this.onDelete}
      />
    );
  }
}
