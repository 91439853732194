export const AdminDataAccess = "Firmenunabhaengigen_Datenzugriff";

export const Start = "Start";

export const Technik = "Technik_Dokumente";

export const Sales = {
  Page: "Sales",
  Arbeitshilfen: "Sales_Arbeitshilfen",
  Reporting: {
    Page: "Sales_Reporting-Portal",
    Import: "Sales_Reporting-Portal_Import",
  },
  Achievements: {
    Page: "Sales_Meine-Leistungen",
    WorkerSales: "Sales_Meine-Leistungen_Absatz",
    MNVTip: "Sales_Meine-Leistungen_MNVTip",
    Successes: "Sales_Meine-Leistungen_Erfolge",
  },
  ProductManagement: "Sales_Produktverwaltung",
  Booking: "Sales_Buchungsanforderung",
  Archive: "Sales_Archiv",
  FAQ: "Sales_FAQ",
  NewsFeed: "Sales_News-Feed",
  CurrentProducts: "Sales_Aktuelle-Produkte",
};

export const Organisation = {
  Page: "Organisation",
  Companies: "Organisation_Firmenprofile",
  CompanyWizard: {
    Page: "Organisation_Firmenprofil",
    Status: "Organisation_Firmenprofile_Status",
    BasicInfo: "Organisation_Firmenprofile_Allgemeine_Daten",
    ITData: "Organisation_Firmenprofile_IT Daten",
    Groups: "Organisation_Firmenprofile_Gewerke",
    TechKeys: "Organisation_Firmenprofile_Technikerschluessel",
    Regions: "Organisation_Firmenprofile_Regionen",
    RegionStorageLocation: "Organisation_Firmenprofile_Region_StorageLocation",
    Debitors: "Organisation_Firmenprofile_Debitoren",
    ContractGiver: "Organisation_Firmenprofile_Auftrageber",
    AdHocNewsRecipents: "Organisation_Firmenprofile_Ad-hoc_News_Verteiler",
    Contractors: "Organisation_Firmenprofile_Subunternehmen",
    ContractorsExport: "Organisation_Firmenprofile_Subunternehmen_Export",
  },
  Workers: "Organisation_Mitarbeiterprofile",
  WorkerWizard: {
    Page: "Organisation_Mitarbeiterprofil",
    BasicInfo: "Organisation_Mitarbeiterprofile_Persoenliche_Daten",
    ExtendedRoles: "Organisation_Mitarbeiterprofile_Aufgabengebiet",
    IDCard: "Organisation_Mitarbeiterprofile_Ausweis",
    ConnectionPasswords: "Organisation_Mitarbeiterprofile_Zugangspasswoerter",
    VodafoneEmail: "Organisation_Mitarbeiterprofile_VFEmail",
    ADConnections: "Organisation_Mitarbeiterprofile_AD_Zugang",
    PTConnections: "Organisation_Mitarbeiterprofile_Prov-Tool",
    DelphiConnections: "Organisation_Mitarbeiterprofile_Delphi-Zugaenge",
    UserDevices: "Organisation_Mitarbeiterprofile_Messgeraete",
    UserDeviceInchargeUserCompany: "Organisation_Mitarbeiterprofile_Messgeraete_FirmaVerantwortlichen",
    TechKeys: "Organisation_Mitarbeiterprofile_Technikerschluessel",
    // ContractGiver: "Organisation_Mitarbeiterprofile_Auftraggeber",
    // Ratings: "Organisation_Mitarbeiterprofile_Bewertungen",
    Certifications: "Organisation_Mitarbeiterprofile_Zertifizierungen",
    TechDevices: "Organisation_Mitarbeiterprofile_VKDGGeraete",
    Seminars: "Organisation_Mitarbeiterprofile_Schulungen",
    Audits: "Organisation_Mitarbeiterprofile_Auditierung",
  },
  Modems: "Organisation_Modems",
  Devices: {
    Page: "Organisation_Messgeraete",
    UserDevices: "Organisation_Messgeraete_Messgeraete",
    Devices: "Organisation_Messgeraete_ReferenzMessgeraete",
  },
  Archive: "Organisation_Archiv",
  Forms: "Organisation_Formulare",
  CommunicationOverview: "Organisation_Kommunikationsuebersicht",
};

export const Webshop = {
  Page: "Webshop",
  Modems: {
    Page: "Webshop_Modem-Logistik",
    Orders: "Webshop_Modem-Logistik_Bestellungen",
    Overview: "Webshop_Modem-Logistik_Uebersicht",
    ModemAvis: "Webshop_Modem-Logistik_ModemAvise",
    Management: "Webshop_Modem-Logistik_Produktverwaltung",
    DebitorMapping: "Webshop_Modem-Logistik_DebitorMapping",
  },
  Keys: {
    Page: "Webshop_Schliessmittel",
    Orders: "Webshop_Schliessmittel_Bestellungen",
    Overview: "Webshop_Schliessmittel_Uebersicht",
    Management: "Webshop_Schliessmittel_Produktverwaltung",
  },
  Cards: {
    Page: "Webshop_Karten/Flyer",
    Orders: "Webshop_Karten/Flyer_Bestellungen",
    Overview: "Webshop_Karten/Flyer_Uebersicht",
    Management: "Webshop_Karten/Flyer_Produktverwaltung",
  },
};

export const News = {
  Page: "News",
  Overview: "News_Uebersicht",
  MessagePanel: "News_News_Einstellen",
  Protocols: "News_News_Versandprotokolle",
  Articles: "News_News_Beitraege",
  Archive: "News_Ad-News_Archiv",
};

export const Tasks = {
  Page: "Aufgaben",
  Modems: "Aufgaben_Modem-Logistik",
  Keys: "Aufgaben_Schliessmittel",
  Cards: "Aufgaben_Karten/Flyer",
  Debitors: "Aufgaben_Debitoren",
  IDCards: "Aufgaben_Ausweise",
  ITConnections: {
    Page: "Aufgaben_IT_Zugaenge",
    ADConnections: "Aufgaben_IT_ADZugaenge",
    MSAuthenticator: "Aufgaben_IT_MSAuthenticator",
    PTConnections: "Aufgaben_IT_PTZugaenge",
    DelphiConnections: "Aufgaben_IT_DelphiZugaenge",
  },
  Devices: "Aufgaben_Messgeraete",
  Reports: {
    Page: "Aufgaben_Reports",
    MyTechie: "Aufgaben_Reports_MyTechie",
  },
  UserManagement: {
    Page: "Aufgaben_Benutzerverwaltung",
    Contact: "Aufgaben_Benutzerverwaltung_Kontaktdaten",
    CompanyEmails: "Aufgaben_Benutzerverwaltung_EmailFirmen",
    EmployeeEmails: "Aufgaben_Benutzerverwaltung_EmailBenutzer",
    EmployeeCompany: "Aufgaben_Benutzerverwaltung_Subunternehmen",
    EmployeeDeactivation: "Aufgaben_Benutzerverwaltung_Mitarbeiterdeaktivierung",
  },
};

export const Training = "Schulung";

export const EMailCommunication = "E-Mail_/_SMS_Kommunikation";

export const Profile = {
  Page: "Profil",
  BasicInfo: "Profil_Persoenliche_Daten",
  ExtendedRoles: "Profil_Aufgabengebiet",
  IDCard: "Profil_Ausweis",
  ADConnections: "Profil_AD_Zugang",
  PTConnections: "Profil_Prov-Tool",
  DelphiConnections: "Profil_Delphi-Zugaenge",
  UserDevices: "Profil_Messgeraete",
  TechKeys: "Profil_Technikerschluessel",
  ContractGiver: "Profil_Auftraggeber",
  Ratings: "Profil_Bewertungen",
  Certifications: "Profil_Zertifizierungen",
  TechDevices: "Profil_VKDGGeraete",
  Seminars: "Profil_Schulungen",
  Audits: "Profil_Auditierung",
};
