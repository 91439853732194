import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'primereact/checkbox';
import { Label } from '../../../components/Inputs';

export default class SubscriptionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    onSave: PropTypes.func,
    disabled: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: {}
  }
  constructor(props) {
    super(props);
    this.state = {
      receiveSms: (props.model.receive_sms === 1),
      receiveEmail: (props.model.receive_email === 1),
    };
  }
  validate = () => {
    const { receiveSms, receiveEmail } = this.state;
    return {
      id: this.props.model.id,
      receive_sms: receiveSms ? 1 : 0,
      receive_email: receiveEmail ? 1 : 0
    };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  onSave = (key, value) => {
    this.setState({ [key]: value });
    console.log('SubscriptionForm.onSave()', key, value);
    if (this.props.onSave) {
      const model = this.getModel();
      model[key] = value ? 0 : 1;
      // console.log('SubscriptionForm.onSave()', model, value);
      this.props.onSave(model);
    }
  }
  render() {
    const { className, disabled } = this.props
    const { receiveSms, receiveEmail } = this.state;
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle">
            <Label>{`Benachrichtigungen empfangen:`}</Label>
          </div>
          <div className="w3-cell w3-cell-middle">
            <div className="p-col-12">
              <Checkbox
                inputId={`receiveSms`}
                value={receiveSms}
                checked={receiveSms}
                onChange={event => this.onSave('receiveSms', event.checked)}
                disabled={disabled}
              />
              <label
                htmlFor={`receiveSms`}
                className="p-checkbox-label"
              >
                {'per SMS'}
              </label>
            </div>
          </div>
          <div className="w3-cell w3-cell-middle">
            <div className="p-col-12">
              <Checkbox
                inputId={`receiveEmail`}
                value={receiveEmail}
                checked={receiveEmail}
                onChange={event => this.onSave('receiveEmail', event.checked)}
                disabled={disabled}
              />
              <label
                htmlFor={`receiveEmail`}
                className="p-checkbox-label"
              >
                {'per E-Mail'}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
