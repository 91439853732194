import { loadOpenTasks } from ".";
import {
  DEVICE_ACTION_IDS,
  DEVICE_STATUS_IDS,
  USER_STATUS_IDS,
} from "../../../components/Utils";
import { canReadAdministrativeData } from "../../Permissions/actions";
import { TASK_SECTION_TYPES } from "../components/Utils";

export const LOAD_EMPLOYEE_TASKS = "task/employee/changes/load";
export const LOAD_EMPLOYEE_TASKS_SUCCESS = "task/employee/changes/load/success";
export const LOAD_EMPLOYEE_TASKS_FAILURE = "task/employee/changes/load/failure";

export const SET_SELECTED_EMPLOYEE_TASKS = "task/employee/changes/set";

export const CLOSE_EMPLOYEE_TASK = "tasks/employee/changes/close";
export const CLOSE_EMPLOYEE_TASK_SUCCESS =
  "tasks/employee/changes/close/success";
export const CLOSE_EMPLOYEE_TASK_FAILURE =
  "tasks/employee/changes/close/failure";

export const CLOSE_EMPLOYEE_TASKS = "tasks/employees/changes/close";
export const CLOSE_EMPLOYEE_TASKS_SUCCESS =
  "tasks/employees/changes/close/success";
export const CLOSE_EMPLOYEE_TASKS_FAILURE =
  "tasks/employees/changes/close/failure";

export const EXPORT_EMPLOYEE_TASKS = "tasks/employees/changes/export";
export const EXPORT_EMPLOYEE_TASKS_SUCCESS =
  "tasks/employees/changes/export/success";
export const EXPORT_EMPLOYEE_TASKS_FAILURE =
  "tasks/employees/changes/export/failure";

export const LOAD_FAILED_EMAIL_TASKS = "task/failedEmail/tasks/load";
export const LOAD_FAILED_EMAIL_TASKS_SUCCESS =
  "task/failedEmail/tasks/load/success";
export const LOAD_FAILED_EMAIL_TASKS_FAILURE =
  "task/failedEmail/tasks/load/failure";

export const SET_SELECTED_FAILED_EMAIL_TASKS = "task/failedEmail/tasks/set";

export const CLOSE_FAILED_EMAIL_TASK = "tasks/failedEmail/tasks/close";
export const CLOSE_FAILED_EMAIL_TASK_SUCCESS =
  "tasks/failedEmail/tasks/close/success";
export const CLOSE_FAILED_EMAIL_TASK_FAILURE =
  "tasks/failedEmail/tasks/close/failure";

export const DEACTIVATE_USER_TASK = "tasks/failedEmail/user/deactivate";
export const DEACTIVATE_USER_TASK_SUCCESS =
  "tasks/failedEmail/user/deactivate/success";
export const DEACTIVATE_USER_TASK_FAILURE =
  "tasks/failedEmail/user/deactivate/failure";

export const CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS =
  "tasks/employees/company/change/close";
export const CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS_SUCCESS =
  "tasks/employees/company/change/close/success";
export const CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS_FAILURE =
  "tasks/employees/company/change/close/failure";

export const CLOSE_EMPLOYEE_DEACTIVATION_TASKS =
  "tasks/employees/deactivation/close";
export const CLOSE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS =
  "tasks/employees/deactivation/close/success";
export const CLOSE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE =
  "tasks/employees/deactivation/close/failure";

export const DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS =
  "tasks/employees/deactivation/deactivate";
export const DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS =
  "tasks/employees/deactivation/deactivate/success";
export const DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE =
  "tasks/employees/deactivation/deactivate/failure";

export const DELETE_EMPLOYEE_DEACTIVATION_TASKS =
  "tasks/employees/deactivation/delete";
export const DELETE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS =
  "tasks/employees/deactivation/delete/success";
export const DELETE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE =
  "tasks/employees/deactivation/delete/failure";

export const OperationType = {
  UpdateDevice: "UPDATE_DEVICE",
  CreateDeviceDeleteTask: "CREATE_DEVICE_DELETE_TASK",
};

export const loadEmployeeTasks = () => {
  return {
    types: [
      LOAD_EMPLOYEE_TASKS,
      LOAD_EMPLOYEE_TASKS_SUCCESS,
      LOAD_EMPLOYEE_TASKS_FAILURE,
    ],
    promise: (client) => client.get(`/employee/changes/tasks`),
  };
};

export const setSelectedEmployeeTasks = (selected) => {
  return {
    type: SET_SELECTED_EMPLOYEE_TASKS,
    selected,
  };
};

export const closeEmployeeTask = ({ task }, onSuccess) => {
  const type = "USER_PROFILE_CHANGE_CONFIRM";
  const data = [task];
  return {
    types: [
      CLOSE_EMPLOYEE_TASK,
      CLOSE_EMPLOYEE_TASK_SUCCESS,
      CLOSE_EMPLOYEE_TASK_FAILURE,
    ],
    promise: (client) =>
      client.post(`/companies/connections/${type}`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.CONTACT_CHANGE));
      onSuccess && onSuccess();
    },
    task,
  };
};

export const closeEmployeeTasks = ({ tasks }, onSuccess) => {
  const type = "USER_PROFILE_CHANGE_CONFIRM";
  const data = tasks;
  return {
    types: [
      CLOSE_EMPLOYEE_TASKS,
      CLOSE_EMPLOYEE_TASKS_SUCCESS,
      CLOSE_EMPLOYEE_TASKS_FAILURE,
    ],
    promise: (client) =>
      client.post(`/companies/connections/${type}`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.CONTACT_CHANGE));
      onSuccess && onSuccess();
    },
    tasks,
  };
};

export const exportEmployeeTasks = () => {
  const type = "USER_PROFILE_CHANGE_EXPORT";
  return (dispatch, getState) => {
    const { employeeTasks } = getState().task;
    const data = [...employeeTasks];
    dispatch({
      types: [
        EXPORT_EMPLOYEE_TASKS,
        EXPORT_EMPLOYEE_TASKS_SUCCESS,
        EXPORT_EMPLOYEE_TASKS_FAILURE,
      ],
      promise: (client) =>
        client.post(`/companies/load/csv/tasks/${type}`, { data }),
    });
  };
};

export const loadFailedEmailTasks = () => {
  return {
    types: [
      LOAD_FAILED_EMAIL_TASKS,
      LOAD_FAILED_EMAIL_TASKS_SUCCESS,
      LOAD_FAILED_EMAIL_TASKS_FAILURE,
    ],
    promise: (client) => client.get(`/employee/failedEmail/tasks`),
  };
};

export const setSelectedFailedEmailTasks = (selected) => {
  // console.log(`REDUX-ACTION: setSelectedFailedEmailTasks()`, selected);
  return {
    type: SET_SELECTED_FAILED_EMAIL_TASKS,
    selected,
  };
};

export const closeFailedEmailTask = ({ task }, onSuccess) => {
  return {
    types: [
      CLOSE_FAILED_EMAIL_TASK,
      CLOSE_FAILED_EMAIL_TASK_SUCCESS,
      CLOSE_FAILED_EMAIL_TASK_FAILURE,
    ],
    promise: (client) =>
      client.put(`/companies/changeEmails/${task.origin}`, { data: task }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.FAILED_EMAILS));
    },
    task,
  };
};

export const deactivateUser = ({ task }, onSuccess) => {
  const taskType = "DELETE_USER_AND_IT_CONNECTIONS";
  return {
    types: [
      DEACTIVATE_USER_TASK,
      DEACTIVATE_USER_TASK_SUCCESS,
      DEACTIVATE_USER_TASK_FAILURE,
    ],
    promise: (client) =>
      client.post(`/companies/connections/${taskType}`, {
        data: [{ ...task, user_id: task.user_id || task.id }],
      }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.EMPLOYEE_DEACTIVATION));
    },
    task,
  };
};

// =============================================================== //

const saveCompanyChangeTask = (task, { taskType, ...props }, onSuccess) => {
  console.log(`REDUX-ACTION: saveCompanyChangeTask()`, task, props);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : user.companyId;
    const device = { ...task, ...props };
    dispatch({
      types: [
        CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS,
        CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS_SUCCESS,
        CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS_FAILURE,
      ],
      promise: (client) =>
        client.put(
          `/companies/${encodeURIComponent(
            companyId
          )}/devices?taskType=${taskType}`,
          {
            data: device,
          }
        ),
      onSuccess: () => {
        onSuccess && onSuccess();
        dispatch(loadOpenTasks(TASK_SECTION_TYPES.COMPANY_CHANGED));
      },
      device,
      ...props,
    });
  };
};

export const updateUserDeviceFromTask = (task, onSuccess) => {
  console.log(`REDUX-ACTION: updateUserDeviceFromTask()`, task);
  return saveCompanyChangeTask(
    task,
    {
      taskType: OperationType.UpdateDevice,
      action_id: 0,
      status_id: DEVICE_STATUS_IDS.FINISHED,
    },
    onSuccess
  );
};

export const deleteUserDeviceFromTask = (task, onSuccess) => {
  console.log(`REDUX-ACTION: deleteUserDeviceFromTask()`, task);
  return saveCompanyChangeTask(
    task,
    {
      taskType: OperationType.CreateDeviceDeleteTask,
      action_id: DEVICE_ACTION_IDS.DELETE,
      status_id: DEVICE_STATUS_IDS.DELETING,
    },
    onSuccess
  );
};

// =============================================================== //

export const closeEmployeeDeactivationTasks = (tasks, onSuccess) => {
  // console.log(`REDUX-ACTION: closeEmployeeDeactivationTasks()`, tasks);
  const data = tasks;
  return {
    types: [
      CLOSE_EMPLOYEE_DEACTIVATION_TASKS,
      CLOSE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS,
      CLOSE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE,
    ],
    promise: (client) =>
      client.post(`/companies/connections/CLOSE_USER_DEACTIVATION`, {
        data,
      }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.EMPLOYEE_DEACTIVATION));
    },
    tasks,
  };
};

export const deactivateEmployeeDeactivationTasks = (tasks, onSuccess) => {
  console.log(`REDUX-ACTION: deactivateEmployeeDeactivationTask()`, tasks);
  const data = tasks.map((item) => ({
    ...item,
    status_id: USER_STATUS_IDS.DEACTIVATED,
  }));
  return {
    types: [
      DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS,
      DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS,
      DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE,
    ],
    promise: (client) =>
      client.post(`/companies/connections/CONFIRM_USER_DEACTIVATION`, {
        data,
      }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.EMPLOYEE_DEACTIVATION));
    },
    tasks,
  };
};

export const deleteEmployeeDeactivationTasks = (tasks, onSuccess) => {
  console.log(`REDUX-ACTION: deleteEmployeeDeactivationTasks()`, tasks);
  return {
    types: [
      DELETE_EMPLOYEE_DEACTIVATION_TASKS,
      DELETE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS,
      DELETE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE,
    ],
    promise: (client) =>
      client.post(`/companies/connections/RESET_USER_DEACTIVATION`, {
        data: tasks,
      }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.EMPLOYEE_DEACTIVATION));
    },
    tasks,
  };
};
