import React from "react";
import PropTypes from "prop-types";
import ModelCatalogItem from "../../components/ModemCatalogItem";
import { WizardButtonPanel } from "../../../../components/Buttons";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadCurrentOrderDebitorDetails,
  saveOrderDebitorChanges,
} from "../../actions";
import { createSortByAttr } from "../../../../components/Utils";
import { isResourceReadable } from "../../../Permissions/actions";
import { Resources } from "../../../Permissions";

const frontload = async (props) => {
  const id = props.match.params.id;
  const onFailure = () => {
    props.history.replace(`/wizard/shop/bestellung/modem/${id}/debitoren`);
  };
  await props.getOrderDebitorDetails({ onFailure });
};
const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissionGroup.admindataaccess,
    loadingDebitor: state.modem.loadingDebitor,
    selectedDebitor: state.modem.selectedDebitor,
    orderDebitorDetails: state.modem.orderDebitorDetails,
    newModems: state.modem.newModems,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrderDebitorDetails: (...args) =>
    dispatch(loadCurrentOrderDebitorDetails(...args)),
  saveOrderDebitorChanges: (...args) =>
    dispatch(saveOrderDebitorChanges(...args)),
});

class OrderModemCatalog extends React.Component {
  static propTypes = {
    // mapped from state
    permissions: PropTypes.shape({}),
    loadingDebitor: PropTypes.bool,
    orderDebitorDetails: PropTypes.object,
    selectedDebitor: PropTypes.object,
    newModems: PropTypes.array,
    // actions
    getOrderDebitorDetails: PropTypes.func.isRequired,
    saveOrderDebitorChanges: PropTypes.func.isRequired,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
  };
  componentWillMount() {
    const { permissions, match, history, orderDebitorDetails } = this.props;
    const id = match.params.id;
    const canReadAdminData = isResourceReadable(
      Resources.AdminDataAccess,
      permissions
    );
    if (!canReadAdminData && orderDebitorDetails) {
      // check status to see if user can edit existing order
      // console.log(
      //   "### Checking if the data for the selected debitor should be editable",
      //   orderDebitorDetails.modems
      // );
      if (
        orderDebitorDetails.modems &&
        orderDebitorDetails.modems.length > 0 &&
        orderDebitorDetails.modems[0].status_id !== undefined &&
        orderDebitorDetails.modems[0].status_id !== 1
      ) {
        console.info("<< Debitor not editable => skipping forward");
        history.replace(`/wizard/shop/bestellung/modem/${id}/uebersicht`);
      }
    }
  }
  openDebitors = () => {
    const { history, match } = this.props;
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/debitoren`);
  };
  openUpdateOverview = () => {
    // getting changes
    const { orderDebitorDetails } = this.props;
    const items = [];
    for (const index in orderDebitorDetails.modems) {
      if (this[`catalogItem${index}`]) {
        const item = this[`catalogItem${index}`].getModel();
        if (item) {
          items.push(item);
        }
      }
    }
    // saving changes
    if (items.length !== orderDebitorDetails.modems.length) {
      console.log("<< Correct errors ...");
      return;
    }
    this.props.saveOrderDebitorChanges(items);
    // navigate to overview page
    const { history, match } = this.props;
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/uebersicht`);
  };
  render() {
    const {
      className,
      loadingDebitor,
      orderDebitorDetails,
      selectedDebitor,
      newModems,
      permissions,
      cancelOrder,
    } = this.props;
    if (loadingDebitor || !orderDebitorDetails || !selectedDebitor) {
      return null;
    }
    const canReadAdminData = isResourceReadable(Resources.AdminDataAccess, permissions);
    // modems
    const modemMap = {};
    const groupedModems = [];
    (newModems || orderDebitorDetails.modems).forEach((item) => {
      const key = `${item.orderNumber}${item.productName.toLowerCase()}`;
      // console.log(">> Modem key", key, modemMap[key]);
      if (!modemMap[key]) {
        groupedModems.push(item);
        modemMap[key] = true;
      }
    });
    const modemMarkup = groupedModems
      .sort(createSortByAttr("productName"))
      .map((modem, index) => {
        const bgColor = index % 2 === 0 ? "w3-light-grey" : "";
        return (
          <ModelCatalogItem
            key={index}
            id={`orderItem${index}`}
            ref={(ref) => (this[`catalogItem${index}`] = ref)}
            className={`pad-big-lr pad-tb w3-border-top ${bgColor}`}
            item={modem}
            model={modem}
            onAdd={null}
            isEditing
            canReadAdminData={canReadAdminData}
          />
        );
      });
    return (
      <div className={`modem-catalog`}>
        <div className={`${className} w3-border`}>
          <div className="pad w3-light-grey">
            <h3 className="no-margin pad-sm-top pad-lr">{`Debitor (${selectedDebitor.debitorKey})`}</h3>
          </div>
          {modemMarkup}
        </div>
        <WizardButtonPanel
          className="margin-big-top w3-border pad-big w3-light-grey"
          onPrevious={this.openDebitors}
          onNext={this.openUpdateOverview}
          onCancel={cancelOrder}
          nextLabel={`Übersicht`}
          disableIfAbsent
          nameSuffix="ModemOrder"
        />
      </div>
    );
  }
}

class Overlay extends React.Component {
  static propTypes = {
    isVisible: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }
  toggleVisibility = (isVisible) => {
    this.setState({ isVisible });
  };
  render() {
    return this.state.isVisible ? <div className="overlay w3-opacity" /> : null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(OrderModemCatalog)
);
