import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PrimaryButton } from "../../../components/Buttons";
import FormDialog from "../../../components/FormDialog";
import SignatureForm from "../components/SignatureForm";

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadSignature,
  saveSignature,
} from "../actions";

const frontload = async (props) => {
  await props.getSignature();
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.news.loading,
    signature: state.news.signature,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getSignature: (...args) => dispatch(loadSignature(...args)),
  setSignature: (...args) => dispatch(saveSignature(...args))
});

const SignatureMenu = ({
  className = "",
  isLoading,
  signature,
  getSignature,
  setSignature
}) => {
  const formDialog = useRef(null);
  const formRef = useRef(null);
  const [signatureData, setSignatureData] = useState(signature);
  useEffect(
    () => {
      if (!isLoading) {
        console.log("Signature fetched:", signature);
        setSignatureData(signature);
      } else {
        console.log(">> fetching signature ...");
      }
    },
    [ isLoading, signature ]
  );
  const handleSave = useCallback(
    () => {
      const model = formRef.current ? formRef.current.getModel() : null;
      console.log('>> saving signature', model);
      model && setSignature(model);
    },
    [ setSignature ]
  );
  const formMarkup = useMemo(
    () => (
      <SignatureForm
        ref={formRef}
        model={signatureData}
      />
    )
  );
  const handleClick = useCallback(
    () => {
      formDialog.current.show(
        true,
        {
          title: `Signatur bearbeiten`,
          body: formMarkup
        },
        handleSave
      );
    },
    [ formMarkup, handleSave ]
  );
  return (
    <div className={`signature-menu list-menu ${className}`}>
      <PrimaryButton id="editSignatureBtn" onClick={handleClick}>
        {`Signatur bearbeiten`}
      </PrimaryButton>
      <FormDialog
        ref={formDialog}
        style={{width: '760px'}}
        saveBtnName="saveSignatureBtn"
        cancelBtnName="cancelSignatureBtn"
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(SignatureMenu)
);
