import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadOrderAVISFile } from '../actions';
import { FormMenu } from '../../../components/Buttons';

const mapStateToProps = (state) => ({
  // orderWeeklyOverview: state.modem.modemOrderWeeklyOverview,
  orderWeeklyOverviewRegion: state.modem.modemOrderWeeklyOverviewRegion,
});
const mapDispatchToProps = (dispatch) => ({
  getOrderAvisFile: (...args) => dispatch(loadOrderAVISFile(...args)),
});

export class ModemOrderWeeklyOverviewTableMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    // orderWeeklyOverview: PropTypes.array,
    orderWeeklyOverviewRegion: PropTypes.array,
    getOrderAvisFile: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: '',
    actions: {}
  }
  getAvisFile = (order) => {
    this.props.getOrderAvisFile(order);
  }
  render() {
    const {
      className,
      // orderWeeklyOverview,
      orderWeeklyOverviewRegion,
      onCancel,
      disabled,
    } = this.props;
    const order = orderWeeklyOverviewRegion ? orderWeeklyOverviewRegion[0] : null;
    return (
      <div className={className}>
      <FormMenu
        className="w3-right-"
        saveBtnName="getAvisFileBtn"
        saveBtnLabel="AVIS-Datei"
        onSave={() => this.getAvisFile(order)}
        saveDisabled={disabled || !order || order.avis_path == null}
        saveHidden={disabled}
        cancelBtnName="closeModemOrderWeeklyOverview2"
        cancelBtnLabel="Zurück"
        onCancel={onCancel}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModemOrderWeeklyOverviewTableMenu);
