import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { SecondaryButton, PrimaryButton } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadSettings, saveSettings, hideSettings } from '../actions/form';
import EmailForm from '../components/forms/EmailForm';

const factory = ({ key, title }) => {
  return class SettingsDialog extends React.Component {
    static propTypes = {
      isVisible: PropTypes.bool,
      settings: PropTypes.shape({}),
      getSettings: PropTypes.func.isRequired,
      setSettings: PropTypes.func.isRequired,
      hideSettings: PropTypes.func.isRequired,
    }
    onHide = () => {
      this.props.hideSettings({ key });
    }
    onConfirmSave = () => {
      const data = this.form.getModel();
      if (data) {
        this.props.setSettings(
          { key, data },
          () => this.onHide()
        );
      }
    }
    render() {
      console.log('SettingsDialog.render()', this.props);
      const { isVisible, settings, disabled } = this.props;
      if (!isVisible || !settings) {
        return null;
      }
      // header
      const header = (
        <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
          <h3 className="no-margin">{title}</h3>
        </div>
      );
      // body
      const body = (
        <EmailForm
          ref={ref => this.form = ref}
          className="pad-big-btm"
          model={settings}
          disabled={disabled}
        />
      );
  
      // footer
      const okBtn = !disabled && (
        <PrimaryButton
          className="margin-lft"
          onClick={() => this.onConfirmSave()}
        >
          {'Speichern'}
        </PrimaryButton>
      );
      const cancelBtn = (
        <SecondaryButton
          className="margin-lft"
          onClick={() => this.onHide()}
        >
          {disabled ? "Schließen": 'Abbrechen'}
        </SecondaryButton>
      );
      const footer = (
        <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
          {okBtn}
          {cancelBtn}
        </div>
      );
      return (
        <div className="pos-rel">
          <Dialog
            header={header}
            footer={footer}
            iconsTemplate={null}
            visible
            style={{width: '700px'}}
            modal={true}
            onHide={this.onHide}>
            {body}
          </Dialog>
        </div>
      );
    }
  };
};


const mapDispatchToProps = (dispatch) => ({
  getSettings: (...args) => dispatch(loadSettings(...args)),
  setSettings: (...args) => dispatch(saveSettings(...args)),
  hideSettings: (...args) => dispatch(hideSettings(...args)),
});


// BookingSettingsDialog
const keyBooking = 'booking';
const frontloadBooking = async (props) => {
  await props.getSettings({ key: keyBooking });
};
const mapStateToPropsBooking = (state) => {
  return {
    isVisible: state.salesForm[`${keyBooking}IsVisible`],
    settings: state.salesForm[`${keyBooking}Settings`],
  };
};
export const BookingSettingsDialog = connect(mapStateToPropsBooking, mapDispatchToProps)(
  frontloadConnect(frontloadBooking, {
    onMount: true,
    onUpdate: false
  })(factory({ key: keyBooking, title: 'Buchungsanforderungen: Formulareinstellungen' }))
);


// MNVTipSettingsDialog
const keyMNVTip = 'mnvTip';
const frontloadMNVTip = async (props) => {
  await props.getSettings({ key: keyMNVTip });
};
const mapStateToPropsMNVTip = (state) => {
  return {
    isVisible: state.salesForm[`${keyMNVTip}IsVisible`],
    settings: state.salesForm[`${keyMNVTip}Settings`],
  };
};
export const MNVTipSettingsDialog = connect(mapStateToPropsMNVTip, mapDispatchToProps)(
  frontloadConnect(frontloadMNVTip, {
    onMount: true,
    onUpdate: false
  })(factory({ key: keyMNVTip, title: 'MNV-Tipp: Formulareinstellungen' }))
);
