import React from "react";
import LoggedInProfile from "./components/LoggedInProfile";
import { buildNav } from "../MainNav/components";
import ProfileIcon from "../../components/media/my-vodafone.svg"; //.png
import NotificationIcon from "../../components/media/notification.svg";
import SearchIcon from "../../components/media/search.svg";
import { Resources } from "../Permissions";

const publicLinks = [
  {
    to: "/start",
    icon: (
      <span className="icon">
        <img src={ProfileIcon} alt="Profil" />
      </span>
    ),
  },
];
export const PublicNav = buildNav("nav", publicLinks);

const searchIcon = (
  <span className="icon">
    <img src={SearchIcon} alt="Suche" />
  </span>
);

const profileIcon = (
  <span className="icon">
    <img src={ProfileIcon} alt="Profil" />
    <span className="indicator" />
  </span>
);

const profileLink = {
  as: LoggedInProfile,
  to: "#",
  icon: profileIcon,
  subMenu: {
    links: [
      {
        to: "/profil",
        label: "Profil öffnen",
        className: "vf-btn",
      },
      {
        to: "#",
        label: "Abmelden",
        onClick: "onLogout",
        className: "vf-btn vf-btn-alt",
      },
    ],
  },
};

const adminLinks = [
  {
    to: "/aufgaben",
    resource: Resources.Tasks.Page,
    render: (counterMap) => {
      const { taskCounter } = counterMap || {};
      let markup = (
        <span className="icon">
          <img src={NotificationIcon} alt="Aufgaben" />
        </span>
      );
      if (taskCounter && taskCounter > 0) {
        markup = (
          <div className="w3-cell-row">
            <div className="w3-cell">{markup}</div>
            <div className="w3-cell w3-text-green bold">{taskCounter}</div>
          </div>
        );
      }
      return markup;
    },
  },
  {
    to: "/search",
    icon: searchIcon,
  },
  profileLink,
];
export const AdminNav = buildNav("nav", adminLinks);

// const compLinks = [
//   {
//     to: "/search",
//     icon: searchIcon,
//   },
//   profileLink,
// ];
// export const CompanyNav = buildNav("nav", compLinks);

// const techLinks = [
//   // {
//   //   to: '#',
//   //   icon: (<span className="icon"><img src={NotificationIcon} alt="icon"/></span>)
//   // },
//   {
//     to: "/search",
//     icon: searchIcon,
//   },
//   profileLink,
// ];
// export const TechNav = buildNav("nav", techLinks);
