import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText, Label, Dropdown } from '../../../../components/Inputs';
// import RegionForm from './RegionForm';
// import AddressForm from './AddressForm';
import CompanyAddressForm from './CompanyAddressForm';
import PersonForm from './PersonForm';

function toTwoDigits(n) {
  return n > 9 ? "" + n: "0" + n;
}

function toThreeDigits(n) {
  if (n < 10) return '00' + n;
  else if (n < 100) return '0' + n;
  else return '' + n;
}

export default class DebitorForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    // debitorKey: PropTypes.string,
    regions: PropTypes.array,
    workers: PropTypes.array,
    contractors: PropTypes.array,
    isCompanySpecialKDL: PropTypes.bool,
    company: PropTypes.object,
    getCompany: PropTypes.func.isRequired
  }
  static defaultProps = {
    className: '',
    model: {
      region: {
        name: '',
        debitorKey: ''
      },
      name: '',
      debitorKey: '',
      company_id: '',
      // address: {
      //   street: '',
      //   houseNumber: '',
      //   areaCode: '',
      //   city: ''
      // },
      person: {
        id: '',
        firstname: '',
        lastname: '',
        cellNumber: '',
        telephoneNumber: '',
        email: ''
      },
      comment: ''
    },
    regions: [],
    users: [],
    workers: [],
    contractors: []
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model, regions, isCompanySpecialKDL } = props;
    let region = model.region ? model.region : '';
    if (regions.length === 1 && region === '') {
      region = regions[0].name;
    }
    let debitorKey = model.debitorKey || '';
    if (debitorKey === '') {
      console.log('>> Missing debitorKey => trying to fix ...');
      const currentRegion = regions.find(item => (item.name === region));
      if (currentRegion) {
        if (isCompanySpecialKDL) {
          debitorKey = 'wird generiert';
          // debitorKey = [
          //   'R' + region, 
          //   toThreeDigits(parseInt(currentRegion.suffix) + 1)
          // ].join('-');
        } else {
          debitorKey = [
            'R' + region, // toTwoDigits(parseInt(region)),
            currentRegion.debitorKey,
            toTwoDigits(parseInt(currentRegion.suffix) + 1)
          ].join('-');
        }
      }
    }
    return ({
      region: region,
      debitorKey: debitorKey,
      comment: model.comment || '',
      error: {
        region: null,
        debitorKey: null
      }
    });
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(error).forEach(key => {
      if (model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!noValidate && !isValid) {
      this.setState({ error });
      return null;
    }

    // person (optional)
    const person = this.personForm.getModel();
    if (person) {
      model.person = person;
    }
    // address (required)
    const addressModel = this.addressForm.getModel();
    if (!noValidate && !addressModel) {
      return null;
    }
    if (addressModel) {
      const { name, company_id, address } = addressModel;
      model.name = name;
      model.company_id = company_id;
      model.address = address;
    }
    const { id, index, status_id } = this.props.model;
    return { id, index, status_id, ...model };
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  onRegionChange = (value) => {
    const region = this.props.regions.find(item => (item.name === value));
    let debitorKey;
    if (this.props.isCompanySpecialKDL) {
      debitorKey = 'wird generiert';
    } else {
      debitorKey = [
        'R' + value,
        region.debitorKey,
        toTwoDigits(parseInt(region.suffix) + 1)
      ].join('-');
    }
    this.setState({ region: value, debitorKey });
  }
  render() {
    const {
      className,
      model: {
        company_id,
        name,
        address,
        person,
        inUse
      },
      regions,
      workers,
      contractors,
      company,
      getCompany,
      disabled,
    } = this.props
    const { region, debitorKey, comment } = this.state;
    const companyAddress = { company_id, name, address };
    const regionOptions = regions.map(item => ({ label: item.name, value: item.name }));
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row">
            <div className="w3-col s6 pad-lr pad-top">
              <Label htmlFor= "regionOptions">{`Region*`}</Label>
              <Dropdown
                id="regionOptions"
                className="w3-block w3-border"
                value={region}
                options={regionOptions}
                onChange={(event) => this.onRegionChange(event.value)}
                showClear
                disabled={disabled || inUse}
              />
            </div>
            <div className="w3-col s6 pad-lr pad-top">
              <Label htmlFor= "debitorKey">{`Kennung*`}</Label>
              <InputText
                id="debitorKey"
                className="w3-block w3-border"
                value={debitorKey}
                disabled={true}
                onChange={(event) => this.setState({debitorKey: event.target.value})}
              />
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-col s6 pad-lr pad-top">
              <h4 className="no-margin-">{`Lieferanschrift*`}</h4>
              <CompanyAddressForm
                ref={ref => this.addressForm = ref}
                model={companyAddress}
                isVertical
                namePrefix="debitor_"
                companies={contractors}
                company={company}
                getCompany={getCompany}
                disabled={disabled || inUse}
              />
            </div>
            <div className="w3-col s6 pad-lr pad-top">
              <h4 className="no-margin-">{`Ansprechpartner`}</h4>
              <PersonForm
                ref={ref => this.personForm = ref}
                model={person}
                workers={workers}
                disabled={disabled || inUse}
              />
            </div>
          </div>
        </div>
        <div className="pad-top">
          <Label htmlFor= "comment">{`Bemerkungen`}</Label>
          <InputTextarea
            id= "comment"
            className="w3-block w3-border"
            value={comment}
            rows={3}
            autoResize
            onChange={(event) => this.setState({comment: event.target.value})}
            disabled={disabled || inUse}
          />
        </div>
      </div>
    );
  }
}

