import React from "react";
import {
  PrimaryButtonLink,
  ButtonLink,
  PrimaryButton,
} from "../../../components/Buttons";
import {
  W3Dropdown,
  W3DropdownToggler,
  W3DropdownContent,
} from "../../../components/W3Dropdown";
import { PARTNER, PRODUCT, VPKN } from "../SalesType";
import { isKDL, createSortByName } from "../../../components/Utils";

export const ManagerSalesMenu = ({
  className,
  isVisible,
  isFullScreen,
  setFullScreen,
  disabled,
}) => {
  if (!isVisible) {
    return null;
  }
  const onSetFullScreen = (event) => {
    console.log("onSetFullScreen()", isFullScreen);
    if (event) event.preventDefault();
    console.log(" >> setting fulscreen to", !isFullScreen);
    setFullScreen({ isFullScreen: !isFullScreen });
  };
  return (
    <div className={`admin-sales-menu list-menu ${className}`}>
      <PrimaryButtonLink
        to={`/wizard/sales/absatz/neu`}
      >{`Absatz melden`}</PrimaryButtonLink>
      <W3Dropdown className="modems dropdown-list-menu margin-lft">
        <W3DropdownToggler as={PrimaryButton}>{`Optionen`}</W3DropdownToggler>
        <W3DropdownContent
          className="w3-bar-block w3-border"
          style={{ right: "0", width: "280px", zIndex: 20 }}
        >
          <ButtonLink
            className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
            to="#"
            onClick={onSetFullScreen}
          >
            {`Vollbildmodus ${isFullScreen ? "aus" : "ein"}schalten`}
          </ButtonLink>
        </W3DropdownContent>
      </W3Dropdown>
    </div>
  );
};

export const AdminSalesMenu = ({
  isFullScreen,
  setFullScreen,
  reportDisabled,
  importDisabled,
}) => {
  const onSetFullScreen = (event) => {
    console.log("onSetFullScreen()", !isFullScreen);
    if (event) event.preventDefault();
    setFullScreen({ isFullScreen: !isFullScreen });
  };
  return (
    <div className={`admin-sales-menu list-menu`}>
      {!reportDisabled && (
        <PrimaryButtonLink
          to={`/wizard/sales/absatz/neu`}
        >{`Absatz melden`}</PrimaryButtonLink>
      )}
      <W3Dropdown className="modems dropdown-list-menu margin-lft">
        <W3DropdownToggler as={PrimaryButton}>{`Optionen`}</W3DropdownToggler>
        <W3DropdownContent
          className="w3-bar-block w3-border"
          style={{ right: "0", width: "280px", zIndex: 20 }}
        >
          {!importDisabled && (
            <>
              <ButtonLink
                className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                to={`/wizard/sales/import/${PARTNER}`}
              >
                {`Absatzdaten Partner importieren`}
              </ButtonLink>
              <a href="#" className="w3-bar-item separator">
                <div className="w3-border-top" style={{ margin: "0 -4px" }} />
              </a>
              <ButtonLink
                className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                to={`/wizard/sales/import/${PRODUCT}`}
              >
                {`Produkte importieren`}
              </ButtonLink>
              <a href="#" className="w3-bar-item separator">
                <div className="w3-border-top" style={{ margin: "0 -4px" }} />
              </a>
              <ButtonLink
                className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                to={`/wizard/sales/import/${VPKN}`}
              >
                {`VPKN importieren`}
              </ButtonLink>
              <a href="#" className="w3-bar-item separator">
                <div className="w3-border-top" style={{ margin: "0 -4px" }} />
              </a>
            </>
          )}
          <ButtonLink
            className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
            to="#"
            onClick={onSetFullScreen}
          >
            {`Vollbildmodus ${isFullScreen ? "aus" : "ein"}schalten`}
          </ButtonLink>
        </W3DropdownContent>
      </W3Dropdown>
    </div>
  );
};

export const getSalesKDLs = (user, companies) => {
  const kdlCompanies = companies
    .filter((item) => isKDL(item))
    .sort(createSortByName());
  const userKdlCompanies = user.companies.filter((company) => {
    // let result = false;
    const extendedRolesData = company.extendedRolesData || [];
    for (let index = 0; index < extendedRolesData.length; index++) {
      const role = extendedRolesData[index].label.toLowerCase();
      if (role.indexOf("sale") >= 0) {
        return true;
      }
    }
    return false;
  });
  console.log(
    ">> KdlCompanies:",
    kdlCompanies,
    "\n>> userCompanies",
    user.companies,
    "\n>> userKdlCompanies",
    userKdlCompanies
  );
  // merge
  userKdlCompanies.forEach((company) => {
    const found = kdlCompanies.find((item) => item.id === company.id);
    if (!found) kdlCompanies.push({ name: company.contractGiver });
  });
  return kdlCompanies;
};

export const DEFAULT_PRODUCT = {
  productName: "",
  //  productShortname: '',
  productCode: "",
  productGroup: "",
  productTruecomp: "",
  productNpv: "",
  productProvision: "",
  startDate: null,
  status: 1,
};

export const ProductsMenu = ({ className, isVisible = true, disabled }) => {
  if (disabled || !isVisible) {
    return null;
  }
  return (
    <div className={`products-menu list-menu ${className}`}>
      {/* <PrimaryButtonLink to={`/wizard/sales/produkte/neu`}>{`Produkt hinzufügen`}</PrimaryButtonLink> */}
      <PrimaryButtonLink
        className="margin-lft"
        to={`/wizard/sales/import/${PRODUCT}`}
      >
        {`Produkte importieren`}
      </PrimaryButtonLink>
    </div>
  );
};
