import React from "react";
import { STATUS_IDS } from "../../../components/Utils";
import { DeviceFormMenu } from "../../../components/Buttons";

export default class UserDeviceFormMenu extends React.Component {
  constructor(props) {
    super(props);
    const userDevice = props.userDevice || {};
    this.state = {
      isMacAddressSet: userDevice.macAddress && userDevice.macAddress !== "",
      isMacAddressChanged: false,
    };
  }
  onMacAddressChanged = (value) => {
    const isMacAddressSet = value !== "";
    const oldUserDevice = this.props.userDevice || {};
    const isOldMacAddressSet =
      oldUserDevice.macAddress && oldUserDevice.macAddress !== "";
    this.setState({
      isMacAddressSet,
      isMacAddressChanged:
        isMacAddressSet &&
        value !== oldUserDevice.macAddress &&
        isOldMacAddressSet,
    });
  };
  render() {
    const { userDevice, disabled, isTask, isUserTask, ...rest } = this.props;
    const { isMacAddressSet, isMacAddressChanged } = this.state;
    if (!userDevice) return null;

    const isSaveable =
      !isTask &&
      (userDevice.status_id === 0 ||
        userDevice.status_id === STATUS_IDS.FINISHED) &&
      isMacAddressSet &&
      (!userDevice.account || userDevice.account === "");
    const isUpdateable =
      isUserTask ||
      (!isTask &&
        userDevice.status_id === STATUS_IDS.FINISHED &&
        isMacAddressSet &&
        isMacAddressChanged &&
        userDevice.account &&
        userDevice.account !== "");
    const isChangeable =
      isTask ||
      userDevice.status_id === 0 ||
      userDevice.status_id === STATUS_IDS.FINISHED;
    const isDeleteable =
      !isTask &&
      userDevice.status_id === STATUS_IDS.FINISHED &&
      isMacAddressSet &&
      userDevice.account &&
      userDevice.account !== "";

    return (
      <DeviceFormMenu
        {...rest}
        saveHidden={disabled || !isSaveable}
        updateHidden={disabled || !isUpdateable}
        changeHidden={disabled || !isChangeable}
        deleteHidden={disabled || !isDeleteable}
      />
    );
  }
}
