import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "primereact/checkbox";
import { createSortByAttr } from "../../../../components/Utils";

export default class TechForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    techKeys: PropTypes.array,
    minimal: PropTypes.bool,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: [],
    techKeys: [],
  };
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      model: model ? model.map((item) => item.id) : [],
    };
  }
  validate = () => {
    const { techKeys } = this.props;
    const { model } = this.state;
    return model.map((id) => {
      const item = techKeys.find((item) => item.id === id);
      return { id, number: item ? item.number : null };
    });
  };
  getModel = () => {
    const model = this.validate();
    return model;
  };
  onCheckboxChange = (event) => {
    let model = [...this.state.model];
    if (event.checked) model.push(event.value);
    else model.splice(model.indexOf(event.value), 1);
    this.setState({ model });
  };
  getColumn = (items, number) => {
    const col = [];
    let size = Math.floor(items.length / 3);
    if (items.length - size * 3 > 1) {
      size++;
    }
    let startIndex = 0;
    let endIndex = size - 1;
    if (number === 1 || number === 2 || number === 3) {
      startIndex = (number - 1) * size;
      endIndex = startIndex + size - 1;
      if (number === 3) {
        endIndex = items.length - 1;
      }
    }
    items.forEach((item, index) => {
      if (index >= startIndex && index <= endIndex) {
        col.push(item);
      }
    });
    return col;
  };
  render() {
    const { className, disabled } = this.props;
    const { model } = this.state;
    const techKeys = this.props.techKeys.sort(createSortByAttr("number"));
    const createCheckbox = (prefix) => (item, index) =>
      (
        <div key={index} className="p-col-12" id={item.number.split(" ").join("")}>
          <Checkbox
            inputId={`${prefix}${index}`}
            value={item.id}
            onChange={this.onCheckboxChange}
            checked={model.includes(item.id)}
            disabled={disabled}
          ></Checkbox>
          <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">
            {item.number}
          </label>
        </div>
      );
    const col1 = this.getColumn(techKeys, 1).map(createCheckbox("cb1-"));
    const col2 = this.getColumn(techKeys, 2).map(createCheckbox("cb2-"));
    const col3 = this.getColumn(techKeys, 3).map(createCheckbox("cb3-"));
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row">
            <div className="w3-col s4 pad-lr">{col1}</div>
            <div className="w3-col s4 pad-lr">{col2}</div>
            <div className="w3-col s4 pad-lr">{col3}</div>
          </div>
        </div>
      </div>
    );
  }
}
