import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import isObject from "lodash/isObject";
import { Dropdown } from "primereact/dropdown";
import { PARTNER, PRODUCT, VPKN } from "../modules/Reporting/SalesType";
import { ConnectionTypes } from "../modules/Task";
// import { last, rest } from 'lodash';
import { PlusIcon, MinusIcon } from "./Buttons";
moment.locale("de");

export const COMPANY_STATUS_IDS = {
  // ACTIVATION_PENDING: 1,
  ACTIVATED: 2,
  // DELETED: 3,
  // DELETION_PENDING: 4,
  DEACTIVATED: 6,
};

export const COMPANY_STATUS_LABELS = {
  // '1': 'in Aktivierung',
  2: "Aktiviert",
  // '3': 'Gelöscht',
  // '4': 'in Löschung',
  6: "Deaktiviert",
};

export const COMPANY_STATUS_ACTIONS = {
  2: "aktivieren",
  6: "deaktivieren",
};

export const isCompanyActivated = (company) => {
  return company && company.status_id === COMPANY_STATUS_IDS.ACTIVATED;
};

export const isCompanyDeactivated = (company) => {
  return company && company.status_id === COMPANY_STATUS_IDS.DEACTIVATED;
};

export const companyHasEmployees = (company) => {
  return (
    company &&
    (company.totalEmployees !== undefined ? company.totalEmployees > 0 : true)
  );
};

export const companyHasActiveEmployees = (company) => {
  return (
    company &&
    (company.activeEmployees !== undefined ? company.activeEmployees > 0 : true)
  );
};

export const getCompanyDeleteText = (company, isContractor) => {
  console.log("getCompanyDeleteText()", company);
  const object = isContractor ? "das Subunternehmen" : "den Servicepartner";
  let text = `Wollen Sie ${object} ${company.name} wirklich löschen?`;
  let activeContractors = 0;
  let showGeneralContractorText = false;
  if (!isContractor) {
    if (company.contractors && Array.isArray(company.contractors)) {
      // find active contractors
      company.contractors.forEach((contractor) => {
        if (isCompanyActivated(contractor)) {
          activeContractors++;
        }
      });
    } else {
      showGeneralContractorText = true;
    }
  }
  const contractorText =
    activeContractors > 0
      ? `${company.contractors.length} Subunternehmen und`
      : "";
  if (company.totalEmployees > 0) {
    const plural = company.totalEmployees > 1;
    const generalContractorText = !showGeneralContractorText
      ? ""
      : ` Beachten Sie weiterhin, dass alle seine Subuntermen mitgelöscht werden.`;
    text = `${isContractor ? "Das Subunternehmen" : "Der Servicepartner"} ${
      company.name
    } hat 
      ${contractorText}${company.totalEmployees} Mitarbeiter, ${
      plural ? "die" : "der"
    } mitgelöscht 
      ${
        plural ? "werden" : "wird"
      }.${generalContractorText} Wollen Sie ${object} wirklich löschen?`;
  } else if (!isContractor) {
    const generalContractorText = !showGeneralContractorText
      ? ""
      : `Beachten Sie, dass alle Subunternehmen des Servicepartners ${company.name} mitgelöscht werden.`;
    text = `${generalContractorText} Wollen Sie ${object} wirklich löschen?`;
  }
  return text;
};

export const ACTION_IDS = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
};

export const ACTION_LABELS = {
  1: "Neuanlage",
  2: "Veränderung",
  3: "Löschung",
};

export const USER_ACTION_IDS = {
  ...ACTION_IDS,
  DEACTIVATE: 6,
};

export const USER_STATUS_IDS = {
  APPROVAL_PENDING: 1,
  APPROVED: 2,
  DELETED: 3,
  DELETION_PENDING: 4,
  DRAFT: 5,
  DEACTIVATED: 6,
  DEACTIVATION_PENDING: 7,
};

export const USER_STATUS_LABELS = {
  1: "Neu",
  2: "Aktiviert",
  3: "Gelöscht",
  4: "in Löschung",
  5: "in Veränderung",
  6: "Deaktiviert",
  7: "wird deaktiviert",
};

export const isUserActivated = (user) => {
  return user && user.status_id === USER_STATUS_IDS.APPROVED;
};

export const STATUS_IDS = {
  NEW: 1,
  PROCESSING: 2,
  FINISHED: 3,
  DELETED: 4,
  UPDATING: 5,
  DELETING: 6,
  DEACTIVATED: 7,
};

export const STATUS_LABELS = {
  1: "Neu",
  2: "in Bearbeitung",
  3: "Abgeschlossen",
  4: "Gelöscht",
  5: "in Veränderung",
  6: "in Löschung",
};

export const statusIsEditable = (status_id, itemId, isNewEditable) => {
  // console.log('statusIsEditable()', status_id, itemId);
  const list = [STATUS_IDS.FINISHED];
  // if item id is given it must be set
  // also, if isNewEditable flag is set to true
  if (isNewEditable || (itemId !== undefined && itemId === "")) {
    list.push(STATUS_IDS.NEW);
  }
  // list.push(STATUS_IDS.NEW);
  return list.includes(status_id);
};

export const DEVICE_STATUS_IDS = {
  ...STATUS_IDS,
  DELETING_ACCOUNT: 7,
  VALIDATING: 8,
};

export const DEVICE_STATUS_LABELS = {
  ...STATUS_LABELS,
  7: "Account in Löschung",
  8: "in Prüfung",
};

export const isDeviceStatusEditable = (status_id, itemId) => {
  const list = [0, DEVICE_STATUS_IDS.FINISHED];
  return list.includes(status_id);
};

export const itemIsTask = (status_id) => {
  const list = [
    STATUS_IDS.NEW,
    STATUS_IDS.PROCESSING,
    STATUS_IDS.UPDATING,
    STATUS_IDS.DELETING,
  ];
  return list.includes(status_id);
};

export const DEVICE_ACTION_IDS = {
  ...ACTION_IDS,
  DELETE_ACCOUNT: 4,
  CHANGE_SUBCOMPANY: 5,
};

export const DEVICE_ACTION_LABELS = {
  ...ACTION_LABELS,
  4: "Accountlöschung",
};

export const USER_STATUS_OPTIONS = [
  { label: "aktiviert", value: "1" },
  { label: "deaktiviert", value: "2" },
];

export const UserStatusFilter = ({
  className,
  placeholder = "",
  value,
  onChange,
}) => (
  <Dropdown
    className={className}
    placeholder={placeholder}
    value={value}
    options={USER_STATUS_OPTIONS.map((item) => ({
      label: item.label,
      value: item.label,
    }))}
    onChange={(event) => onChange && onChange(event.value)}
    showClear
  />
);

export const USER_CONTACT_OPTIONS = [
  { label: "per SMS", value: "cell_number" },
  { label: "per Mail", value: "email" },
];
export const getUserContactOptionLabel = (value) => {
  let result = "";
  const item = USER_CONTACT_OPTIONS.find((option) => option.value === value);
  if (item) {
    result = item.label;
  }
  return result;
};

export const DEBITOR_STATUS_IDS = {
  NEW: 1,
  CONFIRMED: 2,
  CHANGED: 3,
  CLOSED: 4,
};

export const DEBITOR_STATUS_LABELS = {
  1: "Neu",
  2: "in Bearbeitung",
  3: "Bestätigt",
  4: "Gelöscht",
  5: "in Veränderung",
  6: "in Löschung",
};

export const MODEM_ORDER_STATUS_IDS = {
  NEW: 1,
  PROCESSING: 2,
  SENT: 3,
  CANCELED: 4,
  CLOSED: 5,
  AVIS_PROCESSING: 6,
};

export const MODEM_ORDER_STATUS_LABELS = {
  1: "Neu",
  2: "In Bearbeitung",
  3: "Versendet",
  4: "Stoniert",
  5: "Abgeschlossen",
  6: "Avis in Bearbeitung",
};

export const PRODUCT_TYPE_IDS = {
  FLYER: 1,
  CARD: 2,
  CLOTH: 3,
  KEY: 4,
};

export const PRODUCT_STATUS_IDS = {
  ACTIVATED: 1,
  DEACTIVATED: 2,
  DELETED: 3,
};

export const PRODUCT_STATUS_LABELS = {
  1: "Aktiviert",
  2: "Deaktiviert",
  3: "Gelöscht",
};

export const PRODUCT_ORDER_STATUS_IDS = {
  NEW: 1,
  PROCESSING: 2,
  SENT: 3,
  // CANCELED: 4,
  FINISHED: 5,
  AWAITING_CONFIRMATION: 6,
};

export const PRODUCT_ORDER_STATUS_LABELS = {
  1: "Offen",
  2: "In Bearbeitung",
  3: "Versendet",
  // '4': 'Stoniert',
  5: "Abgeschlossen",
  6: "Rückmeldung offen",
};

export const isProductActivated = (product) => {
  return product && product.status_id === PRODUCT_STATUS_IDS.ACTIVATED;
};

export const isProductDeactivated = (product) => {
  return product && product.status_id === PRODUCT_STATUS_IDS.DEACTIVATED;
};

export const isProductDeleted = (product) => {
  return product && product.status_id === PRODUCT_STATUS_IDS.DELETED;
};

export const getActivationText = (type, product, deactivation) => {
  const action = deactivation ? "Deaktivierung" : "Aktivierung";
  const part = deactivation ? "nicht mehr" : "wieder";
  const verb = deactivation ? "deaktivieren" : "aktivieren";
  return (
    <>
      <div>{`Durch die ${action} kann ein Produkt ${part} bestellt werden.`}</div>
      <div>{`Wollen Sie ${type} ${product} wirklich ${verb}?`}</div>
    </>
  );
};

export const getProductStatusChangeConfirmationText = (
  actionId,
  type,
  product
) => {
  let action = null;
  let part = null;
  let verb = null;
  switch (actionId) {
    case 1: // activate
      action = "Aktivierung";
      part = "wieder";
      verb = "aktivieren";
      break;
    case 2: // deactivate
      action = "Deaktivierung";
      part = "nicht mehr";
      verb = "deaktivieren";
      break;
    case 3: // delete
      action = "Löschung";
      part = "nicht mehr";
      verb = "löschen";
      break;
    default:
      break;
  }
  if (action && part && verb) {
    return (
      <>
        <div>{`Durch die ${action} kann ein Produkt ${part} bestellt werden.`}</div>
        <div>{`Wollen Sie ${type} ${product} wirklich ${verb}?`}</div>
      </>
    );
  }
  return null;
};

export const getModemOrderMoveConfirmationText = (selected) => {
  const items = selected.map((item, index) => (
    <div key={index} className="w3-col s6 pad-lr pad-top">
      <div className="pad w3-light-grey">{item}</div>
    </div>
  ));
  return (
    <>
      <div>{`Die folgende Bestellungen sollen in eine andere Woche verschoben werden:`}</div>
      <div className="w3-row neg-margin-lr">{items}</div>
      <div className="pad-top">{`Geben Sie bitte die Zielwoche um fortfahren zu können.`}</div>
    </>
  );
};

export const EMPLOYEE_TASKTYPE_IDS = {
  EMAIL_CHANGE: 1,
  CELLNUMBER_CHANGE: 2,
  TELEPHONENUMBER_CHANGE: 3,
};

export const EMPLOYEE_TASKTYPE_LABELS = {
  1: "E-Mail",
  2: "Telefon (mobil)",
  3: "Telefon (dienstlich)",
};

// notification
let Notifier = null;
export const initNotifier = (ref) => (Notifier = ref);
export const getNotifier = () => Notifier;
export function notifySuccess({ summary, detail, props = { life: 5000 } }) {
  Notifier && Notifier.show({ severity: "success", summary, detail, ...props });
}
export function notifyInfo({ summary, detail, props = { life: 5000 } }) {
  Notifier && Notifier.show({ severity: "info", summary, detail, ...props });
}
export function notifyWarn({ summary, detail, props = { life: 5000 } }) {
  Notifier && Notifier.show({ severity: "warn", summary, detail, ...props });
}
export function notifyError({ summary, detail, props = { life: 8000 } }) {
  Notifier && Notifier.show({ severity: "error", summary, detail, ...props });
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
};

export function getUserRoles(user, extended) {
  let roles = [];
  if (!user) return roles;
  const found = extended ? user.extendedRoles : user.roles;
  if (found) {
    roles = roles.concat(found);
  }
  if (user.companies && user.companies.length > 0) {
    user.companies.forEach((company) => {
      if (
        extended &&
        company.extendedRoles &&
        company.extendedRoles.length > 0
      ) {
        roles = roles.concat(company.extendedRoles);
      } else if (company.role && company.role !== "") {
        roles.push(company.role);
      }
    });
  } else if (user.roles !== undefined && !extended) {
    roles = roles.concat(user.roles);
  }
  return roles;
}

function checkUserRoles(user, value, extended) {
  const roles = getUserRoles(user, extended);
  if (!roles) return false;
  // extended && console.log('extendedRoles', roles);
  const list = roles.map((item) =>
    (isObject(item) ? item.name : item).toLowerCase()
  );
  return list.includes(value.toLowerCase());
}

export const isAdmin = (user) =>
  user && checkUserRoles(user, "VF_Administrator");
export const isCEO = (user) =>
  user && checkUserRoles(user, "SP_Geschäftsführer");
export const isWorkerPlus = (user) =>
  user &&
  (checkUserRoles(user, "SP_Kapazitätsmanager") ||
    checkUserRoles(user, "SP_Geschäftsführer"));
export const isWorker = (user) =>
  user && checkUserRoles(user, "Dokumente lesen");

export const isDeviceManager = (user) =>
  user && checkUserRoles(user, "SP_Messgeräteverantwortlicher", true);
export const isLogisticsAssistant = (user) =>
  user && checkUserRoles(user, "SP_Modemlogistik", true);
export const isLogisticsCards = (user) =>
  user && checkUserRoles(user, "SP_Karten Flyer", true);
export const isLogisticsKeys = (user) =>
  user && checkUserRoles(user, "SP_Schlüsselverantwortlicher", true);

export const isSalesAdmin = (user) =>
  user && checkUserRoles(user, "VF_Sales Admin", true);
export const isSalesOperator = (user) =>
  user && checkUserRoles(user, "VF_Sales Operator", true);
export const isSalesAgent = (user) =>
  user && checkUserRoles(user, "SP_Sales Agent", true);
export const isSalesManager = (user) =>
  user && checkUserRoles(user, "SP_Sales Verantwortlicher", true);

export const isNewsAssistant = (user) =>
  user && checkUserRoles(user, "Newsass", true);
export const isDisponent = (user) =>
  user && checkUserRoles(user, "Disponent", true);
export const isLS4 = (user) =>
  user && checkUserRoles(user, "Leistungsschein 4", true);
export const isTechnician = (user) =>
  user && checkUserRoles(user, "Techniker", true);

export const createUserFlags = (user) => {
  return {
    isAdmin: isAdmin(user),
    isCEO: isAdmin(user) || isCEO(user),
    isWorkerPlus: isAdmin(user) || isWorkerPlus(user),
    isWorker: isAdmin(user) || isWorkerPlus(user) || isWorker(user),
    isUserTaskManager: isAdmin(user) || isCEO(user) || isWorkerPlus(user),
    isProfileChangeManager:
      isAdmin(user) /*  || (isWorkerPlus(user) && !isCEO(user)) */,
    isFailedCompanyEmailManager: isAdmin(user) || isCEO(user),
    isFailedUserEmailManager:
      isAdmin(user) || (isWorkerPlus(user) && !isCEO(user)),

    isCompanyProfileViewer: isAdmin(user) || isCEO(user) || isWorkerPlus(user),
    isWorkerLogisticsAssistant: isWorker(user) && isLogisticsAssistant(user),
    isCompanyProfileEditor:
      isAdmin(user) ||
      isCEO(user) ||
      isWorkerPlus(user) ||
      (isWorker(user) && isLogisticsAssistant(user)),
    isLogisticsAssistant: isAdmin(user) || isLogisticsAssistant(user),
    isLogisticsCards: isAdmin(user) || isLogisticsCards(user),
    isLogisticsKeys: isAdmin(user) || isLogisticsKeys(user),
    isShopViewer:
      isAdmin(user) ||
      isLogisticsAssistant(user) ||
      isLogisticsCards(user) ||
      isLogisticsKeys(user),

    isNewsAssistant: isAdmin(user) || isNewsAssistant(user),
    isDeviceManager: isAdmin(user) || isDeviceManager(user),
    isDebitorEditorOnly: isWorker(user) && isLogisticsAssistant(user),

    isSalesAdmin: isAdmin(user) || isSalesAdmin(user),
    isSalesOperator: isAdmin(user) || isSalesOperator(user),
    isSalesManager: isAdmin(user) || isSalesManager(user),
    isSalesViewer:
      isAdmin(user) ||
      isSalesOperator(user) ||
      isSalesAdmin(user) ||
      isSalesAgent(user) ||
      isSalesManager(user) ||
      isCEO(user) ||
      isLS4(user),
    isSalesReporter:
      isAdmin(user) ||
      isSalesOperator(user) ||
      isSalesAdmin(user) ||
      isSalesAgent(user) ||
      isSalesManager(user),
    isSalesReporterOnly:
      isSalesAgent(user) || isSalesManager(user) || isSalesOperator(user),
    isSalesImporter: isAdmin(user) || isSalesAdmin(user),
    isSalesGuruOnly: isAdmin(user) || isSalesAdmin(user),
    isSalesReportEditor:
      isAdmin(user) || isSalesAdmin(user) || isSalesOperator(user),

    isSalesAchievementsViewer:
      isAdmin(user) ||
      isSalesOperator(user) ||
      isSalesAdmin(user) ||
      isTechnician(user) ||
      isDisponent(user) ||
      isSalesAgent(user) ||
      isSalesManager(user) ||
      isCEO(user),
    isSalesSucessViewer:
      isAdmin(user) || isTechnician(user) || isDisponent(user),
    isSalesWorkerSalesViewer:
      isAdmin(user) ||
      isSalesManager(user) ||
      isSalesOperator(user) ||
      isSalesAdmin(user),
    isSalesConversionReportViewer:
      isAdmin(user) ||
      isSalesManager(user) ||
      isCEO(user) ||
      isSalesOperator(user) ||
      isSalesAdmin(user),

    isSalesBookingViewer:
      isAdmin(user) ||
      isCEO(user) ||
      isLS4(user) ||
      isSalesAgent(user) ||
      isSalesManager(user) ||
      isSalesOperator(user) ||
      isSalesAdmin(user),
    isSalesMNVTipViewer:
      isAdmin(user) ||
      isCEO(user) ||
      isLS4(user) ||
      isSalesAgent(user) ||
      isSalesManager(user) ||
      isSalesOperator(user) ||
      isSalesAdmin(user),
  };
};

export const COMPANY_TYPE_LABELS = {
  1: "Masterfirma",
  2: "KDL",
  3: "DL",
  4: "Firma",
};

export const isMasterCompany = (company) => {
  // console.log('isMasterCompany()', company);
  return company && company.type_id != null && company.type_id === 1; //`${company.type_id}` === '1';
};

export const isKDL = (company) => {
  // console.log('isKDL()', company);
  return company && company.type_id != null && company.type_id === 2; //`${company.type_id}` === '2';
};

export const isSpecialKDL = (company) => {
  // console.log('isSpecialKDL()', company);
  return company && company.type_id != null && company.type_id === 3; //`${company.type_id}` === '3';
};

export const isContractor = (company) => {
  // console.log('isContractor()', company);
  return company && company.type_id != null && company.type_id === 4; //`${company.type_id}` === '4';
};

export const isMasterOrDL = (company) => {
  return isMasterCompany(company) || isSpecialKDL(company);
};

export const isCompanyOfSalesReporter = (company) =>
  isSalesAgent(company) ||
  isSalesManager(company) ||
  isSalesOperator(company) ||
  isSalesAdmin(company);

export const SALES_WEBORDER_STATE = {
  FINISHED: "abgeschlossen",
  ABORTED: "eingestellt",
  MANUAL: "manuell",
  DELAYED: "verzögert",
  ERROR: "Fehler",
  CHECK: "prüfen",
  OPEN: "offen",
  NEW: "Neu",
  WAIT: "warten",
};

export const SALES_WORKORDER_STATE = {
  OPEN: "offen",
  FINISHED: "abgeschlossen",
  DENIED: "abgelehnt",
};

export const SALES_FINAL_STATE = {
  NETTO: "ist Netto",
  CANCELED: "Widerruf/Storno",
};

export const PageTitle = ({ noMarginTop, children }) => (
  <div
    className={`${
      noMarginTop ? "" : "margin-big-top"
    } pad-big w3-border no-border-btm bg-primary border-primary`}
  >
    <div className="clearfix">{children}</div>
  </div>
);

export const IDTag = ({ id, isUnterWizardTitle, isUnderFormTitle }) => {
  // const offset = -90 - (isUnterWizardTitle ? 72 : 0);
  let offset = -90;
  if (isUnterWizardTitle) {
    offset -= 72;
  } else if (isUnderFormTitle) {
    offset -= 97;
  }
  return <div id={id} style={{ position: "absolute", top: `${offset}px` }} />;
};

export const PageHeight100 = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props} style={{ minHeight: "100vh" }}>
      {children}
    </div>
  </div>
);
export const PageHeight80 = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props} style={{ minHeight: "80vh" }}>
      {children}
    </div>
  </div>
);
export const PageHeight60 = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props} style={{ minHeight: "60vh" }}>
      {children}
    </div>
  </div>
);
export const PageHeight40 = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props} style={{ minHeight: "40vh" }}>
      {children}
    </div>
  </div>
);
export const PageHeight30 = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props} style={{ minHeight: "30vh" }}>
      {children}
    </div>
  </div>
);
export const PageHeight20 = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props} style={{ minHeight: "20vh" }}>
      {children}
    </div>
  </div>
);
export const PageHeightAuto = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props}>
      {children}
    </div>
  </div>
);
export const PageHeightCustom = ({
  className,
  id,
  isUnterWizardTitle,
  isUnderFormTitle,
  minHeight,
  children,
  ...props
}) => (
  <div style={{ position: "relative" }}>
    {id && (
      <IDTag
        id={id}
        isUnterWizardTitle={isUnterWizardTitle}
        isUnderFormTitle={isUnderFormTitle}
      />
    )}
    <div className={className} {...props} style={{ minHeight }}>
      {children}
    </div>
  </div>
);

export const addressToString = (address) => {
  if (!address) return "";
  return `${address.street} ${address.houseNumber || address.house_number}, ${
    address.areaCode || address.area_code
  } ${address.city}`;
};

export const SimpleAddress = ({ name, address = {} }) => {
  return (
    <div>
      {name && <div className="pad-sm-top">{`${name}`}</div>}
      <div className="pad-sm-top">{`${address.street} ${
        address.houseNumber ||
        address.housenumber ||
        address.house_number ||
        address.house ||
        ""
      }`}</div>
      <div className="pad-sm-top">{`${
        address.areaCode || address.areacode || address.area_code || ""
      } ${address.city || ""}`}</div>
    </div>
  );
};

export const ImagePane = ({ image, children }) => (
  <div className="w3-row">
    <div className="w3-col" style={{ width: "150px" }}>
      {image}
    </div>
    <div className="w3-rest">{children}</div>
  </div>
);

export const DownloadPanel = ({ links, pageCreated }) => {
  const markup = links.map((link, index) => {
    const className = index % 2 === 0 ? "w3-light-grey" : "";
    const date = link.date || pageCreated;
    return (
      <div key={index} className={`w3-row ${className}`}>
        <div className="w3-col s9 pad">
          <b>{link.title}</b> | {date || ""}
        </div>
        <div className="w3-col s3 pad">
          <span className="pad-rht">
            <a
              href={link.path.replace("/api/", "/data-api/")}
              className="font-primary"
              download
            >{`Download`}</a>
          </span>
          <span className="w3-small">{`(${link.type}, ${link.size})`}</span>
        </div>
      </div>
    );
  });
  return <div className="download-panel pad-big-tb">{markup}</div>;
};

// export const Panel = ({ className = 'w3-border margin-big-top pad-big', id, title, subtitle, children }) => (
//   <div className={className} style={{position: 'relative'}}>
//     {id && (<IDTag id={id}/>)}
//     <h4 className="bg-secondary pad-lr pad-sm-tb">{title}</h4>
//     {subtitle && (<div>{subtitle}</div>)}
//     <div>{children}</div>
//   </div>
// );

export class Panel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.node,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    notToggleable: PropTypes.bool,
    children: PropTypes.node,
  };
  static defaultProps = {
    className: "w3-border margin-big-top pad-big",
  };
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  toggle = () => {
    // console.log('>> Panel.toggle(): isOpen from', this.state.isOpen, 'to', !this.state.isOpen);
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    // console.log('Panel.render()', this.state, this.props);
    const { className, id, title, subtitle, notToggleable, children } =
      this.props;
    let Icon = null;
    if (!notToggleable) {
      Icon = this.state.isOpen ? MinusIcon : PlusIcon;
    }
    return (
      <div className={className} style={{ position: "relative" }}>
        {id && <IDTag id={id} />}
        <div
          className={`bg-secondary pad-lr pad-tb${
            notToggleable ? "" : " clickable"
          }`}
          onClick={notToggleable ? null : () => this.toggle()}
        >
          {Icon && (
            <Icon
              className="w3-right w3-white"
              style={{ position: "relative", top: "3px", right: "2px" }}
            />
          )}
          <h4 className="no-margin">{title}</h4>
        </div>
        <div className={`w3-${this.state.isOpen ? "show" : "hide"}`}>
          {subtitle && <div>{subtitle}</div>}
          <div>{children}</div>
        </div>
      </div>
    );
  }
}

function getTitle(type, model, isNew, isTask) {
  switch (type) {
    case "contact": {
      return "Kontakt";
    }
    case "company": {
      if (model && model.name !== "") {
        return model.name;
      }
      // return 'Neue Firma';
      return "Neuer Servicepartner";
    }
    case "device": {
      if (model && model.name !== "") {
        return `Referenz-Messgerät: ${model.name}`;
      }
      return "Neues Referenz-Messgerät";
    }
    case "userDevice": {
      let prefix = "";
      if (model && model.device !== "") {
        return `Messgerät ${model.device}: Seriennummer ${model.serialNumber}`;
      }
      return `Neues Messgerät${prefix}`;
    }
    case "userDevice-import":
      return `Messgeräte importieren`;
    case "modem": {
      if (model && model.name !== "") {
        return `Modem: ${model.name}`;
      }
      return "Neues Modem";
    }
    case "modemOrder": {
      const prefix = isTask ? "Aufgabenbereich - " : "";
      if (model && model.number) {
        return `${prefix}Modembestellung: ${model.number}`;
      }
      return "Neue Modembestellung";
    }
    case `connection-import-${ConnectionTypes.IT_CONNECTION.toLowerCase()}`:
      return "IT-Zugänge importieren";
    case `connection-import-${ConnectionTypes.PT_CONNECTION.toLowerCase()}`:
      return "PT-Zugänge importieren";
    case `connection-import-${ConnectionTypes.AD_CONNECTION.toLowerCase()}`:
      return "AD-Zugänge importieren";
    case `connection-import-${ConnectionTypes.DELPHI_CONNECTION.toLowerCase()}`:
      return "Delphi-Zugänge importieren";
    case "sale":
      return "Absatz melden";
    case `sale-import-${PARTNER}`: {
      return "Absatzdaten Partner importieren";
    }
    case `sale-import-${PRODUCT}`: {
      return "Produkte importieren";
    }
    case `sale-import-${VPKN}`: {
      return "VPKN importieren";
    }
    case "worker": {
      let prefix = "Neues Mitarbeiterprofil";
      if (model.id) {
        prefix = "Mitarbeiterprofil";
      }
      if (model.firstname !== "" && model.lastname !== "") {
        return `${prefix}: ${model.firstname} ${model.lastname}`;
      }
      return prefix;
    }
    case "card":
      if (model && model.id) {
        return `Karte/Flyer: ${model.name}`;
      }
      return "Neue(s) Karte/Flyer";
    case "cardOrder": {
      const prefix = isTask ? "Aufgabenbereich - " : "";
      if (!isNew) {
        const id = ` ${model && model.orderNumber ? model.orderNumber : ""}`;
        return `${prefix}Karten/Flyer-Bestellung${id} bearbeiten`;
      }
      return "Neue Karten/Flyer-Bestellung";
    }
    case "key":
      if (model && model.id) {
        return `Schließmittel: ${model.name}`;
      }
      return "Neues Schließmittel";
    case "keyOrder": {
      const prefix = isTask ? "Aufgabenbereich - " : "";
      if (!isNew) {
        const id = ` ${model && model.orderNumber ? model.orderNumber : ""}`;
        return `${prefix}Schließmittelbestellung${id} bearbeiten`;
      }
      return "Neue Schließmittelbestellung";
    }
    case `sales-product`: {
      if (model && model.id) {
        return `Produkt: ${model.product_name}`;
      }
      return "Neues Produkt";
    }
    default:
      if (model && model.name) {
        return model.name;
      }
      break;
  }
  return "";
}

export const WizardTitle = ({
  className = "",
  type = "default",
  model,
  menu,
  isNew,
  isTask,
  title = null,
}) => {
  return (
    <div className={`wizard-title-panel-holder`}>
      <div className={`wizard-title-panel bg-primary ${className}`}>
        <div className="container">
          <div className="w3-right">{menu}</div>
          <h2 className="wizard-title">
            {title || getTitle(type, model, isNew, isTask)}
          </h2>
        </div>
      </div>
    </div>
  );
};

export const FixedFormTitle = ({ refId, children }) => {
  const [style, setStyle] = React.useState({ width: "1160px" });
  React.useEffect(() => {
    console.log("FixedFormTitle.effect()");
    const onResize = () => {
      console.log(">> resizing FixedFormTitle ...");
      const element = document.getElementById(refId);
      if (element) {
        setStyle({ width: `${element.offsetWidth}px` });
      }
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);
  const markupStyle = {
    ...style,
    maxWidth: "1160px",
  };
  return (
    <div className="form-title-panel-holder">
      <div className="form-title-panel" style={markupStyle}>
        {children}
      </div>
    </div>
  );
};

export const toUIDate = (
  date,
  format = "YYYY-MM-DDTHH:mm:ss:SSSZ",
  targetFormat = "DD.MM.YYYY"
) => {
  let value = date || "";
  if (value) {
    const temp = moment(value, format);
    if (temp.isValid()) {
      value = temp.format(targetFormat);
    }
  }
  return value;
};

export const toUIInputDate = (date, format = "YYYY-MM-DDTHH:mm:ss:SSSZ") => {
  let value = date || "";
  if (value) {
    const temp = moment(value, format);
    if (temp.isValid()) {
      value = temp.toDate();
    }
  }
  return value;
};

export const toDBDate = (date, format = "YYYY-MM-DD") => {
  let value = date || "";
  if (value) {
    const temp = moment(value, format);
    if (temp.isValid()) {
      value = temp.format("YYYY-MM-DD");
    }
  }
  return value;
};

export const toDBDateTime = (date, format = "DD.MM.YYYY HH:mm") => {
  let value = date || "";
  if (value) {
    const temp = moment(value, format);
    if (temp.isValid()) {
      value = temp.format("YYYY-MM-DD HH:mm:ss");
    }
  }
  return value;
};

export const openFile = (url) => {
  url &&
    setTimeout(() => {
      const found = url.indexOf("/data-api");
      url &&
        window.open(
          `${window.location.protocol}//${window.location.host}${
            found >= 0 ? "" : "/data-api"
          }${url}`,
          "_blank"
        );
    }, 1000);
};

export const showFile = (path) => {
  window.open(path, "_blank").focus();
};

export function getCurrentOrderWeek() {
  const date = moment().add(1, "week");
  // if day is monday and time is not yet 10:00 am, view last week orders
  if (date.isoWeekday() === 1 && date.hour() < 10) {
    date.day(-1);
  }
  date.locale("de");
  const week = date.isoWeek();

  const weekOptions = [];
  const weekYears = [];

  let count = 0;
  while (count < 52) {
    const tmpDate = moment();
    tmpDate.locale("de");
    const countDate = tmpDate.add(count, "week");
    const weekCount = countDate.isoWeek();
    const yearCount = countDate.year();
    weekOptions.push({
      label: countDate.format("ww"),
      value: weekCount,
    });
    weekYears.push({ week: weekCount, year: yearCount });
    count++;
  }

  return {
    week,
    weekStr: date.format("ww"),
    year: date.year(),
    weekOptions,
    weekYears,
  };
}

export function getWeekOptions(props = {}) {
  const today = moment();
  today.locale("de");
  const year = props.year || parseInt(today.format("YYYY"));
  const day = props.year ? moment(`${year}-01-31`, "YYYY-MM-DD") : moment();
  day.locale("de");

  const weekOptions = [];
  for (let weekCount = 1; weekCount <= 52; weekCount++) {
    const weekStt = moment(day).isoWeek(weekCount).startOf("isoWeek");
    const weekEnd = moment(day).isoWeek(weekCount).endOf("isoWeek");
    const suffix = ` : ${weekStt.format("DD.MM.YY")} - ${weekEnd.format(
      "DD.MM.YY"
    )}`;
    weekOptions.push({
      label: `KW${weekCount < 10 ? "0" : ""}${weekCount}${suffix}`,
      value: props.showNextYear ? `${weekCount}:${year}` : weekCount,
    });
  }
  return weekOptions;
}

export function getModemOrderMoveWeekOptions(props = {}) {
  const today = moment();
  today.locale("de");
  const week = today.isoWeek();
  const year = props.year || parseInt(today.format("YYYY"));

  const weekOptions = [];
  const day = moment(`${year}-01-31`, "YYYY-MM-DD");
  day.locale("de");
  const lastWeek = 52;

  let weekEnd = null;
  for (let weekCount = 1; weekCount <= lastWeek; weekCount++) {
    const weekStart = moment(day).isoWeek(weekCount).startOf("isoWeek");
    weekEnd = moment(day).isoWeek(weekCount).endOf("isoWeek");
    const suffix = ` : ${weekStart.format("DD.MM.YY")} - ${weekEnd.format(
      "DD.MM.YY"
    )}`;
    weekOptions.push({
      label: `KW${weekCount < 10 ? "0" : ""}${weekCount}${suffix}`,
      value: props.showNextYear ? `${weekCount}:${year}` : weekCount,
    });
  }
  if (props.showNextYear && lastWeek - week <= 2) {
    // add 2 weeks of the next calendar year
    const newDay = weekEnd.add(1, "d");
    for (let weekCount = 1; weekCount <= 2; weekCount++) {
      const newWeekStart = moment(newDay).isoWeek(weekCount).startOf("isoWeek");
      const newWeekEnd = moment(newDay).isoWeek(weekCount).endOf("isoWeek");
      const suffix = ` : ${newWeekStart.format(
        "DD.MM.YY"
      )} - ${newWeekEnd.format("DD.MM.YY")}`;
      weekOptions.push({
        label: `KW${weekCount < 10 ? "0" : ""}${weekCount}${suffix}`,
        value: `${weekCount}:${year + 1}`,
      });
    }
  }
  console.log("<< getModemOrderMoveWeekOptions() weekOptions", weekOptions);
  return weekOptions;
}

export function getOrderWeekOptions(props = {}) {
  const today = moment();
  today.locale("de");
  const week = today.isoWeek();
  const year = props.year || parseInt(today.format("YYYY"));

  const weekOptions = [];
  const day = moment(`${year}-01-31`, "YYYY-MM-DD");
  day.locale("de");
  const lastWeek = 52;

  let weekEnd = null;
  for (let weekCount = 1; weekCount <= lastWeek; weekCount++) {
    const weekStart = moment(day).isoWeek(weekCount).startOf("isoWeek");
    weekEnd = moment(day).isoWeek(weekCount).endOf("isoWeek");
    const suffix = ` : ${weekStart.format("DD.MM.YY")} - ${weekEnd.format(
      "DD.MM.YY"
    )}`;
    weekOptions.push({
      label: `KW${weekCount < 10 ? "0" : ""}${weekCount}${suffix}`,
      value: props.showNextYear ? `${weekCount}:${year}` : weekCount,
    });
  }
  if (props.showNextYear && lastWeek - week <= 2) {
    // add 2 weeks of the next calendar year
    const newDay = weekEnd.add(1, "d");
    for (let weekCount = 1; weekCount <= 2; weekCount++) {
      const newWeekStart = moment(newDay).isoWeek(weekCount).startOf("isoWeek");
      const newWeekEnd = moment(newDay).isoWeek(weekCount).endOf("isoWeek");
      const suffix = ` : ${newWeekStart.format(
        "DD.MM.YY"
      )} - ${newWeekEnd.format("DD.MM.YY")}`;
      weekOptions.push({
        label: `KW${weekCount < 10 ? "0" : ""}${weekCount}${suffix}`,
        value: `${weekCount}:${year + 1}`,
      });
    }
  }
  // console.log('<< getOrderWeekOptions() weekOptions', weekOptions);
  return {
    week,
    weekStr: today.format("ww"),
    weekOptions,
    year,
  };
}

// export const validateEmail = (email) => {
//   return email && new RegExp('\S+@\S+\.\S+').test(email);
// }

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validateTelNumber = (number) => {
  return number && new RegExp("^\\+[1-9][0-9]+$").test(number);
};

// difference to `validateTelNumber` is that "+" is optional and the phone
// number may include spaces and dashes
const TEL_NUMBER2_PATTERN = /^\+?[0-9][0-9- ]+$/;
export const validateTelNumber2 = (number) => {
  if (!number) {
    return false;
  }
  return TEL_NUMBER2_PATTERN.test(number);
};

export const validatePassword = (password, text = {}) => {
  // return password && password.markup(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9a-zA-Z])([a-zA-Z0-9]{8,})$/);
  if (!password) return false;
  // contains at least 1 digit
  if (!password.match(/\d/)) {
    text.error = "Passwort muss mindestens eine Zahl enthalten";
    return false;
  }
  // contains at least 1 lower case letter
  if (!password.match(/[a-z]/)) {
    text.error = "Passwort muss mindestens einen Kleinbuchstaben enthalten";
    return false;
  }
  // contains at least 1 upper case letter
  if (!password.match(/[A-Z]/)) {
    text.error = "Passwort muss mindestens einen Großbuchstaben enthalten";
    return false;
  }
  // contains at least 1 symbol (non-alphanumeric character)
  if (!password.match(/[^0-9a-zA-Z]/)) {
    text.error = "Passwort muss mindestens ein Sonderzeichen enthalten";
    return false;
  }
  // length = 8 (;
  if (password.length < 8) {
    text.error = "Passwort muss aus mindestens 8 Zeichen bestehen";
    return false;
  }
  return true;
  // return password
  //   && password.match(/\d/) // contains at least 1 digit
  //   && password.match(/[a-z]/) // contains at least 1 lower case letter
  //   && password.match(/[A-Z]/) // contains at least 1 upper case letter
  //   && password.match(/[^0-9a-zA-Z]/) // contains at least 1 symbol (non-alphanumeric character)
  //   && password.match(/{8,}/) // length = 8 (password.length === 8);
};

export const validateAreaCode = (value) => {
  if (!value) return false;
  return value.match(/^\d+$/) && value.length <= 5;
  // return !isNaN(parseInt(value)) && value.length <= 5;
};

export const validateModemOrderAmount = (value) => {
  if (!value) return false;
  return value.match(/^\d+$/) && value.length <= 5;
};

export const validateSerialNumber = (value) => {
  if (!value) return false;
  // alpha-numeric
  return value.match(/^[0-9a-zA-Z]+$/);
};

export const validateDecimalNr = (value, decimalPointChar = ".") => {
  if (!value) return false;
  return `${value}`.replace(decimalPointChar, ".").match(/^\d+(\.\d{1,2})?$/);
};

const isSet = (value) => value !== null && value !== undefined;

export const createSortByAttr = (attr, desc, isString) => (a, b) => {
  const aValue = isString
    ? isSet(a[attr])
      ? a[attr].toLowerCase()
      : null
    : isSet(a[attr])
    ? a[attr]
    : null;
  const bValue = isString
    ? isSet(b[attr])
      ? b[attr].toLowerCase()
      : null
    : isSet(b[attr])
    ? b[attr]
    : null;
  if (aValue !== null && bValue !== null) {
    if (aValue > bValue) return desc ? -1 : 1;
    else if (aValue < bValue) return desc ? 1 : -1;
    return 0;
  } else {
    if (aValue !== null && bValue === null) return desc ? -1 : 1;
    else if (aValue === null && bValue !== null) return desc ? 1 : -1;
    return 0;
  }
};

export const createSortById = (desc) => createSortByAttr("id", desc);
export const createSortByName = (desc) => createSortByAttr("name", desc, true);
export const createSortByLabel = (desc) =>
  createSortByAttr("label", desc, true);
export const createSortByUsername = (desc) =>
  createSortByAttr("username", desc, true);

export const addCompanyToList = (company, companies) => {
  if (!company || !companies) return;
  const found = companies.find((item) => item.id === company.id);
  if (!found) {
    companies.push(company);
    companies.sort(createSortByName());
  }
};

/**
 * Determine connection validity end date
 */
export const getConnectionValidationEnddate = () => {
  const date = moment().add(3, "days");
  // console.log(':>> 3 days from today:', date.format('DD.MM.YYYY'));
  const midYear = moment(`${date.year()}-06-30`); // moment().year(date.year()).month(5).day(30);
  const endYear = moment(`${date.year()}-12-31`); // moment().year(date.year()).month(11).day(31);
  // console.log(':>> MidYear:', midYear.format('DD.MM.YYYY'));
  // console.log(':>> EndYear:', endYear.format('DD.MM.YYYY'));
  return date.isSameOrBefore(midYear) ? midYear.toDate() : endYear.toDate();
};

export const isConnectionPasswordVisible = (
  modifiedDate,
  format = "YYYY-MM-DDTHH:mm:ss:SSSZ"
) => {
  let value = false;
  const date = moment(modifiedDate || "", format);
  if (date.isValid()) {
    const endDate = date.add(48, "hours");
    value = moment().isSameOrBefore(endDate);
  }
  return value;
};

export const CoverText = ({ children }) => (
  <div className="overlay-inner flex-container w3-large">{children}</div>
);

export const UnderConstruction = () => (
  <div className="overlay-inner flex-container w3-large w3-light-grey w3-opacity">
    <h4 className="no-margin display-inline">
      {"Update für diesen Bereich erfolgt mit einer weiteren Version."}
    </h4>
  </div>
);

export const extractObject = (model, keyPrefix, keepPrefix = false) => {
  if (!model || !keyPrefix) return null;
  const result = {};
  let found = false;
  Object.keys(model).forEach((key) => {
    if (key.indexOf(keyPrefix) === 0) {
      found = true;
      const newKey = keepPrefix ? key : key.replace(keyPrefix, "");
      result[newKey] = model[key];
      console.log("<<", key, model[key], newKey, result[newKey]);
    }
  });
  return found ? result : null;
};

export const getFileTitleFromName = (fileName) => {
  if (fileName) {
    const tokens = fileName.split(".");
    tokens.splice(tokens.length - 1, 1);
    let str = tokens.join("");
    str = replaceAll(str, "_", " ");
    return str;
  }
  return null;
};

export const getFileTypeLabel = (fileName) => {
  if (fileName) {
    const tokens = fileName.split(".");
    const ext = tokens[tokens.length - 1];
    switch (ext.toLowerCase()) {
      case "jpg":
      case "jpeg":
        return "JPEG";
      case "png":
        return "PNG";
      case "xls":
      case "xlsx":
        return `EXCEL (.${ext})`;
      case "doc":
      case "docx":
        return `WORD (.${ext})`;
      case "csv":
        return `CSV`;
      case "pdf":
        return `PDF`;
      case "ppt":
      case "pptx":
        return "POWER POINT";
      case "exe":
        return "EXE";
      case "zip":
        return "ZIP";
      default:
        return ext.toUpperCase();
    }
  }
  return null;
};

export const getFileExtension = (fileName) => {
  if (fileName) {
    const tokens = fileName.split(".");
    return tokens[tokens.length - 1];
  }
  return null;
};

export const formatFileSize = (size) => {
  let divisor = 1;
  let units = "B";
  if (size > 1000000) {
    divisor = 1000000;
    units = "MB";
  } else if (size >= 1000) {
    divisor = 1000;
    units = "kB";
  }
  return `${(size / divisor).toFixed(1)} ${units}`;
};

export const scrollToTop = () => {
  if (!document) return;
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

let VISIBILITY = 0;
export const GlobalOverlay = () => (
  <div id="globalOverlay" className="w3-modal" style={{ zIndex: "1000000" }} />
);

export const toggleGlobalOverlay = (isVisible) => {
  // console.log('toggleGlobalOverlay() isVisible:', isVisible);
  try {
    if (typeof window === "undefined") {
      return;
    }
    VISIBILITY += isVisible ? 1 : -1;
    if (VISIBILITY < 1) VISIBILITY = 0;
    const element = document.getElementById("globalOverlay");
    if (element) {
      // element.style.display = (VISIBILITY > 0) ? 'block !important' : 'none';
      // console.log(isVisible ? '>> SHOW' : '<< HIDE', 'OVERLAY', 'Visibility:', VISIBILITY);
      const className = `w3-modal w3-${VISIBILITY > 0 ? "show" : "hide"}`;
      element.className = className;
      // console.log(isVisible ? '>> SHOW' : '<< HIDE', 'OVERLAY', 'Visibility:', VISIBILITY, 'element.className', element.className, className);
    }
  } catch (err) {
    console.log("<< ON SERVER => no overlay");
  }
};

export const encodeObjectToBase64 = (data) => {
  let filterJsonStringBase64 = "";
  if (data) {
    var dataJsonString = JSON.stringify(data);
    // console.log("<<< encodeObjectToBase64 dataJsonString : ",dataJsonString);
    //convert JSON string to Base64
    filterJsonStringBase64 = btoa(unescape(encodeURIComponent(dataJsonString)));
  }
  return filterJsonStringBase64;
};

export const cleanUpList = (list, keyName) => {
  if (!keyName) {
    return list;
  }
  const cleaned = [];
  const map = {};
  list.forEach((item) => {
    if (map[item[keyName]] === undefined) {
      map[item[keyName]] = true;
      cleaned.push(item);
    }
  });
  return cleaned;
};

export const cleanUpDropdownOptions = (options) => {
  const cleaned = [];
  const map = {};
  options.forEach((item) => {
    if (map[item.value] === undefined) {
      map[item.value] = true;
      cleaned.push(item);
    }
  });
  return cleaned;
};

export const labelToUrl = (label) => {
  if (!label) return "#";
  return label
    .split("ä")
    .join("ae")
    .split("ü")
    .join("ue")
    .split("ö")
    .join("oe")
    .split("&")
    .join("und")
    .split("€")
    .join("Euro")
    .split(" ")
    .join("-")
    .toLowerCase();
};

export const isLinkVisible = (link) => {
  if (!link) {
    return false;
  }
  let isVisible = link.is_visible === 1;
  let isPublished = true;
  let isNotExpired = true;
  if (link.data) {
    const { endDate, publicationDate } = link.data;
    const today = moment();
    // publicationDate must be before or same as today
    if (publicationDate) {
      const date = moment(publicationDate, "YYYY-MM-DD");
      isPublished = date.isValid() && date.isSameOrBefore(today, "day");
    }
    // endDate must be after today
    if (endDate) {
      const date = moment(endDate, "YYYY-MM-DD");
      isNotExpired = date.isValid() && date.isAfter(today, "day");
    }
    isVisible = isVisible && isPublished && isNotExpired;
  }
  return { isVisible, isPublished, isNotExpired };
};

export const getVisibilityConfig = (link) => {
  const visibility = isLinkVisible(link);
  let visibilityClassName = "";
  let title = "";
  // console.log('Sublink', link, visibility, isAdmin);
  if (!visibility.isVisible) {
    visibilityClassName = "not-visible w3-opacity-max w3-hover-opacity-off";
    title = "unsichtbar";
    if (!visibility.isPublished) {
      visibilityClassName = "not-published w3-opacity-max w3-hover-opacity-off";
      title = "noch nicht publiziert";
    } else if (!visibility.isNotExpired) {
      visibilityClassName = "expired w3-opacity-max w3-hover-opacity-off";
      title = "ausgelaufen";
    }
  }
  return { visibility, visibilityClassName, title };
};

export const getLinkParentUrl = (url) => {
  if (!url) return null;
  const tokens = url.split("/");
  tokens.splice(tokens.length - 1, 1);
  return tokens.join("/");
};

export const getLinkArchiveUrl = (url) => {
  if (!url) return null;
  const tokens = url.split("/");
  return `/${tokens[1]}/${tokens[1]}`;
};

export const sortByOptionalPosition = (list, asc, noSort) => {
  if (!list) {
    return null;
  }
  // extract positioned items
  const posItems = list.filter(
    (item) => item.position !== undefined && item.position !== null
  );
  posItems.sort(createSortByAttr("position"));
  // console.log('>> Positioned Items:', posItems);
  // sort all others by id, descending
  const restItems = list.filter(
    (item) => item.position === undefined || item.position === null
  );
  if (!noSort) {
    restItems.sort(createSortById(!asc));
  }
  // console.log('>> UnPositioned Items:', restItems);

  // all items are positioned
  if (posItems.length === list.length) {
    // console.log('sortByOptionalPosition() - input:', list,'output:', posItems);
    return posItems;
  }

  // insert positioned items
  posItems.forEach((item) => {
    const index = item.position - 1;
    if (index >= 0 && index < list.length) {
      restItems.splice(index, 0, item);
    } else {
      // if position surpasses the list length, add to the end
      restItems.push(item);
    }
  });
  // console.log('sortByOptionalPosition() - input:', list,'output:', restItems);
  return restItems;
};

export const sortProducts = (products) => {
  if (!products) {
    return [];
  }
  const sortedById = products.sort(createSortById(true));
  let positionedProducts = sortedById.filter((item) => isSet(item.position));
  const unpositionedProducts = sortedById.filter(
    (item) => !isSet(item.position)
  );
  positionedProducts = positionedProducts.sort(createSortByAttr("position"));

  const results = [...unpositionedProducts];
  // insert positioned items
  positionedProducts.forEach((item) => {
    const index = item.position - 1;
    if (index >= 0 && index < products.length) {
      results.splice(index, 0, item);
    } else {
      results.push(item);
    }
  });
  return results;
  // return [
  //   ...positionedProducts,
  //   ...unpositionedProducts
  // ];
};

export const MoreText = ({ className, children }) => {
  return (
    <div className={`more-text-holder ${className}`}>
      <div className="more-text">{children}</div>
      <span className="more-marker">{"..."}</span>
    </div>
  );
};

export const TableEmptyMessage = ({
  itemNamePlural = "Ergebnisse",
  min = false,
  minHeight = "60px",
  prefix = "Keine",
}) => {
  const Holder = min ? PageHeightCustom : PageHeight30;
  const props = min ? { minHeight } : {};
  return (
    <Holder {...props}>
      <CoverText>{`${prefix} ${itemNamePlural}`}</CoverText>
    </Holder>
  );
};
export const ScrollableTableHolder = ({ noScroll = false, children }) => {
  if (noScroll) {
    return <>{children}</>;
  }
  return (
    <div
      className="w3-border-left w3-border-right w3-border-bottom"
      style={{ overflow: "hidden" }}
    >
      <div style={{ margin: "0 -2px -1px -1px" }}>{children}</div>
    </div>
  );
};

export const getIdFromUrl = (url, suffix = "Btn") => {
  if (!url) {
    return undefined;
  }
  const tokens = url.split("/");
  return `${tokens[tokens.length - 1]}${suffix}`;
}
