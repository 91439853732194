import React from 'react';
import PropTypes from 'prop-types';
import { SelectButton } from 'primereact/selectbutton';
import { getTaskTypeOptions } from './Utils';

export default class ActionFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    taskType: PropTypes.string,
    type: PropTypes.string,
    setTaskType: PropTypes.func,
    itemsToCount: PropTypes.array
  }
  static defaultProps = {
    className: '',
    itemsToCount: [],
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  render() {
    const { className, type, taskType, setTaskType, itemsToCount } = this.props;
    const TypeOptions = getTaskTypeOptions(type, itemsToCount);
    console.log('ActionFilter.render() type:', type, "taskType:", taskType, TypeOptions);
    return (
      <div className={className}>
        <SelectButton
          className="action-filter"
          options={TypeOptions}
          value={taskType}
          onChange={(event) => setTaskType && setTaskType(event.value)}
        />
      </div>
    );
  }
}
