import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from "primereact/dropdown";
import { Label, Dropdown } from "../../../../components/Inputs";
import { Calendar } from "primereact/calendar";
import { toDBDate, toUIInputDate } from "../../../../components/Utils";

export default class BookingProductForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		vouchers: PropTypes.array,
		products: PropTypes.array,
		promos: PropTypes.array,
	};
	static defaultProps = {
		className: "",
		model: {},
		products: [],
		promos: [],
		vouchers: [],
	};
	constructor(props) {
		super();
		this.state = this.getState(props);
	}
	getState = (props) => {
		const { model } = props;
		return {
			product: model.product || "",
			promo: model.promo || "",
			voucher: model.voucher || "",
			deliveryDate: toUIInputDate(model.deliveyDate) || "",
			error: {
				product: null,
				promo: null,
				voucher: null,
				// deliveryDate: null,
			},
		};
	};
	reset = () => {
		this.setState(this.getState(this.props));
	};
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});
		if (!isValid) {
			this.setState({ error });
			return null;
		}
		// deliveryDate
		model.deliveryDate = toDBDate(model.deliveryDate);
		return model;
	};

	getModel = (noValidate) => {
		const model = this.validate(noValidate);
		return model;
	};
	getData = () => {
		return this.getModel(true);
	};
	render() {
		const { className, vouchers, products, promos, disabled } = this.props;
		const { voucher, error, product, promo, deliveryDate } = this.state;
		console.log("BookingProductForm.render()", this.props);
		const VouchersOptions = vouchers.map((item) => ({
			label: item,
			value: item,
		}));
		const ProductMap = {};
		const ProductsOptions = [];
		products.forEach((item) => {
			if (!ProductMap[item]) {
				ProductsOptions.push({
					label: item,
					value: item,
				});
				ProductMap[item] = true;
			}
		});
		const PromosOptions = promos.map((item) => ({ label: item, value: item }));
		return (
			<div className={`${className}`}>
				<div className="w3-row neg-margin-lr">
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.product ? "error-group" : ""
							}`}
						>
							<Label htmlFor="product">{`Produkt*`}</Label>
							<Dropdown
								id="product"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								value={product}
								options={ProductsOptions}
								onChange={(event) => this.setState({ product: event.value })}
								showClear
								filter
								disabled={disabled}
							/>
						</div>
						<div
							className={`pad-top form-group-item ${
								error.voucher ? "error-group" : ""
							}`}
						>
							<Label htmlFor="voucher">{`Gutschrift*`}</Label>
							<Dropdown
								id="voucher"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								value={voucher}
								options={VouchersOptions}
								onChange={(event) => this.setState({ voucher: event.value })}
								showClear
								disabled={disabled}
							/>
						</div>
					</div>
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.promo ? " error-group" : ""
							}`}
						>
							<Label htmlFor="promo">{`Promotion (vorbehaltlich Verfügbarkeit)*`}</Label>
							<Dropdown
								id="promo"
								className="w3-block w3-border"
								placeholder="Bitte auswählen"
								value={promo}
								options={PromosOptions}
								onChange={(event) => this.setState({ promo: event.value })}
								showClear
								disabled={disabled}
							/>
						</div>
						<div className={`pad-top form-group-item`}>
							<Label htmlFor="deliveryDate">{`Wunschlieferdatum`}</Label>
							<Calendar
								id="deliveryDate"
								className="w3-block w3-border"
								readOnlyInput={true}
								dateFormat="dd.mm.yy"
								value={deliveryDate}
								onChange={(event) => {
									this.setState({ deliveryDate: event.value });
								}}
								disabled={disabled}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
