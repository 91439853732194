import * as actionTypes from '../actions/auth';
import * as Utils from '../../components/Utils';
import isArray from 'lodash/isArray';

const initialState = {
  initializingApp: true,
  isAuthenticated: false,
  user: null,
  userProfile: null,
  unconfirmedUser: null,
  // isCEO: false,
  // isWorkerPlus: false,
  // isLogisticsAssistant: false,
  // isNewsAssistant: false,
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load app version
    case actionTypes.LOAD_APP_VERSION:
      return {
        ...state,
        loading: true
      }
    case actionTypes.LOAD_APP_VERSION_SUCCESS:
      // console.log(':>> SERVER APP VERSION:', action.result);
      // console.log(':>> CLIENT APP VERSION:', window && window.TAG);
      if (window && action.result.version !== window.TAG) {
        // console.log('<<: outdated client version => reloading app...');
        alert('Browser Version ist veraltet - bitte neuladen!');
        // window.location.reload();
      }
      return {
        ...state,
        loading: false
      }
    case actionTypes.LOAD_APP_VERSION_FAILURE:
      return {
        ...state,
        loading: false
      }
    // load current user
    case actionTypes.LOAD_CURRENT_USER: 
      return {
        ...state,
        initializingApp: true,
        loading: true,
        loadedCurrentUser: false
      };
    case actionTypes.LOAD_CURRENT_USER_SUCCESS: {
      // const userFlags = Utils.createUserFlags(action.result);
      const user = isArray(action.result)
        ? (action.result.length > 0 ? action.result[0] : null)
        : (action.result && Object.keys(action.result).length > 0 ? action.result : null);
      return {
        ...state,
        loading: false,
        loadedCurrentUser: true,
        isAuthenticated: (user ? true : false),
        user,
        unconfirmedUser: null,
        // ...userFlags
      };
    }
    case actionTypes.LOAD_CURRENT_USER_FAILURE: {
      return {
        ...state,
        initializingApp: false,
        loadingCurrentUser: false,
        loadedCurrentUser: true,
        isAuthenticated: false,
        user: null,
        unconfirmedUser: null,
        error: action.result
      };
    }
    // load user profile
    case actionTypes.LOAD_USER_PROFILE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: isArray(action.result) ? action.result[0] : action.result
      };
    case actionTypes.LOAD_USER_PROFILE_FAILURE:
      return {
        ...state,
        userProfile: null,
        error: action.result
      };
    // load user session
    case actionTypes.LOAD_CURRENT_USER_SESSION:
      return {
        ...state,
        initializingApp: true
      }
    case actionTypes.LOAD_CURRENT_USER_SESSION_SUCCESS:
      // console.log('<<::REDUX REDUCER - USER SESSION: User Profile Loaded', action.result.profile);
      // state.userProfile = action.result.profile;
      // return state;
      return {
        ...state,
        userProfile: action.result.profile,
        initializingApp: false
      };
    case actionTypes.LOAD_CURRENT_USER_SESSION_FAILURE:
      // console.log("<< USER SESSION NOT LOADED!");
      return {
        ...state,
        initializingApp: false
      }
    // login
    case actionTypes.LOGIN: 
      return {
        ...state,
        loading: true,
        user: null,
        unconfirmedUser: null,
        credentials: action.credentials,
      };
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        // isAuthenticated: true,
        // user: action.result,
        error: null,
        unconfirmedUser: action.result,
        credentials: null
      };
    }
    case actionTypes.LOGIN_FAILURE: {
      console.log("LOGIN ERROR:", action);
      return {
        ...state,
        loading: false,
        // isAuthenticated: false,
        // user: null,
        unconfirmedUser: null,
        error: {
          ...action.error,
          CODE: 100
        } // action.error
      };
    }
    // login confirmation
    case actionTypes.CONFIRM_LOGIN: 
      return {
        ...state,
        loading: true
      };
    case actionTypes.CONFIRM_LOGIN_SUCCESS: {
      // reload to get current CSPs
      window.location.reload();
      return {
        ...state,
        loading: false
      };
    }
    case actionTypes.CONFIRM_LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: { CODE: 101, message: 'Ungültiges 2FA-Code' }
      };
    }
    // logout
    case actionTypes.LOGOUT: 
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOGOUT_SUCCESS: {
      // Utils.notifySuccess({ summary: 'Aufwiedersehen !'});
      // const userFlags = Utils.createUserFlags();
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        unconfirmedUser: null,
        userProfile: null,
        error: null,
        // ...userFlags
      };
    }
    case actionTypes.LOGOUT_FAILURE: {
      // const userFlags = Utils.createUserFlags();
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        unconfirmedUser: null,
        userProfile: null,
        error: null,
        // ...userFlags
      };
    }
    // save new password
    case actionTypes.SAVE_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SAVE_PASSWORD_SUCCESS:
      // console.log('REDUX REDUCER: new password was successfullý saved ...', action);
      Utils.notifySuccess({
        summary: `Passwortänderung`,
        detail: `Ihr Passwort wurde erfolgreich geändert`
      });
      return {
        ...state,
        loading: false,
        unconfirmedUser: null,
        error: null
      };
    case actionTypes.SAVE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        // error: action.error,
        error: { CODE: 102, message: 'Passwortaktualisierung ist fehlgeschlagen'}
      };
    // reset forgotten password
    case actionTypes.RESET_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case actionTypes.RESET_PASSWORD_SUCCESS: {
      const { firstname, lastname, email } = (action.credentials || {});
      let target = `Ihre E-Mail-Adresse`;
      if (firstname && lastname) {
        target = `die E-Mail-Adresse von ${firstname} ${lastname}`;
      }
      Utils.notifySuccess({
        summary: `Passwort vergessen`,
        detail: `Eine E-Mail wurde an ${target} (${email}) geschickt!`
      });
      return {
        ...state,
        loading: false,
        unconfirmedUser: null,
        passwordResetError: null
      };
    }
    case actionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        passwordResetError: { CODE: 103, message: 'Passwortrücksetzung ist fehlgeschlagen - bitte ihre Eingaben überprüfen.'}
      };
    // update profile
    case actionTypes.UPDATE_BASIC_DATA:
      return {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_BASIC_DATA_SUCCESS:
      Utils.notifySuccess({
        summary: `Profil`,
        detail: `Ihr Profil wurde erfolgreich geändert`
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case actionTypes.UPDATE_BASIC_DATA_FAILURE:
      Utils.notifyError({
        summary: `Mitarbeiterprofil`,
        detail: `Ihr Profil konnte nicht geändert werden`
      });
      return {
        ...state,
        loading: false,
        // error: action.error,
        error: {CODE: 121, message: 'Mitarbeiterprofilaktualisierung ist fehlgeschlagen'},
    };
    // update names
    case actionTypes.UPDATE_NAMES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_NAMES_SUCCESS:
      Utils.notifySuccess({
        summary: `Mitarbeiterprofil`,
        detail: `Die Namen des Mitarbeiters wurden erfolgreich geändert`
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    case actionTypes.UPDATE_NAMES_FAILURE:
      Utils.notifyError({
        summary: `Mitarbeiterprofil`,
        detail: `Die Namen des Mitarbeiters konnten nicht geändert werden`
      });
      return {
        ...state,
        loading: false,
        // error: action.error,
        error: {CODE: 122, message: 'Aktualisierung der Mitarbeiternamen ist fehlgeschlagen'},
      };
    default:
      return {...state};
  }
}
