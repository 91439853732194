import { TASK_SECTION_TYPES } from '../components/Utils';
import { loadOpenTasks } from './index';

export const FILTER_DEBITORS = 'tasks/debitors/filter';
export const SET_SELECTED_DEBITORS = 'task/debitors/set';

export const DELETE_DEBITOR_TASK = 'tasks/debitors/delete';
export const DELETE_DEBITOR_TASK_SUCCESS = 'tasks/debitors/delete/success';
export const DELETE_DEBITOR_TASK_FAILURE = 'tasks/debitors/delete/failure';

export const CLOSE_DEBITOR_TASK = 'tasks/debitors/close';
export const CLOSE_DEBITOR_TASK_SUCCESS = 'tasks/debitors/close/success';
export const CLOSE_DEBITOR_TASK_FAILURE = 'tasks/debitors/close/failure';


export const filterDebitors = (taskType) => {
  return { type: FILTER_DEBITORS, taskType };
};

export const setSelectedDebitors = (selected) => {
  console.log(`REDUX-ACTION: setSelectedDebitors()`, selected);
  return { type: SET_SELECTED_DEBITORS, selected };
};

export const closeDebitorTask = ({ task, debitorTaskType }, onSuccess) => {
  console.log(`REDUX-ACTION: closeDebitorTask()`, task, debitorTaskType);
  return {
    types: [ 
      CLOSE_DEBITOR_TASK, 
      CLOSE_DEBITOR_TASK_SUCCESS, 
      CLOSE_DEBITOR_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/${debitorTaskType}`, { data: [task] }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.DEBITORS));
      onSuccess && onSuccess();
    },
    task,
    debitorTaskType
  };
};

export const deleteDebitorTask = ({ task, debitorTaskType }, onSuccess) => {
  console.log(`REDUX-ACTION: deleteDebitorTask()`, task, debitorTaskType);
  return {
    types: [ 
      DELETE_DEBITOR_TASK, 
      DELETE_DEBITOR_TASK_SUCCESS, 
      DELETE_DEBITOR_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/reset/${debitorTaskType}`, { data: [task] }),
    onSuccess: dispatch => {
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.DEBITORS));
      onSuccess && onSuccess();
    },
    task,
    debitorTaskType
  };
};
