import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label, Dropdown } from '../../../../components/Inputs';
import MediaUploadForm from '../../../../components/MediaUploadForm';

export default class TechKeyForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    techKeys: PropTypes.array,
    disabled: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: {
      company_id: '',
      number: '',
      path: ''
    },
    techKeys: []
  }
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      // product_id: model.product_id || '',
      number: model.number || '',
      error: {
        // product_id: null,
        number: null,
      }
    };
  }
  validate = () => {
    const { error, ...others } = this.state;
    let model = { ...others };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    // file (optional)
    const media = this.mediaForm.getModel();
    if (media.media.length > 0) {
      model.path = media.media[0].path;
    }
    // techKey id
    const found = this.props.techKeys.find(item => item.number === model.number);
    if (found) {
      model.techKeyId = found.id;
    }
    const { id, index, company_id } = this.props.model;
    return { id, index, company_id, ...model };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, model, techKeys, disabled } = this.props
    const { number, error} = this.state;
    const KeyOptions = techKeys.map(item => ({
      label: `${item.number}`,
      value: item.number
    }));
    const files = (model.path && model.path !== '') ? [{ path: model.path }] : [];
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row pad-top-">
            <div className="w3-col s6 pad-lr">
              <div className={`${error.issuer ? 'error-group' : ''}`} style={{minHeight: '200px'}}>
                <Label htmlFor="number">{`Schließmittel*`}</Label>
                <Dropdown
                  id="number"
                  className="w3-block w3-border"
                  value={number}
                  placeholder="Bitte auswählen"
                  options={KeyOptions}
                  onChange={(event) => this.setState({ number: event.value })}
                  disabled={disabled}
                  showClear
                />
              </div>
            </div>
            <div className="w3-col s6 pad-lr">
              <div className="" style={{minHeight: '150px'}}>
                <Label>{`Übergabevereinbarung`}</Label>
                <MediaUploadForm
                  ref={ref => this.mediaForm = ref}
                  model={{ files }}
                  uploadBtnClassName=""
                  uploadUrl="/data-api/media/upload?target=company"
                  itemColClassName="w3-col s6"
                  readOnly={disabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
