import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PageHeight40,
  PageTitle,
  createSortByAttr,
  scrollToTop,
  TableEmptyMessage,
  toUIDate,
  STATUS_LABELS,
  STATUS_IDS,
} from "../../../../components/Utils";
import { FormMenu } from "../../../../components/Buttons";
import ItemListManager from "../../../../components/ItemListManager";
// import MSAuthenticatorTaskForm from "../../components/itConnections/MSAuthenticatorTaskForm";
import MSAuthenticatorTaskMenu from "../../components/itConnections/MSAuthenticatorTaskMenu";

import { connect } from "react-redux";
import {
  setSelectedMSAuthenticatorTasks,
  closeMSAuthenticatorTasks,
  deleteMSAuthenticatorTask,
} from "../../actions";
import SalesTablePageReport from "../../../Reporting/components/SalesTablePageReport";

const CONNECTION_TYPE = "MS";

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    data: state.task.msAuthenticatorTasks,
    selection: state.task.selectedMSAuthenticatorTasks,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelection: (...args) => dispatch(setSelectedMSAuthenticatorTasks(...args)),
  closeTasks: (...args) => dispatch(closeMSAuthenticatorTasks(...args)),
  deleteTask: (...args) => dispatch(deleteMSAuthenticatorTask(...args)),
});

class MSAuthenticatorTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    selection: PropTypes.array,
    setSelection: PropTypes.func.isRequired,
    closeTasks: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    rows: PropTypes.number,
  };
  static defaultProps = {
    className: "",
    rows: 10,
  };
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    };
  }
  componentDidMount() {
    scrollToTop();
  }
  onClose = (task, onHide) => {
    if (!task) return;
    // console.log(`>> closing MS Authenticator task...`, task);
    this.props.closeTasks([task], onHide);
  };
  onDelete = (task) => {
    // console.log(`${CONNECTION_TYPE}ConnectionTaskList.onDelete()`, task);
    this.props.deleteTask(task);
  };
  render() {
    // console.log("MSAuthenticatorTaskList.render():", this.props);
    const {
      className,
      isLoading,
      rows,
      data,
      selection,
      setSelection,
      closeTasks,
    } = this.props;
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items
        .map((item, index) => ({
          ...item,
          // index,
          user: item.user.split(",").join(" "),
          requester: `${item.requester || ""}`.split(",").join(" "),
          requestDate: toUIDate(item.request_date, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
          userInCharge: `${item.userInCharge || ""}`.split(",").join(" "),
          msAuthStatus: STATUS_LABELS[STATUS_IDS.NEW]
        }))
        .sort(createSortByAttr("user"));
      return (
        <DataTable
          value={taskItems}
          paginator={true}
          rows={rows}
          first={this.state.first}
          onPage={(event) => {
            this.setState({ first: event.first });
            scrollToTop();
          }}
          selection={selection}
          onSelectionChange={(event) => setSelection(event.value)}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={taskItems.length}
              filteredRecords={taskItems.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine MS Authenticator Aufgaben"
              itemName="MS Authenticator Aufgabe"
              itemNamePlural="MS Authenticator Aufgaben"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={
            isLoading ? null : <TableEmptyMessage itemNamePlural="MS Authenticator Aufgaben" />
          }
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          {/* <Column
            field="parentCompanyName"
            header="Vertragspartner"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          /> */}
          <Column
            field="user"
            header="Mitarbeiter"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="adCellNumber"
            header="Handynummer"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="vf_email"
            header="Vodafone E-Mail"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            body={item => (item.userData.vf_email)}
          />
          {/* <Column
            field="requester"
            header="Beantrager"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{ width: "12em" }}
          /> */}
          <Column
            field="requestDate"
            header="Erstelldatum"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '10em', textAlign: "center"}}
          />
          <Column
            field="msAuthStatus"
            header="Status"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{ textAlign: "center-", width: "9em" }}
          />
          <Column
            body={actionTemplate}
            style={{ textAlign: "center", width: "7.4em" }}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      return (
        <div>
          <div className="w3-large">{`Wollen Sie die Aufgabe wirklich abschliessen?`}</div>
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{
              marginLeft: "-1em",
              marginRight: "-1em",
              marginBottom: "-1em",
            }}
            onSave={() => this.onClose(item, onHide)}
            onCancel={onHide}
            saveBtnName="closeMSAuthenticatorTaskBtn"
            saveBtnLabel={"Schließen"}
          />
        </div>
      );
    };
    const getItemFormTitle = (/* connection */) => {
      let title = "MS Authenticator-Task schießen";
      return <h3 className="no-margin">{title}</h3>;
    };
    const title = (
      <PageTitle>
        <MSAuthenticatorTaskMenu
          className="w3-right"
          selectedTasks={selection}
          closeTasks={closeTasks}
          items={data}
        />
        <h3 className="no-margin">{"MS Authenticator Aufgaben"}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={data}
          itemPanelStyle={{ width: "600px" }}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`${CONNECTION_TYPE}Connection`}
          itemDeleteable
          // deleteTitle={`${CONNECTION_TYPE}-Zugangsaufgabe löschen`}
          deleteTitle={`MS Authenticator-Aufgabe löschen`}
          getDeleteText={(item) =>
            `Wollen Sie diese Aufgabe unwiderruflich löschen?`
          }
          onDelete={this.onDelete}
        />
      </PageHeight40>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MSAuthenticatorTaskList);
