import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import "./styles.css";

export const Crumbs = ({ className, pathname, labelMap, hideActive }) => {
  if (!pathname) return null;
  const tokens = pathname.replace("/", "").split("/");
  const data = tokens
    .map((item, index) => {
      let url = "";
      for (let counter = 0; counter <= index; counter++) {
        url += tokens[counter].trim() !== "" ? `/${tokens[counter]}` : "";
      }
      return { label: labelMap[url], url };
    })
    .filter((item) => item.label && item.label.trim() !== "");
  // add seperators
  const model = [];
  for (let index = 0; index < data.length; index++) {
    model.length > 0 && model.push({ icon: <span>&#187;</span> });
    model.push(data[index]);
  }
  let markup = [];
  for (let index = 0; index < model.length; index++) {
    const item = model[index].icon ? (
      <span className="separator">{model[index].icon}</span>
    ) : (
      <NavLink
        to={model[index].url}
        exact
        className="step"
        activeClassName={hideActive || model[index].url === "#" ? "" : "active"}
      >
        {model[index].label}
      </NavLink>
    );
    markup.push(
      <div key={index} className="w3-bar-item no-pad">
        {item}
      </div>
    );
  }
  return <div className={`w3-bar ${className}`}>{markup}</div>;
};

class Module extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    labelMap: PropTypes.object,
    location: PropTypes.object,
  };
  static defaultProps = {
    class: "",
    labelMap: {},
  };
  render() {
    const {
      className,
      labelMap,
      location: { pathname },
    } = this.props;
    return (
      <div className={`bread-crumb ${className}`}>
        <Crumbs
          className="w3-border w3-large"
          pathname={pathname}
          labelMap={labelMap}
        />
      </div>
    );
  }
}

export default withRouter(Module);
