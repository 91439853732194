import React from "react";
import PropTypes from "prop-types";
import Dashboard from "../../../components/Dashboard";
import {
  WizardTitle,
  notifyError,
  createSortByName,
  UnderConstruction,
  scrollToElement,
  USER_STATUS_IDS,
} from "../../../components/Utils";
import { FormMenu } from "../../../components/Buttons";
import {
  setCompanyModel,
  getCompanyModel,
  setCompanyModelDelphi,
  renderWorkerDetailsMenu,
} from "../components/Utils";

import BasicDataForm from "../components/wizard/BasicDataForm";
import ITConnectionForm from "../components/wizard/ITConnectionForm";
import CertificationListForm from "../components/wizard/CertificationListForm";
import UserDeviceListForm from "../../Device/components/UserDeviceListForm";
import TechKeyListForm from "../components/wizard/TechKeyListForm";
import UserTrainingPanel from "../components/panels/UserTrainingPanel";
import WorkerBasicDataMenu from "../components/WorkerBasicDataMenu";
// import ContractorCompanyPanel from "../components/panels/ContractorCompanyPanel";
// import UserTechDeviceListForm from "../../Device/components/UserTechDeviceListForm";
// import { TechKeyForm } from "../../Company/components";
// import { UserDeviceListPanel, UserRatingPanel } from "../components/panels";
// import AuditListForm from "../components/wizard/AuditListForm";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  // loadExtendedRoles,
  initWorker,
  loadWorker,
  checkWorker,
  saveWorker,
  cancelWorker,
  loadWorkerCompanies,
} from "../actions";
import { loadTechDevices } from "../../Device/actions";
import EmailForm from "../components/wizard/EmailForm";
import {
  PermissionGroupFetcher,
  PermissionResource,
  PermissionResourceList,
  PermissionSection,
  Resources,
} from "../../Permissions";
import {
  isResourceEditable,
  isResourceReadable,
} from "../../Permissions/actions";

const frontload = async (props) => {
  await props.getTechDevices();
  // await props.getExtendedRoles();
  const id = props.match.params.id;
  await props.getWorker({ id });
  await props.init(true);
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    // permissions: state.permissions.permissionGroup.admindataaccess,
    permissions: state.permissions.permissionMap,
    isInitialized: state.worker.isInitialized,
    worker: state.worker.worker,
    userChecked: state.worker.userChecked,
    userExists: state.worker.userExists,
    roles: state.worker.roles,
    extendedRoles: state.worker.extendedRoles,
    kdls: state.worker.workerKDLs,
    workerKdl: state.worker.workerKDL,
    companies: state.worker.workerCompanies,
    userCompany: state.company.company,
    techDevices: state.device.techDevices,
    userTrainings: state.training.userTrainings,
    techKeyList: state.company.techKeys,
    // techKeyList: state.key.catalogKeys,
  };
};
const mapDispatchToProps = (dispatch) => ({
  init: (...args) => dispatch(initWorker(...args)),
  getWorkerCompanies: (...args) => dispatch(loadWorkerCompanies(...args)),
  getTechDevices: (...args) => dispatch(loadTechDevices(...args)),
  getWorker: (...args) => dispatch(loadWorker(...args)),
  checkWorker: (...args) => dispatch(checkWorker(...args)),
  saveWorker: (...args) => dispatch(saveWorker(...args)),
  cancelWorker: (...args) => dispatch(cancelWorker(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.shape({}),
    permissions: PropTypes.shape({}),
    workerPermissions: PropTypes.shape({}),
    initializing: PropTypes.bool,
    worker: PropTypes.shape({}),
    kdls: PropTypes.array,
    workerKdl: PropTypes.number,
    companies: PropTypes.array,
    userCompany: PropTypes.shape({}),
    userTrainings: PropTypes.array,
    roles: PropTypes.array.isRequired,
    techKeyList: PropTypes.array,
    extendedRoles: PropTypes.array.isRequired,
    init: PropTypes.func.isRequired,
    getWorkerCompanies: PropTypes.func.isRequired,
    getTechDevices: PropTypes.func.isRequired,
    getWorker: PropTypes.func.isRequired,
    checkWorker: PropTypes.func.isRequired,
    saveWorker: PropTypes.func.isRequired,
    cancelWorker: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    userExists: PropTypes.bool,
    userChecked: PropTypes.bool,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
  };
  shouldComponentUpdate(nextProps) {
    return nextProps.isInitialized;
  }
  componentWillUnmount() {
    // reset state
    // console.log("WorkerWizard.componentWillUnmount() => canceling worker ...");
    this.props.cancelWorker();
  }
  onCheckWorker = (worker) => {
    this.props.checkWorker(worker);
  };
  onSave = () => {
    const { worker } = this.props;

    // ----------------------------------- //
    // Basic Info
    // ----------------------------------- //
    let baseData = this.baseData ? this.baseData.getModel() : null;
    if (!baseData && this.baseData) {
      return notifyError({
        summary: "Mitarbeiterprofil",
        detail: `Der Mitarbeiter konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`,
      });
    }
    baseData = baseData || { ...worker };
    const model = { ...baseData };

    // check if company was changed
    let isCompanyChanged = false;
    const companyChanges = [];
    for (const index in model.companies) {
      const isChanged =
        model.companies[index].name !== model.companies[index].oldName;
      if (isChanged) {
        // console.log(
        //   " >> Beschäftigungsfirma changed from",
        //   model.companies[index].oldName,
        //   "to",
        //   model.companies[index].name
        // );
        companyChanges.push({
          oldName: model.companies[index].oldName,
          newName: model.companies[index].name,
        });
        isCompanyChanged = true;
      }
    }

    // VF Email
    const vfEmailModel = this.vfEmail ? this.vfEmail.getModel() : null;
    if (vfEmailModel) {
      model.vf_email = vfEmailModel.email;
    }

    // ----------------------------------- //
    // IT Connections
    // ----------------------------------- //
    const connectionData = this.itData ? this.itData.getModel() : null;
    if (connectionData) {
      const { delphiConnections, ...itConnectionData } = connectionData;
      // delphi connections
      const unassignedDelphiConnection = setCompanyModelDelphi(
        model,
        delphiConnections,
        isCompanyChanged,
        companyChanges
      );
      if (unassignedDelphiConnection) {
        // delphiConnection could not be assigned to company => abort
        const kdlName = unassignedDelphiConnection.byCompanyName;
        const companyName = unassignedDelphiConnection.companyName;
        scrollToElement("it-zugaenge");
        return notifyError({
          summary: "Mitarbeiterprofil - DELPHI-Zugangsfehler",
          detail: `Der DELPHI-Zugang (KDL: ${kdlName}, Beschäftigungsfirma: ${companyName}) konnte nicht zugewiesen werden.`,
        });
      }
      model.itConnectionData = itConnectionData;
      if (
        !isCompanyChanged &&
        worker.itConnectionData &&
        worker.itConnectionData.id
      ) {
        model.itConnectionData.id = worker.itConnectionData.id;
      }
    }

    // ----------------------------------- //
    // Tech Keys
    // ----------------------------------- //
    const techKeys = this.techKeys ? this.techKeys.getModel() : null;
    model.techKeys = techKeys;
    // ----------------------------------- //
    // Tech Devices
    // ----------------------------------- //
    if (this.userTechDevices) {
      const techDevices = this.userTechDevices
        ? this.userTechDevices.getModel()
        : null;
      // console.log("<< saving tech devices:", techDevices);
      if (techDevices) {
        const unassignedDevice = setCompanyModel(
          model,
          techDevices,
          "techDevices",
          "companyName",
          isCompanyChanged
        );
        if (unassignedDevice) {
          // tech device could not be assigned to company => abort
          const companyName = unassignedDevice.companyName;
          scrollToElement("vkdg-geraete");
          return notifyError({
            summary: "Mitarbeiterprofil - Technikergerätfehler",
            detail: `Das Technikergerät (${unassignedDevice.device}, Beschäftigungsfirma: ${companyName}) konnte nicht zugewiesen werden.`,
          });
        }
      }
    }
    // ----------------------------------- //
    // Certifications
    // ----------------------------------- //
    const certificates = this.certificates
      ? this.certificates.getModel()
      : null;
    if (certificates) {
      model.certificates = certificates;
    }
    // }
    if (worker.id) {
      model.id = worker.id;
    }
    console.log("Saving Model:", model);
    const { history } = this.props;
    const onSuccess = () => history.replace("/organisation/mitarbeiter");
    this.props.saveWorker(model, onSuccess);
  };
  onCancel = () => {
    // console.log('WorkerWizard.onCancel()');
    this.props.cancelWorker();
    this.props.history.replace("/organisation/mitarbeiter");
  };
  getUserDeviceListBaseData = () => {
    const { worker } = this.props;
    const baseData = this.baseData.getModel(true);
    console.log("WorkerWizard.getUserDeviceListBaseData()", baseData);
    return {
      currentWorker: worker,
      currentWorkerCompany:
        baseData.companies.length > 0 ? baseData.companies[0] : null,
    };
  };
  getUserTechDeviceListBaseData = () => {
    const { techDevices, companies } = this.props;
    return { techDevices, companies };
  };
  isWorkerEditable = () => {
    const { worker, permissions } = this.props;
    const resourceKeys = Object.keys(Resources.Organisation.WorkerWizard);
    console.log(
      "WorkerWizard.isWorkerEditable()",
      resourceKeys,
      worker,
      permissions
    );
    for (const key of resourceKeys) {
      const isWriteable = isResourceEditable(
        Resources.Organisation.WorkerWizard[key],
        permissions
      );
      if (isWriteable) {
        // return true;
        return (
          !worker.status_id || worker.status_id === USER_STATUS_IDS.APPROVED
        );
      }
    }
    return false;
  };
  render() {
    const {
      className,
      permissions,
      // workerPermissions,
      worker,
      kdls,
      workerKdl,
      companies,
      userCompany,
      roles,
      extendedRoles,
      userChecked,
      techKeyList,
      getWorkerCompanies,
      disabled,
    } = this.props;
    if (!worker) return null;
    // console.log("WorkerWizard.render() permissions", permissions);

    // user check status
    const canReadAdminData = isResourceReadable(
      Resources.AdminDataAccess,
      permissions
    );
    // const workerPlusFlag = isWorkerPlus(user);
    const userExists = worker.id !== undefined || this.props.userExists;
    // const editingMyself = !canReadAdminData/* !workerPlusFlag */ && worker.isOwn;

    // main company
    let mainCompany =
      worker.companies.length === 1
        ? worker.companies[0]
        : worker.companies.find((item) => item.role && item.role !== "");
    if (mainCompany && mainCompany.name === "" && companies.length === 1) {
      mainCompany = { ...companies[0] };
    }

    // const companyList =
    //   mainCompany && (canReadAdminData/* !workerPlusFlag */ || mainCompany.name === "")
    //     ? companies
    //     : [{ ...mainCompany }].sort(createSortByName());
    const companyList = companies.sort(createSortByName());
    const kdlCompanyList = kdls.sort(createSortByName());

    const flag = Boolean((worker.id !== undefined || userChecked) && worker.id);
    const Menu = renderWorkerDetailsMenu(flag);

    // IT Connections
    const itConnectionData = worker.itConnectionData || {};
    let visibleDelphiCompanies = null; // editingMyself ? [mainCompany] : null;
    itConnectionData.delphiConnections = getCompanyModel(
      worker,
      "delphiConnections",
      visibleDelphiCompanies
    );
    itConnectionData.vfEmail = worker.vf_email;
    itConnectionData.isAutheticatorRegistered = worker.hasMsAuthenticator;
    // Devices
    const userDevices = getCompanyModel(worker, "devices");
    // // Tech Devices
    // const userTechDevices = getCompanyModel(worker, 'techDevices');
    return (
      <div className={`worker-wizard clearfix ${className}`}>
        <PermissionGroupFetcher group="Organisation.WorkerWizard" />
        <WizardTitle
          type="worker"
          model={worker}
          menu={
            <FormMenu
              className="pad-big-top"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveDisabled={!worker.id && userChecked === false}
              saveBtnName="saveWorkerBtn2"
              cancelBtnName="cancelWorkerBtn2"
              saveHidden={disabled || !this.isWorkerEditable()}
              cancelBtnLabel={
                !this.isWorkerEditable() ? "Schließen" : undefined
              }
            />
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3} key={Math.random()}>
            <PermissionResource
              resource={Resources.Organisation.WorkerWizard.BasicInfo}
            >
              <PermissionSection
                height="30"
                id="basisdaten"
                className="w3-border margin-big-top pad-big"
                isUnterWizardTitle
              >
                <WorkerBasicDataMenu className="w3-right" user={worker} />
                <h3 className="no-margin-top">{"Persönliche Daten"}</h3>
                <BasicDataForm
                  ref={(ref) => (this.baseData = ref)}
                  model={worker}
                  workerKdl={workerKdl}
                  getWorkerCompanies={getWorkerCompanies}
                  companyList={companyList}
                  kdlCompanyList={kdlCompanyList}
                  contractGivers={worker.contractGivers}
                  userCompany={userCompany}
                  roleList={roles}
                  extendedRoleList={extendedRoles}
                  checkUser={this.onCheckWorker}
                  userChecked={userChecked}
                  userExists={userExists}
                  canReadAdminData={canReadAdminData}
                />
              </PermissionSection>
            </PermissionResource>
            {flag && (
              <>
                <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.IDCard}
                >
                  <PermissionSection
                    height="30"
                    id="vkdg-ausweis"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin-top">{"VKDG Ausweis"}</h3>
                    <UnderConstruction />
                  </PermissionSection>
                </PermissionResource>
                <PermissionResourceList
                  resources={[
                    Resources.Organisation.WorkerWizard.ADConnections,
                    Resources.Organisation.WorkerWizard.PTConnections,
                    Resources.Organisation.WorkerWizard.DelphiConnections,
                  ]}
                >
                  <PermissionSection
                    height="30"
                    id="it-zugaenge"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin-top">{"IT-Zugänge"}</h3>
                    <ITConnectionForm
                      ref={(ref) => (this.itData = ref)}
                      model={itConnectionData}
                      kdlCompanyList={worker.contractGivers}
                      userCompany={userCompany}
                      canReadAdminData={canReadAdminData}
                      vfEmailPermission={
                        permissions[
                          Resources.Organisation.WorkerWizard.VodafoneEmail
                        ]
                      }
                    >
                      <PermissionResource
                        resource={
                          Resources.Organisation.WorkerWizard.VodafoneEmail
                        }
                      >
                        <EmailForm
                          ref={(ref) => (this.vfEmail = ref)}
                          model={{ email: worker.vf_email }}
                        />
                      </PermissionResource>
                    </ITConnectionForm>
                  </PermissionSection>
                </PermissionResourceList>
                {/* <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.ContractGiver}
                >
                  <PermissionSection
                    height="30"
                    id="auftraggeber-taetigkeit"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin-top">
                      {"Tätigkeiten für Auftraggeber"}
                    </h3>
                    <ContractorCompanyPanel companies={worker.companies} />
                  </PermissionSection>
                </PermissionResource> */}
                {/* <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.Ratings}
                >
                  <PermissionSection
                    height="30"
                    id="bewertungen"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin-top">{"Bewertungen"}</h3>
                    <UserRatingPanel
                      model={worker.averageRating}
                      delphiConnections={itConnectionData.delphiConnections}
                      isEditable
                    />
                  </PermissionSection>
                </PermissionResource> */}
                <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.UserDevices}
                >
                  <PermissionSection
                    height="30"
                    id="messgeraete"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    {/* <UserDeviceListPanel model={userDevices} /> */}
                    <UserDeviceListForm
                      ref={(ref) => (this.userDevices = ref)}
                      model={userDevices}
                      getBaseData={this.getUserDeviceListBaseData}
                      readOnly
                    />
                  </PermissionSection>
                </PermissionResource>
                <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.TechKeys}
                >
                  <PermissionSection
                    height="30"
                    id="schluessel"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <TechKeyListForm
                      ref={(ref) => (this.techKeys = ref)}
                      model={worker.techKeys}
                      company={mainCompany}
                      companyKeys={techKeyList || []}
                    />
                  </PermissionSection>
                </PermissionResource>
                <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.Certifications}
                >
                  <PermissionSection
                    height="30"
                    id="zertifizierungen"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <CertificationListForm
                      ref={(ref) => (this.certificates = ref)}
                      model={worker.certificates}
                    />
                  </PermissionSection>
                </PermissionResource>
                <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.TechDevices}
                >
                  <PermissionSection
                    height="30"
                    id="vkdg-geraete"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin">
                      {"VKDG Geräte / Technikergeräte"}
                    </h3>
                    <UnderConstruction />
                    {/* <UserTechDeviceListForm 
                    ref={ref => this.userTechDevices = ref}
                    model={userTechDevices}
                    getBaseData={this.getUserTechDeviceListBaseData
                  /> */}
                  </PermissionSection>
                </PermissionResource>
                <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.Seminars}
                >
                  <PermissionSection
                    height="30"
                    id="schulungen"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin-top">{"Schulungen"}</h3>
                    <UserTrainingPanel model={worker.trainings} isEditable />
                  </PermissionSection>
                </PermissionResource>
                <PermissionResource
                  resource={Resources.Organisation.WorkerWizard.Audits}
                >
                  <PermissionSection
                    height="30"
                    id="auditierung"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <h3 className="no-margin-top">{"Auditierung"}</h3>
                    <UnderConstruction />
                    {/* <AuditListForm
                    ref={ref => this.audits = ref}
                    model={worker.audits}
                  /> */}
                  </PermissionSection>
                </PermissionResource>
              </>
            )}
            <FormMenu
              className="w3-border margin-big-top pad-big w3-light-grey"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveDisabled={!worker.id && userChecked === false}
              saveBtnName="saveWorkerBtn"
              cancelBtnName="cancelWorkerBtn"
              saveHidden={disabled || !this.isWorkerEditable()}
              cancelBtnLabel={
                !this.isWorkerEditable() ? "Schließen" : undefined
              }
            />
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Wizard)
);
