import React from 'react';
import PropTypes from 'prop-types';
import { SelectButton } from 'primereact/selectbutton';
import { InputText, Label } from '../../../../components/Inputs';
import { PrimaryButton } from '../../../../components/Buttons';
import { validateEmail, validateTelNumber, USER_CONTACT_OPTIONS } from '../../../../components/Utils';

export default class CheckDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    userChecked: PropTypes.bool,
    userExists: PropTypes.bool,
    disabled: PropTypes.bool,
    checkUser: PropTypes.func
  }
  static defaultProps = {
    className: '',
    model: {
      firstname: '',
      lastname: '',
      email: '',
      cellNumber: '',
      username: '',
      password: '**********',
      preferredContactMethod: 'email' // 'cell_number'
    }
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const model = props.model || {};
    return {
      firstname: model.firstname || '',
      lastname: model.lastname || '',
      email: model.email || '',
      cellNumber: model.cellNumber || '',
      preferredContactMethod: model.preferredContactMethod || 'email', // 'cell_number',
      error: {
        firstname: null,
        lastname: null,
        email: null,
        cellNumber: null
      }
    };
  }
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    // email
    model.email = model.email.trim();
    // console.log('>> validating email ...', model.email, validateEmail(model.email));
    if (!validateEmail(model.email)) {
      error.email = 'ungültige E-Mail';
      isValid = false;
    }
    // cell number
    model.cellNumber = model.cellNumber.trim();
    // console.log('>> validating cellNumber ...', model.cellNumber, validateTelNumber(model.cellNumber));
    if (!validateTelNumber(model.cellNumber)) {
      error.cellNumber = 'ungültige Handynummer';
      isValid = false;
    }
    !noValidate && this.setState({ error });
    if (!noValidate && !isValid) {
      return null;
    }
    model.username = this.props.model.username;
    model.password = this.props.model.password;
    return model;
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  checkUser = () => {
    const model = this.getModel();
    if (!model) return;
    this.props.checkUser && this.props.checkUser(model);
  }
  render() {
    const { className, userChecked, userExists, model: { username, password }  } = this.props;
    const { firstname, lastname, email, cellNumber, preferredContactMethod, error } = this.state;
    // console.log('CheckDataForm.render()', userChecked, userExists);
    const validationBtn = (userChecked === false) && (
      <div className="w3-col s4 pad-lr pad-top form-group-item ">
        <Label>&nbsp;</Label>
        <PrimaryButton className="" id="checkWorkerBtn" name="checkWorkerBtn" onClick={this.checkUser}>{'Prüfen'}</PrimaryButton>
      </div>
    );
    const generatedMarkup = (userChecked !== false) && (
      <div className="w3-row neg-margin-lr">
        <div className="w3-col s4 pad-lr pad-top">
          <Label>{`Benutzername`}</Label>
          <InputText
            className="w3-block w3-border"
            value={username}
            disabled
          />
        </div>
        <div className="w3-col s4 pad-lr pad-top">
          <Label>{`Passwort`}</Label>
          <InputText
            type="password"
            className="w3-block w3-border"
            value={password || '**********'}
            disabled
          />
        </div>
        <div className="w3-col s4 pad-lr pad-top">
          <Label htmlFor="contact">{`2FA`}</Label>
          <SelectButton
            id="contact"
            options={USER_CONTACT_OPTIONS}
            value={preferredContactMethod}
            onChange={(event) => this.setState({preferredContactMethod: event.value})}
          />
        </div>
      </div>
    )
    return (
      <div className={`${className} check-data-form`}>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s4 pad-lr pad-top form-group-item ${error.lastname ? ' error-group' : ''}`}>
            <Label htmlFor="lastname">{`Nachname`}<span>*</span></Label>
            <InputText
              id="lastname"
              className="w3-block w3-border"
              value={lastname}
              disabled={userExists}
              onChange={(event) => this.setState({lastname: event.target.value})}
            />
          </div>
          <div className={`w3-col s4 pad-lr pad-top form-group-item ${error.firstname ? ' error-group' : ''}`}>
            <Label htmlFor="firstname">{`Vorname`}<span>*</span></Label>
            <InputText
              id="firstname"
              className="w3-block w3-border"
              value={firstname}
              disabled={userExists}
              onChange={(event) => this.setState({firstname: event.target.value})}
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className={`w3-col s4 pad-lr pad-top form-group-item ${error.email ? ' error-group' : ''}`}>
            <Label htmlFor= "email">{`E-Mail`}<span>*</span></Label>
            <InputText
              id="email"
              className="w3-block w3-border"
              value={email}
              type="email"
              onChange={(event) => this.setState({email: event.target.value})}
            />
            {error.email && (<div className="pad-sm-top">{error.email}</div>)}
          </div>
          <div className={`w3-col s4 pad-lr pad-top form-group-item ${error.cellNumber ? ' error-group' : ''}`}>
            <Label htmlFor="cellNumber">{`Telefon (mobil)/Festnetz`}<span>*</span></Label>
            <InputText
              id="cellNumber"
              className="w3-block w3-border"
              value={cellNumber}
              placeholder="+49177123457"
              onChange={(event) => this.setState({cellNumber: event.target.value})}
            />
            {error.cellNumber && (<div className="pad-sm-top">{error.cellNumber}</div>)}
          </div>
          {validationBtn}
        </div>
        {generatedMarkup}
      </div>
    );
  }
}
