import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from "primereact/radiobutton";
import { Label, InputText, Dropdown } from "../../../components/Inputs";
import HtmlContentForm from "../../Static/components/content/HtmlContentForm";
import LinkForm from "./LinkForm";

export default class NewsArticleForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      title: "",
      subtitle: "",
      category_id: 1,
      type_id: 1,
      isPrivate: 1,
      body: "",
    },
    companies: [],
  };
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      title: model.title || "",
      subtitle: model.subtitle != null ? model.subtitle : "",
      isPrivate: model.isPrivate != null ? model.isPrivate : 1,
      body: model.body || "",
      category_id: model.category_id || 1,
      type_id: model.type_id || 1,
      // ...model,
      error: {
        title: null,
        isPrivate: null,
        type_id: null,
      },
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (error[key] !== undefined && model[key] === "") {
        error[key] = true;
        isValid = false;
      }
    });
    // body
    const bodyModel = this.bodyForm.getModel();
    if (!bodyModel) {
      isValid = false;
    } else {
      model.body = bodyModel.body;
    }
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    if (this.props.model.id) {
      model.id = this.props.model.id;
    }
    // console.log('NewsArticleForm.validate()', model);
    return model;
  };
  getModel = () => {
    const model = this.validate();
    if (model) {
      model.isPrivate = model.isPrivate === "0" ? 0 : 1;
    }
    return model;
  };
  render() {
    const { className, model, disabled } = this.props;
    const { title, subtitle, isPrivate, type_id, error } = this.state;
    // console.log('NewsArticleForm.render()', this.state);
    const TypeOptions = [
      { label: "Text", value: 1 },
      { label: "Verweis auf eine Seite", value: 2 },
    ];
    let typeMarkup = (
      <HtmlContentForm
        ref={(ref) => (this.bodyForm = ref)}
        model={model}
        disabled={disabled}
      />
    );
    if (`${type_id}` !== "1") {
      typeMarkup = (
        <LinkForm
          ref={(ref) => (this.bodyForm = ref)}
          model={model}
          disabled={disabled}
        />
      );
    }
    return (
      <div className={className}>
        <div className={`form-group-item ${error.title && "error-group"}`}>
          <Label htmlFor="title">{`Titel*`}</Label>
          <InputText
            id="title"
            className="w3-block w3-border"
            value={title}
            onChange={(event) => this.setState({ title: event.target.value })}
            disabled={disabled}
          />
        </div>
        <div className={`form-group-item ${error.subtitle && "error-group"}`}>
          <Label htmlFor="subtitle">{`Untertitel`}</Label>
          <InputText
            id="subtitle"
            className="w3-block w3-border"
            value={subtitle || ""}
            onChange={(event) =>
              this.setState({ subtitle: event.target.value })
            }
            disabled={disabled}
          />
        </div>
        <div className="neg-margin-lr w3-row">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Sichtbarkeit*`}</Label>
            <div className="w3-row">
              <div className="w3-col s6">
                <div className="p-col-12">
                  <RadioButton
                    inputId={`private`}
                    name="isPrivate"
                    value="1"
                    checked={`${isPrivate}` !== "0"}
                    onChange={(event) =>
                      this.setState({ isPrivate: event.value })
                    }
                    disabled={disabled}
                  />
                  <label
                    htmlFor={`private`}
                    className="p-radiobutton-label pad-lft"
                  >{`Nur sichtbar für angemeldete Benutzer`}</label>
                </div>
              </div>
              <div className="w3-col s6">
                <div className="p-col-12">
                  <RadioButton
                    inputId={`public`}
                    name="isPrivate"
                    value="0"
                    checked={`${isPrivate}` === "0"}
                    onChange={(event) =>
                      this.setState({ isPrivate: event.value })
                    }
                    disabled={disabled}
                  />
                  <label
                    htmlFor={`public`}
                    className="p-radiobutton-label pad-lft"
                  >{`Für alle sichtbar`}</label>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w3-col s6 pad-lr pad-top form-group-item ${
              error.type_id ? " error-group" : ""
            }`}
          >
            <Label htmlFor="type_id">{`Art von Beitragsinhalt*`}</Label>
            <Dropdown
              id="type_id"
              className="w3-block w3-border"
              value={type_id}
              options={TypeOptions}
              onChange={(event) => this.setState({ type_id: event.value })}
              showClear
              disabled={disabled}
            />
          </div>
        </div>
        <div className="pad-top">
          <Label htmlFor="body*">{`Inhalt`}</Label>
          {typeMarkup}
        </div>
      </div>
    );
  }
}
