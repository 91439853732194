import React from "react";
import {
  PageHeight100,
  PageHeight20,
  PageHeight30,
  PageHeight40,
  PageHeight60,
  PageHeight80,
  PageHeightAuto,
  PageHeightCustom,
} from "../../../components/Utils";

export default function PermissionSection({
  height = "auto",
  iseditable,
  children,
  ...props
}) {
  let Component = PageHeightAuto;
  switch (height) {
    case "20":
      Component = PageHeight20;
      break;
    case "30":
      Component = PageHeight30;
      break;
    case "40":
      Component = PageHeight40;
      break;
    case "60":
      Component = PageHeight60;
      break;
    case "80":
      Component = PageHeight80;
      break;
    case "100":
      Component = PageHeight100;
      break;
    case "custom":
      Component = PageHeightCustom;
      break;
    default:
      break;
  }
  return (
    <Component {...props}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          iseditable,
          disabled: iseditable !== "true",
        });
      })}
    </Component>
  );
}
