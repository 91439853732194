import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PageHeight40,
  PageTitle,
  scrollToTop,
  TableEmptyMessage,
  toUIDate,
  ScrollableTableHolder,
} from "../../../../components/Utils";
import ItemListManager from "../../../../components/ItemListManager";
import EmployeeCompanyChangeTaskMenu from "../../components/user/EmployeeCompanyChangeTaskMenu";
import SalesTablePageReport from "../../../Reporting/components/SalesTablePageReport";
import { connect } from "react-redux";
import {
  closeEmployeeTasks,
  deleteUserDeviceFromTask,
  updateUserDeviceFromTask,
} from "../../actions/employee";
import UserDeviceFormHolder from "../../../Device/containers/UserDeviceFormHolder";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

const shouldCreateDeleteTask = (device) => true;
// device.macAddress && device.macAddress !== "";

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    employeeCompanyChangeTasks: state.task.employeeCompanyChangeTasks,
  };
};
const mapDispatchToProps = (dispatch) => ({
  closeTasks: (...args) => dispatch(closeEmployeeTasks(...args)),
  saveDevice: (...args) => dispatch(updateUserDeviceFromTask(...args)),
  deleteDevice: (...args) => dispatch(deleteUserDeviceFromTask(...args)),
});

class EmployeeCompanyChangeTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    rows: PropTypes.number,
    employeeCompanyChangeTasks: PropTypes.arrayOf(PropTypes.shape({})),
    employeeTaskType: PropTypes.string,
    // closeTasks: PropTypes.func.isRequired,
    saveDevice: PropTypes.func.isRequired,
    deleteDevice: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
    rows: 10,
    employeeCompanyChangeTasks: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      first: null,
      selection: [],
    };
  }
  componentDidMount() {
    scrollToTop();
  }
  // onClose = (task) => {
  //   const { closeTasks } = this.props;
  //   closeTasks({ tasks: [task] });
  // };
  onSave = (task, onHide) => {
    console.log(">> saving task", task);
    this.props.saveDevice(task, onHide);
  };
  onDelete = (task) => {
    console.log(">> creating delete task for user device", task);
    this.props.deleteDevice({
      ...task,
      companyId: task.companyId || task.company_id,
    });
  };
  confirmDeprovisioning = (task, onHide) => {
    this.confirm.show(
      true,
      { title: `Messgeräte deprovisionieren`,
        text: `Wollen Sie dieses Messgerät deprovisionieren? Eine Löschaufgabe wird erstellt.`
      },
      () => {
        onHide && onHide();
        this.onDelete(task);
      }
    )
  };
  render() {
    const {
      className,
      isLoading,
      rows,
      employeeCompanyChangeTasks,
      // closeTasks,
      disabled,
    } = this.props;
    const { selection } = this.state;
    const setSelection = (value) => this.setState({ selection: value });

    const columnStyle = {
      verticalAlign: "top",
      paddingTop: "16px",
      paddingBottom: "16px",
    };
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map((item) => {
        const userNames = `${item.oldUserPrimary}`.split(",");
        let employee = "";
        if (userNames.length === 2) {
          employee = `${userNames[1]} ${userNames[0]}`;
        }
        return {
          ...item,
          employee, //: `${item.userData.firstname} ${item.userData.lastname}`,
          requester: `${item.requester || ""}`.split(",").join(" "),
          requestDateUI: toUIDate(item.request_date, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
          requestDate: toUIDate(item.request_date, "YYYY-MM-DDTHH:mm:ss:SSSZ", "YYYY.MM.DD"),
        };
      });
      return (
        <ScrollableTableHolder>
          <DataTable
            value={taskItems}
            paginator={taskItems.length > rows}
            rows={rows}
            first={this.state.first}
            onPage={(event) => {
              this.setState({ first: event.first });
              scrollToTop();
            }}
            paginatorLeft={
              <SalesTablePageReport
                className="pad-lft"
                totalRecords={taskItems.length}
                filteredRecords={taskItems.length}
                first={this.state.first}
                rows={rows}
                emptyText="Keine Subunternehmen-Änderungsaufgaben"
                itemName="Subunternehmen-Änderungsaufgabe"
                itemNamePlural="Subunternehmen-Änderungsaufgaben"
              />
            }
            selection={selection}
            onSelectionChange={(event) => setSelection(event.value)}
            removableSort
            sortMode="multiple"
            emptyMessage={
              isLoading ? null : (
                <TableEmptyMessage itemNamePlural="Subunternehmen-Änderungsaufgaben" />
              )
            }
            scrollable
          >
            {/* <Column
              id="icheck"
              selectionMode="multiple"
              style={{ width: "3em" }}
            /> */}
            <Column
              field="parentCompanyName"
              header="Vertragspartner"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "16em" }}
            />
            <Column
              field="employee"
              header="Mitarbeiter"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "14em" }}
            />
            <Column
              field="companyName"
              header="Von Subunternehmen"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "14em" }}
            />
            <Column
              field="newCompanyName"
              header="Zu Subunternehmen"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "14em" }}
            />
            <Column
              field="requester"
              header="Beantrager"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "12em" }}
            />
            <Column
              field="requestDate"
              header="Erstelldatum"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "10em", textAlign: "center" }}
              body={item => item.requestDateUI}
            />
            {/* <Column
              field="status"
              header="Status"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{textAlign:'center-', width: '9em'}}
            /> */}
            <Column
              body={actionTemplate}
              style={{
                verticalAlign: "top",
                textAlign: "center",
                width: "5em",
              }}
            />
          </DataTable>
        </ScrollableTableHolder>
      );
    };

    const renderItemForm = (item, onHide) => {
      const userDevice = {
        ...item,
        // company_id: item.oldCompanyId,
        // companyName: item.oldCompanyName,
        // newCompanyId: item.company_id,
        // newCompanyName: item.companyName,
      };
      return (
        <div>
          <UserDeviceFormHolder
            key={Math.random()}
            userDevice={userDevice}
            disabled={disabled}
            onSave={(model) => this.onSave(model, onHide)}
            onCancel={onHide}
            updateBtnLabel="Deprovisionieren"
            onUpdate={model => this.confirmDeprovisioning(model, onHide)}
            isTask
            isUserTask
          />
        </div>
      );
    };
    const getItemFormTitle = (/* employeeTask */) => {
      let title = "Subunternehmensänderung: Aufgabe bearbeiten";
      return <h3 className="no-margin">{title}</h3>;
    };
    const title = (
      <PageTitle>
        {/* <EmployeeCompanyChangeTaskMenu
          className="w3-right"
          selectedTasks={selection}
          // closeTasks={closeTasks}
          // exportTasks={exportTasks}
          items={employeeCompanyChangeTasks}
        /> */}
        <h3 className="no-margin pad-btm">{"Änderung Subunternehmen"}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={employeeCompanyChangeTasks}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`Subunternehmen-Änderungs`}
          // iconTitles={{delete: "deprovisionieren"}}
          // itemDeleteable
          // deleteTitle={`Messgeräte deprovisionieren`}
          // getDeleteText={(item) => {
          //   console.log(">> deleting", item);
          //   let suffix = "";
          //   if (shouldCreateDeleteTask(item)) {
          //     suffix = " Eine Löschaufgabe wird erstellt.";
          //   } else {
          //     suffix =
          //       " Für dieses Messgerät ist kein Account hinterlegt, daher wird es direkt gelöscht!";
          //   }
          //   return `Wollen Sie dieses Messgerät löschen?${suffix}`;
          // }}
          // onDelete={this.onDelete}
        />
        <ConfirmationDialog ref={(ref) => (this.confirm = ref)}/>
      </PageHeight40>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCompanyChangeTaskList);
