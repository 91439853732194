import React from 'react';
import PropTypes from 'prop-types';
import { FormMenu } from '../../../components/Buttons';
import TechKeyForm from '../components/wizard/TechKeyForm';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadAvailableKeys } from '../../Product/actions/keys';

const frontload = async (props) => {
  const { companyName, companyKeys, getAvailableKeys } = props;
  // console.log('>> prefetching:', companyName, getAvailableKeys);
  await getAvailableKeys({ companyName, companyKeys: [] });
};
const mapStateToProps = (state) => {
  return {
    techKeys: state.key.avialableKeys,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAvailableKeys: (...args) => dispatch(loadAvailableKeys(...args)),
});

class TechKeyListFormHolder extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    techKeys: PropTypes.array,
    companyName: PropTypes.string,
    companyKeys: PropTypes.array,
    model: PropTypes.shape({}).isRequired,
    getAvailableKeys: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: '',
    techKeys: []
  }
  getModel = () => {
    return this.form.getModel();
  }
  onSave = () => {
    const model = this.getModel();
    const { onSave } = this.props;
    onSave && onSave(model);
  }
  render() {
    console.log('TechKeyListFormHolder.render()', this.props);
    const { className, model, techKeys, onCancel, disabled } = this.props;
    return (
      <div className={className}>
        <TechKeyForm
          ref={(ref) => this.form = ref}
          model={model}
          techKeys={techKeys}
          disabled={disabled || model.id || (model.number && model.number !== '')}
        />
        <FormMenu
          className="margin-big-top w3-border-top pad-big w3-light-grey"
          style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
          onSave={this.onSave}
          onCancel={onCancel}
          saveBtnName="saveTechKeyBtn"
          cancelBtnName="cancelTechKeyBtn"
          saveHidden={disabled}/>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(TechKeyListFormHolder)
);
