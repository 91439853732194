import { loadOpenTasks } from '.';
import { TASK_SECTION_TYPES } from '../components/Utils';

export const SET_SELECTED_MS_AUTHENTICATOR = 'task/ms-authenticator-task/set';

export const DELETE_MS_AUTHENTICATOR_TASK = 'tasks/ms-authenticator-tasks/delete';
export const DELETE_MS_AUTHENTICATOR_TASK_SUCCESS = 'tasks/ms-authenticator-tasks/delete/success';
export const DELETE_MS_AUTHENTICATOR_TASK_FAILURE = 'tasks/ms-authenticator-tasks/delete/failure';

export const CLOSE_MS_AUTHENTICATOR_TASK = 'tasks/ms-authenticator-tasks/close';
export const CLOSE_MS_AUTHENTICATOR_TASK_SUCCESS = 'tasks/ms-authenticator-tasks/close/success';
export const CLOSE_MS_AUTHENTICATOR_TASK_FAILURE = 'tasks/ms-authenticator-tasks/close/failure';


export const setSelectedMSAuthenticatorTasks = (selected) => {
  // console.log(`REDUX-ACTION: setSelectedMSAuthenticatorTasks()`, selected);
  return { type: SET_SELECTED_MS_AUTHENTICATOR, selected };
}

export const closeMSAuthenticatorTasks = (tasks, onSuccess) => {
  return {
    types: [ 
      CLOSE_MS_AUTHENTICATOR_TASK, 
      CLOSE_MS_AUTHENTICATOR_TASK_SUCCESS, 
      CLOSE_MS_AUTHENTICATOR_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/NEW-MS-AUTHENTICATOR`, { data: tasks }),
    onSuccess: dispatch => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.IT_CONNECTIONS));
    },
    tasks
  };
};

export const deleteMSAuthenticatorTask = (task, onSuccess) => {
  return {
    types: [ 
      DELETE_MS_AUTHENTICATOR_TASK, 
      DELETE_MS_AUTHENTICATOR_TASK_SUCCESS, 
      DELETE_MS_AUTHENTICATOR_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/reset/NEW-MS-AUTHENTICATOR`, { data: [task] }),
    onSuccess: dispatch => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.IT_CONNECTIONS));
    },
    task
  };
};
