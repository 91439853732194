import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "primereact/checkbox";

export default class GroupForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: [],
  };
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = { model };
  }
  validate = () => {
    const { model } = this.state;
    return model;
  };
  getModel = () => {
    const model = this.validate();
    return model;
  };
  onCheckboxChange = (event) => {
    let model = [...this.state.model];
    if (event.checked) model.push(event.value);
    else model.splice(model.indexOf(event.value), 1);
    this.setState({ model });
  };
  render() {
    const { className, disabled } = this.props;
    const { model } = this.state;
    const createCheckbox = (prefix) => (value, index) =>
      (
        <div key={index} className="p-col-12" id={value.split(" ").join("")}>
          <Checkbox
            inputId={`${prefix}${index}`}
            value={value}
            onChange={this.onCheckboxChange}
            checked={model.includes(value)}
            disabled={disabled}
          ></Checkbox>
          <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">
            {value}
          </label>
        </div>
      );
    const col1 = ["NE5", "NE4"].map(createCheckbox("group-cb1-"));
    const col2 = ["NE3", "TB"].map(createCheckbox("group-cb2-"));
    const col3 = ["GF", "Q-Prüfung"].map(createCheckbox("group-cb3-"));
    return (
      <div className={className}>
        <div className="neg-margin-lr">
          <div className="w3-row">
            <div className="w3-col s4 pad-lr">{col1}</div>
            <div className="w3-col s4 pad-lr">{col2}</div>
            <div className="w3-col s4 pad-lr">{col3}</div>
          </div>
        </div>
      </div>
    );
  }
}
