import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  EditIconNavButton,
  DeactivateIconButton,
  ActivateIconButton,
} from "../../../components/Buttons";
import {
  scrollToTop,
  TableEmptyMessage,
  USER_STATUS_IDS,
  USER_STATUS_LABELS,
} from "../../../components/Utils";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadFilteredWorkers,
  activateWorker,
  deactivateWorker,
  defaultFilter,
} from "../actions";
import SalesTableUtil from "../../Reporting/components/SalesTableUtil";
import SalesTablePageReport from "../../Reporting/components/SalesTablePageReport";
import { PermissionResource, Resources } from "../../Permissions";
import { isResourceReadable } from "../../Permissions/actions";

const getFilterValues = (props, current) => {
  let values = { ...defaultFilter };
  if (current) {
    values = {
      ...values,
      rows: props.rows || defaultFilter.rows,
      ...current,
    };
  }
  return values;
};

const frontload = async (props) => {
  const filterValues = getFilterValues(props);
  await props.getWorkers(null, filterValues);
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.worker.loading,
    header: state.worker.header,
    workers: state.worker.filteredWorkers,
    roles: state.worker.roles,
    permissions: state.permissions.permissionGroup.admindataaccess,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getWorkers: (...args) => dispatch(loadFilteredWorkers(...args)),
  activateWorker: (...args) => dispatch(activateWorker(...args)),
  deactivateWorker: (...args) => dispatch(deactivateWorker(...args)),
});

class WorkerTable extends React.Component {
  static propTypes = {
    workers: PropTypes.array,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    activateWorker: PropTypes.func.isRequired,
    deactivateWorker: PropTypes.func.isRequired,
    rows: PropTypes.number,
    permissions: PropTypes.shape({}),
    className: PropTypes.string,
  };
  static defaultProps = {
    rows: 10,
    className: "",
    roles: [],
  };
  constructor(props) {
    super();
    this.filterValues = getFilterValues(props);
    this.filterTimerId = null;
    this.sortSelection = [];
    this.state = {
      first: 0,
      filteredCount: (props.workers || []).length,
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onEditWorker = (worker, history) => {
    history.push(`/wizard/organisation/mitarbeiter/${worker.id}`);
  };
  onDeactivateWorker = (worker) => {
    this.dialog.show(
      true,
      {
        title: "Mitarbeiter deaktivieren",
        text: `Wollen Sie den Mitarbeiter ${worker.firstname} ${worker.lastname} wirklich deaktivieren? Eine Aufgabe wird erstellt.`,
      },
      () => this.props.deactivateWorker({ worker })
    );
  };
  onActivateWorker = (worker) => {
    this.dialog.show(
      true,
      {
        title: "Mitarbeiter aktivieren",
        text: `Wollen Sie den Mitarbeiter ${worker.firstname} ${worker.lastname} wirklich aktivieren?`,
      },
      () => this.props.activateWorker(worker)
    );
  };
  actionTemplate = (rowData) => {
    const { disabled, permissions } = this.props;
    const canReadAdminData = isResourceReadable(
      Resources.AdminDataAccess,
      permissions
    );
    const editId = `editWorkerBtn${rowData.id}`;
    const editBtn = (
      <PermissionResource resource={Resources.Organisation.WorkerWizard.Page}>
        <>
          <EditIconNavButton
            id={editId}
            name={editId}
            onClick={(history) => this.onEditWorker(rowData, history)}
          />
        </>
      </PermissionResource>
    );
    const actId = `actWorkerBtn${rowData.id}`;
    const activateBtn = !disabled &&
      rowData.status_id === USER_STATUS_IDS.DEACTIVATED && (
        <ActivateIconButton
          id={actId}
          name={actId}
          onClick={() => this.onActivateWorker(rowData)}
          disabled={!canReadAdminData}
        />
      );
    const deactId = `deactWorkerBtn${rowData.id}`;
    const deactivateBtn = !disabled &&
      rowData.status_id !== USER_STATUS_IDS.DEACTIVATED && (
        <DeactivateIconButton
          id={deactId}
          name={deactId}
          onClick={() => this.onDeactivateWorker(rowData, canReadAdminData)}
          disabled={rowData.status_id !== USER_STATUS_IDS.APPROVED}
        />
      );
    return (
      <div>
        {editBtn}
        <PermissionResource
          resource={Resources.Organisation.WorkerWizard.BasicInfo}
          showOnlyIfEditable
        >
          {activateBtn}
          {deactivateBtn}
        </PermissionResource>
      </div>
    );
  };
  onLazyLoad = (event) => {
    const filterValues = { ...this.filterValues };
    filterValues.first = event.first;
    filterValues.rows = event.rows;
    this.props.getWorkers(
      () => this.setState({ first: event.first }),
      filterValues
    );
  };
  onValueChange = (nextValue) => {
    if (nextValue) {
      this.setState({
        first: 0,
        filteredCount: nextValue.length,
      });
    }
  };
  onFilterChange = (field, value, noTimeout, noFilter) => {
    // console.log("WorkerTable.onFilterChange()", field, value, noTimeout);
    this.filterValues[field] = value;
    !noFilter && this.setState({ first: 0 });
    if (noTimeout) {
      return !noFilter && this.props.getWorkers(null, this.filterValues);
    }
    if (this.filterTimerId) {
      clearTimeout(this.filterTimerId);
    }
    const self = this;
    this.filterTimerId = setTimeout(() => {
      !noFilter && self.props.getWorkers(null, self.filterValues);
    }, 1000);
  };
  onSort = (event) => {
    this.filterValues.sortFields = event.multiSortMeta.map((item) => ({
      ...item,
    }));
    this.props.getWorkers(null, { ...this.filterValues });
  };
  render() {
    const { className, isLoading, workers, rows, header, roles } = this.props;
    if (!workers) {
      return null;
    }

    const totalRecords = Number(
      header ? header["x-result-count"] : workers.length
    );
    const { first, filteredCount } = this.state;
    const data = (workers || []).map((worker, index) => {
      let role = "",
        companyName = "";
      if (worker.companies && worker.companies.length > 0) {
        // if (worker.companies.length === 1) {
        //   companyName = worker.companies[0].name;
        //   if (worker.companies[0].roles && worker.companies[0].roles.length > 0) {
        //     worker.companies[0].roles.sort((a, b) => a.role_id - b.role_id);
        //     role = worker.companies[0].roles[0].role_label;
        //   }
        //   if (!role || role === '') {
        //     role = worker.companies[0].role_label || 'Mitarbeiter'; // alternatively extended roles
        //   }
        // } else {
        //   companyName = [];
        //   worker.companies.forEach(pCompany => {
        //     if (pCompany.role_label) { role = pCompany.role_label; }
        //     companyName.push(pCompany.name);
        //   });
        //   companyName = companyName.join(', ');
        // }
        role = [];
        companyName = [];
        worker.companies.forEach((pCompany) => {
          // console.log("CompanyRoles", pCompany.roles);
          role = role.concat(pCompany.roles || []);
          companyName.push(pCompany.name);
        });
        role = role.map((item) => item.role_label).join(", ");
        companyName = companyName.join(", ");
      }
      return {
        ...worker,
        index,
        role,
        companyName,
        modified: moment(
          worker.modified_date || worker.created_date,
          "YYYY-MM-DDTHH:mm:ss:SSSZ"
        ).format("DD.MM.YYYY HH:mm"),
        status_id: worker.status_id || USER_STATUS_IDS.APPROVED,
        status:
          worker.status ||
          USER_STATUS_LABELS[worker.status_id || USER_STATUS_IDS.APPROVED],
      };
    });
    const role = roles.map((item) => ({
      label: item.label,
      value: item.label,
    }));
    const status_id = [
      {
        label: USER_STATUS_LABELS[USER_STATUS_IDS.APPROVED],
        value: USER_STATUS_IDS.APPROVED,
      },
      {
        label: USER_STATUS_LABELS[USER_STATUS_IDS.DEACTIVATED],
        value: USER_STATUS_IDS.DEACTIVATED,
      },
      {
        label: USER_STATUS_LABELS[USER_STATUS_IDS.DEACTIVATION_PENDING],
        value: USER_STATUS_IDS.DEACTIVATION_PENDING,
      },
    ];
    const optionMap = { role, status_id };

    // filters
    const useInputFilter = SalesTableUtil.createUseInputFilter(
      this.dt,
      400,
      this.onFilterChange
    );
    const useMultiSelectFilter = SalesTableUtil.createUseMultiSelectFilter(
      this.dt,
      data,
      optionMap,
      this.onFilterChange
    );
    const useRangeFilter = SalesTableUtil.createUseRangeFilter(
      this.dt,
      this.onFilterChange
    );
    const usernameFilter = useInputFilter("username", { filterOnChange: true });
    const roleFilter = useMultiSelectFilter("role");
    const companyNameFilter = useInputFilter("companyName", {
      filterOnChange: true,
    });
    const modifiedFilter = useRangeFilter("modified");
    const statusFilter = useMultiSelectFilter("status_id");
    const columnStyle = {
      verticalAlign: "top",
      paddingTop: "16px",
      paddingBottom: "16px",
    };

    return (
      <div className={`worker-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          ref={(ref) => (this.dt = ref)}
          onValueChange={this.onValueChange}
          first={first}
          lazy={true}
          totalRecords={totalRecords}
          onPage={(event) => this.onLazyLoad(event)}
          paginator
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={totalRecords}
              filteredRecords={filteredCount}
              first={first}
              rows={rows}
              emptyText="Keine Mitarbeiter"
              itemName="Mitarbeiter"
              itemNamePlural="Mitarbeiter"
            />
          }
          removableSort
          sortMode="multiple"
          multiSortMeta={data.length <= 1 ? [] : this.filterValues.sortFields}
          onSort={this.onSort}
          emptyMessage={
            isLoading ? null : (
              <TableEmptyMessage itemNamePlural="Mitarbeiter" />
            )
          }
        >
          <Column
            field="username"
            header="Mitarbeiter"
            sortable={data.length > 1}
            filter
            {...usernameFilter}
            style={columnStyle}
          />
          <Column
            field="role"
            header="Position"
            filter
            {...roleFilter}
            style={columnStyle}
            body={(item) => (
              <>
                {item.role.split(", ").map((role) => (
                  <label key={role} className="w3-block">
                    {role}
                  </label>
                ))}
              </>
            )}
          />
          <Column
            field="companyName"
            header="Beschäftigungsfirma"
            filter
            {...companyNameFilter}
            style={columnStyle}
          />
          <Column
            field="modified"
            header="Letzte Änderung"
            filter
            {...modifiedFilter}
            style={{ ...columnStyle, textAlign: "center", width: "12em" }}
          />
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter
            {...statusFilter}
            body={(rowData) => rowData.status}
            style={{ ...columnStyle, textAlign: "center", width: "10em" }}
          />
          <Column
            body={this.actionTemplate}
            style={{
              verticalAlign: "top",
              textAlign: "center",
              width: "7.5em",
            }}
          />
        </DataTable>
        <ConfirmationDialog ref={(ref) => (this.dialog = ref)} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(WorkerTable)
);
