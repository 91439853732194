import {
  itemIsTask,
  ACTION_IDS,
  STATUS_IDS,
  DEVICE_ACTION_IDS,
  EMPLOYEE_TASKTYPE_IDS,
} from "../../../components/Utils";
// import { TEST, OrderManager } from '../../Product/actions/data';

export const TASK_SECTION_TYPES = {
  DEBITORS: "DEBITORS",
  IT_CONNECTIONS: "IT-CONNECTIONS",
  DEVICES: "DEVICES",
  MODEMS: "MODEMS",
  KEYS: "KEYS",
  FLYERS: "FLYERS",
  CONTACT_CHANGE: "EMPLOYEE_EMAIL_PHONE_CHANGED",
  FAILED_EMAILS: "FAILED_EMAILS",
  COMPANY_CHANGED: "EMPLOYEE_COMPANY_CHANGED",
  EMPLOYEE_DEACTIVATION: "EMPLOYEE_DEACTIVATION",
};

function getSectionTasks(newTasks, oldTasks, referenceTaskType, taskType) {
  let tasks = newTasks || oldTasks;
  if (taskType && taskType === referenceTaskType) {
    tasks = newTasks || [];
  }
  return tasks;
}

export const parseTaskResults = (data, state, taskType) => {
  let taskCounter = 0;
  console.log("parseTaskResults()", data, state, taskType)
  console.log("taskCounter:", taskCounter)

  // Debitors
  const debitorTasks = getSectionTasks(
    data.debitorTasks,
    state.debitorTasks,
    TASK_SECTION_TYPES.DEBITORS,
    taskType
  );
  // const debitorTasks = data.debitorTasks || state.debitorTasks;
  taskCounter += debitorTasks.length;

  // Devices
  const deviceTasks = getSectionTasks(
    data.deviceTasks,
    state.deviceTasks,
    TASK_SECTION_TYPES.DEVICES,
    taskType
  ).filter((task) => {
  // const deviceTasks = (data.deviceTasks || state.deviceTasks).filter((task) => {
    return itemIsTask(task.status_id);
  });
  taskCounter += deviceTasks.length;

  // IT Connections
  const itTasks = {
    adConnections: state.adConnections,
    ptConnections: state.ptConnections,
    delphiConnections: state.delphiConnections,
    msAuthenticators: state.msAuthenticatorTasks,
    ...data.itTasks,
  };
  const adConnections = [];
  const ptConnections = [];
  const delphiConnections = [];
  const msAuthenticatorTasks = [];

  // adConnections
  (itTasks.adConnections || []).forEach((connection) => {
    if (itemIsTask(connection.status_id)) {
      adConnections.push(connection);
    }
  });
  taskCounter += adConnections.length;

  // ptConnections
  (itTasks.ptConnections || []).forEach((connection) => {
    if (itemIsTask(connection.status_id)) {
      ptConnections.push(connection);
    }
  });
  taskCounter += ptConnections.length;

  // delphiConnections
  (itTasks.delphiConnections || []).forEach((connection) => {
    if (itemIsTask(connection.status_id)) {
      delphiConnections.push(connection);
    }
  });
  taskCounter += delphiConnections.length;

  // msAuthenticatorTasks
  (itTasks.msAuthenticators || []).forEach((task) => {
      msAuthenticatorTasks.push(task);
    // }
  });
  taskCounter += msAuthenticatorTasks.length;

  // Modem Orders
  const modemOrders = data.modemTasks || state.modemOrders ;
  taskCounter += modemOrders.length;

  // Key Orders
  const keyOrders = getSectionTasks(
    data.keyTasks,
    state.keyOrders,
    TASK_SECTION_TYPES.KEYS,
    taskType
  );
  // const keyOrders = data.keyTasks || state.keyOrders;
  taskCounter += keyOrders.length;

  // Card Orders
  const cardOrders = getSectionTasks(
    data.flyerTasks,
    state.cardOrders,
    TASK_SECTION_TYPES.FLYERS,
    taskType
  );
  // const cardOrders = data.flyerTasks || state.cardOrders;
  taskCounter += cardOrders.length;

  // User/Employee Tasks
  const employeeTasks = getSectionTasks(
    data.employeeTasks,
    state.employeeTasks,
    TASK_SECTION_TYPES.CONTACT_CHANGE,
    taskType
  ).map((task) => {
  // const employeeTasks = (data.employeeTasks || state.employeeTasks).map((task) => {
    let type = task.type || null;
    let fromValue = task.fromValue;
    let toValue = task.toValue;
    if (!type) {
      const keys = Object.keys(task);
      const fromKey = keys.find((item) => item.indexOf("from") === 0);
      if (fromKey) {
        switch (fromKey) {
          case "fromEmail":
            type = EMPLOYEE_TASKTYPE_IDS.EMAIL_CHANGE;
            fromValue = task.fromEmail;
            toValue = task.toEmail;
            break;
          case "fromCellNumber":
            type = EMPLOYEE_TASKTYPE_IDS.CELLNUMBER_CHANGE;
            fromValue = task.fromCellNumber;
            toValue = task.toCellNumber;
            break;
          case "fromTelephoneNumber":
            type = EMPLOYEE_TASKTYPE_IDS.TELEPHONENUMBER_CHANGE;
            fromValue = task.fromTelephoneNumber;
            toValue = task.toTelephoneNumber;
            break;
        }
      }
    }
    return {
      ...task,
      type,
      fromValue,
      toValue,
    };
  });
  taskCounter += employeeTasks.length;

  // Invalid Email Addresses ()
  const failedEmailAddresses = getSectionTasks(
    data.failedEmailAddresses,
    state.failedEmailAddresses,
    TASK_SECTION_TYPES.FAILED_EMAILS,
    taskType
  );
  // const failedEmailAddresses = data.failedEmailAddresses || state.failedEmailAddresses;
  taskCounter += failedEmailAddresses.length;

  // Employee Deactivation Tasks
  const employeeDeactivationTasks = getSectionTasks(
    data.employeeDeactivationTasks,
    state.employeeDeactivationTasks,
    TASK_SECTION_TYPES.EMPLOYEE_DEACTIVATION,
    taskType
  );
  // const employeeDeactivationTasks = data.employeeDeactivationTasks || state.employeeDeactivationTasks;
  taskCounter += employeeDeactivationTasks.length;

  // Employee Contractor Change Tasks
  const employeeCompanyChangeTasks = getSectionTasks(
    data.employeeCompanyChangeTasks,
    state.employeeCompanyChangeTasks,
    TASK_SECTION_TYPES.COMPANY_CHANGED,
    taskType
  );
  // const employeeCompanyChangeTasks = data.employeeCompanyChangeTasks || state.employeeCompanyChangeTasks;
  taskCounter += employeeCompanyChangeTasks.length;

  return {
    taskCounter,
    debitorTasks,
    deviceTasks,
    adConnections,
    ptConnections,
    delphiConnections,
    msAuthenticatorTasks,
    modemOrders,
    keyOrders,
    cardOrders,
    employeeTasks,
    failedEmailAddresses,
    employeeDeactivationTasks,
    employeeCompanyChangeTasks,
  };
};

export const ConnectionTypes = {
  IT_CONNECTION: "IT-CONNECTION",
  PT_CONNECTION: "PT-CONNECTION",
  AD_CONNECTION: "AD-CONNECTION",
  DELPHI_CONNECTION: "DELPHI-CONNECTION",
};

export const TASK_TYPES = {
  CREATE: "NEW",
  UPDATE: "RENEW",
  DELETE: "DELETE",
};
export const CONNECTION_TASK_TYPES = TASK_TYPES;

export const filterTasksByTaskType = (task, taskType) => {
  // console.log("filterTasksByTaskType()", task, taskType);
  if (task && taskType) {
    const prefix = (taskType.split("-") || [])[0];
    switch (prefix) {
      case TASK_TYPES.CREATE:
        return (
          task.action_id === ACTION_IDS.CREATE ||
          task.status_id === STATUS_IDS.NEW
        );
      case TASK_TYPES.UPDATE:
        return (
          task.action_id === ACTION_IDS.UPDATE ||
          task.status_id === STATUS_IDS.UPDATING
        );
      case TASK_TYPES.DELETE:
        return (
          task.action_id === ACTION_IDS.DELETE ||
          task.status_id === STATUS_IDS.DELETING
        );
      default:
        break;
    }
  }
  return false;
};

export const isDeletionTask = (taskType) => {
  let result = false;
  if (taskType) {
    const pos = taskType.indexOf(TASK_TYPES.DELETE);
    result = (pos === 0);
    // const prefix = (taskType.split("-") || [])[0];
    // result = (prefix === TASK_TYPES.DELETE);
  }
  return result;
};

export const countTasksByType = (itemsToCount, taskType) => {
  const prefix = (taskType.split("-") || [])[0];
  let countedItems = [];
  switch (prefix) {
    case TASK_TYPES.CREATE:
      countedItems = itemsToCount.filter(
        (task) =>
          task.action_id === ACTION_IDS.CREATE ||
          task.status_id === STATUS_IDS.NEW
      );
      break;
    case TASK_TYPES.UPDATE:
      countedItems = itemsToCount.filter(
        (task) =>
          task.action_id === ACTION_IDS.UPDATE ||
          task.status_id === STATUS_IDS.UPDATING
      );
      break;
    case TASK_TYPES.DELETE:
      countedItems = itemsToCount.filter(
        (task) =>
          task.action_id === ACTION_IDS.DELETE ||
          task.status_id === STATUS_IDS.DELETING
      );
      break;
    default:
      break;
  }
  const count = countedItems.length;
  return count > 0 ? ` (${count})` : "";
};

export const getTaskTypeOptions = (taskType, itemsToCount) => {
  let type = taskType ? taskType.toUpperCase().trim() : "IT";
  switch (type) {
    case "AD":
    case "PT":
    case "DELPHI":
      type = `${type}-CONNECTION`;
      break;
    default:
      break;
  }
  const list = [
    { label: "Neu Anlage", value: `${TASK_TYPES.CREATE}-${type}` },
    { label: "Veränderung", value: `${TASK_TYPES.UPDATE}-${type}` },
    { label: "Löschung", value: `${TASK_TYPES.DELETE}-${type}` },
  ];
  if (taskType === "AD") {
    list.splice(1, 1);
  }
  return list.map((item) => ({
    ...item,
    label: itemsToCount
      ? `${item.label}${countTasksByType(itemsToCount, item.value)}`
      : item.label,
  }));
};

export const getTaskTypeByActionID = (connectionType, actionId) => {
  switch (actionId) {
    case ACTION_IDS.CREATE:
      return `${TASK_TYPES.CREATE}-${connectionType.toUpperCase()}-CONNECTION`;
    case ACTION_IDS.UPDATE:
      return `${TASK_TYPES.UPDATE}-${connectionType.toUpperCase()}-CONNECTION`;
    case ACTION_IDS.DELETE:
      return `${TASK_TYPES.DELETE}-${connectionType.toUpperCase()}-CONNECTION`;
    default:
      break;
  }
  return null;
};

export const getTaskTypeByStatusID = (connectionType, statusId) => {
  switch (statusId) {
    case STATUS_IDS.NEW:
      return `${TASK_TYPES.CREATE}-${connectionType.toUpperCase()}-CONNECTION`;
    case STATUS_IDS.UPDATING:
      return `${TASK_TYPES.UPDATE}-${connectionType.toUpperCase()}-CONNECTION`;
    case STATUS_IDS.DELETING:
      return `${TASK_TYPES.DELETE}-${connectionType.toUpperCase()}-CONNECTION`;
    default:
      break;
  }
  return null;
};

export const getTaskFormTitle = (taskType, task) => {
  console.log(">> getTaskFormTitle() taskType:", taskType);
  if (taskType) {
    switch (taskType) {
      case "NEW-DEVICE":
        return "Messgerätaufgabe: Neuanlage bestätigen";
      case "RENEW-DEVICE": {
        const action =
          task && task.action_id === DEVICE_ACTION_IDS.DELETE_ACCOUNT
            ? "Accountlöschung"
            : "Änderung";
        return `Messgerätaufgabe: ${action} bestätigen`;
      }
      case "DELETE-DEVICE":
        return "Messgerätaufgabe: Löschung bestätigen";
      default:
        break;
    }
  }
  return "";
};

export const getSaveTaskLabel = (taskType, task) => {
  console.log(">> getSaveTaskLabel() taskType:", taskType);
  if (taskType) {
    const tokens = taskType.split("-");
    switch (tokens[0]) {
      case "NEW":
        return "Speichern";
      case "RENEW": {
        const action =
          task && task.action_id === DEVICE_ACTION_IDS.DELETE_ACCOUNT
            ? "Accountlöschung"
            : "Änderung";
        return `${action} bestätigen`;
      }
      case "DELETE":
        return "Löschung bestätigen";
      default:
        break;
    }
  }
  return "Speichern";
};
