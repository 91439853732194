import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { PrimaryButton, SecondaryButton } from './Buttons';

export default class FormDialog extends React.Component {
  static propTypes = {
    style: PropTypes.shape({}),
    children: PropTypes.node,
    title: PropTypes.string,
    body: PropTypes.string,
    onSave: PropTypes.func,
    saveBtnName: PropTypes.string,
    cancelBtnName: PropTypes.string,
  }
  static defaultProps = {
    style: {},
    saveBtnName: "saveBtn",
    cancelBtnName: "cancelBtn",
  }
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      body: props.body,
      onSave: props.onSave,
      isVisible: false
    }
  }
  show = (isVisible, data, onSave) => {
    const newState = data || {};
    this.setState({
      ...newState,
      onSave,
      isVisible
    });
  }
  onHide = () => {
    this.setState({
      isVisible: false,
      title: null,
      body: null,
      onSave: null,
    });
  }
  onClick = (isSave) => {
    this.onHide();
    if (isSave && this.state.onSave) {
      this.state.onSave();
    }
  }
  render() {
    const { style,saveBtnName, cancelBtnName } = this.props;
    const { title, body, isVisible } = this.state;

    const dialogWidth = {
      width: '600px',
      ...style
    };
    const header = (
      <div className="bg-secondary-" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{title}</h3>
      </div>
    );
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        <PrimaryButton id={saveBtnName} className="margin-lft" onClick={() => this.onClick(true)}>Speichern</PrimaryButton>
        <SecondaryButton id={cancelBtnName} className="margin-lft" onClick={() => this.onClick(false)}>Abbrechen</SecondaryButton>
      </div>
    );
    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={dialogWidth}
        modal={true}
        onHide={this.onHide}>
        <div style={{zIndex: 100}}>{body}</div>
      </Dialog>
    );
  }
}
