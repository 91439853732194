import React from "react";
import { withRouter } from "react-router-dom";
import { AdminNav, PublicNav } from "./components";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/auth";

const mapStateToProps = (state) => ({
  loggedOut: !(state.auth.user),
  taskCounter: state.task.taskCounter,
});
const mapDispatchToProps = (dispatch) => ({
  signOut: (...args) => dispatch(logout(...args)),
});

function UserNav({ loggedOut, taskCounter, history, signOut }) {
  console.log("UserNav.render()");
  const onClickEvents = {
    onLogout: (event) => {
      event.preventDefault();
      signOut(() => history.push("/start"));
    },
  };
  return (
    <div className="user-nav level-0 w3-right">
      <PublicNav isVisible={loggedOut} />
      <AdminNav
        isVisible={!loggedOut}
        counter={{ taskCounter }}
        onClickEvents={onClickEvents}
      />
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserNav));
