import {
  canReadAdministrativeData,
  canWriteAdministrativeData,
} from "../Permissions/actions";
import { DEFAULT_USER_DEVICE } from "./components/Utils";

export const LOAD_DEVICES = "devices/all/load";
export const LOAD_DEVICES_SUCCESS = "devices/all/load/success";
export const LOAD_DEVICES_FAILURE = "devices/all/load/failure";

export const LOAD_DEVICE = "device/load";
export const LOAD_DEVICE_SUCCESS = "device/load/success";
export const LOAD_DEVICE_FAILURE = "device/load/failure";

export const CANCEL_DEVICE_WIZARD = "device/cancel";

export const SAVE_DEVICE = "device/save";
export const SAVE_DEVICE_SUCCESS = "device/save/success";
export const SAVE_DEVICE_FAILURE = "device/save/failure";

export const SELECT_DEVICE = "device/select";
export const DESELECT_DEVICE = "device/deselect";

export const LOAD_DEPENDENT_USER_DEVICES = "devices/user/dependent/load";
export const LOAD_DEPENDENT_USER_DEVICES_SUCCESS =
  "devices/user/dependent/load/success";
export const LOAD_DEPENDENT_USER_DEVICES_FAILURE =
  "devices/user/dependent/load/failure";

export const DELETE_DEVICE = "device/delete";
export const DELETE_DEVICE_SUCCESS = "device/delete/success";
export const DELETE_DEVICE_FAILURE = "device/delete/failure";

export const LOAD_USER_DEVICES = "devices/user/load";
export const LOAD_USER_DEVICES_SUCCESS = "devices/user/load/success";
export const LOAD_USER_DEVICES_FAILURE = "devices/user/load/failure";

export const LOAD_USER_DEVICE = "device/user/load";
export const LOAD_USER_DEVICE_SUCCESS = "device/user/load/success";
export const LOAD_USER_DEVICE_FAILURE = "device/user/load/failure";

export const CANCEL_USER_DEVICE_WIZARD = "device/user/cancel";

export const SAVE_USER_DEVICE = "device/user/save";
export const SAVE_USER_DEVICE_SUCCESS = "device/user/save/success";
export const SAVE_USER_DEVICE_FAILURE = "device/user/save/failure";

export const DELETE_USER_DEVICE = "device/user/delete";
export const DELETE_USER_DEVICE_SUCCESS = "device/user/delete/success";
export const DELETE_USER_DEVICE_FAILURE = "device/user/delete/failure";

export const LOAD_USER_DEVICE_COMPANIES = "device/user/companies/load";
export const LOAD_USER_DEVICE_COMPANIES_SUCCESS =
  "device/user/companies/load/success";
export const LOAD_USER_DEVICE_COMPANIES_FAILURE =
  "device/user/companies/load/failure";

export const LOAD_USER_DEVICE_WORKERS = "device/user/workers/load";
export const LOAD_USER_DEVICE_WORKERS_SUCCESS =
  "device/user/workers/load/success";
export const LOAD_USER_DEVICE_WORKERS_FAILURE =
  "device/user/workers/load/failure";

export const LOAD_TECH_DEVICES = "devices/tech/all/load";
export const LOAD_TECH_DEVICES_SUCCESS = "devices/tech/all/load/success";
export const LOAD_TECH_DEVICES_FAILURE = "devices/tech/all/load/failure";

export const LOAD_TECH_DEVICE = "device/tech/load";
export const LOAD_TECH_DEVICE_SUCCESS = "device/tech/load/success";
export const LOAD_TECH_DEVICE_FAILURE = "device/tech/load/failure";

export const CANCEL_TECH_DEVICE_WIZARD = "device/tech/cancel";

export const SAVE_TECH_DEVICE = "device/tech/save";
export const SAVE_TECH_DEVICE_SUCCESS = "device/tech/save/success";
export const SAVE_TECH_DEVICE_FAILURE = "device/tech/save/failure";

export const LOAD_USER_TECH_DEVICES = "devices/tech/user/load";
export const LOAD_USER_TECH_DEVICES_SUCCESS = "devices/tech/user/load/success";
export const LOAD_USER_TECH_DEVICES_FAILURE = "devices/tech/user/load/failure";

export const LOAD_USER_TECH_DEVICE = "device/tech/user/load";
export const LOAD_USER_TECH_DEVICE_SUCCESS = "device/tech/user/load/success";
export const LOAD_USER_TECH_DEVICE_FAILURE = "device/tech/user/load/failure";

export const CANCEL_USER_TECH_DEVICE_WIZARD = "device/tech/user/cancel";

export const SAVE_USER_TECH_DEVICE = "device/tech/user/save";
export const SAVE_USER_TECH_DEVICE_SUCCESS = "device/tech/user/save/success";
export const SAVE_USER_TECH_DEVICE_FAILURE = "device/tech/user/save/failure";

export const EXPORT_USER_DEVICES = "devices/user/export";
export const EXPORT_USER_DEVICES_SUCCESS = "devices/user/export/success";
export const EXPORT_USER_DEVICES_FAILURE = "devices/user/export/failure";

export const RESET = "devices/reset";

export const reset = () => ({ type: RESET });

export const loadDevices = () => {
  console.log("REDUX-ACTION: loadAllDevices() ...");
  return {
    types: [LOAD_DEVICES, LOAD_DEVICES_SUCCESS, LOAD_DEVICES_FAILURE],
    promise: (client) => client.get(`/companies/devices/null`),
  };
};

export const loadDevice = ({ id }) => {
  console.log(`REDUX-ACTION: loadDevice(${id}) ...`);
  if (id === "neu") {
    const device = {
      name: "",
      options: [],
      // checkInterval: ''
    };
    return { type: LOAD_DEVICE_SUCCESS, result: device };
  }
  return {
    types: [LOAD_DEVICE, LOAD_DEVICE_SUCCESS, LOAD_DEVICE_FAILURE],
    promise: (client) => client.get(`/companies/devices/${id}`),
  };
};

/**
 * Save a device
 * @param {Object} device the device object to be saved
 * @param {*} onSuccess callback to be invoked on successful execution
 */
export const saveDevice = (device, onSuccess) => {
  console.log(`REDUX-ACTION: saveDevice()`, device);
  return {
    types: [SAVE_DEVICE, SAVE_DEVICE_SUCCESS, SAVE_DEVICE_FAILURE],
    promise: (client) => client.put(`/companies/devices/`, { data: device }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      // dispatch(loadDevices());
    },
    device,
  };
};

export const cancelDevice = () => ({ type: CANCEL_DEVICE_WIZARD });

/**
 * Select a device
 * @param {*} device the device to be selected
 */
export const selectDevice = (device) => ({ type: SELECT_DEVICE });

/**
 * Cancel device selection
 */
export const deselectDevice = () => ({ type: DESELECT_DEVICE });

/**
 * Load all user devices for a given device
 * @param {*} device the given device
 */
export const loadDependentUserDevices = (device) => {
  return {
    types: [
      LOAD_DEPENDENT_USER_DEVICES,
      LOAD_DEPENDENT_USER_DEVICES_SUCCESS,
      LOAD_DEPENDENT_USER_DEVICES_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `/companies/inUsed/referenceDevices/${encodeURIComponent(device.name)}`
      ),
    device,
  };
};

/**
 * Delete a device
 * @param {*} device the device to be deleted
 */
export const deleteDevice = (device) => {
  console.log("REDUX-ACTION: deleteDevice()", device);
  return {
    types: [DELETE_DEVICE, DELETE_DEVICE_SUCCESS, DELETE_DEVICE_FAILURE],
    promise: (client) =>
      client.del(
        `/companies/remove/referenceDevices/${encodeURIComponent(device.name)}`
      ),
    onSuccess: (dispatch) => dispatch(loadDevices()),
    device,
  };
};

/**
 * Load user devices
 */
export const loadUserDevices = () => {
  console.log("REDUX-ACTION: loadUserDevices() ...");
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : user.companyId;
    dispatch({
      types: [
        LOAD_USER_DEVICES,
        LOAD_USER_DEVICES_SUCCESS,
        LOAD_USER_DEVICES_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `/companies/${encodeURIComponent(companyId)}/devices/0?related=true`
        ),
    });
  };
};
/**
 * Load a user device by its id
 * @param {Object} param0
 */

export const loadUserDevice = ({ id }) => {
  console.log(`REDUX-ACTION: loadUserDevice(${id}) ...`);
  // if (id === "neu") {
  //   const device = DEFAULT_USER_DEVICE;
  //   return { type: LOAD_USER_DEVICE_SUCCESS, result: device };
  // }
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const canReadAdminData = canReadAdministrativeData(getState());
    if (id === "neu") {
      const userInChargeCompanyId = !canReadAdminData ? user.companyId : 0
      return dispatch({
        type: LOAD_USER_DEVICE_SUCCESS,
        result: {
          ...DEFAULT_USER_DEVICE,
          userInChargeCompanyId,
        },
      });
    }
    const companyId = canReadAdminData ? 0 : user.companyId;
    dispatch({
      types: [
        LOAD_USER_DEVICE,
        LOAD_USER_DEVICE_SUCCESS,
        LOAD_USER_DEVICE_FAILURE,
      ],
      promise: (client) =>
        client.get(`/companies/${encodeURIComponent(companyId)}/devices/${id}`),
    });
  };
};

/**
 * Save a user device
 * @param {Object} device the device object to be saved
 * @param {*} onSuccess callback to called on successful execution
 */
export const saveUserDevice = (device, onSuccess) => {
  console.log(`REDUX-ACTION: saveUserDevice()`, device);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : user.companyId;
    dispatch({
      types: [
        SAVE_USER_DEVICE,
        SAVE_USER_DEVICE_SUCCESS,
        SAVE_USER_DEVICE_FAILURE,
      ],
      promise: (client) =>
        client.put(`/companies/${encodeURIComponent(companyId)}/devices`, {
          data: device,
        }),
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      device,
    });
  };
};

/**
 * Delete a user device
 * @param {Object} device the device object to be deleted
 * @param {*} onSuccess callback to called on successful execution
 */
export const deleteUserDevice = (device, onSuccess) => {
  console.log(`REDUX-ACTION: deleteUserDevice()`, device);
  return {
    types: [
      DELETE_USER_DEVICE,
      DELETE_USER_DEVICE_SUCCESS,
      DELETE_USER_DEVICE_FAILURE,
    ],
    promise: (client) => client.del(`/companies/devices/remove/${device.id}`),
    onSuccess: (dispatch) => {
      dispatch(loadUserDevices());
      onSuccess && onSuccess();
    },
    device,
  };
};

export const cancelUserDevice = () => ({ type: CANCEL_USER_DEVICE_WIZARD });

export const loadUserDeviceCompanies = (props = {}, onSuccess) => {
  console.log("REDUX-ACTION - loadUserDeviceCompanies()", props);
  return (dispatch, getState) => {
    const { /* isDeviceManager,  */ user } = getState().auth;
    if (props.isManager && !canReadAdministrativeData(getState())) {
      // const result = [];
      // let deviceManagers = null;
      // let companyId = null;
      // if (isDeviceManager) {
      //   companyId = user.companyId;
      //   result.push({ id: companyId, name: user.company });
      //   deviceManagers = [user];
      // }
      // dispatch({
      //   type: LOAD_USER_DEVICE_COMPANIES_SUCCESS,
      //   result,
      //   deviceManagers,
      //   isManager: props.isManager,
      // });
      // return (
      //   companyId && dispatch(loadUserDeviceCompanies({ companyId }, onSuccess))
      // );
      console.log(
        "<< User Device Manager in action -> use their name and company",
        user.companyId,
        user.company
      );
      dispatch({
        type: LOAD_USER_DEVICE_COMPANIES_SUCCESS,
        result: [{ id: user.companyId, name: user.company }],
        deviceManagers: [user],
        isManager: props.isManager,
      });
      return dispatch(
        loadUserDeviceCompanies({ companyId: user.companyId }, onSuccess)
      );
    }

    const companyId = props.companyId || 0;
    // if (isWorker(user) && isDeviceManager) {
    //   const company = getState().company.company;
    //   return dispatch({
    //     type: LOAD_USER_DEVICE_COMPANIES_SUCCESS,
    //     result: [{ id: company.id, name: company.name }],
    //     companyId,
    //   });
    // }

    // let companyId = props.companyId || 0;
    // const company = getState().company.company;
    // if (isWorker(user) && company.contractGivers && company.contractGivers.length > 0) {
    //   companyId = company.contractGivers[0].id;
    // }
    const companyType = props.isManager ? `summaries` : `contractors`
    return dispatch({
      types: [
        LOAD_USER_DEVICE_COMPANIES,
        LOAD_USER_DEVICE_COMPANIES_SUCCESS,
        LOAD_USER_DEVICE_COMPANIES_FAILURE,
      ],
      promise: (client) =>
        client.get(`/companies/${companyId}/${companyType}?kdl=true&form=true`),
      onSuccess: (dispatch) => onSuccess && onSuccess(dispatch),
      isManager: props.isManager,
      companyId,
    });
  };
};

export const loadUserDeviceWorkers = (props = {}, onSuccess) => {
  console.log("REDUX-ACTION: Reporting - loadUserDeviceWorkers()", props);
  return (dispatch, getState) => {
    const { user } = getState().auth;
    let companyId = props.companyId;
    if (!companyId) {
      companyId = canReadAdministrativeData(getState()) ? 0 : user.companyId;
    }
    const query = props.isManager ? `&deviceManager=true` : "";
    // const related = props.deviceManagerCompanyId === companyId ? `` : `&related=false`;
    dispatch({
      types: [
        LOAD_USER_DEVICE_WORKERS,
        LOAD_USER_DEVICE_WORKERS_SUCCESS,
        LOAD_USER_DEVICE_WORKERS_FAILURE,
      ],
      promise: (client) =>
        client.get(
          // `/companies/${companyId}/employees/summaries/forDevices?form=true${related}${query}`
          `/companies/${companyId}/employees/summaries/forDevices?form=true${query}`
        ),
      onSuccess: (dispatch) => onSuccess && onSuccess(dispatch),
      currentUser: user,
      isManager: props.isManager,
      companyId,
    });
  };
};

// Tech Devices

export const loadTechDevices = () => {
  console.log("REDUX-ACTION: loadAllTechDevices() ...");
  const result = ["CI+Modul", "HD DVR", "Receiver", "SmartCard"];
  return { type: LOAD_TECH_DEVICES_SUCCESS, result };
  /* return {
    types: [
      LOAD_TECH_DEVICES,
      LOAD_TECH_DEVICES_SUCCESS,
      LOAD_TECH_DEVICES_FAILURE
    ],
    promise: (client) => client.get(`/companies/devices/tech/null`)
  }; */
};

export const loadTechDevice = ({ id }) => {
  console.log(`REDUX-ACTION: loadTechDevice(${id}) ...`);
  if (id === "neu") {
    const device = {
      name: "",
    };
    return { type: LOAD_TECH_DEVICE_SUCCESS, result: device };
  }
  return {
    types: [
      LOAD_TECH_DEVICE,
      LOAD_TECH_DEVICE_SUCCESS,
      LOAD_TECH_DEVICE_FAILURE,
    ],
    promise: (client) => client.get(`/companies/devices/tech/${id}`),
  };
};

export const saveTechDevice = (device, onSuccess) => {
  console.log(`REDUX-ACTION: saveTechDevice()`, device);
  return {
    types: [
      SAVE_TECH_DEVICE,
      SAVE_TECH_DEVICE_SUCCESS,
      SAVE_TECH_DEVICE_FAILURE,
    ],
    promise: (client) =>
      client.put(`/companies/devices/tech`, { data: device }),
    onSuccess,
    device,
  };
};

export const cancelTechDevice = () => ({ type: CANCEL_TECH_DEVICE_WIZARD });

export const loadUserTechDevices = () => {
  console.log("REDUX-ACTION: loadUserTechDevices() ...");
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : user.companyId;
    dispatch({
      types: [
        LOAD_USER_TECH_DEVICES,
        LOAD_USER_TECH_DEVICES_SUCCESS,
        LOAD_USER_TECH_DEVICES_FAILURE,
      ],
      promise: (client) =>
        client.get(`/companies/${encodeURIComponent(companyId)}/devices/tech`),
    });
  };
};

export const loadUserTechDevice = ({ id }) => {
  console.log(`REDUX-ACTION: loadUserTechDevice(${id}) ...`);
  if (id === "neu") {
    const device = {
      name: "",
      serialNumber: "",
      type: "",
      comment: "",
    };
    return { type: LOAD_USER_TECH_DEVICE_SUCCESS, result: device };
  }
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : user.companyId;
    dispatch({
      types: [
        LOAD_USER_TECH_DEVICE,
        LOAD_USER_TECH_DEVICE_SUCCESS,
        LOAD_USER_TECH_DEVICE_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `/companies/${encodeURIComponent(companyId)}/devices/tech/${id}`
        ),
    });
  };
};

export const saveUserTechDevice = (device, onSuccess) => {
  console.log(`REDUX-ACTION: saveUserTechDevice()`, device);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canWriteAdministrativeData(getState())
      ? 0
      : user.companyId;
    dispatch({
      types: [
        SAVE_USER_TECH_DEVICE,
        SAVE_USER_TECH_DEVICE_SUCCESS,
        SAVE_USER_TECH_DEVICE_FAILURE,
      ],
      promise: (client) =>
        client.put(`/companies/${encodeURIComponent(companyId)}/devices/tech`, {
          data: device,
        }),
      onSuccess: () => onSuccess(),
    });
  };
};

export const cancelUserTechDevice = () => ({
  type: CANCEL_USER_TECH_DEVICE_WIZARD,
});

export const exportUserDevices = (props = {}) => {
  console.log(`REDUX-ACTION: exportUserDevices()`, props);
  let params = [];
  for (const key in props) {
    params.push(`${key}=${encodeURIComponent(props[key])}`);
  }
  params = params.join("&");
  return (dispatch, getState) => {
    const company = getState().company.company;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : company
      ? company.id
      : null;
    if (companyId === null) {
      console.error("<< ERROR exportUserDevices(): Company Mising => abort!");
      return {
        type: EXPORT_USER_DEVICES_FAILURE,
        error: {
          label: "error.company.missing",
          message: "Firma ist unbekannt",
        },
      };
    }
    dispatch({
      types: [
        EXPORT_USER_DEVICES,
        EXPORT_USER_DEVICES_SUCCESS,
        EXPORT_USER_DEVICES_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `/companies/devices/csv?company=${companyId}&device=null&related=true`
        ),
    });
  };
};
