import React from "react";
import PropTypes from "prop-types";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import RoleForm from "./RoleForm";
import {
  // isContractor as isCompanyContractor,
  validateTelNumber,
  createSortByLabel,
} from "../../../../components/Utils";

const findAttrByAttr = (list, value, listAttr = "id", retAttr = "name") => {
  let found = list.find((item) => item[listAttr] === value);
  if (found) {
    found = found[retAttr];
  }
  return found;
};

export default class CompanyDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    namePrefix: PropTypes.string,
    model: PropTypes.shape({}),
    workerKdl: PropTypes.number,
    getWorkerCompanies: PropTypes.func.isRequired,
    isMain: PropTypes.bool,
    company: PropTypes.shape({}),
    companyList: PropTypes.array,
    kdlCompanyList: PropTypes.array,
    userCompany: PropTypes.shape({}),
    roleList: PropTypes.array,
    extendedRoleList: PropTypes.array,
    userExists: PropTypes.bool,
    canReadAdminData: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    namePrefix: "",
    model: {
      id: 0,
      contractGiver: "",
      contractGiverId: 0,
      telephoneNumber: "",
      roles: [],
      extendedRoles: [],
    },
    companyList: [],
    kdlCompanyList: [],
    roleList: [],
    extendedRoleList: [],
  };
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentDidMount() {
    const { id, contractGiverId, contractGiver } = this.state;
    if (contractGiver !== "" && contractGiverId === 0) {
      const found = this.props.kdlCompanyList.find(
        (item) => item.name === contractGiver
      );
      if (found) {
        this.setContractGiverId(found.id);
      }
    } else if (id !== 0) {
      this.setCompany(id);
    }
  }
  getState = (props) => {
    const model = props.model || {};
    let id = model.id || "";
    let contractGiver = model.contractGiver || "";
    let contractGiverId = model.contractGiverId || 0;
    if (!props.canReadAdminData && props.userCompany && contractGiver === "") {
      contractGiver = props.userCompany.name;
      contractGiverId = props.userCompany.id;
    } else if (props.workerKdl) {
      contractGiverId = props.workerKdl;
      const found = props.kdlCompanyList.find(
        (item) => item.id === props.workerKdl
      );
      if (found) {
        contractGiver = found.name;
      }
    }
    return {
      id,
      contractGiver,
      contractGiverId,
      telephoneNumber: model.telephoneNumber || "",
      isContractor: false,
      roles: model.roles || [],
      roleList: props.roleList,
      error: {
        id: null,
        contractGiverId: null,
        telephoneNumber: null,
        roles: null,
      },
    };
  };
  validate = (noValidate) => {
    const { isMain, companyList, kdlCompanyList } = this.props;
    const { error, isContractor, roleList, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (
        error[key] !== undefined &&
        model[key] === "" &&
        key !== "roles" &&
        isMain
      ) {
        error[key] = true;
        isValid = false;
      }
    });
    // telephone number
    if (!validateTelNumber(model.telephoneNumber)) {
      error.telephoneNumber = "ungültige Telefonnummer";
      isValid = false;
    }
    // roles
    model.roles = this.roles.getModel() || [];
    if (model.roles.length === 0) {
      error.roles = true;
      isValid = false;
    } else {
      error.roles = null;
    }

    !noValidate && this.setState({ ...model, error });
    if (!noValidate && !isValid) {
      return null;
    }

    // extended roles
    model.extendedRoles = this.extendedRoles.getModel() || [];
    // original id and contract giver
    const oldModel = this.props.model || {};
    model.oldName = oldModel.name;
    model.oldId = oldModel.id;
    model.oldContractGiver = oldModel.contractGiver;
    model.oldContractGiverId =
      oldModel.contractGiverId ||
      findAttrByAttr(kdlCompanyList, oldModel.contractGiver, "name", "id");
    // set company name
    model.name = findAttrByAttr(companyList, model.id, "id", "name");
    // console.log("<< CompanyData:", model);
    return model;
  };
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  };
  generateRoleData = (id, contractGiverId, oldRoles) => {
    // filter roles
    const roleList = this.props.roleList.filter((item) => {
      // if (item.kdl_equal_contractor === 1) {
      //   return id === contractGiverId;
      // }
      return true;
    });
    const roles = oldRoles.filter((roleName) => {
      const pos = roleList.findIndex((item) => item.name === roleName);
      return pos >= 0;
    });
    return { roles, roleList };
  };
  setContractGiverId = (value) => {
    if (value && value !== 0) {
      this.props.getWorkerCompanies(value);
    }
  };
  setCompany = (value) => {
    const { contractGiverId, roles: oldRoles } = this.state;
    const { roles, roleList } = this.generateRoleData(
      value,
      contractGiverId,
      oldRoles
    );
    const state = {
      id: value,
      roles,
      roleList,
      error: {
        ...this.state.error,
        id: null,
      },
    };
    const company = this.props.companyList.find((item) => item.id === value);
    if (company && company.type_id > 3) {
      state.isContractor = true;
    } else {
      state.isContractor = false;
    }
    this.setState(state);
  };
  setTelephoneNumber = (value) => {
    // console.log(`setTelephoneNumber(${value})`)
    this.setState({
      telephoneNumber: value,
      roles: this.roles.getModel() || [],
      error: {
        ...this.state.error,
        telephoneNumber: null,
      },
    });
  };
  render() {
    const {
      className,
      companyList,
      kdlCompanyList,
      extendedRoleList,
      disabled,
      model,
      namePrefix,
    } = this.props;
    const {
      id,
      contractGiverId,
      telephoneNumber,
      roles,
      roleList,
      isContractor,
      error,
    } = this.state;
    const KDLCompanyOptions = kdlCompanyList
      .map((company) => ({
        label: company.name,
        value: company.id,
      }))
      .sort(createSortByLabel());
    const CompanyOptions = companyList
      .map((company) => ({
        label: company.name,
        value: company.id,
      }))
      .sort(createSortByLabel());

    // contract giver (KDL)
    const contractGiverMarkup = (
      <Dropdown
        id={`${namePrefix}contractGiver`}
        className="w3-block w3-border"
        value={contractGiverId}
        placeholder="Bitte auswählen"
        options={KDLCompanyOptions}
        onChange={(event) => this.setContractGiverId(event.value)}
        showClear
        disabled={disabled}
      />
    );

    // company (Beschäftigungsfirma)
    const companyMarkup = (
      <Dropdown
        id={`${namePrefix}name`}
        className="w3-block w3-border"
        value={id}
        placeholder="Bitte auswählen"
        options={CompanyOptions}
        onChange={(event) => this.setCompany(event.value)}
        showClear
        disabled={disabled}
      />
    );

    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.contractGiverId ? " error-group" : ""
            }`}
          >
            <Label htmlFor={`${namePrefix}contractGiver`}>
              {`KDL/DL`}
              <span>*</span>
            </Label>
            {contractGiverMarkup}
          </div>
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.id ? " error-group" : ""
            }`}
          >
            <Label htmlFor={`${namePrefix}name`}>
              {`Beschäftigungsfirma`}
              <span>*</span>
            </Label>
            {companyMarkup}
          </div>
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.telephoneNumber ? " error-group" : ""
            }`}
          >
            <Label htmlFor={`${namePrefix}telephoneNumber`}>
              {`Telefon (dienstlich)`}
              <span>*</span>
            </Label>
            <InputText
              id={`${namePrefix}telephoneNumber`}
              className="w3-block w3-border"
              value={telephoneNumber}
              onChange={(event) => this.setTelephoneNumber(event.target.value)}
            />
            {error.telephoneNumber && (
              <div className="pad-sm-top">{error.telephoneNumber}</div>
            )}
          </div>
        </div>
        <RoleForm
          ref={(ref) => (this.roles = ref)}
          key={Math.random()}
          id="roles"
          className="pad-top"
          title="AN-Portal Rollen*"
          model={roles}
          roleList={roleList}
          disabled={disabled}
          isContractor={isContractor}
          min={1}
          error={error.roles}
        />
        <RoleForm
          ref={(ref) => (this.extendedRoles = ref)}
          id="extendedRoles"
          className="pad-top"
          title="Aufgabenbereiche"
          model={model.extendedRoles || []}
          roleList={extendedRoleList}
          disabled={disabled}
          isContractor={isContractor}
        />
      </div>
    );
  }
}
