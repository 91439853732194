import * as actionTypes from "./actions";
import { LOGOUT_SUCCESS } from "../../redux/actions/auth";

const initialState = {
  loading: false,
  permission: null,
  permissionMap: {},
  loadingGroup: {
    news: false,
    organisation: false,
    sales: false,
    tasks: false,
    "tasks.usermanagement": false,
    webshop: false,
  },
  permissionGroup: {
    admindataaccess: {},
    news: {},
    organisation: {},
    "organisation.companywizard": {},
    "organisation.workerwizard": {},
    sales: {},
    tasks: {},
    "tasks.usermanagement": {},
    webshop: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load all permissions
    case actionTypes.LOAD_ALL_PERMISSIONS: {
      const loadingGroup = state.loadingGroup;
      if (action.resourceGroup) {
        loadingGroup[action.resourceGroup.toLowerCase()] = true;
      }
      return {
        ...state,
        loading: true,
        loadingGroup,
      };
    }
    case actionTypes.LOAD_ALL_PERMISSIONS_SUCCESS: {
      const loadingGroup = state.loadingGroup;
      const permissionGroup = state.permissionGroup;
      if (action.resourceGroup) {
        const resourceGroup = action.resourceGroup.toLowerCase();
        loadingGroup[resourceGroup] = false;
        permissionGroup[resourceGroup] = {
          ...action.result,
        };
      }
      return {
        ...state,
        loading: false,
        permissionMap: {
          ...state.permissionMap,
          ...action.result,
        },
        loadingGroup,
        permissionGroup,
      };
    }
    case actionTypes.LOAD_ALL_PERMISSIONS_FAILURE: {
      const loadingGroup = state.loadingGroup;
      if (action.resourceGroup) {
        loadingGroup[action.resourceGroup.toLowerCase()] = false;
      }
      return {
        ...state,
        loading: false,
        loadingGroup,
      };
    }

    // load permissions
    case actionTypes.LOAD_PERMISSIONS: {
      const loadingGroup = state.loadingGroup;
      if (action.resourceGroup) {
        loadingGroup[action.resourceGroup.toLowerCase()] = true;
      }
      return {
        ...state,
        loading: true,
        loadingGroup,
      };
    }
    case actionTypes.LOAD_PERMISSIONS_SUCCESS: {
      // console.log(">> LOAD_PERMISSIONS_SUCCESS", state.permissionMap, action);
      const result =
        Array.isArray(action.result) && action.result.length > 0
          ? action.result[0]
          : action.result;
      const loadingGroup = state.loadingGroup;
      const permissionGroup = state.permissionGroup;
      if (action.resourceGroup) {
        const resourceGroup = action.resourceGroup.toLowerCase();
        loadingGroup[resourceGroup] = false;
        permissionGroup[resourceGroup] = {
          ...result,
        };
        // console.log(">> PermissionGroup", permissionGroup, resourceGroup);
      }
      return {
        ...state,
        loading: false,
        permissionMap: {
          ...state.permissionMap,
          ...result,
        },
        loadingGroup,
        permissionGroup,
      };
    }
    case actionTypes.LOAD_PERMISSIONS_FAILURE:{
      const loadingGroup = state.loadingGroup;
      if (action.resourceGroup) {
        loadingGroup[action.resourceGroup.toLowerCase()] = false;
      }
      return {
        ...state,
        loading: false,
        loadingGroup,
      };
    }

    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return { ...state };
  }
};
