import * as Resources from "../resources";

const RESOURCES = [
  { name: Resources.Start, url: "/start" },
  { name: Resources.Technik, url: "/technik" },
  { name: "Sales", url: "/sales" },
  { name: "Sales_Arbeitshilfen" },
  { name: "Sales_Reporting Portal" },
  { name: "Sales_Meine Leistungen" },
  { name: "Sales_Produktverwaltung" },
  { name: "Sales_Buchungsanforderung" },
  { name: "Sales_Archiv" },
  { name: "Sales_FAQ" },
  { name: "Sales_News Feed" },
  { name: "Sales_aktuelle Produkte" },
  { name: Resources.Webshop.Page },
  { name: Resources.Webshop.Modems.Page },
  { name: Resources.Webshop.Modems.Orders },
  { name: Resources.Webshop.Modems.Overview },
  { name: Resources.Webshop.Modems.Management },
  { name: Resources.Webshop.Modems.DebitorMapping },
  { name: Resources.Webshop.Cards.Page },
  { name: Resources.Webshop.Cards.Orders },
  { name: Resources.Webshop.Cards.Overview },
  { name: Resources.Webshop.Cards.Management },
  { name: Resources.Webshop.Keys.Page },
  { name: Resources.Webshop.Keys.Orders },
  { name: Resources.Webshop.Keys.Overview },
  { name: Resources.Webshop.Keys.Management },
  { name: Resources.Organisation.Page },
  { name: Resources.Organisation.Companies },
  { name: Resources.Organisation.CompanyWizard.Page },
  { name: Resources.Organisation.CompanyWizard.Status },
  { name: Resources.Organisation.CompanyWizard.BasicInfo },
  { name: Resources.Organisation.CompanyWizard.Groups },
  { name: Resources.Organisation.CompanyWizard.TechKeys },
  { name: Resources.Organisation.CompanyWizard.Regions },
  { name: Resources.Organisation.CompanyWizard.RegionStorageLocation },
  { name: Resources.Organisation.CompanyWizard.Debitors },
  { name: Resources.Organisation.CompanyWizard.AdHocNewsRecipents },
  { name: Resources.Organisation.CompanyWizard.Contractors },
  { name: Resources.Organisation.Workers },
  { name: Resources.Organisation.WorkerWizard.Page },
  { name: Resources.Organisation.WorkerWizard.BasicInfo },
  { name: Resources.Organisation.WorkerWizard.ExtendedRoles },
  { name: Resources.Organisation.WorkerWizard.IDCard },
  { name: Resources.Organisation.WorkerWizard.ADConnections },
  { name: Resources.Organisation.WorkerWizard.PTConnections },
  { name: Resources.Organisation.WorkerWizard.DelphiConnections },
  { name: Resources.Organisation.WorkerWizard.ContractGiver },
  { name: Resources.Organisation.WorkerWizard.Ratings },
  { name: Resources.Organisation.WorkerWizard.UserDevices },
  { name: Resources.Organisation.WorkerWizard.TechKeys },
  { name: Resources.Organisation.WorkerWizard.Certifications },
  { name: Resources.Organisation.WorkerWizard.TechDevices },
  { name: Resources.Organisation.WorkerWizard.Seminars },
  { name: Resources.Organisation.WorkerWizard.Audits },
  { name: Resources.Organisation.Modems },
  { name: Resources.Organisation.Devices.Page },
  { name: Resources.Organisation.Devices.UserDevices },
  { name: Resources.Organisation.Devices.Devices },
  { name: Resources.Organisation.Archive },
  { name: Resources.Organisation.Forms },
  { name: Resources.Organisation.CommunicationOverview },
  { name: Resources.News.Page },
  { name: Resources.News.Overview },
  { name: Resources.News.MessagePanel },
  { name: Resources.News.Protocols },
  { name: Resources.News.Articles },
  { name: Resources.News.Archive },
  { name: "Aufgaben" },
  { name: "Aufgaben_Modem-Logistik" },
  { name: "Aufgaben_Schließmittel" },
  { name: "Aufgaben_Karten/Flyer" },
  { name: "Aufgaben_Debitoren" },
  { name: "Aufgaben_IT Zugänge" },
  { name: "Aufgaben_Messgeräte" },
  { name: "Aufgaben_Reports_mytechie" },
  { name: "Aufgaben_Benutzerverwaltung" },
  { name: "Schulung" },
  { name: "E-Mail / SMS Kommunikation" },
];

const ROLES = [
  "VF_Administrator",
  "kein Zugang",
  "Dokumente lesen",
  "VF_Materialmanagement",
  "VF_Usermanagement",
  "VF_Infomanagement",
  "VF_Reporting",
  "VF_Sales Operator",
  "VF_Sales Admin",
  "SP_Geschäftsführer",
  "SP_Kapazitätsmanager",
  "SP_Modemlogistik",
  "SP_Schlüsselverantwortlicher",
  "SP_Messgeräteverantwortlicher",
  "SP_Karten Flyer",
  "SP_Sales Agent",
  "SP_Sales Verantwortliche",
  "Sales_Archiv",
  "Sales_FAQ",
  "Sales_News Feed",
  "Sales_aktuelle Produkte",
];


const CEOReadResources = [
  Resources.Start,
  Resources.Technik,
  "Sales",
  "Sales_Arbeitshilfen",
  "Sales_Reporting Portal",
  "Sales_Archiv",
  "Sales_FAQ",
  "Sales_News Feed",
  "Sales_Buchungsanforderung",
  "Sales_aktuelle Produkte",
  Resources.Organisation.Page,
  Resources.Organisation.Companies,
  Resources.Organisation.CompanyWizard.Page,
  Resources.Organisation.CompanyWizard.Status,
  Resources.Organisation.CompanyWizard.BasicInfo,
  Resources.Organisation.CompanyWizard.Groups,
  Resources.Organisation.CompanyWizard.TechKeys,
  Resources.Organisation.CompanyWizard.Regions,
  Resources.Organisation.CompanyWizard.Debitors,
  Resources.Organisation.CompanyWizard.AdHocNewsRecipents,
  Resources.Organisation.CompanyWizard.Contractors,
  Resources.Organisation.Workers,
  Resources.Organisation.WorkerWizard.Page,
  Resources.Organisation.WorkerWizard.BasicInfo,
  Resources.Organisation.WorkerWizard.ExtendedRoles,
  Resources.Organisation.WorkerWizard.ADConnections,
  Resources.Organisation.WorkerWizard.PTConnections,
  Resources.Organisation.WorkerWizard.DelphiConnections,
  Resources.Organisation.WorkerWizard.TechKeys,
  Resources.Organisation.UserDevices,
  Resources.Organisation.Archive,
  Resources.Organisation.Forms,
  Resources.Organisation.CommunicationOverview,
  Resources.News.Page,
  Resources.News.Overview,
  Resources.News.Archive,
  "Aufgaben_Benutzerverwaltung",
  "Schulung",
  "E-Mail / SMS Kommunikation",
];
const CEOWriteResources = [
  "Sales_Buchungsanforderung",
  Resources.Organisation.CompanyWizard.AdHocNewsRecipents,
  Resources.Organisation.CompanyWizard.Contractors,
  Resources.Organisation.Workers,
  Resources.Organisation.WorkerWizard.Page,
  Resources.Organisation.WorkerWizard.BasicInfo,
  Resources.News.Overview,
  "Aufgaben_Benutzerverwaltung",
  "E-Mail / SMS Kommunikation",
];

const WorkerPlusReadResources = [
  Resources.Start,
  Resources.Technik,
  "Sales",
  "Sales_Arbeitshilfen",
  "Sales_Reporting Portal",
  "Sales_Archiv",
  "Sales_FAQ",
  "Sales_News Feed",
  "Sales_aktuelle Produkte",
  Resources.Organisation.Page,
  Resources.Organisation.Companies,
  Resources.Organisation.CompanyWizard.Page,
  Resources.Organisation.CompanyWizard.Status,
  Resources.Organisation.CompanyWizard.BasicInfo,
  Resources.Organisation.CompanyWizard.Groups,
  Resources.Organisation.CompanyWizard.TechKeys,
  Resources.Organisation.CompanyWizard.Regions,
  Resources.Organisation.CompanyWizard.Debitors,
  Resources.Organisation.CompanyWizard.AdHocNewsRecipents,
  Resources.Organisation.CompanyWizard.Contractors,
  Resources.Organisation.Workers,
  Resources.Organisation.WorkerWizard.Page,
  Resources.Organisation.WorkerWizard.BasicInfo,
  Resources.Organisation.WorkerWizard.ExtendedRoles,
  Resources.Organisation.WorkerWizard.ADConnections,
  Resources.Organisation.WorkerWizard.PTConnections,
  Resources.Organisation.WorkerWizard.DelphiConnections,
  Resources.Organisation.WorkerWizard.TechKeys,
  Resources.Organisation.Archive,
  Resources.Organisation.Forms,
  Resources.Organisation.CommunicationOverview,
  Resources.News.Page,
  Resources.News.Overview,
  Resources.News.Archive,
  "Aufgaben_Benutzerverwaltung",
  "Schulung",
  "E-Mail / SMS Kommunikation",
];
const WorkerPlusWriteResources = [
  Resources.Organisation.Companies,
  Resources.Organisation.CompanyWizard.Debitors,
  Resources.Organisation.CompanyWizard.Contractors,
  Resources.Organisation.Workers,
  Resources.Organisation.WorkerWizard.BasicInfo,
  Resources.Organisation.WorkerWizard.ExtendedRoles,
  Resources.Organisation.WorkerWizard.ADConnections,
  Resources.Organisation.WorkerWizard.PTConnections,
  Resources.Organisation.WorkerWizard.DelphiConnections,
  Resources.Organisation.WorkerWizard.TechKeys,
  Resources.News.Overview,
  Resources.News.Archive,
  "Aufgaben_Benutzerverwaltung",
  "E-Mail / SMS Kommunikation",
];

// ModemLogistik
const MLReadResources = [
  Resources.Start,
  Resources.Technik,
  Resources.Webshop.Page,
  Resources.Webshop.Modems.Page,
  Resources.Webshop.Modems.Orders,
  Resources.Organisation.Page,
  Resources.Organisation.Companies,
  Resources.Organisation.CompanyWizard.Page,
  Resources.Organisation.CompanyWizard.Debitors,
  Resources.Organisation.Archive,
  Resources.Organisation.Forms,
  Resources.Organisation.CommunicationOverview,
  Resources.News.Page,
  Resources.News.Overview,
  Resources.News.Archive,
  "Aufgaben_Modem-Logistik",
  "Schulung",
  "E-Mail / SMS Kommunikation",
];
const MLWriteResources = [
  Resources.Webshop.Modems.Orders,
  Resources.Organisation.Companies,
  Resources.Organisation.CompanyWizard.Debitors,
  Resources.News.Overview,
  "Aufgaben_Modem-Logistik",
  "E-Mail / SMS Kommunikation",
];

const permissionsMatrix = {};
ROLES.forEach((role) => {
  permissionsMatrix[role] = {};
});
RESOURCES.map(resource => resource.name).forEach((resource) => {
  ROLES.forEach((role) => {
    if (role === "VF_Administrator") {
      // admin
      permissionsMatrix[role][resource] = {
        read: true,
        write: true,
      };
    } else if (role === "Dokumente lesen") {
      // worker
      if ([Resources.Start, Resources.Technik, "Schulung"].includes(resource)) {
        permissionsMatrix[role][resource] = {
          read: true,
          write: resource === Resources.Start,
        };
      }
    } else if (role === "SP_Kapazitätsmanager") {
      // worker plus
      if (WorkerPlusReadResources.includes(resource)) {
        permissionsMatrix[role][resource] = {
          read: true,
          write: WorkerPlusWriteResources.includes(resource),
        };
      }
    } else if (role === "SP_Geschäftsführer") {
      // CEO
      if (CEOReadResources.includes(resource)) {
        permissionsMatrix[role][resource] = {
          read: true,
          write: CEOWriteResources.includes(resource),
        };
      }
    } else if (role === "SP_Modemlogistik") {
      // CEO
      if (MLReadResources.includes(resource)) {
        permissionsMatrix[role][resource] = {
          read: true,
          write: MLWriteResources.includes(resource),
        };
      }
    }
  });
});
console.log("### PermissionsMatrix:", permissionsMatrix);

const translateRole = (role) => {
  switch (role.toLowerCase()) {
    case "admin":
      return "VF_Administrator";
    case "mitarbeiter":
      return "Dokumente lesen";
    case "maplus":
      return "SP_Kapazitätsmanager";
    case "geschäftsf":
      return "SP_Geschäftsführer";
    case "logistikverantwortlicher":
      return "SP_Modemlogistik";
    case "messgeräteverantwortlicher":
      return "SP_Messgeräteverantwortlicher";
    default:
      return role;
  }
};

const getResourcePermissions = (resource, roles) => {
  let result = undefined;
  [...new Set(roles)]
    .map((role) => role.name)
    .forEach((role) => {
      const foundRole = permissionsMatrix[translateRole(role)] || undefined;
      if (foundRole) {
        const permissions = foundRole[resource];
        if (permissions) {
          if (!result) {
            result = { ...permissions };
          } else {
            result.read = result.read || permissions.read;
            result.write = result.write || permissions.write;
          }
        }
      }
    });
  return result;
};

// export
const getPermission = (resource, role, permission) => {
  const permissions = getResourcePermissions(resource, role);
  return permissions[permission];
};

// export
const canRead = (resource, role) => {
  return getPermission(resource, role, "read") === true;
};

// export
const canWrite = (resource, role) => {
  return getPermission(resource, role, "write") === true;
};

// // export
// const getResourceByUrl = (url, resources = RESOURCES) => {
//   const found = resources.find((item) => item.url === url);
//   return url && found ? found.name : undefined;
// };

export const fetchPermissions = ({
  resources,
  roles,
}) => {
  const resultMap = {};
  for (const resource in resources) {
    resultMap[resource] = getResourcePermissions(resource, roles);
  }
  return resultMap;
};
