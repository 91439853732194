import React from "react";
import PropTypes from "prop-types";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import {
  COMPANY_TYPE_LABELS,
  validateAreaCode,
} from "../../../../components/Utils";

export default class BasicDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    types: PropTypes.array,
    userCompany: PropTypes.object,
    isVertical: PropTypes.bool,
    namePrefix: PropTypes.string,
    onCompanyTypeChanged: PropTypes.func,
    disabled: PropTypes.bool,
    onlyServicePartner: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      name: "",
      type_id: "",
      address: {
        street: "",
        houseNumber: "",
        areaCode: "",
        city: "",
      },
      contractGiver: "",
      footPrint: "",
      contractNumber: "",
      // nGumPartner: "",
      // delphiGroup: "",
      // delphiComments: "",
    },
    namePrefix: "",
  };
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  getState = (props) => {
    const { model } = props;
    const address = model.address || {};
    return {
      name: model.name || "",
      typeId: model.type_id || "",
      street: address.street || "",
      houseNumber: address.houseNumber || "",
      areaCode: address.areaCode || "",
      city: address.city || "",
      footPrint: model.footPrint || "",
      contractNumber: model.contractNumber || "",
      // nGumPartner: model.nGumPartner || "",
      // delphiGroup: model.delphiGroup || "",
      // delphiComments: model.delphiComments || "",
      error: {
        name: null,
        typeId: null,
        street: null,
        houseNumber: null,
        areaCode: null,
        city: null,
      },
    };
  };
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (
        !noValidate &&
        error[key] !== undefined &&
        (model[key] === "" || model[key] === null)
      ) {
        error[key] = true;
        isValid = false;
      }
    });
    if (!validateAreaCode(model.areaCode)) {
      isValid = false;
      error.areaCode = true;
    }
    if (!noValidate && !isValid) {
      this.setState({ error });
      return null;
    }
    const {
      name,
      typeId,
      footPrint,
      contractNumber,
      // nGumPartner,
      // delphiGroup,
      // delphiComments,
      ...address
    } = model;
    return {
      id: this.props.model.id,
      name,
      type_id: typeId,
      footPrint,
      contractNumber,
      // nGumPartner,
      // delphiGroup,
      // delphiComments,
      address: { ...this.props.model.address, ...address },
      contractGiver: this.props.model.contractGiver,
    };
  };
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  };
  onCompanyTypeChanged = (value) => {
    const { onCompanyTypeChanged } = this.props;
    this.setState({ typeId: value });
    onCompanyTypeChanged && onCompanyTypeChanged(value);
  };
  renderStreetHouse = (className) => {
    const { namePrefix } = this.props;
    const { street, houseNumber, error } = this.state;
    return (
      <div
        className={`${className} ${
          error.street || error.houseNumber ? " error-group-" : ""
        }`}
      >
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}address_form_street`}
        >{`Straße`}</Label>
        <Label className="display-inline-block">{`/`}</Label>
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}address_form_housenumber`}
        >{`Hausnummer*`}</Label>
        <div className="w3-row">
          <div className="w3-col s8 pad-sm-rht">
            <InputText
              id={`${namePrefix}address_form_street`}
              className={`w3-block w3-border ${
                error.street ? "w3-border-red" : ""
              }`}
              value={street}
              onChange={(event) =>
                this.setState({ street: event.target.value })
              }
              disabled={this.props.disabled}
            />
          </div>
          <div className="w3-col s4 pad-sm-lft">
            <InputText
              id={`${namePrefix}address_form_housenumber`}
              className={`w3-block w3-border ${
                error.houseNumber ? "w3-border-red" : ""
              }`}
              value={houseNumber}
              onChange={(event) =>
                this.setState({ houseNumber: event.target.value })
              }
              disabled={this.props.disabled}
            />
          </div>
        </div>
      </div>
    );
  };
  renderAreaCity = (className) => {
    const { namePrefix } = this.props;
    const { areaCode, city, error } = this.state;
    return (
      <div
        className={`${className} ${
          error.areaCode || error.city ? " error-group-" : ""
        }`}
      >
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}area_code`}
        >{`PLZ`}</Label>
        <Label className="display-inline-block">{`/`}</Label>
        <Label
          className="display-inline-block"
          htmlFor={`${namePrefix}city`}
        >{`Ort*`}</Label>
        <div className="w3-row">
          <div className="w3-col s4 pad-sm-rht">
            <InputText
              id={`${namePrefix}area_code`}
              className={`w3-block w3-border ${
                error.areaCode ? "w3-border-red" : ""
              }`}
              value={areaCode}
              onChange={(event) =>
                this.setState({ areaCode: event.target.value })
              }
              disabled={this.props.disabled}
            />
          </div>
          <div className="w3-col s8 pad-sm-lft">
            <InputText
              id={`${namePrefix}city`}
              className={`w3-block w3-border ${
                error.city ? "w3-border-red" : ""
              }`}
              value={city}
              onChange={(event) => this.setState({ city: event.target.value })}
              disabled={this.props.disabled}
            />
          </div>
        </div>
      </div>
    );
  };
  renderOptionalFields = () => {
    const { disabled, namePrefix } = this.props;
    const { contractNumber /* , nGumPartner, delphiGroup, delphiComments */ } =
      this.state;
    return (
      <>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}contractNumber`}
            >{`Rahmenvertragsnummer`}</Label>
            <InputText
              id={`${namePrefix}contractNumber`}
              className={`w3-block w3-border`}
              value={contractNumber}
              onChange={(event) =>
                this.setState({ contractNumber: event.target.value })
              }
              disabled={disabled}
            />
          </div>
          {/* <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}nGumPartner`}
            >{`nGum Partner Company`}</Label>
            <InputText
              id={`${namePrefix}nGumPartner`}
              className={`w3-block w3-border`}
              value={nGumPartner}
              onChange={(event) =>
                this.setState({ nGumPartner: event.target.value })
              }
              disabled={disabled}
            />
          </div> */}
        </div>
        {/* <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}delphiGroup`}
            >{`Delphi Kräftegruppe`}</Label>
            <InputText
              id={`${namePrefix}delphiGroup`}
              className={`w3-block w3-border`}
              value={delphiGroup}
              onChange={(event) =>
                this.setState({ delphiGroup: event.target.value })
              }
              disabled={disabled}
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}delphiComments`}
            >{`Delphi Kurzbezeichnung`}</Label>
            <InputText
              id={`${namePrefix}delphiComments`}
              className={`w3-block w3-border`}
              value={delphiComments}
              onChange={(event) =>
                this.setState({ delphiComments: event.target.value })
              }
              disabled={disabled}
            />
          </div>
        </div> */}
      </>
    );
  };
  render() {
    const {
      className,
      isVertical,
      namePrefix,
      types,
      // userCompany,
      model,
      disabled,
      onlyServicePartner,
    } = this.props;
    const { name, typeId, error } = this.state;
    console.log("BasicDataForm() props", this.props, name, typeId, error);

    let TypeOptions = (types || []).map((item) => {
      return { label: item.label, value: item.id };
    });
    /* if (userCompany && userCompany.id === model.id) {
      const found = TypeOptions.find(
        (item) => item.value === userCompany.type_id
      );
      if (!found) {
        TypeOptions = [
          { label: userCompany.typeName, value: userCompany.type_id },
        ].concat(TypeOptions);
      }
    } elseif (model.id) {
      const found = TypeOptions.find((item) => item.value === model.type_id);
      if (!found) {
        TypeOptions = [
          {
            label: COMPANY_TYPE_LABELS[`${model.type_id}`],
            value: model.type_id,
          },
        ].concat(TypeOptions);
      }
    } */
    // exclude TypeID = 4 (Firma) when creating Service Partners (KDLs and DLs)
    if (model.type_id === 1) {
      TypeOptions = [
        {
          label: COMPANY_TYPE_LABELS[`${model.type_id}`],
          value: model.type_id,
        },
      ];
    } else {
      TypeOptions = TypeOptions.filter((item) =>
        onlyServicePartner ? item.value < 4 : item.value >= 4
      );
    }
    const typeMarkup = (
      <Dropdown
        id={`${namePrefix}typeId`}
        className={`w3-block w3-border`}
        value={typeId}
        options={TypeOptions}
        onChange={(event) => this.onCompanyTypeChanged(event.value)}
        disabled={disabled || model.type_id === 1}
        showClear
      />
    );
    return (
      <div className={className}>
        <div className="neg-margin-lr w3-row">
          <div
            className={`w3-col s6 pad-lr pad-top form-group-item ${
              error.name && " error-group"
            }`}
          >
            <Label htmlFor={`${namePrefix}name`}>{`Firmenname*`}</Label>
            <InputText
              id={`${namePrefix}name`}
              className={`w3-block w3-border`}
              value={name}
              onChange={(event) => this.setState({ name: event.target.value })}
              disabled={disabled}
            />
          </div>
          <div
            className={`w3-col s6 pad-lr pad-top form-group-item ${
              error.typeId && " error-group"
            }`}
          >
            <Label htmlFor={`${namePrefix}typeId`}>{`Firmentyp*`}</Label>
            {typeMarkup}
          </div>
        </div>
        <div className="neg-margin-lr">
          <div className="w3-row">
            {this.renderStreetHouse(
              `w3-col ${
                isVertical ? "m12" : "m6"
              } pad-lr pad-top form-group-item`
            )}
            {this.renderAreaCity(
              `w3-col ${
                isVertical ? "m12" : "m6"
              } pad-lr pad-top form-group-item`
            )}
          </div>
        </div>
        {onlyServicePartner && this.renderOptionalFields()}
      </div>
    );
  }
}
