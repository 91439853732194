import { Resources } from "../../Permissions";

const specialChildren = [
  // {
  //   to: "/organisation/firma",
  //   label: "Firmenprofil",
  //   isVisibleForFlag: "isWorkerLogisticsAssistant",
  // },
  {
    to: "/organisation/firmen",
    label: "Firmenprofile",
    resource: Resources.Organisation.Companies,
  },
  {
    to: "/organisation/mitarbeiter",
    label: "Mitarbeiterprofile",
    resource: Resources.Organisation.Workers,
  },
  {
    to: "/organisation/messgeraete",
    label: "Messgeräte",
    resource: Resources.Organisation.Devices.Page,
    subMenu: {
      className: "nav-level-2",
      links: [
        {
          to: "/organisation/messgeraete/mitarbeiter",
          label: "Messgeräte",
          resource: Resources.Organisation.Devices.UserDevices,
        },
        {
          to: "/organisation/messgeraete/admin",
          label: "Referenzmessgeräte",
          resource: Resources.Organisation.Devices.Devices,
        },
      ],
    },
  }
];

export const buildOrganisationTree = (tree) => {
  const organisationTree = JSON.parse(JSON.stringify(tree));
  for (let index = 0; index < organisationTree.children.length; index++) {
    const { url } = organisationTree.children[index];
    switch (url) {
      case "/organisation/archiv":
        organisationTree.children[index].resource = Resources.Organisation.Archive;
        break;
      case "/organisation/formulare":
        organisationTree.children[index].resource = Resources.Organisation.Forms;
        break;
      case "/organisation/kommunikation":
        organisationTree.children[index].resource = Resources.Organisation.CommunicationOverview;
        break;
      default:
        break;
    }
  }
  // console.log("OrganisationTree:", {organisationTree})
  organisationTree.children.splice(0, 0, ...specialChildren);
  return organisationTree;
};
