import * as actionTypes from "../actions";
import { notifySuccess, notifyError } from "../../../components/Utils";

export const initialState = {
  msAuthenticatorTasks: [],
  selectedMSAuthenticatorTasks: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_MS_AUTHENTICATOR:
      return {
        ...state,
        selectedMSAuthenticatorTasks: action.selected,
      };
    // close connection task
    case actionTypes.CLOSE_MS_AUTHENTICATOR_TASK:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CLOSE_MS_AUTHENTICATOR_TASK_SUCCESS: {
      let users = action.tasks.map((task) => task.user).join(", ");
      const article = users.length > 1 ? "die" : "den";
      const suffix = users.length > 1 ? "n" : "";
      if (users.length > 1) {
        users = `(${users})`;
      }
      notifySuccess({
        summary: `MS Authenticator Aufgabe${suffix} abschliessen`,
        detail: `Die MS Authenticator Aufgabe${suffix} für ${article} Mitarbeiter ${users} wurde${suffix} erfolgreich abgeschlossen.`,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.CLOSE_MS_AUTHENTICATOR_TASK_FAILURE:
      {
        const users = action.tasks.map((task) => task.user);
        const article = users.length > 1 ? "die" : "den";
        const suffix = users.length > 1 ? "n" : "";
        notifyError({
          summary: `MS Authenticator Aufgabe${suffix} abschliessen`,
          detail: `Die MS Authenticator Aufgabe${suffix} für ${article} Mitarbeiter (${users.join(
            ", "
          )}) konnte nicht abgeschlossen werden.`,
        });
      }
      return {
        ...state,
        loading: false,
        error: action.result,
      };

    // delete connection task
    case actionTypes.DELETE_MS_AUTHENTICATOR_TASK:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MS_AUTHENTICATOR_TASK_SUCCESS:
      notifySuccess({
        summary: `MS Authenticator Aufgabe löschen`,
        detail: `Der gewählte MS Authenticator Aufgabe wurde erfolgreich gelöscht.`,
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_MS_AUTHENTICATOR_TASK_FAILURE:
      notifyError({
        summary: `MS Authenticator Aufgabe löschen`,
        detail: `Die gewählte MS Authenticator Aufgabe konnte nicht gelöscht werden.`,
      });
      return {
        ...state,
        loading: false,
        error: action.result,
      };

    default:
      return null;
  }
};
