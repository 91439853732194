import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Dashboard from '../../../components/Dashboard';
import ModemWizardTitle from './ModemWizardTitle';

import CartDebitorList from './cart/CartDebitorList';
import CartModemCatalog from './cart/CartModemCatalog';
import ModemShoppingCart from './cart/ModemShoppingCart';

import OrderDebitorList from './order/OrderDebitorList';
import OrderModemCatalog from './order/OrderModemCatalog';
import OrderOverview from './order/OrderOverview';

import { connect } from 'react-redux';
import { cancelModemOrder } from '../actions';
import { PermissionGroupFetcher } from '../../Permissions';


const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  cancelOrder: (...args) => dispatch(cancelModemOrder(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    cancelOrder: PropTypes.func.isRequired,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  cancelOrder = () => {
    console.log('ModemOrderWizard.onCancel()');
    const { cancelOrder, history } = this.props;
    cancelOrder();
    history.replace('/shop/modems');
  }
  renderMenu = () => {
    const isEditing = (this.props.match.params.id !== 'neu');
    return (
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            <li><NavLink to={`debitoren`} className="disabled-link" activeClassName="active">{'Debitoren'}</NavLink></li>
            <li><NavLink to={`katalog`} className="disabled-link" activeClassName="active">{'Modems'}</NavLink></li>
            {!isEditing && (<li><NavLink to={`warenkorb`} className="disabled-link" activeClassName="active">{'Warenkorb'}</NavLink></li>)}
            {isEditing && (<li><NavLink to={`uebersicht`} className="disabled-link" activeClassName="active">{'Übersicht'}</NavLink></li>)}
          </ul>
        </div>
      </div>
    );
  }
  render() {
    const { className, history, match } = this.props;
    console.log('ModemOrderWizard.render()');
    const Menu = this.renderMenu();
    const pageProps = { history, match, cancelOrder: this.cancelOrder };
    const DebitorListPage = () => (<DebitorListChooserPage {...pageProps}/>);
    const CatalogPage = () => (<ModemCatalogChooserPage {...pageProps}/>);
    const OverviewPage = () => (<OverviewChooserPage {...pageProps}/>);
    return (
      <div className={`modem-order-wizard clearfix ${className}`}>
        <ModemWizardTitle/>
        <PermissionGroupFetcher group="AdminDataAccess"/>
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <div className="pad-big-top">
              <Switch>
                <Route path="/wizard/shop/bestellung/modem/:id/debitoren" exact component={DebitorListPage}/>
                <Route path="/wizard/shop/bestellung/modem/:id/katalog" exact component={CatalogPage}/>
                <Route path="/wizard/shop/bestellung/modem/:id/warenkorb" exact component={OverviewPage}/>
                <Route path="/wizard/shop/bestellung/modem/:id/uebersicht" exact component={OverviewPage}/>
                <Route path="*" render={(props) => (<Redirect to={`/wizard/shop/bestellung/modem/${props.match.params.id || 'neu'}/debitoren`} />)}/>
              </Switch>
            </div>
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);

const DebitorListChooserPage = ({ match, ...props }) => {
  const id = match.params.id;
  const Component = (id === 'neu') ? CartDebitorList : OrderDebitorList;
  return (<Component match={match} {...props}/>);
};

const ModemCatalogChooserPage = ({ match, ...props }) => {
  const id = match.params.id;
  const Component = (id === 'neu') ? CartModemCatalog : OrderModemCatalog;
  return (<Component match={match} {...props}/>);
};

const OverviewChooserPage = ({ match, ...props }) => {
  const id = match.params.id;
  const Component = (id === 'neu') ? ModemShoppingCart : OrderOverview;
  return (<Component match={match} {...props}/>);
};
