
import { getTaskTypeByActionID, TASK_SECTION_TYPES } from '../components/Utils';
import { loadOpenTasks } from '.';

export const FILTER_DEVICES = 'tasks/devices/filter';

export const SET_SELECTED_DEVICES = 'task/device/set';

export const EXPORT_DEVICES = 'task/device/export';
export const EXPORT_DEVICES_SUCCESS = 'task/deviceexport/success';
export const EXPORT_DEVICES_FAILURE = 'task/deviceexport/failure';

export const DELETE_DEVICE_TASK = 'tasks/devices/delete';
export const DELETE_DEVICE_TASK_SUCCESS = 'tasks/devices/delete/success';
export const DELETE_DEVICE_TASK_FAILURE = 'tasks/devices/delete/failure';

export const CLOSE_DEVICE_TASK = 'tasks/devices/close';
export const CLOSE_DEVICE_TASK_SUCCESS = 'tasks/devices/close/success';
export const CLOSE_DEVICE_TASK_FAILURE = 'tasks/devices/close/failure';


export const filterDevices = (taskType) => {
  return { type: FILTER_DEVICES, taskType };
};

export const setSelectedDevices = (selected) => {
  console.log(`REDUX-ACTION: setSelectedDevices()`, selected);
  return { type: SET_SELECTED_DEVICES, selected };
};

export const exportDevices = ({ deviceTaskType, valid_until }) => {
  console.log(`REDUX-ACTION: exportDevices(${deviceTaskType}, ${valid_until})`);
  return (dispatch, getState) => {
    const { selectedDevices } = getState().task;
    const data = selectedDevices.map(item => (valid_until ? { ...item, valid_until } : { ...item }));
    dispatch({
      types: [ 
        EXPORT_DEVICES, 
        EXPORT_DEVICES_SUCCESS, 
        EXPORT_DEVICES_FAILURE 
      ],
      promise: client => client.post(`/companies/load/csv/tasks/${deviceTaskType}`, { data }),
      onSuccess: dispatch => dispatch(loadOpenTasks(TASK_SECTION_TYPES.DEVICES)),
      deviceTaskType
    });
  }
};

export const closeDeviceTask = ({ task, deviceTaskType }, onSuccess) => {
  const taskType = getTaskTypeByActionID(task.action_id);
  console.log(`REDUX-ACTION: closeDeviceTask(${taskType})`, task, deviceTaskType);
  return {
    types: [ 
      CLOSE_DEVICE_TASK, 
      CLOSE_DEVICE_TASK_SUCCESS, 
      CLOSE_DEVICE_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/${deviceTaskType}`, { data: [task] }),
    onSuccess: dispatch => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.DEVICES));
    },
    task,
    deviceTaskType
  };
};

export const deleteDeviceTask = ({ task, deviceTaskType }, onSuccess) => {
  const taskType = getTaskTypeByActionID(task.action_id);
  console.log(`REDUX-ACTION: deleteDeviceTask(${taskType})`, task, deviceTaskType);
  return {
    types: [ 
      DELETE_DEVICE_TASK, 
      DELETE_DEVICE_TASK_SUCCESS, 
      DELETE_DEVICE_TASK_FAILURE 
    ],
    promise: client => client.post(`/companies/connections/reset/${deviceTaskType}`, { data: [task] }),
    onSuccess: dispatch => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.DEVICES));
    },
    task,
    deviceTaskType
  };
};
