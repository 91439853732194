import { toggleGlobalOverlay } from "../../components/Utils";

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      if (typeof action === "function") {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      toggleGlobalOverlay(true);
      next({ ...rest, type: REQUEST });

      const actionPromise = promise(client);
      actionPromise
        .then(
          (response) => {
            // console.log("## Call Response:", response);
            const { body: result, header } = response;
            next({ ...rest, result, type: SUCCESS, header });
            // dispatching onSuccess actions
            if (action.onSuccess && typeof action.onSuccess === "function") {
              action.onSuccess(dispatch, getState, result, header);
            }
          },
          (...args) => {
            const [response, ...restArgs] = args;
            // console.log("## Call Error Response:", response, ...restArgs);
            const { body: error } = response;
            next({ ...rest, error, type: FAILURE });
            // dispatching onFailure actions ...
            if (action.onFailure && typeof action.onFailure === "function") {
              action.onFailure(dispatch, getState);
            }
          }
        )
        .catch((error) => {
          console.error("MIDDLEWARE ERROR:", error);
          next({ ...rest, error, type: FAILURE });
        })
        .finally(() => {
          toggleGlobalOverlay(false);
        });
      return actionPromise;
    };
  };
}
