import React from "react";
import PropTypes from "prop-types";
import { InputText, Label } from "../../../../components/Inputs";
import { ProfilePhoto } from "../../../Profile/components";
import BasicProfileDataMenu from "../BasicProfileDataMenu";
import {
  getUserContactOptionLabel,
} from "../../../../components/Utils";

export default class BasicDataPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    menu: PropTypes.node,
  };
  static defaultProps = {
    className: "",
    model: {
      username: "",
      firstname: "",
      lastname: "",
      email: "",
      cellNumber: "",
      company: "",
      roles: [],
      extendedRoles: [],
      image: null,
    },
    menu: null,
  };
  renderCompany = (company, index, isMain) => {
    const roles = (company.roles || []).concat(
      company.extendedRoles || []
    );
    return (
      <div key={index} className="w3-row neg-margin-lr pad-big-top">
        <div className="w3-col m6 l3 pad-lr">
          <Label>{`Beschäftigt bei`}</Label>
          <InputText className="w3-block" value={company.name} readonly />
        </div>
        <div className="w3-col m6 l3 pad-lr">
          <Label>{`KDL/DL`}</Label>
          <InputText
            className="w3-block"
            value={company.contractGiver}
            readonly
          />
        </div>
        <div className="w3-col m6 l3 pad-lr">
          <Label>{"Position"}</Label>
          <div>
            {roles.map((role, index) => (
              <InputText
                key={`role${index}`}
                className="w3-block"
                value={role}
                readonly
              />
            ))}
          </div>
        </div>
        <div className="w3-col m6 l3 pad-lr">
          <Label>{`Telefon (dienstlich)`}</Label>
          <InputText
            className="w3-block"
            value={company.telephoneNumber}
            readonly
          />
        </div>
      </div>
    );
  };
  render() {
    const { className, model, menu } = this.props;
    const Menu = menu || <BasicProfileDataMenu />;
    const {
      firstname,
      lastname,
      username,
      email,
      cellNumber,
      preferredContactMethod,
      companies,
      image,
    } = model;
    let mainCompany = null;
    const otherCompanies = [];
    for (const index in companies) {
      const isMain = companies[index].role;
      if (isMain)
        mainCompany = this.renderCompany(companies[index], index, isMain);
      else otherCompanies.push(this.renderCompany(companies[index], index));
    }
    return (
      <div className={className}>
        <div className="clearfix">
          <div className="w3-right">{Menu}</div>
          <h3 className="no-margin-top">{`${firstname} ${lastname}`}</h3>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s10 pad-lr">
            <div className="w3-row neg-margin-lr pad-top">
              <div className="w3-col m6 l3 pad-lr">
                <Label>{`Benutzername`}</Label>
                <InputText className="w3-block" value={username} readonly />
              </div>
              <div className="w3-col m6 l3 pad-lr">
                <Label>{`E-Mail`}</Label>
                <InputText className="w3-block" value={email} readonly />
              </div>
              <div className="w3-col m6 l3 pad-lr">
                <Label>{`Telefon (mobil)/Festnetz`}</Label>
                <InputText className="w3-block" value={cellNumber} readonly />
              </div>
              <div className="w3-col m6 l3 pad-lr">
                <Label>{`2FA`}</Label>
                <InputText
                  className="w3-block"
                  value={getUserContactOptionLabel(preferredContactMethod)}
                  readonly
                />
              </div>
            </div>
            {mainCompany}
            {otherCompanies}
          </div>
          <div className="w3-col s2 pad-lr">
            <ProfilePhoto className="margin-top" image={image} />
          </div>
        </div>
      </div>
    );
  }
}
