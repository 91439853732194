import React from "react";
import PropTypes from "prop-types";
import RichTextEditor from "../../../../components/RichTextEditor";
import { replaceAll } from "../../../../components/Utils";
import {
  getContentTypeIdFromType,
  CONTENT_HTML,
  toEditableHtml,
} from "../Utils";
import HtmlText from "../../../../components/HtmlText";

export default class HtmlContentForm extends React.PureComponent {
  static propTypes = {
    pageId: PropTypes.string,
    className: PropTypes.string,
    model: PropTypes.shape({}),
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: { body: "" },
  };
  constructor(props) {
    super(props);
    const { model } = props;
    let body = toEditableHtml(model.body);
    this.state = { body };
  }
  getModel = (noValidate) => {
    const { body } = this.state;
    const { id } = this.props.model;
    // formating and cleaning up
    let data = replaceAll(body, "<ul>", '<ul class="vf-list no-margin-tb">');
    data = replaceAll(data, "<p><br></p>", "");
    data = replaceAll(data, "<p></p>", "");
    //
    return {
      id,
      content_type_id: getContentTypeIdFromType(CONTENT_HTML),
      body: data,
    };
  };
  render() {
    const { className, pageId, disabled } = this.props;
    const { body } = this.state;
    return (
      <div
        className={`html-content-form ${className} pad-big-`}
        style={{ margin: "-1px" }}
      >
        <div className="pos-rel">
          {disabled ? (
            <div className="pad-tb pad-big-lr w3-border w3-round">
              <HtmlText text={body} />
            </div>
          ) : (
            <RichTextEditor
              className=""
              value={body}
              onChange={(event) => this.setState({ body: event.target.value })}
              bounds={`#${pageId}`}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    );
  }
}
