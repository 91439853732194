import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import {
  InputText,
  Label,
  Dropdown,
  Calendar,
  MultiSelect,
} from "../../../components/Inputs";
import {
  toDBDate,
  toUIInputDate,
  toUIDate,
  createSortByLabel,
  PageHeight40,
  validateSerialNumber,
} from "../../../components/Utils";
import { PermissionResource, Resources } from "../../Permissions";

const createOptionList = (item) => ({
  label: `${item.firstname} ${item.lastname}`,
  // value: item.id,
  value: `${item.id}`,
});

// const getNewStateValue = (parKey, chdkey, state, newValue, defaultValue) => {
//   let result = state[key];
//   if (newValue !== result) {
//     result = defaultValue;
//   }
//   return result;
// };

export default class UserDeviceForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    devices: PropTypes.array,
    deviceManagerCompanies: PropTypes.array,
    deviceManagers: PropTypes.array,
    companies: PropTypes.array,
    users: PropTypes.array,
    disabled: PropTypes.bool,
    currentWorker: PropTypes.shape({}),
    reset: PropTypes.func.isRequired,
    getCompanies: PropTypes.func /* .isRequired */,
    getWorkers: PropTypes.func /* .isRequired */,
    onMacAddressChanged: PropTypes.func,
    isTask: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      device: "",
      serialNumber: "",
      userInChargeCompanyId: 0,
      companyId: 0,
      // userInCharge: "",
      // userInChargeId: 0,
      // userPrimary: "",
      // userPrimaryId: 0,
      // userSecondary: "",
      // userSecondaryId: 0,
      usersInCharge: [],
      userPrimary: "",
      userPrimaryId: "",
      isCalibrated: 0,
      comparismDevice: "",
      macAddress: "",
      lastCheckDate: "",
      nextCheckDate: "",
      modifyDate: "",
      locationText: "",
      options: [],
      regions: [],
    },
    devices: [],
    deviceManagerCompanies: [],
    deviceManagers: [],
    companies: [],
    users: [],
  };
  constructor(props) {
    super(props);
    const { model, devices } = props;
    const optionList = this.getOptionList(devices, model.device);
    // userInChargeId list
    let userInChargeIds = [];
    if (model.usersInCharge) {
      userInChargeIds = model.usersInCharge.map((user) => `${user.id}`);
    }
    // if (model.userInChargeIds) {
    //   userInChargeIds = model.userInChargeIds.map((user) => `${user.id}`);
    // } else {
    //   model.userInChargeId && userInChargeIds.push(`${model.userInChargeId}`);
    // }
    this.state = {
      device: model.device || "",
      serialNumber: model.serialNumber || "",
      userInChargeCompanyIsKDLFlag: null,
      userInChargeCompanyId: model.userInChargeCompanyId || 0,
      companyId: model.company_id || model.companyId || 0,
      // userInChargeId: model.userInChargeId || 0,
      // userPrimaryId: model.userPrimaryId || 0,
      // userSecondaryId: model.userSecondaryId || 0,
      userInChargeIds,
      userPrimaryId: model.userPrimaryId || null,
      isCalibrated: model.isCalibrated,
      comparismDevice: model.comparismDevice || "",
      macAddress: model.macAddress || "",
      lastCheckDate: toUIInputDate(model.lastCheckDate),
      nextCheckDate: toUIDate(model.nextCheckDate),
      modifyDate: toUIDate(model.modifyDate),
      locationText: model.locationText || "",
      account: model.account || "",
      options: model.options || [],
      optionList,
      error: {
        device: null,
        serialNumber: null,
        userInChargeCompanyId: null,
        companyId: null,
        lastCheckDate: null,
        nextCheckDate: null,
      },
    };
    console.log("UserDeviceForm.constructor()", this.state, props);
  }
  componentDidMount() {
    const {
      currentWorker,
      model,
      deviceManagerCompanies,
      deviceManagers,
      getCompanies,
      getWorkers,
    } = this.props;
    console.log(
      "UserDeviceForm.componentDidMount()",
      currentWorker,
      deviceManagerCompanies,
      deviceManagers,
      model
    );
    const { userInChargeCompanyId, companyId } = this.state;
    // not admin
    const state = {
      userInChargeCompanyId,
      companyId,
    };
    if (state.userInChargeCompanyId !== 0) {
      console.log(
        `>> fetching device user companies for device manager company ${userInChargeCompanyId} ...`
      );
      getWorkers(
        {
          isManager: true,
          companyId: state.userInChargeCompanyId,
        },
        () =>
          getCompanies({ companyId: state.userInChargeCompanyId }, () => {
            if (companyId) {
              getWorkers({
                companyId: state.companyId,
                deviceManagerCompany: state.userInChargeCompanyId,
              });
            }
          })
      );
    } else if (deviceManagerCompanies.length === 1) {
      // auto select device manager company if only option is available
      this.setState({ userInChargeCompanyId: deviceManagerCompanies[0].id });
    }
  }
  componentDidUpdate() {
    const { model, devices } = this.props;
    if (!this.state.optionList && devices.length > 0) {
      const optionList = this.getOptionList(devices, model.device);
      if (optionList) {
        this.setState({ optionList });
      }
    }
  }
  componentWillUnmount() {
    console.log("UserDeviceForm.componentWillUnmount()");
    this.props.reset();
  }
  getOptionList = (devices, device) => {
    // console.log("UserDeviceForm.getOptionList()", "devices:", devices, "device:", device);
    let optionList = null;
    if (devices && device !== "") {
      const found = (devices || []).find((item) => item.label === device);
      optionList = found && found.options ? found.options : null;
    }
    return optionList;
  };
  findUserAttributes = (users, value, attributeName, user) => {
    let found = user && user.id === value;
    if (!found) found = users.find((item) => `${item.id}` === `${value}`);
    if (found) {
      return {
        [attributeName]: `${found.firstname} ${found.lastname}`,
        [`${attributeName}Id`]: found.id,
      };
    }
    return null;
  };
  validate = () => {
    const {
      error,
      optionList,
      userInChargeCompanyIsKDLFlag,
      userInChargeIds,
      userIds,
      ...otherProps
    } = this.state;
    let model = { ...otherProps };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (error[key] !== undefined) {
        const invalid = model[key] === "";
        if (invalid) {
          error[key] = true;
          isValid = false;
          console.error("<< validation ERROR", key, model[key]);
        } else {
          error[key] = null;
        }
      }
    });
    // serial number
    if (!validateSerialNumber(model.serialNumber)) {
      error.serialNumber = true;
      isValid = false;
    } else {
      error.serialNumber = null;
    }
    // console.log('UserDeviceForm.validate()', model, isValid, error);
    this.setState({ error });
    if (!isValid) {
      return null;
    }

    const { deviceManagerCompanies, users, deviceManagers } = this.props;
    // device manager company
    const deviceManagerCompany = deviceManagerCompanies.find(
      (item) => item.id === model.userInChargeCompanyId
    );
    if (deviceManagerCompany) {
      model.userInChargeCompanyName = deviceManagerCompany.name || "";
    }
    // company
    const company = this.props.companies.find(
      (item) => item.id === model.companyId
    );
    if (company) {
      model.companyName = company.name || "";
      model.contractGiver = company.contractGiver || null;
    }
    // set users
    model.usersInCharge = [];
    userInChargeIds.forEach((userId) => {
      const result = this.findUserAttributes(deviceManagers/* users */, userId, "user");
      if (result) {
        /* deviceUsersInCharge */
        model.usersInCharge.push({ id: result.userId, name: result.user });
      }
    });
    const userPrimary = this.findUserAttributes(
      users,
      model.userPrimaryId,
      "userPrimary"
    );
    if (userPrimary) {
      model = { ...model, ...userPrimary };
      model.userPrimaryId = Number(model.userPrimaryId);
    }
    if (!userPrimary || model.userPrimaryId === "") {
      // console.log("<< old userPrimary:", userPrimary, "new userPrimaryId:", model.userPrimaryId);
      model.userPrimaryId = null;
    }
    // dates
    model.lastCheckDate = toDBDate(model.lastCheckDate);
    model.nextCheckDate = toDBDate(model.nextCheckDate, "DD.MM.YYYY"); // toDBDate(this.props.model.nextCheckDate, 'DD.MM.YYYY');
    model.modifyDate = this.props.model.modifyDate;
    //
    const { id, index, status_id, status } = this.props.model;
    const result = {
      id,
      index,
      status_id,
      status,
      oldStatusId: status_id,
      ...model,
    };
    // if (!model.companyId)
    // console.log('>> saving device', result);
    return result;
  };
  getModel = () => {
    const model = this.validate();
    return model;
  };
  setManagerCompany = (value, noInit) => {
    const { getCompanies, getWorkers } = this.props;
    const state = {
      userInChargeCompanyId: value,
      userInChargeCompanyIsKDLFlag: null,
      error: {
        ...this.state.error,
        userInChargeCompanyId: isNaN(value) ? true : null,
      },
    };
    if (!noInit && value !== this.state.userInChargeCompanyId) {
      state.companyId = 0;
      state.userPrimaryId = 0;
    }
    let companyId = value;
    this.setState(state);
    getWorkers({ isManager: true, companyId: value }, () =>
      getCompanies({ companyId })
    );
  };
  setCompany = (value) => {
    const { getWorkers } = this.props;
    this.setState({
      companyId: value,
      userInChargeIds: [],
      userPrimaryId: "",
      error: {
        ...this.state.error,
        companyId: isNaN(value) ? true : null,
      },
    });
    getWorkers({
      companyId: value,
      deviceManagerCompanyId: this.state.userInChargeCompanyId,
    });
  };
  setDevice = (value) => {
    const optionList = this.getOptionList(this.props.devices, value);
    this.setState({
      device: value,
      optionList,
      error: {
        ...this.state.error,
        device: value === "",
      },
    });
  };
  onOptionCheckboxChange = (event) => {
    let options = [...this.state.options];
    if (event.checked) {
      options.push(event.value);
    } else {
      options.splice(options.indexOf(event.value), 1);
    }
    this.setState({ options });
  };
  setLastCheckDate = (value) => {
    const state = {
      lastCheckDate: value,
      error: {
        ...this.state.error,
        lastCheckDate: value === "",
      },
    };
    const lastCheckDate = moment(value);
    const device = this.props.devices.find(
      (item) => item.label === this.state.device
    );
    if (device && lastCheckDate.isValid()) {
      let add = parseInt(device.checkInterval);
      if (isNaN(add)) add = 24;
      state.nextCheckDate = lastCheckDate
        .add(add, "months")
        .format("DD.MM.YYYY");
      state.error.nextCheckDate = "";
      // console.log('>> UserDeviceForm.setLastCheckDate()', value, device, state);
    }
    this.setState(state);
  };
  onMacAddressChanged = (value) => {
    const { onMacAddressChanged } = this.props;
    this.setState({ macAddress: value });
    onMacAddressChanged && onMacAddressChanged(value);
  };
  render() {
    const {
      className,
      devices,
      deviceManagerCompanies,
      deviceManagers,
      users,
      companies,
      // optionalPrimaryUser,
      disabled,
      isTask,
    } = this.props;
    const {
      device,
      serialNumber,
      companyId,
      // userInChargeCompanyIsKDLFlag,
      userInChargeCompanyId,
      // userInChargeId,
      // userSecondaryId,
      userInChargeIds,
      userPrimaryId,
      comparismDevice,
      isCalibrated,
      macAddress,
      lastCheckDate,
      nextCheckDate,
      modifyDate,
      locationText,
      account,
      options,
      optionList,
      error,
    } = this.state;
    const DeviceOptions = devices.map((item) => ({
      label: item.label,
      value: item.label,
    }));

    // device manager options
    const deviceManagerCompanyOptions = deviceManagerCompanies
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))
      .sort(createSortByLabel());
    // user options
    const DeviceManagerOptions = deviceManagers.map(createOptionList).sort(createSortByLabel());
    const UserOptions = users.map(createOptionList).sort(createSortByLabel());
    const PrimaryUserOptions = [{ label: "Keiner", value: "" }].concat(
      UserOptions
    );
    // console.log("UserDeviceForm.render() deveiceManagerOptions:", )
    // companyId options
    let CompanyOptions = companies.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    // options
    const createCheckbox = (prefix) => (value, index) =>
      (
        <div key={index} className="w3-col s4">
          <div className="p-col-12">
            <Checkbox
              inputId={`${prefix}${index}`}
              value={value}
              onChange={this.onOptionCheckboxChange}
              checked={options.includes(value)}
              disabled={disabled || isTask}
            ></Checkbox>
            <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">
              {value}
            </label>
          </div>
        </div>
      );
    const optionsMarkup = !optionList
      ? null
      : optionList.map(createCheckbox("usrDev-"));
    // companyInCharge
    const companyInChargeMarkup = (
      <PermissionResource
        resource={
          Resources.Organisation.WorkerWizard.UserDeviceInchargeUserCompany
        }
      >
        <div
          className={`pad-top form-group-item ${
            error.userInChargeCompanyId && "error-group"
          }`}
        >
          <Label htmlFor="userInChargeCompanyId">{`Vertragspartner*`}</Label>
          <Dropdown
            id="userInChargeCompanyId"
            className="w3-block w3-border"
            value={userInChargeCompanyId}
            placeholder="Bitte auswählen"
            options={deviceManagerCompanyOptions}
            onChange={(event) => this.setManagerCompany(event.value)}
            showClear
            disabled={disabled || isTask}
          />
        </div>
      </PermissionResource>
    );
    return (
      <PageHeight40 className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr">
            <div className="neg-margin-lr w3-row pad-top-">
              <div
                className={`w3-col s6 pad-lr form-group-item ${
                  error.device && "error-group"
                }`}
              >
                <Label htmlFor="device">{`Messgerät*`}</Label>
                <Dropdown
                  id="device"
                  className="w3-block w3-border"
                  value={device}
                  placeholder="Bitte auswählen"
                  options={DeviceOptions}
                  onChange={(event) => this.setDevice(event.value)}
                  showClear
                  disabled={disabled || isTask}
                />
              </div>
              <div
                className={`w3-col s6 pad-lr form-group-item ${
                  error.serialNumber && "error-group"
                }`}
              >
                <Label htmlFor="serialNumber">{`Seriennummer*`}</Label>
                <InputText
                  id="serialNumber"
                  className="w3-block w3-border"
                  value={serialNumber}
                  onChange={(event) =>
                    this.setState({
                      serialNumber: event.target.value,
                      error: {
                        ...error,
                        serialNumber: event.target.value === "",
                      },
                    })
                  }
                  disabled={disabled || isTask}
                />
              </div>
            </div>
            {companyInChargeMarkup}
            <div
              className={`pad-top form-group-item ${
                error.companyId && "error-group"
              }`}
            >
              <Label htmlFor="companyId">{`Subunternehmen*`}</Label>
              <Dropdown
                id="companyId"
                className="w3-block w3-border"
                value={companyId}
                placeholder="Bitte auswählen"
                options={CompanyOptions}
                onChange={(event) => this.setCompany(event.value)}
                showClear
                disabled={disabled}
              />
            </div>
            <div className={`pad-top form-group-item`}>
              <Label htmlFor="userInChargeIds">{`Verantwortlicher`}</Label>
              <MultiSelect
                id="userInChargeIds"
                className="w3-border"
                value={userInChargeIds}
                placeholder="Keiner"
                options={DeviceManagerOptions}
                onChange={(event) =>
                  this.setState({ userInChargeIds: event.value })
                }
                showClear
                disabled={disabled}
              />
            </div>
            <div className={`pad-top form-group-item`}>
              <Label htmlFor="userPrimaryId">{`Nutzer`}</Label>
              <Dropdown
                id="userPrimaryId"
                className="w3-block w3-border"
                value={""+userPrimaryId}
                placeholder="Bitte auswählen"
                options={PrimaryUserOptions}
                onChange={(event) =>
                  this.setState({ userPrimaryId: event.value })
                }
                showClear
                disabled={disabled}
              />
            </div>
            <div className="neg-margin-lr w3-row">
              <div className="w3-col s6 pad-lr pad-top form-group-item">
                <Label>{"Ist ein kalibriertes Messgerät?"}</Label>
                <div className="w3-row">
                  <div className="w3-col s6">
                    <div className="p-col-12">
                      <RadioButton
                        inputId={`ch0`}
                        name="isCalibrated"
                        value={1}
                        checked={isCalibrated === 1}
                        onChange={(event) =>
                          this.setState({ isCalibrated: event.value })
                        }
                        disabled={disabled || isTask}
                      />
                      <label
                        htmlFor={`ch0`}
                        className="p-checkbox-label"
                      >{`Ja`}</label>
                    </div>
                  </div>
                  <div className="w3-col s6">
                    <div className="p-col-12">
                      <RadioButton
                        inputId={`ch1`}
                        name="isCalibrated"
                        value={0}
                        checked={isCalibrated === 0}
                        onChange={(event) =>
                          this.setState({ isCalibrated: event.value })
                        }
                        disabled={disabled || isTask}
                      />
                      <label
                        htmlFor={`ch1`}
                        className="p-checkbox-label"
                      >{`Nein`}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w3-col s6 pad-lr pad-top form-group-item">
                <Label htmlFor="comparismDevice">{`Geprüft mit kalibriertem Vergleichsgerät`}</Label>
                <InputText
                  id="comparismDevice"
                  className="w3-block w3-border"
                  value={comparismDevice}
                  onChange={(event) =>
                    this.setState({ comparismDevice: event.target.value })
                  }
                  disabled={disabled || isTask}
                />
              </div>
            </div>
          </div>
          <div className="w3-col s6 pad-lr">
            <div className="pad-top- form-group-item">
              <Label id="options">{`Optionen`}</Label>
              {optionsMarkup}
            </div>
            <div
              className={`pad-top form-group-item ${
                error.lastCheckDate && "error-group"
              }`}
            >
              <Label htmlFor="lastCheckDate">{`Geprüft am*`}</Label>
              <Calendar
                id="lastCheckDate"
                className="w3-block w3-border"
                value={lastCheckDate}
                readOnlyInput={true}
                dateFormat="dd.mm.yy"
                maxDate={new Date()}
                disabled={disabled || device === "" || isTask}
                onChange={(event) => this.setLastCheckDate(event.value)}
                showButtonBar
              />
            </div>
            <div
              className={`pad-top form-group-item ${
                error.nextCheckDate && "error-group"
              }`}
            >
              <Label htmlFor="nextCheckDate">{`Prüfung fällig am*`}</Label>
              <InputText
                id="nextCheckDate"
                className="w3-block w3-border"
                value={nextCheckDate}
                disabled
              />
            </div>
            <div className="pad-top form-group-item">
              <Label htmlFor="modifyDate">{`Letzte Änderung`}</Label>
              <InputText
                id="modifyDate"
                className="w3-block w3-border"
                value={modifyDate}
                disabled
              />
            </div>
            <div className="pad-top form-group-item">
              <Label htmlFor="locationText">{`Standort/Messabweichungen (250 Zeichen)`}</Label>
              <InputTextarea
                id="locationText"
                className="w3-block w3-border"
                value={locationText}
                onChange={(event) =>
                  this.setState({ locationText: event.target.value })
                }
                autoResize
                style={{ height: "40.5px" }}
                disabled={disabled || isTask}
              />
            </div>
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-top pad-lr">
            <Label htmlFor="macAddress">{`CM1 MAC`}</Label>
            <InputText
              id="macAddress"
              className="w3-block w3-border"
              value={macAddress}
              onChange={(event) => this.onMacAddressChanged(event.target.value)}
              disabled={disabled || isTask}
            />
          </div>
          <div className="w3-col s6 pad-top pad-lr">
            <Label htmlFor="account">{`Account`}</Label>
            <InputText
              id="account"
              className="w3-block w3-border"
              value={account}
              disabled
            />
          </div>
        </div>
      </PageHeight40>
    );
  }
}
