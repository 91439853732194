import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Dashboard from '../../../components/Dashboard';
import { PageHeight30, WizardTitle, notifyError } from '../../../components/Utils';
import { FormMenu } from '../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadSale, cancelSale, saveSale, loadSalesWorkers } from '../actions/reporting';

import SalesForm from '../components/SaleForm';
// import { buildSalesKDLs } from '../components/Utils';


const frontload = async (props) => {
  const id = props.match.params.id;
  // await props.getSalesWorkers();
  await props.getSale({ id });
};
const mapStateToProps = (state) => {
  return {
    sale: state.report.sale,
    user: state.worker.currentWorker,
    hasAdminAccess: state.permissions.permissionGroup.admindataaccess,
    salesPartners: state.report.salesPartners,
    salesAgents: state.report.salesAgents,
    tipGivers: state.report.tipGivers
  };
};
const mapDispatchToProps = (dispatch) => ({
  getSale: (...args) => dispatch(loadSale(...args)),
  getSalesWorkers: (...args) => dispatch(loadSalesWorkers(...args)),
  cancelSale: (...args) => dispatch(cancelSale(...args)),
  saveSale: (...args) => dispatch(saveSale(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    hasAdminAccess: PropTypes.shape({}),
    sale: PropTypes.object,
    user: PropTypes.object,
    // companies: PropTypes.array,
    salesPartners: PropTypes.array,
    salesAgents: PropTypes.array,
    tipGivers: PropTypes.array,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getSale: PropTypes.func.isRequired,
    cancelSale: PropTypes.func.isRequired,
    saveSale: PropTypes.func.isRequired,
    className: PropTypes.string
  }
  static defaultProps = {
    className: '',
    salesAgents: [],
    tipGivers: []
  }
  constructor(props) {
    super();
    this.state = {}
  }
  componentWillUnmount() {
    this.props.cancelSale();
  }
  onCancel = () => {
    // console.log('SaleWizard.onCancel()');
    const { cancelSale, history } = this.props;
    cancelSale();
    history.replace('/sales/reporting-portal');
  }
  onSave = () => {
    // console.log('SaleWizard.onSave()');
    const model = this.form.getModel();
    if (!model) {
      return notifyError({
        summary: 'Absatzverwaltung',
        detail: `Der Absatz konnte nicht gemeldet werden. Bitte Ihre Eingaben überprüfen.`
      });
    }
    // console.log('Saving Model: ', model);
    const { match, getSale } = this.props;
    const id = match.params.id;
    const onSuccess = () => getSale({ id });;
    this.props.saveSale({ sale: model }, onSuccess);
  }
  renderMenu = () => {
    return (
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            <li><NavLink to={`/daten`} className="disabled-link" activeClassName="active">{'Allgemeine Daten'}</NavLink></li>
          </ul>
        </div>
      </div>
    );
  }
  render() {
    const {
      className, user, hasAdminAccess, sale,
      salesPartners, salesAgents, tipGivers, getSalesWorkers
    } = this.props;
    // console.log('SaleWizard.render()', this.props);
    if (!sale || !user || !salesPartners/*  || !companies */) {
      return null;
    }
    console.log('SaleWizard.render() salesPartners', this.props);
    const Menu = this.renderMenu();
    const kdlCompanies = salesPartners; // buildSalesKDLs(user, companies);
    return (
      <div className={`sale-order-wizard clearfix ${className}`}>
        <WizardTitle
          type="sale"
          model={sale}
          menu={<FormMenu className="pad-big-top" onSave={this.onSave} onCancel={this.onCancel} saveBtnName="saveSaleBtn2"/>}
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight30 id="/daten" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <h3 className="no-margin-top">Allgemeine Daten</h3>
              <SalesForm
                ref={ref => this.form = ref}
                model={sale}
                companies={kdlCompanies}
                currentUser={user}
                salesAgents={salesAgents}
                tipGivers={tipGivers}
                hasAdminAccess={hasAdminAccess}
                getSalesWorkers={getSalesWorkers}
              />
            </PageHeight30>
            <FormMenu
              className="w3-border margin-big-top pad-big w3-light-grey"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveSaleBtn"/>
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
