import { getUserRoles } from "../../components/Utils";
import { fetchPermissions } from "./test/repository";
import * as Resources from "./resources";

function getAllLeaves(tree) {
  const leaves = [];
  function traverse(node) {
    if (typeof node === "object" && node !== null) {
      // If the node is an object, recursively traverse its properties
      for (const key in node) {
        if (node.hasOwnProperty(key)) {
          traverse(node[key]);
        }
      }
    } else {
      // If the node is not an object, it's a leaf, add it to the array
      leaves.push(node);
    }
  }
  traverse(tree);
  return leaves;
}

const getGroupResources = (resourceTree, path = "") => {
  let result = null;
  const tokens = path.split(".");
  let currentObject = resourceTree;
  if (path !== "all") {
    for (const token of tokens) {
      if (!currentObject.hasOwnProperty(token)) {
        // Token doesn't exist as an attribute name
        return null;
      }
      currentObject = currentObject[token];
      if (typeof currentObject !== "object" || currentObject === null) {
        // If a token doesn't represent an object and it's not the last token
        if (tokens.indexOf(token) !== tokens.length - 1) {
          return null;
        }
      }
    }
  }
  // Return values of the object found at the end of navigation
  if (typeof currentObject === "object" && currentObject !== null) {
    // result = Object.values(currentObject);
    result = getAllLeaves(currentObject);
  } else if (typeof currentObject === "string") {
    result = [currentObject];
  }
  return result;
};

export const LOAD_ALL_PERMISSIONS = "access-control/permissionS/load";
export const LOAD_ALL_PERMISSIONS_SUCCESS =
  "access-control/permissionS/load/success";
export const LOAD_ALL_PERMISSIONS_FAILURE =
  "access-control/permissionS/load/failure";

export const LOAD_PERMISSIONS = "access-control/permission/load";
export const LOAD_PERMISSIONS_SUCCESS =
  "access-control/permission/load/success";
export const LOAD_PERMISSIONS_FAILURE =
  "access-control/permission/load/failure";

export const CREATE_PERMISSION = "access-control/permission/create";
export const CREATE_PERMISSION_SUCCESS =
  "access-control/permission/create/success";
export const CREATE_PERMISSION_FAILURE =
  "access-control/permission/create/failure";

export const UPDATE_PERMISSION = "access-control/permission/update";
export const UPDATE_PERMISSION_SUCCESS =
  "access-control/permission/update/success";
export const UPDATE_PERMISSION_FAILURE =
  "access-control/permission/update/failure";

export const DELETE_PERMISSION = "access-control/permission/delete";
export const DELETE_PERMISSION_SUCCESS =
  "access-control/permission/delete/success";
export const DELETE_PERMISSION_FAILURE =
  "access-control/permission/delete/failure";

export const HAS_READ_PERMISSION = "access-control/permission/has/read";
export const HAS_READ_PERMISSION_SUCCESS =
  "access-control/permission/has/read/success";
export const HAS_READ_PERMISSION_FAILURE =
  "access-control/permission/has/read/failure";

export const HAS_WRITE_PERMISSION = "access-control/permission/has/write";
export const HAS_WRITE_PERMISSION_SUCCESS =
  "access-control/permission/has/write/success";
export const HAS_WRITE_PERMISSION_FAILURE =
  "access-control/permission/has/write/failure";

export const loadAllPermissions = () => ({
  types: [
    LOAD_ALL_PERMISSIONS,
    LOAD_ALL_PERMISSIONS_SUCCESS,
    LOAD_ALL_PERMISSIONS_FAILURE,
  ],
  // promise: (client) => client.get(`/companies/permissions`),
  promise: (client) => client.get(`/companies/permissions-by-roleNames/null`),
});

export const loadPermissions = ({
  resources,
  resourceGroup,
  roles,
  permission,
}) => {
  // console.log(">> ACTION::loadPermissions()", resources);
  const resourcesParam = encodeURIComponent(resources.join(","));
  return {
    types: [
      LOAD_PERMISSIONS,
      LOAD_PERMISSIONS_SUCCESS,
      LOAD_PERMISSIONS_FAILURE,
    ],
    promise: (client) => client.get(`/companies/resources/${resourcesParam}`),
    resources,
    resourceGroup,
  };
};

export const createPermission = (permission = {}) => {
  const { resource, role, canRead, canWrite } = permission;
  const data = { resource, role, canRead, canWrite };
  return {
    types: [
      CREATE_PERMISSION,
      CREATE_PERMISSION_SUCCESS,
      CREATE_PERMISSION_FAILURE,
    ],
    promise: (client) => client.post(`/permissions`, { data }),
  };
};

export const updatePermission = (permission = {}) => {
  const { resource, role, canRead, canWrite } = permission;
  const data = { resource, role, canRead, canWrite };
  return {
    types: [
      UPDATE_PERMISSION,
      UPDATE_PERMISSION_SUCCESS,
      UPDATE_PERMISSION_FAILURE,
    ],
    promise: (client) => client.put(`/permissions`, { data }),
  };
};

export const deletePermission = (permission = {}) => ({
  types: [
    DELETE_PERMISSION,
    DELETE_PERMISSION_SUCCESS,
    DELETE_PERMISSION_FAILURE,
  ],
  promise: (client) =>
    client.get(`/permissions/${encodeURIComponent(permission)}`),
});

// export const hasReadPermission = (resource) => {
//   return (dispatch, getState) => {
//     const roles = getUserRoles(getState().auth.user);
//     dispatch(loadPermissions({ resource, roles, permission: "read" }));
//   };
// };

// export const hasWritePermission = (resource) => {
//   return (dispatch, getState) => {
//     const roles = getUserRoles(getState().auth.user);
//     dispatch(loadPermissions({ resource, roles, permission: "write" }));
//   };
// };

// ================================================================= //

export const loadResourcePermissions = (resource) =>
  loadPermissions({ resources: [resource] });

export const loadResourceListPermissions = (resources, resourceGroup) =>
  loadPermissions({ resources, resourceGroup });

export const loadResourceGroupPermissions = (resourceGroup = "all") => {
  const resources = getGroupResources(Resources, resourceGroup);
  // console.log(">> loading group resources:", resourceGroup, resources);
  if (!resources) {
    return { type: LOAD_PERMISSIONS_FAILURE };
  }
  return loadResourceListPermissions(resources, resourceGroup);
};

// export const loadResourcePermissions = (resource) => {
//   return (dispatch, getState) => {
//     const { user } = getState().auth;
//     const roles = getUserRoles(user).concat(getUserRoles(user, true));
//     dispatch(loadPermissions({ resources: [ resource ], roles }));
//   };
// };

// export const loadResourceListPermissions = (resources) => {
//   console.log("loadResourceListPermissions()", resources);
//   return (dispatch, getState) => {
//     const roles = getUserRoles(getState().auth.user);
//     dispatch(loadPermissions({ resources, roles }));
//   };
// };

// ================================================================= //

export const isResourceReadable = (resource, permissionMap) =>
  resource &&
  permissionMap &&
  permissionMap[resource] &&
  permissionMap[resource].read;

export const isResourceEditable = (resource, permissionMap) =>
  resource &&
  permissionMap &&
  permissionMap[resource] &&
  permissionMap[resource].write;

export const getResourcePermissions = (resource, permissionMap) => {
  let result = null;
  if (resource && permissionMap && permissionMap[resource]) {
    result = { ...permissionMap[resource] };
  }
  return result;
};

export const canReadAdministrativeData = (state) => {
  const { permissionMap } = state.permissions;
  return isResourceReadable(Resources.AdminDataAccess, permissionMap);
};

export const canWriteAdministrativeData = (state) => {
  const { permissionMap } = state.permissions;
  return isResourceEditable(Resources.AdminDataAccess, permissionMap);
};