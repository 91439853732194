import React from "react";
import PropTypes from "prop-types";
import Dashboard from "../../../components/Dashboard";
import { PageHeight30, UnderConstruction } from "../../../components/Utils";
import { getCompanyModel, renderWorkerDetailsMenu } from "../components/Utils";
import {
  BasicDataPanel,
  ITConnectionPanel,
  TeckKeysPanel,
  UserDeviceListPanel,
  CertificationListPanel,
  TechKeyListPanel,
  UserTechDeviceListPanel,
  ContractorCompanyPanel,
  UserRatingPanel,
} from "../components/panels";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadCurrentWorker } from "../actions";
import UserTrainingPanel from "../components/panels/UserTrainingPanel";
import EmailForm from "../components/wizard/EmailForm";
import {
  PermissionResource,
  PermissionResourceList,
  Resources,
} from "../../Permissions";

const frontload = async (props) => {
  await props.getWorker();
};
const mapStateToProps = (state) => {
  return {
    worker: state.worker.currentWorker,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getWorker: (...args) => dispatch(loadCurrentWorker(...args)),
});

class WorkerPanel extends React.Component {
  static propTypes = {
    worker: PropTypes.object,
    getWorker: PropTypes.func.isRequired,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
    worker: {
      username: "",
      name: "",
      lastname: "",
      email: "",
      telephoneNumber: "",
      cellNumber: "",
      company: "",
      position: "",
      roles: [],
    },
  };
  render() {
    const { className, worker } = this.props;
    console.log("WorkerPanel.render()", worker);
    if (!worker) return null;
    const Menu = renderWorkerDetailsMenu(worker.isOwn, true);

    // IT Connections
    const itConnectionData = worker.itConnectionData
      ? { ...worker.itConnectionData }
      : {};
    itConnectionData.delphiConnections = getCompanyModel(
      worker,
      "delphiConnections"
    );
    // Devices
    const userDevices = getCompanyModel(worker, "devices");
    // Tech Devices
    const userTechDevices = getCompanyModel(worker, "techDevices");
    // Tech Keys
    const techKeyMarkup = worker.companies.length > 0 && (
      <TeckKeysPanel company={worker.companies[0]} />
    );
    return (
      <div className={`profile-panel clearfix ${className}`}>
        <Dashboard menu={Menu} menuSize={3}>
          <PermissionResource resource={Resources.Profile.BasicInfo}>
            <PageHeight30
              id="basisdaten"
              className="w3-border margin-big-top pad-big"
            >
              <BasicDataPanel model={worker} />
            </PageHeight30>
          </PermissionResource>
          <PermissionResource resource={Resources.Profile.IDCard}>
            <PageHeight30
              id="vkdg-ausweis"
              className="w3-border margin-big-top pad-big"
            >
              <h3 className="no-margin-top">{"VKDG Ausweis"}</h3>
              <UnderConstruction />
            </PageHeight30>
          </PermissionResource>
          <PermissionResourceList
            resources={[
              Resources.Profile.ADConnections,
              Resources.Profile.PTConnections,
              Resources.Profile.DelphiConnections,
            ]}
          >
            <PageHeight30
              id="it-zugaenge"
              className="w3-border margin-big-top pad-big"
            >
              <h3 className="no-margin-top">{"IT-Zugänge"}</h3>
              <ITConnectionPanel model={itConnectionData}>
                <EmailForm model={{ email: worker.vf_email }} readOnly />
              </ITConnectionPanel>
            </PageHeight30>
          </PermissionResourceList>
          {/* {worker.isOwn && (
            <PermissionResource resource={Resources.Profile.ContractGiver}>
              <PageHeight30
                id="auftraggeber-taetigkeit"
                className="w3-border margin-big-top pad-big"
              >
                <h3 className="no-margin-top">
                  {"Tätigkeiten für Auftraggeber"}
                </h3>
                <ContractorCompanyPanel companies={worker.companies} />
              </PageHeight30>
            </PermissionResource>
          )} */}
          {/* <PermissionResource resource={Resources.Profile.Ratings}>
            <PageHeight30
              id="bewertungen"
              className="w3-border margin-big-top pad-big"
            >
              <h3 className="no-margin-top">{"Bewertungen"}</h3>
              <UserRatingPanel
                model={worker.averageRating}
                delphiConnections={itConnectionData.delphiConnections}
              />
            </PageHeight30>
          </PermissionResource> */}
          <PermissionResource resource={Resources.Profile.UserDevices}>
            <PageHeight30
              id="messgeraete"
              className="w3-border margin-big-top pad-big"
            >
              <UserDeviceListPanel model={userDevices} />
            </PageHeight30>
          </PermissionResource>
          <PermissionResource resource={Resources.Profile.TechKeys}>
            <PageHeight30
              id="schluessel"
              className="w3-border margin-big-top pad-big"
            >
              <TechKeyListPanel model={worker.techKeys} />
            </PageHeight30>
          </PermissionResource>
          <PermissionResource resource={Resources.Profile.Certifications}>
            <PageHeight30
              id="zertifizierungen"
              className="w3-border margin-big-top pad-big"
            >
              <CertificationListPanel model={worker.certificates} />
            </PageHeight30>
          </PermissionResource>
          <PermissionResource resource={Resources.Profile.TechDevices}>
            <PageHeight30
              id="vkdg-geraete"
              className="w3-border margin-big-top pad-big"
            >
              <h3 className="no-margin-top">
                {"VKDG Geräte / Technikergeräte"}
              </h3>
              <UnderConstruction />
              {/* <UserTechDeviceListPanel
              model={userTechDevices}
            /> */}
            </PageHeight30>
          </PermissionResource>
          <PermissionResource resource={Resources.Profile.Seminars}>
            <PageHeight30
              id="schulungen"
              className="w3-border margin-big-top pad-big"
            >
              <h3 className="no-margin-top">{"Schulungen"}</h3>
              <UserTrainingPanel model={worker.trainings} />
            </PageHeight30>
          </PermissionResource>
          <PermissionResource resource={Resources.Profile.Audits}>
            <PageHeight30
              id="auditierung"
              className="w3-border margin-big-top pad-big"
            >
              <h3 className="no-margin-top">{"Auditierung"}</h3>
              <UnderConstruction />
            </PageHeight30>
          </PermissionResource>
        </Dashboard>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(WorkerPanel)
);
