import TaskDashboardFactory from "./containers/TaskDashboardFactory";

export { default as AllNewTasks } from "./components/AllNewTasks";
export { ConnectionTypes } from "./components/Utils";

export { default as DebitorTaskList } from "./containers/debitors/DebitorTaskList";
export { default as ADConnectionTaskList } from "./containers/itConnections/ADConnectionTaskList";
export { default as PTConnectionTaskList } from "./containers/itConnections/PTConnectionTaskList";
export { default as DelphiConnectionTaskList } from "./containers/itConnections/DelphiConnectionTaskList";
export { default as MSAuthenticatorTaskList } from "./containers/itConnections/MSAuthenticatorTaskList";

export { default as ITConnectionTaskDashboard } from "./containers/itConnections/ITConnectionTaskDashboard";
export { default as ConnectionImportWizard } from "./containers/itConnections/ConnectionImportWizard";

export { default as DeviceTaskList } from "./containers/device/DeviceTaskList";
export { default as DeviceImportWizard } from "./containers/device/DeviceImportWizard";

export { default as CardOrderTaskList } from "./containers/order/CardOrderTaskList";
export { default as CardOrderTaskOverview } from "./containers/order/CardOrderTaskOverview";
export { default as CardOrderTaskDetails } from "./containers/order/CardOrderTaskDetails";

export { default as KeyOrderTaskOverview } from "./containers/order/KeyOrderTaskOverview";
export { default as KeyOrderTaskDetails } from "./containers/order/KeyOrderTaskDetails";

export { default as ReportTaskList } from "./containers/reports/ReportTaskList";

export { default as UserTaskList } from "./containers/user/UserTaskList";
export { default as FailedEmailTaskList } from "./containers/user/FailedEmailTaskList";
export { default as EmployeeCompanyChangeTaskList } from "./containers/user/EmployeeCompanyChangeTaskList";
export { default as EmployeeDeactivationTaskList } from "./containers/user/EmployeeDeactivationTaskList";

export { default as TasksFetcher } from "./containers/TasksFetcher";

export const DebitorTaskDashboard = TaskDashboardFactory({
  stateKey: "debitorTasks",
  title: "Debitoren",
  url: "/aufgaben/debitoren",
});
export const ModemOrderTaskDashboard = TaskDashboardFactory({
  stateKey: "modemOrders",
  title: "Modem Logistik",
  url: "/aufgaben/modems",
});
export const DeviceTaskDashboard = TaskDashboardFactory({
  stateKey: "deviceTasks",
  title: "Messgeräte",
  url: "/aufgaben/messgeraete",
});
export const CardOrderTaskDashboard = TaskDashboardFactory({
  stateKey: "cardOrders",
  title: "Karten/Flyer",
  url: "/aufgaben/karten",
});
export const KeyOrderTaskDashboard = TaskDashboardFactory({
  stateKey: "keyOrders",
  title: "Schließmittel",
  url: "/aufgaben/schliessmittel",
});
export const ReportTaskDashboard = TaskDashboardFactory({
  stateKey: "reportTasks",
  title: "Reports",
  url: "/aufgaben/reports",
});
export const UserTaskDashboard = TaskDashboardFactory({
  id: "userTasks",
  stateKey: [
    "employeeTasks",
    "failedEmailAddresses",
    "employeeDeactivationTasks",
    "employeeCompanyChangeTasks",
  ],
  title: "Benutzerverwaltung",
  url: "/aufgaben/benutzer",
});

export { default as taskReducer } from "./reducer";
