import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PermissionResource from "./PermissionResource";

const PermissionRoute = ({
  component: Component,
  redirectUrl,
  resource,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuthenticated ? (
          <PermissionResource resource={resource} redirectUrl={redirectUrl}>
            <Component {...props} />
          </PermissionResource>
        ) : (
          <Redirect to={`/login?redirect=${props.location.pathname}`} />
        )
      }
    />
  );
};

const mapStateToPropsPriv = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToPropsPriv, null)(PermissionRoute);
