import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../components/W3Dropdown';
import { PrimaryButton, ButtonLink } from '../../../components/Buttons';
import PasswordChangeForm from '../../Login/containers/PasswordChangeForm';
import BasicDataChangeForm from '../../Login/containers/BasicDataChangeForm';

const MODETYPES = {
  DEFAULT: 1,
  EDITING_DATA: 2,
  EDITING_PASSWORD: 3,
};

export default class BasicProfileDataMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  }
  static defaultProps = {
    className: ''
  }
  constructor() {
    super();
    this.state = {
      mode: MODETYPES.DEFAULT
    }
  }
  onEditData = (event) => {
    if (event) event.preventDefault();
    this.setState({ mode: MODETYPES.EDITING_DATA });
  }
  onChangePassword = (event) => {
    if (event) event.preventDefault();
    this.setState({ mode: MODETYPES.EDITING_PASSWORD });
  }
  onHide = () => {
    this.setState({ mode: MODETYPES.DEFAULT });
  }
  renderDataEdit = () => {
    return {
      title: (<h3 className="no-margin">{`Profiledaten bearbeiten`}</h3>),
      body: (<BasicDataChangeForm onClose={this.onHide}/>)
    };
  }
  renderPasswordEdit = () => {
    return {
      title: (<h3 className="no-margin">{`Passwort ändern`}</h3>),
      body: (<PasswordChangeForm onClose={this.onHide}/>)
    };
  }
  render() {
    const { mode } = this.state;
    let data = null;
    switch(mode) {
      case MODETYPES.EDITING_DATA:
        data = this.renderDataEdit();
        break;
      case MODETYPES.EDITING_PASSWORD:
        data = this.renderPasswordEdit();
        break;
      default:
        break;
    }
    const style = { width: '400px' };
    const dialog = data && (
      <Dialog
        header={(<div className="bg-secondary" style={{margin: '-1em', padding: '1em'}}>{data.title}</div>)}
        visible
        style={style}
        modal
        onHide={this.onHide}>
        {data.body}
      </Dialog>
    );
    return (
      <div>
        <W3Dropdown className="modems dropdown-list-menu">
          <W3DropdownToggler as={PrimaryButton} size="small">{`Optionen`}</W3DropdownToggler>
          <W3DropdownContent className="w3-bar-block w3-border" style={{right: '0', width: '180px'}}>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onEditData}>
                {`Profil bearbeiten`}
            </ButtonLink>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onChangePassword}>
                {`Passwort ändern`}
            </ButtonLink>
          </W3DropdownContent>
        </W3Dropdown>
        {dialog}
      </div>
    );
  }
}
