import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PageHeight40,
  PageTitle,
  scrollToTop,
  TableEmptyMessage,
  EMPLOYEE_TASKTYPE_LABELS,
  toUIDate,
  ScrollableTableHolder,
} from "../../../../components/Utils";
import { FormMenu } from "../../../../components/Buttons";
import ItemListManager from "../../../../components/ItemListManager";
import EmployeeDeactivationTaskMenu from "../../components/user/EmployeeDeactivationTaskMenu";
import SalesTablePageReport from "../../../Reporting/components/SalesTablePageReport";
import { connect } from "react-redux";
import {
  closeEmployeeDeactivationTasks,
  deactivateEmployeeDeactivationTasks,
  deleteEmployeeDeactivationTasks,
} from "../../actions/employee";

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    employeeDeactivationTasks: state.task.employeeDeactivationTasks,
  };
};
const mapDispatchToProps = (dispatch) => ({
  closeTasks: (...args) => dispatch(closeEmployeeDeactivationTasks(...args)),
  deactivateTasks: (...args) =>
    dispatch(deactivateEmployeeDeactivationTasks(...args)),
  deleteTasks: (...args) => dispatch(deleteEmployeeDeactivationTasks(...args)),
});

class EmployeeDeactivationTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    rows: PropTypes.number,
    employeeDeactivationTasks: PropTypes.arrayOf(PropTypes.shape({})),
    employeeTaskType: PropTypes.string,
    closeTasks: PropTypes.func.isRequired,
    deactivateTasks: PropTypes.func.isRequired,
    deleteTasks: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
    rows: 10,
    employeeDeactivationTasks: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      first: null,
      selection: [],
    };
  }
  componentDidMount() {
    scrollToTop();
  }
  resetSelection = (onHide) => {
    this.setState({ selection: [] });
    onHide && onHide();
  };
  onClose = (tasks, onHide) => {
    this.props.closeTasks(tasks, () => this.resetSelection(onHide));
  };
  onDeactivate = (tasks, onHide) => {
    this.props.deactivateTasks(tasks, () => this.resetSelection(onHide));
  };
  onDelete = (tasks, onHide) => {
    this.props.deleteTasks(tasks, () => this.resetSelection(onHide));
  };
  render() {
    const { className, isLoading, rows, employeeDeactivationTasks, disabled } =
      this.props;
    const { selection } = this.state;
    const setSelection = (value) => this.setState({ selection: value });
    const columnStyle = {
      verticalAlign: "top",
      paddingTop: "16px",
      paddingBottom: "16px",
    };
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map((item) => ({
        ...item,
        employee: `${item.firstname} ${item.lastname}`,
        requester: `${item.requester || ""}`.split(",").join(" "),
        requestDateUI: toUIDate(item.request_date, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
        requestDate: toUIDate(item.request_date, "YYYY-MM-DDTHH:mm:ss:SSSZ", "YYYY.MM.DD"),
      }));
      return (
        <ScrollableTableHolder noScroll>
          <DataTable
            value={taskItems}
            paginator={taskItems.length > rows}
            rows={rows}
            first={this.state.first}
            onPage={(event) => {
              this.setState({ first: event.first });
              scrollToTop();
            }}
            paginatorLeft={
              <SalesTablePageReport
                className="pad-lft"
                totalRecords={taskItems.length}
                filteredRecords={taskItems.length}
                first={this.state.first}
                rows={rows}
                emptyText="Keine Mitarbeiterdeaktivierungsaufgaben"
                itemName="Mitarbeiterdeaktivierungsaufgabe"
                itemNamePlural="Mitarbeiterdeaktivierungsaufgaben"
              />
            }
            selection={selection}
            onSelectionChange={(event) => setSelection(event.value)}
            removableSort
            sortMode="multiple"
            emptyMessage={
              isLoading ? null : (
                <TableEmptyMessage itemNamePlural="Mitarbeiterdeaktivierungsaufgaben" />
              )
            }
            // scrollable
          >
            <Column
              id="icheck"
              selectionMode="multiple"
              style={{ ...columnStyle, width: "3em" }}
            />
            <Column
              field="parentCompanyName"
              header="Vertragspartner"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle /* width: "16em" */ }}
            />
            <Column
              field="employee"
              header="AN Portal Name"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "14em" }}
            />
            <Column
              field="requester"
              header="Beantrager"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "12em" }}
            />
            <Column
              field="requestDate"
              header="Erstelldatum"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "10em", textAlign: "center" }}
              body={item => item.requestDateUI}
            />
            {/* <Column
              field="status"
              header="Status"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{textAlign:'center-', width: '9em'}}
            /> */}
            <Column
              body={actionTemplate}
              style={{
                verticalAlign: "top",
                textAlign: "center",
                width: "10em",
              }}
            />
          </DataTable>
        </ScrollableTableHolder>
      );
    };

    const renderItemForm = (item, onHide) => {
      return (
        <div>
          {/* <div className="w3-large">{`Diese Aufgabe wird hiermit ohne weitere Aktion als erledigt bestätigt.`}</div> */}
          <div className="w3-large">{`Wollen Sie diese Aufgabe wirklich als erledigt bestätigen?`}</div>
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{
              marginLeft: "-1em",
              marginRight: "-1em",
              marginBottom: "-1em",
            }}
            onSave={() => this.onClose([item], onHide)}
            onCancel={onHide}
            saveBtnName="saveEmployeeDeactivationTaskBtn"
            saveBtnLabel="Bestätigen"
          />
        </div>
      );
    };
    const getItemFormTitle = (task) => {
      const title = `Mitarbeiter ${
        task ? task.employee + " " : ""
      }deaktivieren`;
      return <h3 className="no-margin">{title}</h3>;
    };
    const title = (
      <PageTitle>
        <EmployeeDeactivationTaskMenu
          className="w3-right"
          selectedTasks={selection}
          closeTasks={this.onClose}
          deactivateTasks={this.onDeactivate}
          deleteTasks={this.onDelete}
          items={employeeDeactivationTasks}
          disabled={disabled}
        />
        <h3 className="no-margin pad-btm">{"Mitarbeiter deaktivieren"}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={employeeDeactivationTasks}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          itemPanelStyle={{ width: "600px" }}
          isEditDisabled={() => true}
          hideDisabledEdit
          namePrefix={`Mitarbeiterdeaktivierung-`}
          itemDeleteable
          deleteTitle={`Mitarbeiterdeaktivierungsaufgabe löschen`}
          getDeleteText={(item) =>
            `Wollen Sie diese Aufgabe wirklich löschen und so den Mitarbeiter als aktiviert wiederherstellen?`
          }
          onDelete={(task, onHide) => this.onDelete([task], onHide)}
          itemDeactivateable
          isActivateDisabled={() => true}
          deactivateTitle={`Mitarbeiter deaktivieren`}
          getDeactivateText={(item) =>
            `Wollen Sie den Mitarbeiter ${item.employee} deaktivieren und die Aufgabe als erledigt bestätigen?`
          }
          onDeActivate={(task) => this.onDeactivate([task])}
          itemCloseableOnly={disabled}
          hideDisabledClose
          isCloseDisabled={() => disabled}
          // showItemDeleteReason
          // itemVisibleOnly={disabled}
          // closeTitle={`Mitarbeiterdeaktivierungsaufgabe schließen`}
          // getCloseText={
          //   () => `Wollen Sie diese Aufgabe wirklich schließen?`
          // }
          // onClose={this.onClose}
        />
      </PageHeight40>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeDeactivationTaskList);
