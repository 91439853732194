import React from "react";
import PropTypes from "prop-types";
// import { Checkbox } from 'primereact/checkbox';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label } from "../../../../components/Inputs";
// import { DelphiConnectionPermissionOptions } from '../Utils';

export default class DelphiConnectionTaskForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    isDeleting: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      id: "",
      parentCompanyName: "",
      username: "",
      password: "",
      permissions: "",
      regions: "",
      userInCharge: "",
    },
    isDeleting: false,
  };
  constructor(props) {
    super(props);
    const model = props.model || {};
    this.state = {
      username: model.username || "",
      password: model.password || "",
      error: {
        username: props.isDeleting ? undefined : null,
        password: props.isDeleting ? undefined : null,
      },
    };
  }
  validate = () => {
    const originalModel = this.props.model || {};
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (error[key] !== undefined && model[key] === "") {
        error[key] = true;
        isValid = false;
      }
    });
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    return { ...originalModel, ...model };
  };
  getModel = (initialize = false) => {
    const { model, isDeleting } = this.props;
    // isDeleting && console.log("Confirming deletion:", model);
    return isDeleting ? { ...model } : this.validate(initialize);
    // const model = this.validate(initialize);
    // return model;
  };
  render() {
    const { className, model, isDeleting } = this.props;
    const { username, password, error } = this.state;
    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Vertragspartner`}</Label>
            <InputText
              className="w3-block w3-border"
              value={model.parentCompanyName}
              disabled
            />
          </div>
          {/* <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Regionen`}</Label>
            <InputText className="w3-block w3-border" value={model.regions} disabled/>
          </div> */}
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Benutzer`}</Label>
            <InputText
              className="w3-block w3-border"
              value={model.user}
              disabled
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Bearbeiter`}</Label>
            <InputText
              className="w3-block w3-border"
              value={model.userInCharge}
              disabled
            />
          </div>
        </div>
        {!isDeleting && (
          <div className="w3-row neg-margin-lr">
            <div
              className={`w3-col s6 pad-lr pad-top form-group-item ${
                error.username ? " error-group" : ""
              }`}
            >
              <Label htmlFor="username">{`Kennung*`}</Label>
              <InputText
                id="username"
                className="w3-block w3-border"
                value={username}
                onChange={(event) =>
                  this.setState({
                    username: event.target.value,
                    error: { ...error, username: username === "" },
                  })
                }
              />
            </div>
            <div
              className={`w3-col s6 pad-lr pad-top form-group-item ${
                error.password ? " error-group" : ""
              }`}
            >
              <Label htmlFor="password">{`Passwort*`}</Label>
              <InputText
                id="password"
                className="w3-block w3-border"
                value={password}
                onChange={(event) =>
                  this.setState({
                    password: event.target.value,
                    error: { ...error, password: password === "" },
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
