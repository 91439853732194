import { DEFAULT_KEY_ORDER, DEFAULT_KEY } from "../components";
import { OrderManager } from "./data";
import {
  PRODUCT_ORDER_STATUS_IDS,
  PRODUCT_ORDER_STATUS_LABELS,
  PRODUCT_TYPE_IDS,
} from "../../../components/Utils";
import { canReadAdministrativeData } from "../../Permissions/actions";
// import { loadCompanies } from "../../Company/actions";

export const LOAD_KEYS = "keys/load";
export const LOAD_KEYS_SUCCESS = "keys/load/success";
export const LOAD_KEYS_FAILURE = "keys/load/failure";

export const LOAD_KEY = "key/load";
export const LOAD_KEY_SUCCESS = "key/load/success";
export const LOAD_KEY_FAILURE = "key/load/failure";

export const SAVE_KEY = "keys/save";
export const SAVE_KEY_SUCCESS = "keys/save/success";
export const SAVE_KEY_FAILURE = "keys/save/failure";

export const CANCEL_KEY = "key/cancel";

export const DELETE_KEY = "keys/delete";
export const DELETE_KEY_SUCCESS = "keys/delete/success";
export const DELETE_KEY_FAILURE = "keys/delete/failure";

export const SELECT_KEY = "keys/select";
export const CANCEL_KEY_SELECTION = "keys/select/cancel";

export const CHECK_USAGE = "keys/usage/check";
export const CHECK_USAGE_SUCCESS = "keys/usage/check/success";
export const CHECK_USAGE_FAILURE = "keys/usage/check/failure";

export const CHANGE_STATUS = "keys/status/change";
export const CHANGE_STATUS_SUCCESS = "keys/status/change/success";
export const CHANGE_STATUS_FAILURE = "keys/status/change/failure";

export const LOAD_KEY_ORDERS = "keys/orders/load";
export const LOAD_KEY_ORDERS_SUCCESS = "keys/orders/load/success";
export const LOAD_KEY_ORDERS_FAILURE = "keys/orders/load/failure";

export const LOAD_KEY_ORDER = "key/order/load";
export const LOAD_KEY_ORDER_SUCCESS = "key/order/load/success";
export const LOAD_KEY_ORDER_FAILURE = "key/order/load/failure";

export const LOAD_ADDRESSES = "keys/order/addresses/load";
export const LOAD_ADDRESSES_SUCCESS = "keys/order/addresses/load/success";
export const LOAD_ADDRESSES_FAILURE = "keys/order/addresses/load/failure";

export const SAVE_KEY_ORDER_CATALOG = "keys/order/catalog/save";
export const SAVE_KEY_ORDER_ADDRESS = "keys/order/address/save";

export const SAVE_KEY_ORDER = "keys/order/save";
export const SAVE_KEY_ORDER_SUCCESS = "keys/order/save/success";
export const SAVE_KEY_ORDER_FAILURE = "keys/order/save/failure";

export const CANCEL_KEY_ORDER = "keys/orders/cancel";

export const LOAD_ORDER_AVIS_FILE = "keys/orders/avis/load";
export const LOAD_ORDER_AVIS_FILE_SUCCESS = "keys/orders/avis/load/success";
export const LOAD_ORDER_AVIS_FILE_FAILURE = "keys/orders/avis/load/failure";

export const SAVE_ORDER_FILES = "keys/orders/files/save";
export const SAVE_ORDER_FILES_SUCCESS = "keys/orders/files/save/success";
export const SAVE_ORDER_FILES_FAILURE = "keys/orders/files/save/failure";

export const SAVE_ORDER_AVIS_FILE = "keys/orders/avis/save";
export const SAVE_ORDER_AVIS_FILE_SUCCESS = "keys/orders/avis/save/success";
export const SAVE_ORDER_AVIS_FILE_FAILURE = "keys/orders/avis/save/failure";

export const LOAD_AVIALABLE_KEYS = "keys/avialable/load";
export const LOAD_AVIALABLE_KEYS_SUCCESS = "keys/avialable/load/success";
export const LOAD_AVIALABLE_KEYS_FAILURE = "keys/avialable/load/failure";

export const SET_WIZARD_PAGE = "cards/wizard/page/set";

/**
 * Loads all keys
 */
export const loadKeys = (onSuccess) => {
  console.log("REDUX-ACTION loadKeys()");
  return {
    types: [LOAD_KEYS, LOAD_KEYS_SUCCESS, LOAD_KEYS_FAILURE],
    promise: (client) => client.get(`/fccShops/key/catalogs/0?status=all`),
    onSuccess,
  };
};

/**
 * Load a key by its name
 * @param {Object} param0 object containing ID of key to be loaded
 */
export const loadKey = (key = { id }) => {
  console.log("REDUX-ACTION loadKey()", key);
  const keyId = key.id || "neu";
  if (keyId === "neu") {
    const result = { ...DEFAULT_KEY };
    // console.log('<< Key:', result)
    return { type: LOAD_KEY_SUCCESS, result };
  }
  return {
    types: [LOAD_KEY, LOAD_KEY_SUCCESS, LOAD_KEY_FAILURE],
    promise: (client) =>
      client.get(
        `/fccShops/key/catalogs/${encodeURIComponent(keyId)}?status=all`
      ),
  };
};

/**
 * Save a key
 * @param {*} key the key to be saved
 */
export const saveKey = (key = {}, onSuccess) => {
  console.log("REDUX-ACTION saveKey()", key);
  const { files, type, type_id, ...others } = key;
  const data = {
    ...others,
    type_id: type_id || PRODUCT_TYPE_IDS.KEY,
  };
  if (files && files.length > 0) {
    data.image_paths = JSON.stringify(files);
  }
  return {
    types: [SAVE_KEY, SAVE_KEY_SUCCESS, SAVE_KEY_FAILURE],
    promise: (client) => client.put(`/fccShops/key/catalogs`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadKeys());
      onSuccess && onSuccess();
    },
    key,
  };
};

/**
 * Change the status of a key
 * @param {*} key
 * @param {*} onSuccess
 */
export const changeKeyStatus = (key = {}, onSuccess) => {
  console.log("REDUX-ACTION changeCardStatus()", key);
  const { id, type_id, status_id } = key;
  const data = [{ id, type_id, status_id }];
  return {
    types: [CHANGE_STATUS, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAILURE],
    promise: (client) =>
      client.post(`/fccShops/key/catalogs/${key.id}/changeStatus`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadKeys());
      onSuccess && onSuccess();
    },
    key,
  };
};

/**
 * Cancel a key
 */
export const cancelKey = () => {
  return {
    type: CANCEL_KEY,
  };
};

/**
 * Delete a key
 * @param {*} key the key to be deleted
 */
export const deleteKey = (key = {}, onSuccess) => {
  console.log("REDUX-ACTION deleteKey()", key);
  return {
    types: [DELETE_KEY, DELETE_KEY_SUCCESS, DELETE_KEY_FAILURE],
    promise: (client) => client.del(`/fccShops/keys/catalogs/${key.id}/remove`),
    onSuccess: (dispatch) => {
      dispatch(loadKeys());
      onSuccess && onSuccess();
    },
    key,
  };
};

/**
 * select a key
 */
export const selectKey = ({ key, purpose }) => {
  return {
    type: SELECT_KEY,
    key,
    purpose,
  };
};

/**
 * Cancel a key selection
 */
export const cancelKeySelection = () => {
  return {
    type: CANCEL_KEY_SELECTION,
  };
};

/**
 * Get all open orders that depend on a particular key
 * @param {object} key
 */
export const checkKeyUsage = (key = {}) => {
  console.log("REDUX-ACTION checkKeyUsage()", key);
  const type = "key";
  return {
    types: [CHECK_USAGE, CHECK_USAGE_SUCCESS, CHECK_USAGE_FAILURE],
    promise: (client) =>
      client.get(`/fccShops/${type}/catalogs/${key.id}/inUsed`),
    key,
  };
};

/**
 * select a key for deletion
 * @param {object} key
 */
export const selectKeyForDeletion = (key) => {
  return (dispatch) => {
    dispatch(checkKeyUsage(key));
    dispatch(selectKey({ key, purpose: "delete" }));
  };
};

/**
 * Loads all key orders
 */
export const loadAllKeyOrders = () => {
  console.log("REDUX-ACTION loadAllKeyOrders()");
  return {
    types: [LOAD_KEY_ORDERS, LOAD_KEY_ORDERS_SUCCESS, LOAD_KEY_ORDERS_FAILURE],
    promise: (client) =>
      client.get(
        `/fccShops/companies/0/summaries/productTypes/key?related=true`
      ),
  };
};

/**
 * Loads a user's key orders
 */
export const loadKeyOrders = (onSuccess, onFailure) => {
  console.log("REDUX-ACTION loadKeyOrders()");
  return (dispatch, getState) => {
    const company = getState().company.company;
    if (!company) {
      console.error("COMPANY_NOT_SET: session was not correctly initialized!");
      return onFailure && onFailure();
    }
    return dispatch({
      types: [
        LOAD_KEY_ORDERS,
        LOAD_KEY_ORDERS_SUCCESS,
        LOAD_KEY_ORDERS_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `/fccShops/companies/${encodeURIComponent(
            company.id
          )}/summaries/productTypes/key?related=true`
        ),
      onSuccess: () => onSuccess && onSuccess(),
    });
  };
};

/**
 * Loads a key order
 */
export const loadKeyOrder = ({ id }) => {
  console.log("REDUX-ACTION loadKeyOrder()", id);
  return (dispatch, getState) => {
    const onSuccess = () => {
      const keyId = id || "neu";
      if (keyId === "neu") {
        const result = getState().key.keyOrder || { ...DEFAULT_KEY_ORDER };
        console.log("<< KeyOrder:", result);
        return dispatch({ type: LOAD_KEY_ORDER_SUCCESS, result });
      }
      const company = getState().company.company;
      if (!company) {
        console.error(
          "COMPANY_NOT_SET: session was not correctly initialized!"
        );
        return onFailure && onFailure();
      }
      const companyId = canReadAdministrativeData(getState()) ? 0 : encodeURIComponent(company.id);
      // const productType = 'null';
      return dispatch({
        types: [LOAD_KEY_ORDER, LOAD_KEY_ORDER_SUCCESS, LOAD_KEY_ORDER_FAILURE],
        promise: (client) =>
          client.get(
            `/fccShops/companies/${companyId}/productTypes/key/orders/${keyId}`
          ),
      });
    };
    const keys = getState().key.keys;
    if (!keys || keys.length === 0) {
      dispatch(loadKeys(onSuccess));
    } else {
      onSuccess();
    }
  };
};

/**
 * Save the keys in an order
 * @param {*} param0
 * @param {*} onSuccess
 */
export const saveOrderCatalog = ({ items }, onSuccess) => {
  console.log("REDUX-ACTION saveOrderCatalog()", items);
  return (dispatch) => {
    dispatch({ type: SAVE_KEY_ORDER_CATALOG, result: items });
    onSuccess && onSuccess(dispatch);
  };
};

/**
 * Get Data for creating the order address
 */
export const getOrderAddressData = (props = {}) => {
  console.log("REDUX-ACTION getOrderAddressData()");
  return (dispatch) => {
    // dispatch(loadCompanies());
    const companyId = props.companyId;
    if (!companyId) {
      console.error("COMPANY_NOT_SET: company name must be given!");
      return;
    }
    dispatch({
      types: [LOAD_ADDRESSES, LOAD_ADDRESSES_SUCCESS, LOAD_ADDRESSES_FAILURE],
      promise: (client) =>
        client.get(
          `/fccShops/companies/${companyId}/productTypes/key/addresses/0`
        ),
    });
  };
};

/**
 * Save the address in an order
 * @param {*} param0
 * @param {*} onSuccess
 */
export const saveOrderAddress = ({ address }, onSuccess) => {
  console.log("REDUX-ACTION saveOrderAddress()", address);
  return (dispatch) => {
    dispatch({
      type: SAVE_KEY_ORDER_ADDRESS,
      result: address,
    });
    onSuccess && onSuccess(dispatch);
  };
};

/**
 * Saves a key order
 */
export const saveKeyOrder = ({ keyOrder }, onSuccess) => {
  console.log("REDUX-ACTION saveKeyOrder()", keyOrder);
  return (dispatch, getState) => {
    const company = getState().company.company;
    if (!company) {
      console.error("COMPANY_NOT_SET: session was not correctly initialized!");
      return; // onFailure && onFailure();
    }
    const data = keyOrder;
    const companyId = data.companyId || company.id;
    return dispatch({
      types: [SAVE_KEY_ORDER, SAVE_KEY_ORDER_SUCCESS, SAVE_KEY_ORDER_FAILURE],
      promise: (client) =>
        client.put(`/fccShops/companies/${companyId}/productTypes/key/orders`, {
          data,
        }),
      onSuccess: (dispatch) => {
        dispatch(loadKeyOrders());
        onSuccess && onSuccess();
      },
      keyOrder,
    });
  };
};

/**
 * Cancel a key order
 */
export const cancelKeyOrder = () => {
  return {
    type: CANCEL_KEY_ORDER,
  };
};

export const loadOrderAVISFile = ({ orderNumber, avis_path }) => {
  console.log(`REDUX-ACTION: loadOrderAVISFile(${orderNumber}) ...`);
  return {
    type: LOAD_ORDER_AVIS_FILE_SUCCESS,
    orderNumber,
    avis_path,
  };
};

/**
 * Save Order Files (by the administrator)
 * @param {*} param0
 * @param {*} onSuccess
 */
export const saveOrderFiles = ({ id, files }, onSuccess) => {
  console.log(`REDUX-ACTION: saveOrderFiles(${id}) `, files);
  const status_id =
    files && files.adminDeliveryPath && files.adminAvisPath
      ? PRODUCT_ORDER_STATUS_IDS.AWAITING_CONFIRMATION
      : PRODUCT_ORDER_STATUS_IDS.SENT;
  const data = [
    {
      id,
      avis_paths: JSON.stringify(files),
      status_id,
      status: PRODUCT_ORDER_STATUS_LABELS[status],
    },
  ];
  return {
    types: [
      SAVE_ORDER_AVIS_FILE,
      SAVE_ORDER_AVIS_FILE_SUCCESS,
      SAVE_ORDER_AVIS_FILE_FAILURE,
    ],
    promise: (client) =>
      client.post(`/fccShops/orders/avis/changeStatus`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadKeyOrders());
      onSuccess && onSuccess();
    },
  };
};

/**
 * Save Order AVIS-File (by the orderer)
 * @param {*} param0
 * @param {*} onSuccess
 */
export const saveOrderAVISFile = ({ id, files }, onSuccess) => {
  console.log(`REDUX-ACTION: saveAVISFile(${id}) `, files);
  const data = [
    {
      id,
      avis_paths: JSON.stringify(files),
      status_id: PRODUCT_ORDER_STATUS_IDS.FINISHED,
      status: PRODUCT_ORDER_STATUS_LABELS[PRODUCT_ORDER_STATUS_IDS.FINISHED],
    },
  ];
  return {
    types: [
      SAVE_ORDER_AVIS_FILE,
      SAVE_ORDER_AVIS_FILE_SUCCESS,
      SAVE_ORDER_AVIS_FILE_FAILURE,
    ],
    promise: (client) =>
      client.post(`/fccShops/orders/avis/changeStatus`, { data }),
    onSuccess: (dispatch) => {
      dispatch(loadKeyOrders());
      onSuccess && onSuccess();
    },
  };
};

export const setWizardPage = ({ wizardType, page }) => {
  return {
    type: SET_WIZARD_PAGE,
    wizardType,
    page,
  };
};

/**
 * Load the avialable keys of a company
 * @param {Object} param0
 * @param {Func} onSuccess
 */
export const loadAvailableKeys = ({ companyId, companyKeys }, onSuccess) => {
  console.log(`REDUX-ACTION: loadAvailableKeys(${companyId})`, companyKeys);
  return (dispatch) => {
    const result = (companyKeys || []).map((item) => ({ name: item }));
    if (result.length > 0) {
      dispatch({
        type: LOAD_AVIALABLE_KEYS_SUCCESS,
        result,
      });
      onSuccess && onSuccess();
      return;
    }
    return dispatch(loadKeys(onSuccess));
  };
  // return {
  //   types: [ LOAD_AVIALABLE_KEYS, LOAD_AVIALABLE_KEYS_SUCCESS, LOAD_AVIALABLE_KEYS_FAILURE ],
  //   // promise: (client) => client.get(`/fccShops/companies/${encodeURIComponent(companyId)}/productTypes/key/avialable`),
  //   promise: (client) => client.get(`/fccShops/key/catalogs/0`),
  //   onSuccess: () => onSuccess && onSuccess()
  // };
};
