import React from 'react';
import PropTypes from 'prop-types';
import { WizardButtonPanel } from '../../../../components/Buttons';
import DebitorOrderOverview from '../DebitorOrderOverview';

export default class ModemShoppingCartContent extends React.Component {
  static propTypes = {
    // mapped from state
    debitorOrder: PropTypes.object,
    // actions
    saveDebitorOrder: PropTypes.func.isRequired,
    // passed by parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: '',
    rows: 12
  }
  constructor(props) {
    super(props);
    console.log('>> saving shopping cart without showing ...');
    this.onSave(props);
  }
  openDebitors = (props) => {
    const { history, match } = (props || this.props);
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/debitoren`);
  }
  openCatalog = () => {
    const { history, match } = this.props;
    const id = match.params.id;
    history.replace(`/wizard/shop/bestellung/modem/${id}/katalog`);
  }
  onSave = (props) => {
    const { debitorOrder, saveDebitorOrder } = (props || this.props);
    saveDebitorOrder(debitorOrder, () => this.openDebitors(props));
  }
  isDebitorComplete = (flag) => {
    this.btnPanel && this.btnPanel.disableBtn('next', !flag);
  }
  render() {
    return null;
    // const { className, debitorOrder, cancelOrder, rows, match } = this.props;
    // const id = match.params.id;
    // console.log('ModemShoppingCartContent.render()', debitorOrder);
    // if (!debitorOrder) {
    //   return null;
    // }
    // const debitor = debitorOrder.debitor;
    // const modems = debitorOrder.modems;
    // return (
    //   <div className={`modem-shopping-cart clearfix`}>
    //     <DebitorOrderOverview
    //       className={className}
    //       title="Debitor im Warenkorb"
    //       debitor={debitor}
    //       modems={modems}
    //       hideDebitorSubtitle/>
    //     <WizardButtonPanel
    //       ref={ref => this.btnPanel = ref}
    //       className="margin-big-top w3-border pad-big w3-light-grey"
    //       onPrevious={this.openCatalog}
    //       onNext={this.onSave}
    //       onCancel={cancelOrder}
    //       nextLabel="Speichern"
    //       disableIfAbsent
    //     />
    //   </div>
    // );
  }
}
