import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  EditIconNavButton,
  ViewIconNavButton,
  SecondaryButton,
} from "../../../components/Buttons";
import {
  toUIDate,
  STATUS_IDS,
  MODEM_ORDER_STATUS_IDS,
  scrollToTop,
  TableEmptyMessage,
  createSortByAttr,
  MODEM_ORDER_STATUS_LABELS,
} from "../../../components/Utils";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadModemOrderAvises,
  loadOrderAVISFile,
  defaultModemOrderAvisesFilter,
} from "../actions";
import SalesTablePageReport from "../../Reporting/components/SalesTablePageReport";
import SalesTableUtil from "../../Reporting/components/SalesTableUtil";

const getFilterValues = (props, current) => {
  let values = { ...defaultModemOrderAvisesFilter };
  if (current) {
    values = {
      ...values,
      rows: props.rows || defaultFilter.rows,
      ...current,
    };
  }
  return values;
};

const frontload = async (props) => {
  await props.getOrders();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.modem.loading,
    header: state.modem.modemOrderAvisesHeader,
    orders: state.modem.modemOrderAvises,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrders: (...args) => dispatch(loadModemOrderAvises(...args)),
  getOrderAvisFile: (...args) => dispatch(loadOrderAVISFile(...args)),
});

class ModemOrderAvisTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    header: PropTypes.shape({}),
    orders: PropTypes.arrayOf(PropTypes.shape({})),
    getOrders: PropTypes.func.isRequired,
    getOrderAvisFile: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
  };
  static defaultProps = {
    rows: 12,
    className: "",
  };
  constructor(props) {
    super();
    // this.state = { first: 0 };
    this.filterValues = getFilterValues(props);
    this.filterTimerId = null;
    this.sortSelection = [];
    this.state = {
      first: 0,
      filteredCount: (props.workers || []).length,
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  // onPage = (event) => {
  //   this.setState({ first: event.first });
  // };
  onShowDetails = (modem, history) => {
    console.log("Editing", modem);
    history.push(
      `/wizard/shop/bestellung/modem/${modem.orderNumber}/debitoren`
    );
  };
  getAvisFile = (order) => {
    this.props.getOrderAvisFile(order);
  };
  onLazyLoad = (event) => {
    const filterValues = { ...this.filterValues };
    filterValues.first = event.first;
    filterValues.rows = event.rows;
    this.props.getOrders(
      () => this.setState({ first: event.first }),
      filterValues
    );
  };
  onValueChange = (nextValue) => {
    if (nextValue) {
      this.setState({
        first: 0,
        filteredCount: nextValue.length,
      });
    }
  };
  onFilterChange = (field, value, noTimeout, noFilter) => {
    console.log("WorkerTable.onFilterChange()", field, value, noTimeout);
    this.filterValues[field] = value;
    !noFilter && this.setState({ first: 0 });
    if (noTimeout) {
      return !noFilter && this.props.getOrders(null, this.filterValues);
    }
    if (this.filterTimerId) {
      clearTimeout(this.filterTimerId);
    }
    const self = this;
    this.filterTimerId = setTimeout(() => {
      !noFilter && self.props.getOrders(null, self.filterValues);
    }, 1000);
  };
  onSort = (event) => {
    this.filterValues.sortFields = event.multiSortMeta.map((item) => ({
      ...item,
    }));
    this.props.getOrders(null, { ...this.filterValues });
  };
  render() {
    const { className, isLoading, orders, rows, header, disabled } = this.props;
    console.log("ModemOrderAvisTable.render()", orders);
    if (!orders) {
      return null;
    }
    const totalRecords = Number(
      header ? header["x-result-count"] : orders.length
    );
    const { first, filteredCount } = this.state;
    const data = (orders || [])
      .map((order, index) => ({
        ...order,
        index,
        order_date: order.orderDate,
        orderDate: toUIDate(order.orderDate, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
      }))
      .sort(createSortByAttr("order_date", true, true));
    // const actionTemplate = (rowData) => {
    //   const id = `editOrderBtn${rowData.index}`;
    //   const Component =
    //     disabled || rowData.status_id !== STATUS_IDS.NEW
    //       ? ViewIconNavButton
    //       : EditIconNavButton;
    //   return (
    //     <div>
    //       <Component
    //         id={id}
    //         name={id}
    //         onClick={(history) => this.onShowDetails(rowData, history)}
    //       />
    //     </div>
    //   );
    // };
    const avisTemplate = (rowData) => {
      let btnMarkup = null;
      // if (rowData.status_id === MODEM_ORDER_STATUS_IDS.CLOSED) {
      if (
        rowData.status_id >= MODEM_ORDER_STATUS_IDS.SENT &&
        rowData.avis_path
      ) {
        const id = `getAvisBtn${rowData.index}`;
        btnMarkup = (
          <SecondaryButton
            id={id}
            size="small"
            onClick={() => this.getAvisFile(rowData)}
          >
            {`AVIS-Datei`}
          </SecondaryButton>
        );
      }
      return <div>{btnMarkup}</div>;
    };

    // filters
    const status_id = Object.keys(MODEM_ORDER_STATUS_IDS)
      .map((key) => MODEM_ORDER_STATUS_IDS[key])
      .map((value) => ({
        label: MODEM_ORDER_STATUS_LABELS[value],
        value,
      }));
    const optionMap = { status_id };
    const useInputFilter = SalesTableUtil.createUseInputFilter(
      this.dt,
      400,
      this.onFilterChange
    );
    const useMultiSelectFilter = SalesTableUtil.createUseMultiSelectFilter(
      this.dt,
      data,
      optionMap,
      this.onFilterChange
    );
    const useRangeFilter = SalesTableUtil.createUseRangeFilter(
      this.dt,
      this.onFilterChange
    );
    const orderNumberFilter = useInputFilter("orderNumber", {
      filterOnChange: true,
    });
    const companyNameFilter = useInputFilter("companyName", {
      filterOnChange: true,
    });
    const orderGiverFilter = useInputFilter("orderGiver", {
      filterOnChange: true,
    });
    const orderDateFilter = useRangeFilter("orderDate");
    const weekFilter = useInputFilter("week", { filterOnChange: true });
    const statusFilter = useMultiSelectFilter("status_id");
    const columnStyle = {
      verticalAlign: "top",
      paddingTop: "16px",
      paddingBottom: "16px",
    };

    return (
      <div className={`modem-order-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          ref={(ref) => (this.dt = ref)}
          // paginator={data.length > rows}
          // first={this.state.first}
          // onPage={this.onPage}
          paginator
          onValueChange={this.onValueChange}
          first={first}
          lazy={true}
          totalRecords={totalRecords}
          onPage={(event) => this.onLazyLoad(event)}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              // totalRecords={data.length}
              // filteredRecords={data.length}
              // first={this.state.first}
              totalRecords={totalRecords}
              filteredRecords={filteredCount}
              first={first}
              rows={rows}
              emptyText="Keine Modembestellungen"
              itemName="Modembestellung"
              itemNamePlural="Modembestellungen"
            />
          }
          removableSort
          sortMode="multiple"
          multiSortMeta={data.length <= 1 ? [] : this.filterValues.sortFields}
          onSort={this.onSort}
          emptyMessage={
            isLoading ? null : (
              <TableEmptyMessage itemNamePlural="Modembestellungen" />
            )
          }
        >
          <Column
            field="orderNumber"
            header="Bestellnummer"
            // sortable={data.length > 1}
            // filter={data.length > rows}
            // filterMatchMode="contains"
            filter
            {...orderNumberFilter}
            style={{ ...columnStyle, textAlign: "center", width: "11em" }}
          />
          <Column
            field="companyName"
            header="Servicepartner"
            sortable={data.length > 1}
            // filter={data.length > rows}
            // filterMatchMode="contains"
            filter
            {...companyNameFilter}
            style={{ ...columnStyle /* ,textAlign:'center', width: '12em' */ }}
          />
          <Column
            field="orderGiver"
            header="Besteller"
            // sortable={data.length > 1}
            // filter={data.length > rows}
            // filterMatchMode="contains"
            filter
            {...orderGiverFilter}
            style={{ ...columnStyle /* ,textAlign:'center', width: '12em' */ }}
          />
          <Column
            field="orderDate"
            header="Bestelldatum"
            // sortable={data.length > 1}
            // filter={data.length > rows}
            // filterMatchMode="contains"
            filter
            {...orderDateFilter}
            style={{ ...columnStyle, textAlign: "center", width: "10em" }}
          />
          <Column
            field="week"
            header="KW"
            sortable={data.length > 1}
            // filter={data.length > rows}
            // filterMatchMode="contains"
            filter
            {...weekFilter}
            style={{ ...columnStyle, textAlign: "center", width: "6em" }}
          />
          <Column
            body={avisTemplate}
            header="AVIS"
            style={{ ...columnStyle, textAlign: "center", width: "10em" }}
          />
          <Column
            field="status_id"
            header="Status"
            // sortable={data.length > 1}
            // filter={data.length > rows}
            filter
            {...statusFilter}
            body={(rowData) => rowData.status}
            style={{ ...columnStyle, textAlign: "center", width: "10em" }}
          />
          {/* <Column
            body={actionTemplate}
            style={{verticalAlign: "top",textAlign:'center', width: '4em'}}
          /> */}
        </DataTable>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(ModemOrderAvisTable)
);
