import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Label } from '../../../../components/Inputs';
import { getTaskTypeOptions } from '../Utils';
import { PrimaryButton, SecondaryButton } from '../../../../components/Buttons';
import { getConnectionValidationEnddate, toDBDate } from '../../../../components/Utils';

export default class ITTaskDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    onConfirm: PropTypes.func
  }
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      text: props.text,
      onConfirm: props.onConfirm,
      isVisible: false,
      showTaskType: true,
      showValidity: false,
      type: props.type || 'IT',
      taskType: `NEW-${props.type}-CONNECTION`,
      valid_until: new Date()
    }
  }
  componentDidUpdate() {
    const { taskType, showValidity } = this.state;
    if (showValidity && taskType.indexOf('DELETE') >= 0) {
      this.setState({ showValidity: false });
    }
  }
  show = (isVisible, data, onConfirm) => {
    console.log('ITTaskDialog.show()', data);
    const newState = data || {};
    if (newState.showValidity) {
      newState.valid_until = getConnectionValidationEnddate();
    }
    if (newState.showTaskType) {
      newState.taskType = `NEW-${newState.type}-CONNECTION`;
    }
    this.setState({
      ...newState,
      onConfirm,
      isVisible
    });
  }
  onHide = () => {
    this.setState({isVisible: false});
  }
  onClick = (isYes) => {
    this.onHide();
    if (isYes && this.state.onConfirm) {
      const { taskType, valid_until, showValidity } = this.state;
      const data = { taskType };
      if (showValidity) {
        data.valid_until = toDBDate(valid_until);
      }
      this.state.onConfirm(data);
    }
  }
  render() {
    const { title, text, type, taskType, valid_until, isVisible, showTaskType, showValidity } = this.state;
    const header = (
      <div className="" style={{margin: '-1em', padding: '1em'}}>
        <h3 className="no-margin">{title}</h3>
      </div>
    );
    const footer = (
      <div className="alg-right w3-light-grey" style={{margin: '-0.571em -1em', padding: '1em'}}>
        <PrimaryButton className="margin-lft" onClick={() => this.onClick(true)}>Weiter</PrimaryButton>
        <SecondaryButton className="margin-lft" onClick={() => this.onClick(false)}>Abbrechen</SecondaryButton>
      </div>
    );
    const TypeOptions = getTaskTypeOptions(type);
    return (
      <Dialog
        header={header}
        footer={footer}
        iconsTemplate={null}
        visible={isVisible}
        style={{width: '600px'}}
        modal={true}
        onHide={this.onHide}>
        <div className="w3-large">{text}</div>
        <div className="neg-margin-lr w3-row pad-top">
          {showTaskType && (
            <div className="w3-col s6 pad-lr form-group-item">
              <Label htmlFor="taskType">{`Aktion auswählen`}</Label>
              <Dropdown
                id="taskType"
                className="w3-block w3-border"
                value={taskType}
                placeholder="Auswahl"
                options={TypeOptions}
                onChange={(event) => this.setState({taskType: event.value})}
                showClear
              />
            </div>
          )}
          {showValidity && (
            <div className="w3-col s6 pad-lr form-group-item">
              <Label htmlFor="valid_until">{`Erneuerungsdatum`}</Label>
              <Calendar
                id="valid_until"
                className="w3-block w3-border w3-round"
                value={valid_until}
                dateFormat="dd.mm.yy"
                disabled={false}
                onChange={(event) => this.setState({valid_until: event.value})}
              />
            </div>
          )}
        </div>
      </Dialog>
    );
  }
}
