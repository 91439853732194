import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  PageHeight30,
  PageTitle,
  scrollToTop,
} from "../../../components/Utils";
import { Label } from "../../../components/Inputs";
import { FormMenu } from "../../../components/Buttons";
import MessageForm from "../components/MessageForm";
import MediaUploadForm from "../../../components/MediaUploadForm";
import SignatureMenu from "./SignatureMenu";
import { buildSignature } from "../components/SignatureForm";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadContractGivers,
  loadContractors,
  sendMessage,
  loadRoles,
  loadOptions,
  loadSignature,
} from "../actions";

const MAX_TOTAL_FILESIZE = 20000000;

const frontload = async (props) => {
  await props.getRoles();
  await props.getOptions();
  await props.getSignature();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.news.sending || state.news.loading,
    roles: state.news.roles,
    // footPrints: state.news.footPrints,
    footPrintOptions: state.news.footPrintOptions,
    footPrintGroups: state.news.footPrintGroups,
    footPrintGroupOptions: state.news.footPrintGroupOptions,
    companyGroupOptions: state.news.companyGroupOptions,
    signature: state.news.signature,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getContractGivers: (...args) => dispatch(loadContractGivers(...args)),
  getContractors: (...args) => dispatch(loadContractors(...args)),
  getRoles: (...args) => dispatch(loadRoles(...args)),
  getOptions: (...args) => dispatch(loadOptions(...args)),
  getSignature: (...args) => dispatch(loadSignature(...args)),
  sendMessage: (...args) => dispatch(sendMessage(...args)),
});

class MessagePanel extends React.Component {
  static propTypes = {
    roles: PropTypes.array,
    // footPrints: PropTypes.shape({}),
    footPrintOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    footPrintGroups: PropTypes.arrayOf(PropTypes.shape({})),
    footPrintGroupOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      )
    ),
    companyGroupOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    getRoles: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
    getSignature: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "margin-big-top",
  };
  componentDidMount() {
    scrollToTop();
  }
  onSend = () => {
    // basic message
    const message = this.messageData.getModel();
    // add signature
    message.text += buildSignature(this.props.signature);
    // get attachments
    const { media } = this.mediaForm.getModel();
    message.attachments = media && media.length > 0 ? media : [];
    // send
    console.log("Sending ...", message);
    this.btnPanel && this.btnPanel.toggleDisabled(true);
    this.props.sendMessage({
      message,
      onSuccess: () => {
        this.btnPanel && this.btnPanel.toggleDisabled(false);
        this.messageData.reset();
        scrollToTop();
      },
    });
  };
  onFormUpdated = (isValid) => {
    const isFilesValid =
      this.mediaForm && this.mediaForm.getTotalFileSize() < MAX_TOTAL_FILESIZE;
    this.btnPanel && this.btnPanel.toggleDisabled(!isValid || !isFilesValid);
  };
  onFilesUpdated = (isValid) => {
    const isFormValid =
      this.messageData && this.messageData.getModel() !== null;
    this.btnPanel && this.btnPanel.toggleDisabled(!isValid || !isFormValid);
  };
  render() {
    const {
      className,
      roles,
      footPrintGroups,
      footPrintGroupOptions,
      companyGroupOptions,
      signature,
    } = this.props;
    const signatureMarkupStr = buildSignature(signature);
    // console.log("Signature size:", signatureMarkupStr.length)
    const year = moment().year();
    const titlePrefixOptions = ["ad-hoc News", "Firmeninformation"].map(
      (item) => ({
        label: `${year} ${item}`,
        value: `${year}-<Anzahl> ${item}`,
      })
    );

    return (
      <PageHeight30 id="/nachrichten" className={`${className}`}>
        <PageTitle>
          <SignatureMenu className="w3-right" />
          <h3 className="no-margin">News Einstellen</h3>
        </PageTitle>
        <div className="pad-big w3-border no-border-btm">
          <p>Über diese Seite können E-Mail oder eine SMS versandt werden.</p>
        </div>
        <div className="pos-relative">
          {this.props.isLoading && (
            <div
              className="w3-border"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                background: "#fff",
                zIndex: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          )}
          <MessageForm
            ref={(ref) => (this.messageData = ref)}
            className="pad-big w3-border"
            roles={roles}
            footPrintGroups={footPrintGroups}
            footPrintGroupOptions={footPrintGroupOptions}
            companyGroupOptions={companyGroupOptions}
            titlePrefixOptions={titlePrefixOptions}
            onUpdated={this.onFormUpdated}
            signature={signatureMarkupStr}
          />
        </div>
        <MediaUploadForm
          ref={(ref) => (this.mediaForm = ref)}
          className="margin-big-top pad-big w3-border"
          title={`Anhänger`}
          uploadBtnClassName="w3-right"
          uploadUrl="/data-api/media/upload?target=news"
          itemRowClassName="neg-margin-lr"
          itemColClassName="w3-col s3"
          btnLabel="Datei hochladen"
          // model={{ files: attachments }}
          max={null}
          maxTotalFileSize={MAX_TOTAL_FILESIZE}
          onUpdated={this.onFilesUpdated}
          showFileName
        />
        <ButtonPanel
          ref={(ref) => (this.btnPanel = ref)}
          className="w3-border margin-big-top pad-big w3-light-grey"
          onSend={this.onSend}
        />
      </PageHeight30>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(MessagePanel)
);

class ButtonPanel extends React.Component {
  static propTypes = {
    onSend: PropTypes.func,
    className: PropTypes.string,
  };
  constructor() {
    super();
    this.state = { disabled: true };
  }
  toggleDisabled = (disabled) => {
    this.setState({ disabled });
  };
  render() {
    const { className, onSend } = this.props;
    return (
      <FormMenu
        className={className}
        onSave={onSend}
        saveBtnLabel="Nachricht senden"
        saveBtnName="closeMessageBtn"
        saveDisabled={this.state.disabled}
        cancelHidden
      />
    );
  }
}
