import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  EditIconNavButton,
  DeleteIconNavButton,
  ActivateIconButton,
  DeactivateIconButton,
  ViewIconNavButton
} from '../../../../components/Buttons';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import {
  PRODUCT_STATUS_LABELS,
  PRODUCT_STATUS_IDS,
  getActivationText,
  isProductActivated,
  isProductDeactivated,
  isProductDeleted,
  PageHeight40,
  scrollToTop,
  TableEmptyMessage
} from '../../../../components/Utils';
import { sortProductsByName } from '../../components';
import { ProductStatusFilter } from '../../../../components/ColumnFilters';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadKeys,
  changeKeyStatus,
  selectKeyForDeletion
} from '../../actions/keys';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => {
  await props.getProducts();
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.key.loading,
    products: state.key.keys
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProducts: (...args) => dispatch(loadKeys(...args)),
  changeProductStatus: (...args) => dispatch(changeKeyStatus(...args)),
  selectForDeletion: (...args) => dispatch(selectKeyForDeletion(...args)),
});

class KeyTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    products: PropTypes.array,
    getProducts: PropTypes.func.isRequired,
    changeProductStatus: PropTypes.func.isRequired,
    selectForDeletion: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  constructor() {
    super();
    this.state = {
      selectedStatus: null,
      first: 0
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onEditProduct = (product, history) => {
    console.log('Editing', product);
    history.push(`/wizard/produkte/schliessmittel/${product.id}`);
  }
  onDeactivate = (product) => {
    console.log('KeyTable.onDeactivate()', product);
    this.dialog.show(
      true,
      {
        title: 'Schließmittel deaktivieren',
        text: getActivationText('das Schließmittel', `${product.name} (${product.number})`, true)
        // `Wollen Sie den Schließmittel ${product.name} (${product.number}) wirklich deaktivieren?`
      },
      () => this.props.changeProductStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.DEACTIVATED
      })
    );
  }
  onActivate = (product) => {
    console.log('KeyTable.onActivate()', product);
    this.dialog.show(
      true,
      {
        title: 'Schließmittel aktivieren',
        text: getActivationText('das Schließmittel', `${product.name} (${product.number})`, false)
        // `Wollen Sie den Schließmittel ${product.name} (${product.number}) wirklich aktivieren?`
      },
      () => this.props.changeProductStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.ACTIVATED
      })
    );
  }
  onDeleteProduct = (product) => {
    console.log('KeyTable.onDeleteProduct()', product);
    this.props.selectForDeletion(product);
  }
  actionTemplate = (rowData) => {
    const { disabled } = this.props;
    const editId = `editProductBtn${rowData.id}`;
    const EditComponent = (disabled || isProductDeleted(rowData)) ? ViewIconNavButton : EditIconNavButton;
    const actId = `actProductBtn${rowData.id}`;
    const activateBtn = !disabled && !isProductActivated(rowData) && !isProductDeleted(rowData) && (
      <ActivateIconButton
        id={actId}
        name={actId}
        onClick={() => this.onActivate(rowData)}
      />
    );
    const deactId = `deactProductBtn${rowData.id}`;
    const deactivateBtn = !disabled && isProductActivated(rowData) && (
      <DeactivateIconButton
        id={deactId}
        name={deactId}
        onClick={() => this.onDeactivate(rowData)}
      />
    );
    const delId = `delProductBtn${rowData.id}`;
    const deleteBtn = !disabled && !isProductDeleted(rowData) && (
      <DeleteIconNavButton
        id={delId}
        name={delId}
        onClick={() => this.onDeleteProduct(rowData)}
      />
    );
    return (
      <div>
        <div className="w3-cell w3-cell-middle">
          <EditComponent
            id={editId}
            name={editId}
            onClick={(history) => this.onEditProduct(rowData, history)}
          />
        </div>
        <div className="w3-cell w3-cell-middle">
          {activateBtn}
          {deactivateBtn}
        </div>
        <div className="w3-cell w3-cell-middle">
          {deleteBtn}
        </div>
      </div>
    );
  }
  onStatusChange = (selectedStatus) => {
    this.dt.filter(selectedStatus, 'status_id', 'equals');
    this.setState({ selectedStatus });
  }
  render() {
    const { className, isLoading, products, rows } = this.props;
    // console.log('KeyTable.render()', products);
    if (!products) {
      return null;
    }
    const data = sortProductsByName(products).map((product, index) => ({
      ...product,
      index,
      status: product.status || PRODUCT_STATUS_LABELS[product.status_id || PRODUCT_STATUS_IDS.ACTIVATED]
    }));
    const statusBodyTemplate = (rowData) => (
      <span className={`status-text product-status-text-${rowData.status_id}`}>{rowData.status}</span>
    );
    const statusFilter = (
      <ProductStatusFilter
        value={this.state.selectedStatus}
        onChange={this.onStatusChange}
      />
    );
    return (
      <PageHeight40 className={`key-table clearfix ${className}`}>
        <DataTable
          ref={ref => this.dt = ref}
          value={data}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Schließmitteln"
              itemName="Schließmittel"
              itemNamePlural="Schließmitteln"
            />
          }
          rows={rows}
          rowClassName={(data) => ({
            'w3-text-grey product-table-deactivated': isProductDeactivated(data),
            'w3-text-red product-table-deleted': isProductDeleted(data)
          })}
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Schließmitteln"/>)}
        >
          <Column
            field="number"
            header="VKDG-Bestell-Nr."
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{textAlign:'center', width: '12em'}}
          />
          <Column
            field="name"
            header="VKDG-Bezeichnung"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          {/* <Column
            field="status"
            header="Status"
            style={{textAlign:'center', width: '10em'}}
          /> */}
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterElement={statusFilter}
            body={statusBodyTemplate}
            style={{width: '12em'}}
          />
          <Column
            body={this.actionTemplate}
            style={{textAlign:'center', width: '10.6em'}}
          />
        </DataTable>
        <ConfirmationDialog ref={ref => this.dialog = ref} />
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(KeyTable)
);
