import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AppLogo from "../AppLogo";
import MainNav from "../MainNav";
import UserNav from "../UserNav";
import "./styles.css";
import LocationChangeListener from "../../components/LocationChangeListener";
import { HamburgerMenu } from "../../components/VFIcon";

const mapStateToProps = (state) => {
  return {
    technikTree: state.staticPage.tree.technik,
    salesTree: state.staticPage.menuTree.sales,
    organisationTree: state.staticPage.menuTree.organisation,
  };
};
const mapDispatchToProps = null;

class AppHeader extends React.Component {
  static propTypes = {
    technikTree: PropTypes.shape({}),
    salesTree: PropTypes.shape({}),
    organisationTree: PropTypes.shape({}),
  };
  constructor() {
    super();
    // this mechanism only affects smaller screens where the menu is only
    // accessable via the `HamburgerMenu`
    this.hideMenu = this.hideMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      showMenu: false,
    };
  }
  toggleMenu() {
    this.setState((state) => ({
      showMenu: !state.showMenu,
    }));
  }
  hideMenu() {
    this.setState({ showMenu: false });
  }
  render() {
    const { technikTree, salesTree, organisationTree } =
      this.props;
    const tree = {
      technik: technikTree,
      sales: salesTree,
      organisation: organisationTree,
    };
    const { showMenu } = this.state;
    return (
      <>
        <LocationChangeListener onChange={this.hideMenu} />
        <header
          className={classnames("app-header", {
            "nav-display": showMenu,
          })}
        >
          <div className="menu-pane w3-border-bottom">
            <div className="container">
              <div className="w3-row">
                <div className="w3-col logo-holder">
                  <Link className="app-home-link" to="/">
                    <AppLogo />
                    <span className="app-title">AN-Portal</span>
                  </Link>
                </div>
                <div className="w3-col w3-right burger-holder">
                  <HamburgerMenu onClick={this.toggleMenu} />
                </div>
                <div className="w3-col w3-right user-nav-holder">
                  <UserNav />
                </div>
                <div className="w3-rest">
                  <MainNav tree={tree} />
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
