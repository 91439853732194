import React from 'react';
import { SecondaryButton } from '../../../../components/Buttons';
import { Label, CalendarRange } from '../../../../components/Inputs';
import { toDBDate } from '../../../../components/Utils';

import { connect } from 'react-redux';
import { loadMyTechieReport } from '../../actions/report';
import moment from 'moment';


const mapDispatchToPropsIntRep = (dispatch) => ({
  getReport: (...args) => dispatch(loadMyTechieReport(...args)),
});

function IntervalReportsModule({
  className,
  getReport
}) {
  const [ interval, setInterval ] = React.useState([]);
  const handleChange = React.useCallback(
    (event) => {
      setInterval(event.value);
    },
    [ interval ]
  );
  const handleClick = () => {
    getReport(
      { interval: interval.map(item => toDBDate(item)) },
      () => {
        console.log('<< Report generation successfull ...');
        // setInterval([]);
      }
    );
    setInterval([]);
  };
  console.log('IntervalReportsModule.render() interval:', interval);
  return (
    <div className={`report-task-myTechie ${className} w3-hover-light-grey`}>
      <div className="w3-cell-row">
        <div className="w3-cell w3-cell-bottom">
          <Label htmlFor="interval">{`Zeitinterval (von - bis)`}</Label>
          <CalendarRange
            id="interval"
            className="w3-block w3-border w3-round"
            value={interval}
            readOnlyInput={true}
            dateFormat="dd.mm.yy"
            maxDate={new Date()}
            minDate={moment().subtract(2, "years").toDate()}
            onChange={handleChange}
          />
        </div>
        <div className="w3-cell w3-cell-bottom alg-rht" style={{width: '300px'}}>
          <SecondaryButton
            size="small"
            onClick={handleClick}
            disabled={!interval || interval.length !== 2}
          >
            {`Report herunterladen`}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export const IntervalReports = connect(null, mapDispatchToPropsIntRep)(IntervalReportsModule);


export const MyTechieReports = ({
  className
}) => {
  return (
    <div className={`report-task-debitors ${className}`}>
      <IntervalReports className="clearfix pad neg-margin-lr"/>
    </div>
  );
};
