import React from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'primereact/dropdown';
import { InputText, Label, Dropdown } from '../../../components/Inputs';
import { SALES_FINAL_STATE } from '../../../components/Utils';

export default class SaleStatusForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    companies: PropTypes.array,
    salesAgents: PropTypes.array,
    tipGivers: PropTypes.array,
    currentUser: PropTypes.object,
    getSalesWorkers: PropTypes.func,
    getTipGivers: PropTypes.func,
  }
  static defaultProps = {
    className: '',
    model: {
      weborder_id: '',
      sales_agent: '',
      state_final: ''
    },
    companies: [],
    salesAgents: [],
    tipGivers: []
  }
  constructor(props) {
    super(props);
    const { model } = props;
    this.state = {
      weborder_id: model.weborder_id || '',
      sales_agent: model.sales_agent || '',
      state_final: model.state_final || '',
      error: {
        // weborder_id: null,
        // sales_agent: null,
        // state_final: null,
      }
    };
  }
  setModel = (model) => {
    if (model) {
      this.setState({
        weborder_id: model.weborder_id || '',
        sales_agent: model.sales_agent || '',
        state_final: model.state_final || '',
      });
    }
  }
  validate = () => {
    const { error, ...otherProps } = this.state;
    let model = { ...otherProps };
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach(key => {
      if (error[key] !== undefined && model[key] === '') {
        error[key] = true;
        isValid = false;
      }
    });
    if (!isValid) {
      this.setState({ error });
      return null;
    }
    return {
      id: this.props.model.id,
      state_final: model.state_final
    };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    console.log('SaleStatusForm.render()', this.props, '\nstate', this.state);
    const { className } = this.props;
    const {
      weborder_id,
      sales_agent,
      state_final,
    } = this.state;
    const FinalStatusOptions = Object.keys(SALES_FINAL_STATE).map(key => ({
      label: SALES_FINAL_STATE[key], value: SALES_FINAL_STATE[key]
    }));
    return (
      <div className={className}>
        <div className={`pad-top`}>
          <Label htmlFor="weborder_id">{`IAV Nummer/Weborder-ID*`}</Label>
          <InputText
            id="weborder_id"
            className="w3-block w3-border"
            value={weborder_id}
            disabled
          />
        </div>
        <div className={`pad-top`}>
          <Label htmlFor="salesagent_username">{`Sales Agent*`}</Label>
          <InputText
            id="salesagent_username"
            className="w3-block w3-border"
            value={sales_agent}
            disabled
          />
        </div>
        <div className={`pad-top`}>
          <Label htmlFor="state_final">{`Status Final`}</Label>
          <Dropdown
            id="state_final"
            className="w3-block w3-border"
            value={state_final}
            placeholder="Bitte auswählen"
            options={FinalStatusOptions}
            onChange={(event) => this.setState({state_final: event.value})}
          />
        </div>
      </div>
    );
  }
}
