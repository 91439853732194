import React from 'react';
import PropTypes from 'prop-types';
import DebitorItem from '../DebitorItem';
import { PageHeight40, createSortByAttr } from '../../../../components/Utils';
import { WizardButtonPanel } from '../../../../components/Buttons';
import DescriptionForm from '../DescriptionForm';

export default class TaskDebitorListContent extends React.Component {
  static propTypes = {
    // state
    modemOrder: PropTypes.array,
    // actions 
    getModemOrder: PropTypes.func.isRequired,
    setDebitor: PropTypes.func.isRequired,
    saveModemOrder: PropTypes.func.isRequired,
    // rest
    isTask: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = { debitor: null };
  }
  validate = () => {
    const { debitor } = this.state;
    if (!debitor) {
      return null;
    }
    return { ...debitor };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  setDebitor = () => {
    const { history, match, setDebitor } = this.props;
    const id = match.params.id;
    setDebitor(this.getModel());
    history.replace(`/wizard/aufgabe/bestellung/modem/${id}/katalog`);
  }
  onSave = () => {
    const { debitors, cancelOrder } = this.props;
    this.props.saveModemOrder(debitors, cancelOrder);
  }
  render() {
    const { className, modemOrder, cancelOrder } = this.props;
    if (!modemOrder || modemOrder.length === 0) {
      return null;
    }
    const refOrderItem = modemOrder[0];
    const currentDebitor = this.state.debitor;
    // console.log('TaskDebitorList.render()', modemOrder);
    // debitors
    let description = '';
    const debitorMarkup = modemOrder.sort(
      createSortByAttr('debitorKey')
    ).map((item, index) => {
      if (item.status_id !== 1) {
        item.debitor.status_id = item.status_id;
      }
      if ( index === 0) {
        description = item.description;
      }
      return (
        <div key={index} className="w3-col s3 pad-lr">
          <DebitorItem
            id={`debitorBtn${index}`}
            className={`w3-block margin-big-top`}
            debitor={item.debitor}
            onClick={(debitor) => this.setState({ debitor })}
            selected={currentDebitor && item.debitor.id === currentDebitor.id}
            hideRegion
          />
        </div>
      );
    });
    const week = `${refOrderItem.week < 10 ? '0' : ''}${refOrderItem.week}`;
    return (
      <div className={`debitor-list`}>
        <div className={`${className}`}>
          <div className="pad w3-border">
            <h3 className="no-margin pad-sm-top pad-lr">{`Debitoren (KW${week}): Region ${refOrderItem.debitor.region}`}</h3>
          </div>
          <PageHeight40 className="w3-border no-border-top pad-big no-pad-top">
            <div className="w3-row neg-margin-lr">
              {debitorMarkup}
            </div>
            <DescriptionForm
              ref={ref => this.form = ref}
              model={{ description }}
              className="pad-top"
              disabled
            />
          </PageHeight40>
        </div>
        <WizardButtonPanel
          className="margin-big-top w3-border pad-big w3-light-grey"
          onNext={(currentDebitor === null) ? null : this.setDebitor}
          onCancel={cancelOrder}
          nextLabel={`Debitor bearbeiten`}
          disableIfAbsent
        />
      </div>
    );
  }
}
