import React from "react";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadResourceGroupPermissions } from "../actions";
// import * as Resources from "../resources";

const frontload = async (props) => {
  // const resourceGroup = getGroupResources(Resources, props.group);
  await props.getResourceGroupPermissions(props.group);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getResourceGroupPermissions: (...args) =>
    dispatch(loadResourceGroupPermissions(...args)),
});

const PermissionGroupFetcher = () => {
  return <></>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(PermissionGroupFetcher)
);
