import * as actionTypes from '../actions/cards';
import { LOGOUT_SUCCESS } from '../../../redux/actions/auth';
import { notifySuccess, notifyError, PRODUCT_STATUS_IDS, isProductActivated } from '../../../components/Utils';
import { isArray } from 'lodash';
import { sortProductsByName } from '../components';
// import isArray from 'lodash/isArray';

const initialState = {
  loading: false,
  cards: [],
  catalogCards: [],
  card: null,
  cardOrders: [],
  cardOrder: null,
  addresses: [],
  selectedCard: null,
  selectionPurpose: null,
  dependentCardOrders: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    // load cards
    case actionTypes.LOAD_CARDS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_CARDS_SUCCESS: {
      const cards = action.result.map(card => {
        if (card.image_paths) {
          try {
            card.files = JSON.parse(card.image_paths);
          } catch(error) {
            card.files = [];
            console.error(error);
          }
        }
        return card;
      });
      return {
        ...state,
        loading: false,
        cards: sortProductsByName(cards),
        catalogCards: sortProductsByName(cards.filter(card => isProductActivated(card)))
      };
    }
    case actionTypes.LOAD_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        cards: [],
        catalogCards: [],
        error: action.result
      };
    // load a card
    case actionTypes.LOAD_CARD:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_CARD_SUCCESS: {
      const card = isArray(action.result) ? action.result[0] : action.result;
      if (card.image_paths) {
        try {
          card.files = JSON.parse(card.image_paths);
        } catch(error) {
          console.error(error);
        }
        // card.files = [{ path: card.image_path }];
      }
      return {
        ...state,
        loading: false,
        card
      };
    }
    case actionTypes.LOAD_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        card: null,
        error: action.result
      };
    // save a card
    case actionTypes.SAVE_CARD:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SAVE_CARD_SUCCESS:
      notifySuccess({
        summary: `Karten/Flyer ${action.card.id ? 'aktualisieren' : 'speichern'}`,
        detail: `Das Produkt ${action.card.name} wurde erfolgreich ${action.card.id ? 'aktualisiert' : 'gespeichert'}!`
      });
      return {
        ...state,
        loading: false,
        card: null
      };
    case actionTypes.SAVE_CARD_FAILURE:
      notifyError({
        summary: `Karten/Flyer ${action.card.id ? 'aktualisieren' : 'speichern'}`,
        detail: `Das Produkt ${action.card.name} konnte nicht ${action.card.id ? 'aktualisiert' : 'gespeichert'} werden!`
      });
      return {
        ...state,
        loading: false,
        card: null,
        error: action.result
    };
    // cancel card
    case actionTypes.CANCEL_CARD:
      return {
        ...state,
        card: null
      }
    // delete a card
    case actionTypes.DELETE_CARD:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_CARD_SUCCESS:
      notifySuccess({
        summary: `Karten/Flyer löschen`,
        detail: `Das Produkt ${action.card.name} wurde erfolgreich gelöscht!`
      });
      return {
        ...state,
        loading: false,
        selectedCard: null,
        selectionPurpose: null
      };
    case actionTypes.DELETE_CARD_FAILURE:
      notifyError({
        summary: `Karten/Flyer löschen`,
        detail: `Das Produkt ${action.card.name} konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result,
        selectedCard: null,
        selectionPurpose: null
      };
    // select a card
    case actionTypes.SELECT_CARD:
      return {
        ...state,
        selectedCard: action.card,
        selectionPurpose: action.purpose
      };
    // cancel a card selection
    case actionTypes.CANCEL_CARD_SELECTION:
      return {
        ...state,
        selectedCard: null,
        selectionPurpose: null
      };
    // check card usage
    case actionTypes.CHECK_USAGE:
      return {
        ...state,
        loading: true,
        dependentCardOrders: null
      };
    case actionTypes.CHECK_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dependentCardOrders: action.result
      };
    case actionTypes.CHECK_USAGE_FAILURE:
      return {
        ...state,
        loading: false,
        dependentCardOrders: null,
        error: action.error || action.result
      };
    // change a card's status
    case actionTypes.CHANGE_STATUS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CHANGE_STATUS_SUCCESS:
      notifySuccess({
        summary: `Karte/Flyer ${action.card.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Die Karte/Der Flyer ${action.card.name} wurde erfolgreich ${action.card.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert!`
      });
      return {
        ...state,
        loading: false,
        card: null
      };
    case actionTypes.CHANGE_STATUS_FAILURE:
      notifyError({
        summary: `Karte/Flyer ${action.card.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktivieren`,
        detail: `Die Karte/Der Flyer ${action.card.name} konnte nicht ${action.card.status_id === PRODUCT_STATUS_IDS.ACTIVATED ? '' : 'de'}aktiviert werden!`
      });
      return {
        ...state,
        loading: false,
        card: null,
        error: action.result,
        selectedCard: null,
        selectionPurpose: null
      };
    
    // load card orders
    case actionTypes.LOAD_CARD_ORDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_CARD_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        cardOrders: action.result
      };
    case actionTypes.LOAD_CARD_ORDERS_FAILURE: {
      const error = action.result || action.error;
      console.log("Card Orders Fetch ERROR:", action);
      notifyError({
        summary: `Karte/Flyer`,
        detail: `Die Karten/Flyer konnten nicht geladen werden${(error.code === 403) ? ': NICHT ERLAUBT' : ''}!`
      });
      return {
        ...state,
        loading: false,
        cardOrders: [],
        error,
      };}
    // load card order
    case actionTypes.LOAD_CARD_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_CARD_ORDER_SUCCESS: {
      const cardOrder = isArray(action.result) ? action.result[0] : action.result;
      cardOrder.person = (cardOrder.address || {}).contact_person_name;
      cardOrder.address_id = (cardOrder.address || {}).id;
      cardOrder.items = cardOrder.items.map(item => {
        const card = (state.cards || []).find(card => card.id === item.product_id);
        item.files = card ? card.files : [];
        return item;
      });
      return {
        ...state,
        loading: false,
        cardOrder
      };
    }
    case actionTypes.LOAD_CARD_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        cardOrders: null,
        error: action.result
      };
    // load order address
    case actionTypes.LOAD_ADDRESSES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: action.result
      };
    case actionTypes.LOAD_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        addresses: [],
        error: action.result
      };
    // save order catalog
    case actionTypes.SAVE_CARD_ORDER_CATALOG: {
      const cardOrder = state.cardOrder || {};
      cardOrder.items = action.result;
      // console.log('REDUX_REDUCER saveOrderCatalog()', action);
      return {
        ...state,
        cardOrder
      };
    }
    // save order address
    case actionTypes.SAVE_CARD_ORDER_ADDRESS: {
      const cardOrder = state.cardOrder || {};
      return {
        ...state,
        cardOrder: { ...cardOrder, ...action.result }
      };
    }
    // save card order
    case actionTypes.SAVE_CARD_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SAVE_CARD_ORDER_SUCCESS:
      notifySuccess({
        summary: `Karten/Flyer bestellen`,
        detail: `Ihre Bestellung wurde erfolgreich ${action.cardOrder.id ? 'aktualisiert' : 'abgegeben'}!`
      });
      return {
        ...state,
        loading: false,
        cardOrder: null
      };
    case actionTypes.SAVE_CARD_ORDER_FAILURE:
      notifyError({
        summary: `Karten/Flyer bestellen`,
        detail: `Ihre Bestellung konnte nicht ${action.cardOrder.id ? 'aktualisiert' : 'abgegeben'} werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // cancel card order
    case actionTypes.CANCEL_CARD_ORDER:
      return {
        ...state,
        cardOrder: null
      }

    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
