import React from "react";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  getResourcePermissions,
  loadResourceListPermissions,
} from "../actions";

const frontload = async (props) => {
  await props.getResourceListPermissions(props.resources);
};

const mapStateToProps = (state) => ({
  permissions: state.permissions.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  getResourceListPermissions: (...args) =>
    dispatch(loadResourceListPermissions(...args)),
});

const PermissionListFetcher = ({ permissions, resources, children }) => {
  const permissionMap = {};
  if (resources) {
    resources.forEach((resource) => {
      permissionMap[resource] = { read: false, write: false };
      const permission = getResourcePermissions(resource, permissions);
      if (permission) {
        permissionMap[resource] = permission;
      }
    });
  }
  return (
    <>
      {React.Children.map(children, (child) => {
        return child
          ? React.cloneElement(child, { permissions: permissionMap })
          : child;
      })}
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(PermissionListFetcher)
);
