import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { News } from "../../Permissions/resources";
import { isResourceEditable } from "../../Permissions/actions";
import { PrimaryButtonLink } from "../../../components/Buttons";

const mapStateToProps = (state) => ({
  permissions: state.permissions.permissionMap,
});
const mapDispatchToProps = null;

class NewsMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    categoryName: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    permissions: PropTypes.shape({}),
  };
  static defaultProps = {
    className: "",
    categoryName: "general",
  };
  render() {
    const { className, categoryName, disabled, permissions } = this.props;
    const isEditable = isResourceEditable(News.Articles, permissions);
    if (disabled || !isEditable) {
      return null;
    }
    let url = "#";
    switch (categoryName) {
      case "Sales":
        url = `/sales/news-feed/beitrag/neu`;
        break;
      default:
        url = `/news-service/beitrag/neu`;
        break;
    }
    return (
      <div className={`news list-menu ${className}`}>
        <PrimaryButtonLink
          id="createNewsArticleBtn"
          to={url}
        >{`Beitrag hinzufügen`}
        </PrimaryButtonLink>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsMenu);
