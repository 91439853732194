import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  EditIconNavButton,
  DeleteIconNavButton,
  ActivateIconButton,
  DeactivateIconButton,
  ViewIconNavButton
} from '../../../components/Buttons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import {
  PRODUCT_STATUS_LABELS,
  PRODUCT_STATUS_IDS,
  getProductStatusChangeConfirmationText,
  isProductActivated,
  isProductDeactivated,
  isProductDeleted,
  PageHeight40,
  scrollToTop,
  TableEmptyMessage
} from '../../../components/Utils';
import { sortProductsByName } from '../../Product/components';
import { ProductStatusFilter } from '../../../components/ColumnFilters';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import {
  loadModems,
  changeModemStatus,
  selectModemForDeletion
} from '../actions';
import SalesTablePageReport from '../../Reporting/components/SalesTablePageReport';

const frontload = async (props) => (
  await props.getModems()
);
const mapStateToProps = (state) => {
  return {
    isLoading: state.modem.loading,
    modems: state.modem.modems
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModems: (...args) => dispatch(loadModems(...args)),
  changeStatus: (...args) => dispatch(changeModemStatus(...args)),
  selectForDeletion: (...args) => dispatch(selectModemForDeletion(...args)),
});

class ModemTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    modems: PropTypes.array,
    getModems: PropTypes.func.isRequired,
    changeStatus: PropTypes.func.isRequired,
    selectForDeletion: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string
  }
  static defaultProps = {
    rows: 10,
    className: ''
  }
  constructor() {
    super();
    this.state = {
      selectedStatus: null,
      first: 0
    };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  }
  onEdit = (modem, history) => {
    console.log('Editing', modem);
    history.push(`/wizard/produkte/modem/${encodeURIComponent(modem.name)}`);
  }
  onDeactivate = (product) => {
    console.log('ModemTable.onDeactivate()', product);
    this.dialog.show(
      true,
      {
        title: 'Modem deaktivieren',
        text: getProductStatusChangeConfirmationText(PRODUCT_STATUS_IDS.DEACTIVATED, 'das Modem', product.name)
      },
      () => this.props.changeStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.DEACTIVATED
      })
    );
  }
  onActivate = (product) => {
    console.log('ModemTable.onActivate()', product);
    this.dialog.show(
      true,
      {
        title: 'ModemTable aktivieren',
        text: getProductStatusChangeConfirmationText(PRODUCT_STATUS_IDS.ACTIVATED, 'das Modem', product.name)
      },
      () => this.props.changeStatus({
        ...product,
        status_id: PRODUCT_STATUS_IDS.ACTIVATED
      })
    );
  }
  onDelete = (product) => {
    console.log('ModemTable.onDelete()', product);
    this.props.selectForDeletion(product);
  }
  // onDeleteModem = (selected) => {
  //   console.log('>> deleting the modem', selected);
  //   this.dialog.show(
  //     true,
  //     { title: 'Modem löschen', text: `Wollen Sie das Modem ${selected.name} unwidderuflich löschen?` },
  //     () => this.props.removeModem(selected)
  //   );
  // }
  actionTemplate = (rowData/* , column */) => {
    const editId = `editProductBtn${rowData.id}`;
    const EditComponent = isProductDeleted(rowData) ? ViewIconNavButton : EditIconNavButton;
    const actId = `actProductBtn${rowData.id}`;
    const activateBtn = !isProductActivated(rowData) && !isProductDeleted(rowData) && (
      <ActivateIconButton
        id={actId}
        name={actId}
        onClick={() => this.onActivate(rowData)}
      />
    );
    const deactId = `deactProductBtn${rowData.id}`;
    const deactivateBtn = isProductActivated(rowData) && (
      <DeactivateIconButton
        id={deactId}
        name={deactId}
        onClick={() => this.onDeactivate(rowData)}
      />
    );
    const delId = `delProductBtn${rowData.id}`;
    const deleteBtn = !isProductDeleted(rowData) && (
      <DeleteIconNavButton
        id={delId}
        name={delId}
        onClick={() => this.onDelete(rowData)}
      />
    );
    return (
      <div>
        <div className="w3-cell w3-cell-middle">
          <EditComponent
            id={editId}
            name={editId}
            onClick={(history) => this.onEdit(rowData, history)}
          />
        </div>
        <div className="w3-cell w3-cell-middle">
          {activateBtn}
          {deactivateBtn}
        </div>
        <div className="w3-cell w3-cell-middle">
          {deleteBtn}
        </div>
      </div>
    );
  }
  onStatusChange = (selectedStatus) => {
    this.dt.filter(selectedStatus, 'status_id', 'equals');
    this.setState({ selectedStatus });
  }
  render() {
    console.log('ModemTable.render()', this.props);
    const { className, isLoading, modems, rows } = this.props;
    const data = sortProductsByName(modems).map((modem, index) => ({
      ...modem,
      index,
      modelsStr: (modem.models || []).map(item => item.name).join(', '),
      status_id: modem.status_id || PRODUCT_STATUS_IDS.ACTIVATED,
      status: modem.status || PRODUCT_STATUS_LABELS[modem.status_id || PRODUCT_STATUS_IDS.ACTIVATED]
    }))
    // // sort by id in descending order
    // .sort((a, b) => {
    //   if (a.id > b.id) return -1;
    //   else if (a.id > b.id) return 1;
    //   return 0;
    // });
    const statusBodyTemplate = (rowData) => (
      <span className={`status-text product-status-text-${rowData.status_id}`}>{rowData.status}</span>
    );
    const statusFilter = (
      <ProductStatusFilter
        value={this.state.selectedStatus}
        onChange={this.onStatusChange}
      />
    );
    return (
      <PageHeight40 className={`modem-table clearfix ${className}`}>
        <DataTable
          ref={ref => this.dt = ref}
          value={data}
          paginator={data.length > rows}
          first={this.state.first}
          onPage={this.onPage}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Modems"
              itemName="Modem"
              itemNamePlural="Modems"
            />
          }
          rows={rows}
          rowClassName={(data) => ({
            'w3-text-grey product-table-deactivated': isProductDeactivated(data),
            'w3-text-red product-table-deleted': isProductDeleted(data)
          })}
          removableSort
          sortMode="multiple"
          emptyMessage={isLoading ? null : (<TableEmptyMessage itemNamePlural="Modems"/>)}
        >
          <Column
            field="name"
            header="Modem"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '14em'}}
          />
          <Column
            field="modelsStr"
            header="Typen"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          {/* <Column
            field="packageSize"
            header="Paketstückanzahl"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{width: '11em'}}
          /> */}
          <Column
            field="status_id"
            header="Status"
            sortable={data.length > 1}
            filter={data.length > rows}
            // filterMatchMode="contains"
            filterElement={statusFilter}
            body={statusBodyTemplate}
            style={{width: '12em'}}
          />
          <Column
            body={this.actionTemplate}
            style={{textAlign:'center', width: '10.6em'}}
          />
        </DataTable>
        <ConfirmationDialog ref={ref => this.dialog = ref}/>
      </PageHeight40>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(ModemTable)
);
