import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from "primereact/dropdown";
import { InputText, Label,  } from "../../../../components/Inputs";
import { InputTextarea } from "primereact/inputtextarea";

export default class BookingReporterForm extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		model: PropTypes.object,
		updateable: PropTypes.bool,
	};
	static defaultProps = {
		className: "",
		model: {
			orderNumber: "",
			clientNumber: "",
			clientName: "",
			comments: "",
		},
	};
	constructor(props) {
		super(props);
		this.state = this.getState(props);
	}
	componentWillReceiveProps(props) {
		this.props.updateable && this.setState(this.getState(props));
	}
	getState = (props) => {
		const { model } = props;
		return {
			orderNumber: model.orderNumber || "",
			clientNumber: model.clientNumber || "",
			clientName: model.clientName || "",
			comments: model.comments || "",
			error: {
				orderNumber: null,
				clientNumber: null,
				clientName: null,
			},
		};
	};
	reset = () => {
		this.setState(this.getState(this.props));
	};
	validate = () => {
		const { error, ...model } = this.state;
		// check if all values are set
		let isValid = true;
		Object.keys(model).forEach((key) => {
			if (error[key] !== undefined && model[key] === "") {
				error[key] = true;
				isValid = false;
			}
		});
		if (!isValid) {
			this.setState({ error });
			return null;
		}
		return model;
	};
	getModel = (noValidate) => {
		const model = this.validate(noValidate);
		return model;
	};
	render() {
		const { className, disabled } = this.props;
		const {
			orderNumber,
			clientNumber,
			clientName,
			comments,
			error,
		} = this.state;
		return (
			<div className={className}>
				<div className="w3-row neg-margin-lr">
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.orderNumber ? "error-group" : ""
							}`}
						>
							<Label htmlFor="orderNumber">{`Delphi-Auftragsnummer*`}</Label>
							<InputText
								id="orderNumber"
								className="w3-block w3-border"
								value={orderNumber}
								onChange={(event) =>
									this.setState({ orderNumber: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
						<div
							className={`pad-top form-group-item ${
								error.clientNumber ? "error-group" : ""
							}`}
						>
							<Label htmlFor="clientNumber">{`Kundennummer*`}</Label>
							<InputText
								id="clientNumber"
								className="w3-block w3-border"
								value={clientNumber}
								onChange={(event) =>
									this.setState({ clientNumber: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
					</div>
					<div className="w3-col s6 pad-lr">
						<div
							className={`pad-top form-group-item ${
								error.clientName ? "error-group" : ""
							}`}
						>
							<Label htmlFor="clientName">{`Kundenname*`}</Label>
							<InputText
								id="clientName"
								className="w3-block w3-border"
								value={clientName}
								onChange={(event) =>
									this.setState({ clientName: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
						<div className="pad-top form-group-item">
							<Label htmlFor="comments">{`Bemerkungen (optional)`}</Label>
							<InputTextarea
								id="comments"
								className="w3-block w3-border"
								value={comments}
								rows={3}
								autoResize
								onChange={(event) =>
									this.setState({ comments: event.target.value })
								}
								disabled={disabled}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
