import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadModems,
  loadCurrentDebitorDetails,
  addToCart,
  addToCartAndSaveDebitorOrder,
} from "../../actions";
import CartModemCatalogContent from "../../components/cart/CartModemCatalogContent";

const frontload = async (props) => {
  const id = props.match.params.id;
  const onFailure = () => {
    props.history.replace(`/wizard/shop/bestellung/modem/${id}/debitoren`);
  };
  await props.getModems();
  await props.getDebitorDetails(onFailure);
};
const mapStateToProps = (state) => {
  return {
    loadingDebitor: state.modem.loadingDebitor,
    debitorOrder: state.modem.shoppingCart,
    modems: state.modem.catalogModems,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModems: (...args) => dispatch(loadModems(...args)),
  getDebitorDetails: (...args) => dispatch(loadCurrentDebitorDetails(...args)),
  addToCart: (...args) => dispatch(addToCart(...args)),
  saveCart: (...args) => dispatch(addToCartAndSaveDebitorOrder(...args)),
});

class CartModemCatalog extends React.Component {
  static propTypes = {
    // mapped from state
    loadingDebitor: PropTypes.bool,
    debitorOrder: PropTypes.object,
    modems: PropTypes.array,
    // actions
    getModems: PropTypes.func.isRequired,
    getDebitorDetails: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    saveCart: PropTypes.func.isRequired,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
  };
  render() {
    // const { loadingDebitor, debitorOrder } = this.props;
    // if (loadingDebitor || !debitorOrder) {
    //   return null;
    // }
    return <CartModemCatalogContent {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(CartModemCatalog)
);
