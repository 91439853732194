import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, ButtonLink } from "../../../../components/Buttons";

import {
  W3Dropdown,
  W3DropdownToggler,
  W3DropdownContent,
} from "../../../../components/W3Dropdown";
import ITTaskDialog from "./ITTaskDialog";

export default class MSAuthenticatorTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedTasks: PropTypes.array,
    closeTasks: PropTypes.func.isRequired,
    items: PropTypes.array,
    history: PropTypes.object,
  };
  static defaultProps = {
    className: "",
    actions: {},
    items: [],
  };
  onClose = (event) => {
    if (event) event.preventDefault();
    const { selectedTasks, closeTasks } = this.props;
    let counter = 0;
    let isSingle = true;
    if (selectedTasks && selectedTasks.length > 0) {
      counter += selectedTasks.length;
      isSingle = selectedTasks.length === 1;
    }
    const text = isSingle
      ? "Die ausgewählte MS Authenticator-Aufgabe wird"
      : "Alle ${counter} ausgewählte MS Authenticator-Aufgaben werden";
    this.taskDialog.show(
      true,
      {
        title: `MS Authenticator Aufgabe${isSingle ? "" : "n"} schliessen`,
        text: `
          ${text} nun abgeschlossen.  
          Wollen Sie fortfahren?
        `,
        showTaskType: false,
      },
      () => closeTasks(selectedTasks)
    );
  };
  render() {
    const { className, selectedTasks } = this.props;
    const isCloseable = selectedTasks && selectedTasks.length > 0;
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle pad-lft">
            <W3Dropdown className="modems dropdown-list-menu">
              <W3DropdownToggler
                as={PrimaryButton}
                size=""
              >{`Optionen`}</W3DropdownToggler>
              <W3DropdownContent
                className="w3-bar-block w3-border"
                style={{ right: "0", width: "320px" }}
              >
                <ButtonLink
                  to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onClose}
                  disabled={!isCloseable}
                >
                  {`MS Authenticator-Aufgaben schliessen`}
                </ButtonLink>
              </W3DropdownContent>
            </W3Dropdown>
          </div>
        </div>
        <ITTaskDialog ref={(ref) => (this.taskDialog = ref)} />
      </div>
    );
  }
}
