import React from "react";
import PropTypes from "prop-types";
import { PrimaryButtonLink, PrimaryButton } from "../../../components/Buttons";
import { connect } from "react-redux";
import { exportWorkers } from "../actions";
import { Organisation } from "../../Permissions/resources";
import {
  isResourceEditable,
  isResourceReadable,
} from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  permissions: state.permissions.permissionMap,
});
const mapDispatchToProps = (dispatch) => ({
  getWorkersAsCSV: (...args) => dispatch(exportWorkers(...args)),
});

class WorkerListMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    permissions: PropTypes.shape({}).isRequired,
    getWorkersAsCSV: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
  };
  onExportWorkers = (event) => {
    if (event) event.preventDefault();
    this.props.getWorkersAsCSV();
  };
  render() {
    const { className, permissions } = this.props;
    const isReadable = isResourceReadable(
      Organisation.WorkerWizard.BasicInfo,
      permissions
    );
    const isEditable = isResourceEditable(
      Organisation.WorkerWizard.BasicInfo,
      permissions
    );
    return (
      <div className={`worker list-menu ${className}`}>
        {isReadable && isEditable && (
          <PrimaryButtonLink
            to={`/wizard/organisation/mitarbeiter/neu`}
            name="addWorkerBtn"
          >
            {`Mitarbeiter hinzufügen`}
          </PrimaryButtonLink>
        )}
        <PrimaryButton
          className="margin-lft"
          name="exportWorkerBtn"
          onClick={this.onExportWorkers}
        >
          {"CSV Exportieren"}
        </PrimaryButton>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkerListMenu);
