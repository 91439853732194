import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "primereact/checkbox";
import { Label } from "../../../../components/Inputs";

const getRoleNames = (roleList, model) => {
  return model.map((roleLabel) => {
    const role = roleList.find((item) => item.label === roleLabel);
    return role ? role.name : roleLabel; // return label if the name is not found
  });
};

const getRoleLabels = (roleList, model) => {
  return model.map((roleName) => {
    const role = roleList.find((item) => item.name === roleName);
    return role ? role.label : roleName; // return name if the label is not found
  });
};

const findInRoleList = (roleList, value) => {
  const role = roleList.find((item) => item.name === value);
  return role ? true : false;
};

const isRoleEditableInList = (roleList, value) => {
  const role = roleList.find((item) => item.name === value);
  return role && (role.extended ? true : role.write); // ? true : false;
};

const mergeRoleLists = (modelRoles, roleLists) => {
  // return [
  //   ...new Set(modelRoles.map((label) => ({ label })).concat(roleLists)),
  // ];
  const uniqueRoles = modelRoles.filter(
    (roleLabel) => !findInRoleList(roleLists, roleLabel)
  );
  return uniqueRoles.map((label) => ({ label })).concat(roleLists);
};

export default class RoleForm extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    model: PropTypes.array,
    roleList: PropTypes.array,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    error: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: [],
    roleList: [],
    title: "Aufgabenbereiche",
    min: 0,
  };
  constructor(props) {
    super(props);
    const { model, roleList } = props;
    this.state = {
      model: getRoleLabels(roleList, model),
      error: null,
    };
  }
  validate = () => {
    const { model } = this.state;
    const roles = getRoleNames(this.props.roleList, model);
    const error = (roles.length < this.props.min) ? true : null;
    // console.log("RoleForm.validate() new roles", roles, "roleList:", this.props.roleList, "min roles:", this.props.min, "error:", error);
    this.setState({ error });
    return error ? null : roles;
  };
  getModel = () => {
    const model = this.validate();
    return model;
  };
  onCheckboxChange = (event) => {
    let model = [...this.state.model];
    if (event.checked) model.push(event.value);
    else model.splice(model.indexOf(event.value), 1);
    this.setState({ model });
  };
  getColumn = (roles, number) => {
    const col = [];
    let size = Math.floor(roles.length / 3);
    if (roles.length - size * 3 > 1) {
      size++;
    }
    let startIndex = 0;
    let endIndex = size - 1;
    if (number === 1 || number === 2 || number === 3) {
      startIndex = (number - 1) * size;
      endIndex = startIndex + size - 1;
      if (number === 3) {
        endIndex = roles.length - 1;
      }
    }
    roles.forEach((role, index) => {
      if (index >= startIndex && index <= endIndex) {
        col.push(role.label);
      }
    });
    return col;
  };
  render() {
    const { id, className, roleList, title, disabled } = this.props;
    const { model, error } = this.state;
    const createCheckbox = (prefix) => (value, index) =>
      (
        <div key={index} className="p-col-12" id={value.split(" ").join("")}>
          <Checkbox
            inputId={`${prefix}${index}`}
            value={value}
            onChange={this.onCheckboxChange}
            checked={model.includes(value)}
            disabled={disabled || !isRoleEditableInList(roleList, value)}
          />
          <label htmlFor={`${prefix}${index}`} className="p-checkbox-label">
            {value}
          </label>
        </div>
      );
    const allRoles = mergeRoleLists(model, roleList);
    const col1 = this.getColumn(allRoles, 1).map(createCheckbox("cb1-"));
    const col2 = this.getColumn(allRoles, 2).map(createCheckbox("cb2-"));
    const col3 = this.getColumn(allRoles, 3).map(createCheckbox("cb3-"));
    return (
      <div id={id} className={className}>
        <Label className={(error || this.props.error) ? ' error-group' : ''}>{title}</Label>
        <div className="neg-margin-lr">
          <div className="w3-row">
            <div className="w3-col s4 pad-lr">{col1}</div>
            <div className="w3-col s4 pad-lr">{col2}</div>
            <div className="w3-col s4 pad-lr">{col3}</div>
          </div>
        </div>
      </div>
    );
  }
}
