import React from 'react';
import PropTypes from 'prop-types';
import DebitorItem from '../DebitorItem';
import { Label, Dropdown } from '../../../../components/Inputs';
// import { Dropdown } from 'primereact/dropdown';
import { PageHeight40, getCurrentOrderWeek, createSortByAttr, cleanUpDropdownOptions } from '../../../../components/Utils';
import { WizardButtonPanel } from '../../../../components/Buttons';
import DescriptionForm from '../DescriptionForm';

export default class CartDebitorListContent extends React.Component {
  static propTypes = {
    // mapped from state
    currentWeek: PropTypes.number,
    companyOptions: PropTypes.array,
    currentCompanyId: PropTypes.number,
    // currentCompanyName: PropTypes.string,
    regionOptions: PropTypes.array,
    currentRegionName: PropTypes.string,
    debitors: PropTypes.array,
    message: PropTypes.object,
    savedRegion: PropTypes.string,
    // actions 
    // getRegions: PropTypes.func.isRequired,
    getDebitors: PropTypes.func.isRequired,
    setDebitor: PropTypes.func.isRequired,
    saveModemOrder: PropTypes.func.isRequired,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  constructor(props) {
    super(props);
    this.state = {
      debitor: null,
      weekData: getCurrentOrderWeek()
    };
  }
  componentDidMount() {
    console.log('CartDebitorListContent.componentDidMount()', this.props);
    const {
      companyOptions,
      currentCompanyId,
      // currentCompanyName,
      currentRegionName,
      savedRegion
    } = this.props;
    if (companyOptions && companyOptions.length === 1 && currentCompanyId === 0) {
      // initial load
      this.setCompany(companyOptions[0].value);
    } else if (savedRegion) {
      // load after a debitor sub-order is saved into the shopping cart
      this.setRegion(currentRegionName);
    }
  }
  validate = () => {
    const { debitor } = this.state;
    if (!debitor) {
      return null;
    }
    return { ...debitor };
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  setWeek = (week) => {
    console.log(`CartDebitorListContent.setWeek(${week})`);
    let year = 0;
    const found = this.state.weekData.weekYears.find(item => item.week === week);
    if (found) { year = found.year; }
    this.props.getDebitors({
      companyName: this.props.currentCompanyName,
      week,
      year,
      regionName: this.props.currentRegionName
    });
  }
  setCompany = (companyId) => {
    console.log(`CartDebitorListContent.setCompany(${companyId})`);
    const week = this.props.currentWeek;
    let year = 0;
    const found = this.state.weekData.weekYears.find(item => item.week === week);
    if (found) { year = found.year; }
    const company = this.props.companyOptions.find(item => item.id === companyId);
    this.props.getDebitors({
      companyId,
      companyName: company ? company.label : "",
      week,
      year,
      regionName: 'null'
    });
  }
  setRegion = (regionName) => {
    const week = this.props.currentWeek;
    let year = 0;
    const found = this.state.weekData.weekYears.find(item => item.week === week);
    if (found) { year = found.year; }
    this.props.getDebitors({
      companyId: this.props.currentCompanyId,
      week,
      year,
      regionName
    });
  }
  setDebitor = () => {
    const { history, match, setDebitor } = this.props;
    const id = match.params.id;
    setDebitor(this.getModel());
    history.replace(`/wizard/shop/bestellung/modem/${id}/katalog`);
  }
  onSave = () => {
    const { debitors, cancelOrder } = this.props;
    // get description
    let description = '';
    if (this.form) {
      const data = this.form.getModel();
      if (data && data.description) {
        description = data.description;
      }
    }
    this.props.saveModemOrder(debitors, cancelOrder, description);
  }
  onSelectDebitor = (debitor) => {
    console.log('>> Debitor double clicked', debitor);
    const { history, match, setDebitor } = this.props;
    const id = match.params.id;
    setDebitor({ ...debitor });
    history.replace(`/wizard/shop/bestellung/modem/${id}/katalog`);
  }
  render() {
    const {
      className,
      currentWeek,
      companyOptions,
      currentCompanyId,
      regionOptions,
      currentRegionName,
      debitors,
      message,
      cancelOrder
    } = this.props;
    if (!companyOptions || !regionOptions || !debitors) {
      return null;
    }
    const currentDebitor = this.state.debitor;
    const weekData = this.state.weekData;
    const weekLabel = (weekData.weekOptions.find(item => item.value === currentWeek) || { label: '' }).label;
    // region markup
    let regionCompleted = 0;
    let regionMarkup = null;
    let debitorMarkup = null;
    if (regionOptions.length > 0/*  && debitors.length > 0 */) {
      // regions
      regionMarkup = (
        <div className="w3-col s4 pad-lr pad-top-">
          <Label htmlFor="region">{`Region`}</Label>
          <Dropdown
            id="region"
            className="w3-block w3-border"
            value={currentRegionName}
            placeholder="Bitte auswählen"
            options={regionOptions}
            onChange={(event) => this.setRegion(event.value)}
            showClear
          />
        </div>
      );
      // debitors
      debitorMarkup = debitors.sort(
        createSortByAttr('debitorKey')
      ).map((item, index) => {
        regionCompleted += item.isComplete ? 1 : 0;
        return (
          <div key={index} className="w3-col s3 pad-lr">
            <DebitorItem
              className={`w3-block margin-big-top`}
              debitor={item}
              onClick={(debitor) => this.setState({ debitor })}
              onDoubleClick={this.onSelectDebitor}
              selected={currentDebitor && item.id === currentDebitor.id}
              hideRegion
            />
          </div>
        );
      });
      debitorMarkup = (
        <div className="neg-margin-big-lr margin-big-top w3-border-top pad-big-lr">
          <div className="w3-row neg-margin-lr">{debitorMarkup}</div>
        </div>
      );
    } else {
      let msg = '';
      if (message && message.message === 'label.company.has.no.region') {
        msg = 'Für diese Firma sind keine Debitoren eingetragen.';
      } else if (message && message.message === 'label.already.ordered.for.this.week') {
        // const week = getCurrentOrderWeek(); // moment().format('ww');
        // msg = `Alle Bestellungen für die KW${week.weekStr} sind schon eingegangen.`;
        msg = `Alle Bestellungen für die KW${weekLabel} sind schon eingegangen.`;
      }
      debitorMarkup = (
        <div className="alg-center pad-lg" style={{height: '100%'}}>
          <h3>{msg}</h3>
        </div>
      );
    }
    const isRegionComplete = regionCompleted > 0 && (regionCompleted === debitors.length);
    return (
      <div className={`debitor-list`}>
        <div className={`${className}`}>
          <div className="pad w3-border w3-light-grey">
            {/* <h3 className="no-margin pad-sm-top pad-lr">{`Debitorauswahl (KW${moment().format('ww')})`}</h3> */}
            <h3 className="no-margin pad-sm-top pad-lr">{`Debitorauswahl (KW${weekLabel})`}</h3>
          </div>
          <div className="w3-border no-border-top pad-big">
            <PageHeight40>
              <div className="w3-row neg-margin-lr">
                <div className="w3-col s4 pad-lr pad-top-">
                  <Label htmlFor="region">{`Kalenderwoche`}</Label>
                  <Dropdown
                    id="week"
                    className="w3-block w3-border"
                    value={currentWeek}
                    placeholder="Bitte auswählen"
                    options={weekData.weekOptions}
                    onChange={(event) => this.setWeek(event.value)/* this.setState({week: event.value}) */}
                    showClear
                    // disabled
                  />
                </div>
                <div className="w3-col s4 pad-lr pad-top-">
                  <Label htmlFor="region">{`Firma`}</Label>
                  <Dropdown
                    id="company"
                    className="w3-block w3-border"
                    value={currentCompanyId}
                    placeholder="Bitte auswählen"
                    options={cleanUpDropdownOptions(companyOptions)}
                    onChange={(event) => this.setCompany(event.value)}
                    showClear
                  />
                </div>
                {regionMarkup}
              </div>
              {debitorMarkup}
              {isRegionComplete && (<DescriptionForm ref={ref => this.form = ref} className="pad-big-top"/>)}
            </PageHeight40>
          </div>
        </div>
        <WizardButtonPanel
          className="margin-big-top w3-border pad"
          onNext={(currentDebitor === null) ? null : this.setDebitor}
          onCancel={cancelOrder}
          nextLabel={`Modemkatalog`}
          saveLabel={`Bestellen`}
          onSave={isRegionComplete ? this.onSave : null}
          disableIfAbsent
          nameSuffix="ModemOrder"
        />
      </div>
    );
  }
}
