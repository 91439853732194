import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, ButtonLink } from "../../../../components/Buttons";
import {
  W3Dropdown,
  W3DropdownToggler,
  W3DropdownContent,
} from "../../../../components/W3Dropdown";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

export default class EmployeeDeactivationTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedTasks: PropTypes.array,
    closeTasks: PropTypes.func.isRequired,
    deactivateTasks: PropTypes.func.isRequired,
    deleteTasks: PropTypes.func.isRequired,
    history: PropTypes.object,
  };
  static defaultProps = {
    className: "",
    actions: {},
  };
  onClose = (event) => {
    if (event) event.preventDefault();
    const { selectedTasks, closeTasks } = this.props;
    console.log(">> closing", selectedTasks);
    this.taskDialog.show(
      true,
      {
        title: "Mitarbeiter deaktivieren: Erledigen",
        text: "Wollen Sie die ausgewählte Aufgaben wirklich als erledigt bestätigen?",
      },
      () => closeTasks(selectedTasks)
    );
  };
  onDeactivate = (event) => {
    if (event) event.preventDefault();
    const { selectedTasks, deactivateTasks } = this.props;
    console.log(">> closing", selectedTasks);
    this.taskDialog.show(
      true,
      {
        title: "Mitarbeiter deaktivieren: Deaktivieren und erledigen",
        text: "Wollen Sie die ausgewählte Mitarbeiter wirklich deaktivieren und die Aufgaben als erledigt bestätigen?",
      },
      () => deactivateTasks(selectedTasks)
    );
  };
  onDelete = (event) => {
    if (event) event.preventDefault();
    const { selectedTasks, deleteTasks } = this.props;
    console.log(">> closing", selectedTasks);
    this.taskDialog.show(
      true,
      {
        title: "Mitarbeiter deaktivieren: Löschen",
        text: "Wollen Sie diese Aufgaben wirklich löschen und so die Mitarbeiter als aktiviert wiederherstellen?",
      },
      () => deleteTasks(selectedTasks)
    );
  };
  render() {
    const { className, selectedTasks, disabled } = this.props;
    const isEnabled = selectedTasks.length > 0;
    if (disabled) {
      return null;
    }
    return (
      <div className={className}>
        <W3Dropdown className="modems dropdown-list-menu">
          <W3DropdownToggler as={PrimaryButton} size="small-">
            {`Optionen`}
          </W3DropdownToggler>
          <W3DropdownContent
            className="w3-bar-block w3-border"
            style={{ right: "0", width: "280px" }}
          >
            {/*<ButtonLink
              to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onClose}
              disabled={!isEnabled}
            >
              {`Aufgaben erledigen`}
            </ButtonLink>
            <a href="#" className="w3-bar-item separator">
              <div className="w3-border-top" style={{ margin: "0 -4px" }} />
            </a>*/}
            <ButtonLink
              to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onDeactivate}
              disabled={!isEnabled}
            >
              {`Deaktivieren und Aufgaben erledigen`}
            </ButtonLink>
            <a href="#" className="w3-bar-item separator">
              <div className="w3-border-top" style={{ margin: "0 -4px" }} />
            </a>
            <ButtonLink
              to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onDelete}
              disabled={!isEnabled}
            >
              {`Aufgaben löschen`}
            </ButtonLink>
          </W3DropdownContent>
        </W3Dropdown>
        <ConfirmationDialog ref={(ref) => (this.taskDialog = ref)} />
      </div>
    );
  }
}
