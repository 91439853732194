import React from "react";
import PropTypes from "prop-types";
import {
  PageHeight80,
  PageHeight30,
  PageHeightAuto,
  scrollToTop,
} from "../../../components/Utils";
import BookingReporterForm from "../components/forms/BookingReporterForm";
import BookingProductForm from "../components/forms/BookingProductForm";
import BookingClientForm from "../components/forms/BookingClientForm";
import { PrimaryButton } from "../../../components/Buttons";
import InPageNavMenu from "../../../components/InPageNavMenu";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadSalesWorkers } from "../actions/reporting";
import { initBookingForm, saveBooking } from "../actions/form";
import { buildSalesKDLs } from "../components/Utils";

const frontload = async (props) => {
  await props.init();
};
const mapStateToProps = (state) => {
  return {
    user: state.worker.currentWorker,
    companies: state.company.companies,
    workers: state.report.salesAgents,
    products: state.salesForm.bookingProducts,
    promos: state.salesForm.bookingPromos,
    vouchers: state.salesForm.bookingVouchers,
  };
};
const mapDispatchToProps = (dispatch) => ({
  init: (...args) => dispatch(initBookingForm(...args)),
  save: (...args) => dispatch(saveBooking(...args)),
  getWorkers: (...args) => dispatch(loadSalesWorkers(...args)),
});

class Booking extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    companies: PropTypes.array,
    workers: PropTypes.array,
    vouchers: PropTypes.array,
    products: PropTypes.array,
    promos: PropTypes.array,
    init: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    getWorkers: PropTypes.func.isRequired,
    className: PropTypes.string,
    showMenu: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    companies: [],
    workers: [],
    products: [],
    promos: [],
    vouchers: [],
  };
  componentDidMount() {
    scrollToTop();
  }
  reset = () => {
    // console.log('Booking.reset()');
    scrollToTop();
    this.reporterForm.reset();
    this.productForm.reset();
    this.clientForm.reset();
  };
  onSave = () => {
    const reporter = this.reporterForm.getModel();
    const product = this.productForm.getModel();
    const client = this.clientForm.getModel();
    const model = { reporter, product, client };
    if (!reporter || !product || !client) {
      return null;
    }
    this.props.save({ data: model }, this.reset);
  };
  renderMenu = () => {
    const links = [
      { isVisible: true, to: "melder", label: "Melder" },
      { isVisible: true, to: "produkt", label: "Produkt" },
      { isVisible: true, to: "kunde", label: "Kunde" },
    ];
    return (
      <InPageNavMenu
        className="fixed-form-menu"
        links={links}
        initialActiveId="melder"
      />
    );
  };
  renderBody = () => {
    const {
      user,
      companies,
      products,
      promos,
      vouchers,
      workers,
      getWorkers,
      disabled,
    } = this.props;
    const kdlCompanies = buildSalesKDLs(user, companies);
    console.log("Booking.render", this.props);
    return (
      <PageHeight80>
        <PageHeight30
          id="melder"
          className="w3-border margin-big-top pad-big"
          isUnderFormTitle
        >
          <h3 className="no-margin-top">{`Angaben zum Melder`}</h3>
          <BookingReporterForm
            ref={(ref) => (this.reporterForm = ref)}
            companies={kdlCompanies}
            workers={workers}
            getWorkers={getWorkers}
            disabled={disabled}
          />
        </PageHeight30>
        <PageHeight30
          id="produkt"
          className="w3-border margin-big-top pad-big"
          isUnderFormTitle
        >
          <h3 className="no-margin-top">{`Produkt`}</h3>
          <BookingProductForm
            ref={(ref) => (this.productForm = ref)}
            products={products}
            promos={promos}
            vouchers={vouchers}
            disabled={disabled}
          />
        </PageHeight30>
        <PageHeightAuto
          id="kunde"
          className="w3-border margin-big-top pad-big"
          isUnderFormTitle
        >
          <h3 className="no-margin-top">{`Angaben zum Kunden`}</h3>
          <BookingClientForm
            ref={(ref) => (this.clientForm = ref)}
            disabled={disabled}
          />
        </PageHeightAuto>
        <div className="margin-big-top w3-border pad-big w3-light-grey alg-right">
          {!disabled && (
            <PrimaryButton id="saveBookingBtn" onClick={this.onSave}>
              {"Hinweis senden"}
            </PrimaryButton>
          )}
        </div>
      </PageHeight80>
    );
  };
  render() {
    const { showMenu } = this.props;
    const body = this.renderBody();
    if (showMenu) {
      const menu = this.renderMenu();
      return (
        <div className="w3-row neg-margin-lr">
          <div className="w3-col m12 l10 pad-lr">{body}</div>
          <div className="w3-col l2 w3-show-large pad-lr">{menu}</div>
        </div>
      );
    }
    return body;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Booking)
);
