import React from "react";
import { AppNavFactory } from "./components";
import "./styles.css";

export default function MainNav({ tree }) {
  const { technik, sales, organisation } = tree || {};
  if (!technik || !sales || !organisation) {
    return null;
  }
  const AppNav = AppNavFactory(technik, sales, organisation);
  return (
    <div className="main-nav level-0">
      <AppNav isVisible />
    </div>
  );
}
