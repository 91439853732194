import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Label } from "./Inputs";

class FootprintForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      footPrint: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    footPrints: PropTypes.arrayOf(PropTypes.string),
    footPrintOptions: PropTypes.arrayOf(PropTypes.shape({})),
    namePrefix: PropTypes.string,
    required: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    label: "Footprint",
    model: {
      footPrint: "",
    },
    namePrefix: "",
    footPrints: [
      "VF West",
      "VF  Deutschland",
      "beide Footprints",
    ]
  };
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const { model } = props;
    const error = {};
    if (props.required) {
      error.footPrint = null;
    }
    return {
      footPrint: model.footPrint || null,
      error,
    };
  };
  validate = (noValidate) => {
    const { error, ...model } = this.state;
    let isValid = true;
    Object.keys(error).forEach((key) => {
      if (error[key] !== undefined && (model[key] === null || model[key] === "")) {
        error[key] = true;
        isValid = false;
      }
    });
    this.setState({ error });
    if (!noValidate && !isValid) {
      return null;
    }
    return { ...model };
  };
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  };
  onChange = (footPrint) => {
    this.setState({
      footPrint,
      error: {
        ...this.state.error,
        footPrint: null,
      },
    });
  };
  render() {
    const { className, disabled, label, footPrints, namePrefix, required } = this.props;
    const { footPrint, error } = this.state;
    const options = footPrints.map((value) => ({
      label: value,
      value: value,
    }));
    const formClassName = required ? `${error.footPrint ? "w3-border-red" : ""}` : "";
    return (
      <div className={`${className}`}>
        <Label htmlFor={`${namePrefix}footPrint`} noMargin>
          {label}
        </Label>
        <Dropdown
          id={`${namePrefix}footPrint`}
          className={`w3-block w3-border ${formClassName}`}
          value={footPrint}
          placeholder="Footprint auswählen"
          options={options}
          onChange={(event) => this.onChange(event.value)}
          disabled={disabled}
          // appendTo={document.getElementById(`${namePrefix}footPrint`)}
          showClear
        />
      </div>
    );
  }
}

export default FootprintForm;
