import React from 'react';
import { PrimaryButton } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { showSettings } from '../../actions/form';


// const mapStateToProps = (state) => ({
//   is_Admin: state.auth.is_Admin
// });
const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  showDialog: (...args) => dispatch(showSettings(...args)),
});

const MenuFactory = (key) => {
  return ({ showDialog, disabled }) => {
    // if (!is_Admin) {
    //   return null;
    // }
    return (
      <PrimaryButton onClick={() => showDialog({ key, disabled })}>
        {`Einstellungen`}
      </PrimaryButton>
    );
  };
};

export const BookingSettingsMenu = connect(mapStateToProps, mapDispatchToProps)(MenuFactory('booking'));
export const MNVTipSettingsMenu = connect(mapStateToProps, mapDispatchToProps)(MenuFactory('mnvTip'));
