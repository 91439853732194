import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Page from "../../../pages/Page";
import { getLinkParentUrl, PageTitle } from "../../../components/Utils";
import {
  getContentComponent,
  getContentTypeFromId,
  isProductPage,
  PageMenu,
  PageMetaData,
  PAGE_TYPES,
} from "../components/Utils";
import PageForm from "../components/PageForm";
import ProductPageForm from "../components/ProductPageForm";
import { FormMenu, SecondaryButton } from "../../../components/Buttons";
import ProductColumnContent from "../components/products/ProductColumnContent";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadContent,
  saveContent,
  createOrUpdatePagePath,
  savePathToArchive,
  showPathMovementDialog,
  editPageContent,
  cancelPageContent,
} from "../actions";
import ProductLegend from "../components/products/ProductLegend";
import { getTreeNode } from "../../MainNav";

import "../styles.css";

export default function StaticPageFactory(
  key,
  label,
  type = "default",
  subLinks = [],
  editValidators = [],
  linkData = {},
  treeData = { links: [], tree: {} }
) {
  // console.log('StaticPageFactory - Tree Node:', linkData, treeData.tree)
  const parentTreeNode = getTreeNode(
    getLinkParentUrl(linkData.url),
    treeData.tree
  );
  const nrSiblings = ((parentTreeNode || {}).children || []).length;
  let oldSiblings = 0,
    newSiblings = 0,
    isParentNoPage = false;
  if (/* isProductPage(type) &&  */ nrSiblings > 0) {
    isParentNoPage = parentTreeNode.data && parentTreeNode.data.noPage;
    oldSiblings = parentTreeNode.children.filter(
      (item) => item.data && item.data.color !== "black"
    ).length;
    newSiblings = parentTreeNode.children.filter(
      (item) => item.data && item.data.color === "black"
    ).length;
  }
  const treeNode = getTreeNode(linkData.url, treeData.tree);
  const nrChildren = (
    (!parentTreeNode ? linkData : treeNode || {}).children || []
  ).length;
  // const pageId = `page-${key.split('/').join('_')}`;
  const randomId = `${Math.random()}`.split(".").join("_");
  const pageId = `page-${randomId}`;

  class StaticPage extends React.Component {
    static propTypes = {
      user: PropTypes.object,
      disabled: PropTypes.bool,
      content: PropTypes.object,
      isEditing: PropTypes.bool,
      history: PropTypes.object.isRequired,
      getContent: PropTypes.func.isRequired,
      saveContent: PropTypes.func.isRequired,
      createOrUpdatePath: PropTypes.func.isRequired,
      saveToArchive: PropTypes.func.isRequired,
      moveSubTree: PropTypes.func.isRequired,
      editPage: PropTypes.func.isRequired,
      cancelPage: PropTypes.func.isRequired,
    };
    componentDidMount() {
      // console.log('StaticPage.componentDidMount()');
      this.scrollToTop();
    }
    componentDidUpdate() {}
    componentWillUnmount() {
      this.props.cancelPage();
    }
    onEdit = () => {
      this.props.editPage();
    };
    onCancel = () => {
      this.props.cancelPage();
    };
    onSave = () => {
      const data = this.form.getModel();
      if (!data) return null;
      // set creator and modifier id
      data.modified_by_user_id = this.props.user.id;
      if (!data.id) {
        data.created_by_user_id = this.props.user.id;
      }
      const model = { pathname: key, ...data };
      console.log("StaticPage.onSave()", model);
      this.props.saveContent(model);
      this.setState({ isEditing: false, content: null });
    };
    scrollToTop = () => {
      // window.scrollTo(0, this.header.offsetTop);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    generateProductContent = (savedContents) => {
      console.log("generateProductContent()", savedContents, subLinks);
      if (savedContents && savedContents.length > 0) {
        return savedContents;
      }
      let contents = [];
      if (subLinks.length > 0) {
        contents = [];
        subLinks.forEach((link) => {
          if (link.data.isVisible || this.state.isEditing) {
            contents.push({ body: JSON.stringify(link) });
          }
        });
      }
      return contents;
    };
    renderEditingDefault = (content) => {
      return (
        <PageForm
          pageId={pageId}
          className="clearfix w3-border no-border-top pad-big"
          ref={(ref) => (this.form = ref)}
          model={content}
        />
      );
    };
    renderEditingProducts = (content) => {
      // console.log("StaticPage.renderEditingProducts()", content);
      return (
        <div>
          <ProductPageForm
            key={Math.random()}
            className="clearfix w3-border no-border-top pad-big"
            ref={(ref) => (this.form = ref)}
            model={content}
            editPath={this.props.createOrUpdatePath}
            saveToArchive={this.props.saveToArchive}
          />
          <ProductLegend />
        </div>
      );
    };
    renderEditing = () => {
      let markup = null;
      switch (type) {
        case PAGE_TYPES.PRODUCT:
          markup = this.renderEditingProducts({
            contents: this.generateProductContent(this.props.content),
          });
          break;
        default:
          markup = this.renderEditingDefault(this.props.content);
          break;
      }
      return (
        <div id={pageId} className="clearfix pos-rel">
          <div className="clearfix w3-border-bottom w3-border-left w3-border-right pad bg-primary">
            <FormMenu
              className="w3-right"
              saveBtnName="saveStaticPageBtn2"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveHidden={isProductPage(type)}
              cancelBtnLabel={isProductPage(type) ? "Schließen" : "Abbrechen"}
            />
            <h3 className="no-margin pad-lft pad-sm-top">
              {"Seiteninhalt bearbeiten"}
            </h3>
          </div>
          {markup}
          <FormMenu
            className="w3-border margin-big-top pad"
            saveBtnName="saveStaticPageBtn"
            onSave={this.onSave}
            onCancel={this.onCancel}
            saveHidden={isProductPage(type)}
            cancelBtnLabel={isProductPage(type) ? "Schließen" : "Abbrechen"}
          />
        </div>
      );
    };
    renderDisplayDefault = (contents, created_date) => {
      const markup =
        contents &&
        contents.length > 0 &&
        contents.map((item, index) => {
          if (!item) {
            return null;
          }
          const { content_type_id, ...model } = item;
          const type = getContentTypeFromId(content_type_id);
          const Component = getContentComponent(type);
          if (!Component) {
            console.error(
              "<< Content Type Error: unknown content type",
              type,
              item
            );
            return null;
          }
          const createdDate = moment(
            created_date,
            "YYYY-MM-DDTHH:mm:ss:SSSZ"
          ).format("DD.MM.YYYY");
          return (
            <Component
              key={index}
              className="margin-big-top"
              model={model}
              pageCreated={createdDate}
            />
          );
        });
      return <div style={{ minHeight: "80px" }}>{markup}</div>;
    };
    renderDisplayProducts = (pContents, created_date) => {
      // console.log('StaticPage.renderDisplayProducts()', pContents, subLinks);
      let markup = null;
      const contents = this.generateProductContent(pContents);
      markup = contents.map((item, index) => {
        return (
          <div key={index} className="w3-col s4 pad-lr">
            <ProductColumnContent model={item} />
          </div>
        );
      });
      markup = <div className="w3-row neg-margin-lr">{markup}</div>;
      return (
        <div>
          {markup}
          <ProductLegend />
        </div>
      );
    };
    renderDisplay = (isEditable, content) => {
      const { createOrUpdatePath, saveToArchive, moveSubTree, history } =
        this.props;
      const {
        title,
        subtitle,
        contents,
        modified_by_user_name,
        modified_date,
        created_date,
      } = content;

      let pageTitle = title;
      let bodyMarkup = null;
      switch (type) {
        case PAGE_TYPES.PRODUCT:
          pageTitle = title || "Aktuelle Produkte";
          bodyMarkup = this.renderDisplayProducts(contents, created_date);
          break;
        default:
          bodyMarkup = this.renderDisplayDefault(contents, created_date);
          break;
      }
      const contentDates = [];
      (contents || []).forEach((item) => {
        item && contentDates.push(item.modified_date || item.created_date);
      });
      return (
        <Page title={pageTitle} description={pageTitle}>
          <PageTitle noMarginTop>
            <PageMenu
              contents={contents}
              isVisible={isEditable}
              type={type}
              linkData={linkData}
              onEditPageContent={this.onEdit}
              onEditPageLink={() =>
                createOrUpdatePath({
                  linkData,
                  config: {
                    type: isParentNoPage
                      ? PAGE_TYPES.PRODUCT
                      : PAGE_TYPES.DEFAULT,
                    isProductDetailsPage: true,
                    nrChildren: nrSiblings,
                    nrOld: oldSiblings,
                    nrNew: newSiblings,
                  },
                })
              }
              onCreatePageSubLink={() =>
                createOrUpdatePath({
                  parentUrl: key,
                  config: { nrChildren: nrChildren + 1 },
                })
              }
              onMoveToArchive={(...args) =>
                saveToArchive(...args, () =>
                  history.replace(getLinkParentUrl(linkData.url))
                )
              }
              onMoveSubTree={() =>
                moveSubTree({ linkData, treeLinks: treeData.links })
              }
            />
            <h3 className="no-margin pad-sm-top">{pageTitle}</h3>
          </PageTitle>
          <div className="w3-border no-border-top pad-big clearfix">
            <p className="no-margin">{subtitle}</p>
            {bodyMarkup}
            <div className="pad-big-top">
              <SecondaryButton
                className="w3-right"
                size="small"
                onClick={this.scrollToTop}
              >
                {`nach oben`}
              </SecondaryButton>
              <PageMetaData
                modifiedBy={modified_by_user_name}
                createdDate={created_date}
                modifiedDate={modified_date}
                contentsModified={contentDates}
              />
            </div>
          </div>
        </Page>
      );
    };
    render() {
      const { user, disabled, content, isEditing } = this.props;
      if (!content) return null;
      let isEditable = false;
      if (disabled === undefined) {
        if (editValidators && editValidators.length > 0) {
          let isValid = false;
          editValidators.forEach((func) => {
            isValid = isValid || func(user);
          });
          isEditable = isEditable || isValid;
        }
      } else {
        isEditable = !disabled;
      }
      console.log("StaticPage.render()", disabled, editValidators, isEditable);
      return (
        <div className="clearfix">
          <div ref={(ref) => (this.header = ref)}></div>
          {isEditing /* this.state.isEditing */
            ? this.renderEditing()
            : this.renderDisplay(isEditable, content)}
        </div>
      );
    }
  }
  const frontload = async (props) => await props.getContent({ key, label });
  const mapStateToProps = (state) => {
    return {
      user: state.auth.user,
      content: state.staticPage.html[key],
      isEditing: state.staticPage.isEditingPage,
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    getContent: (...args) => dispatch(loadContent(...args)),
    saveContent: (...args) => dispatch(saveContent(...args)),
    createOrUpdatePath: (...args) => dispatch(createOrUpdatePagePath(...args)),
    saveToArchive: (...args) => dispatch(savePathToArchive(...args)),
    moveSubTree: (...args) => dispatch(showPathMovementDialog(...args)),
    editPage: (...args) => dispatch(editPageContent(...args)),
    cancelPage: (...args) => dispatch(cancelPageContent(...args)),
  });
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false,
    })(StaticPage)
  );
}
