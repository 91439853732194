import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PageHeight40,
  PageTitle,
  ScrollableTableHolder,
  scrollToTop,
  TableEmptyMessage,
  toUIDate,
} from "../../../../components/Utils";
import { ProductFormMenu } from "../../../../components/Buttons";
import ItemListManager from "../../../../components/ItemListManager";
import FailedEmailTaskform from "../../components/user/FailedEmailTaskform";
import SalesTablePageReport from "../../../Reporting/components/SalesTablePageReport";

import { connect } from "react-redux";
import { closeFailedEmailTask, deactivateUser } from "../../actions/employee";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    failedEmailTasks: state.task.failedEmailAddresses,
  };
};
const mapDispatchToProps = (dispatch) => ({
  closeTask: (...args) => dispatch(closeFailedEmailTask(...args)),
  deactivateTaskUser: (...args) => dispatch(deactivateUser(...args)),
});

class FailedEmailTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    rows: PropTypes.number,
    failedEmailTasks: PropTypes.array,
    failedEmailTaskType: PropTypes.string,
    closeTask: PropTypes.func.isRequired,
    deactivateTaskUser: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
    rows: 10,
    failedEmailTasks: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    };
  }
  componentDidMount() {
    scrollToTop();
  }
  onSave = (onHide) => {
    const task = this.form.getModel();
    if (!task) return;
    const { closeTask } = this.props;
    console.log(`>> saving user task...`, task);
    closeTask({ task }, onHide);
  };
  onDeactivate = (task, onHide) => {
    const { deactivateTaskUser } = this.props;
    if (task.origin !== "userprofile") {
      console.error(" ERROR: invalid Task origin!", task);
      return;
    }
    console.log(`>> deactivating user task...`, task);
    this.confirmDialog.show(
      true,
      {
        title: `Benutzer deaktivieren`,
        text: `
          Die Deaktivierung von ${task.username} mitsamt allen bestehenden IT-Zugängen wird hiermit beantragt.  
          Wollen Sie wirklich fortfahren?
        `,
      },
      () => deactivateTaskUser({ task }, onHide)
    );
  };
  render() {
    const {
      className,
      isLoading,
      rows,
      failedEmailTasks,
      failedEmailTaskType,
    } = this.props;
    const isCompany = failedEmailTaskType === "company";
    const tasks = failedEmailTasks.filter(
      (item) => item.origin === `${isCompany ? "company" : "user"}profile`
    );
    // console.log("FailedEmailTaskList.render()", this.props, "tasks:", tasks);
    const columnStyle = {
      verticalAlign: "top",
      paddingTop: "16px",
      paddingBottom: "16px",
    };
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items.map((item) => ({
        ...item,
        sendDateUI: toUIDate(item.sent_date || item.created_date),
        sendDate: toUIDate(item.sent_date || item.created_date, "YYYY-MM-DDTHH:mm:ss:SSSZ", "YYYY.MM.DD"),
        requester: `${item.requester || ""}`.split(",").join(" "),
        requestDateUI: toUIDate(item.request_date || item.sent_date, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
        requestDate: toUIDate(item.request_date || item.sent_date, "YYYY-MM-DDTHH:mm:ss:SSSZ", "YYYY.MM.DD"),
      }));
      return (
        <ScrollableTableHolder>
          <DataTable
            value={taskItems}
            paginator={taskItems.length > rows}
            rows={rows}
            first={this.state.first}
            onPage={(event) => {
              this.setState({ first: event.first });
              scrollToTop();
            }}
            paginatorLeft={
              <SalesTablePageReport
                className="pad-lft"
                totalRecords={taskItems.length}
                filteredRecords={taskItems.length}
                first={this.state.first}
                rows={rows}
                emptyText="Keine Unversandte E-Mails"
                itemName="Unversandte E-Mail"
                itemNamePlural="Unversandte E-Mails"
              />
            }
            removableSort
            sortMode="multiple"
            emptyMessage={
              isLoading ? null : (
                <TableEmptyMessage itemNamePlural="Unversandte E-Mails" />
              )
            }
            scrollable
          >
            {/* {!isCompany && ( */}
            <Column
              field="parentCompanyName"
              header={"Vertragspartner"}
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "14em" }}
            />
            {/* )} */}
            <Column
              field="username"
              header={isCompany ? "Firma" : "Benutzer"}
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "12em" }}
            />
            <Column
              field="to_emails"
              header="E-Mail Adresse"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "18em" }}
            />
            <Column
              field="subject"
              header="Titel"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "14em" }}
            />
            <Column
              field="sendDate"
              header="Verschickt am"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "10em" }}
              body={item => item.sendDateUI}
            />
            <Column
              field="requester"
              header="Beantrager"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "12em" }}
            />
            <Column
              field="requestDate"
              header="Erstelldatum"
              sortable={taskItems.length > 1}
              filter={taskItems.length > rows}
              filterMatchMode="contains"
              style={{ ...columnStyle, width: "10em", textAlign: "center" }}
              body={item => item.requestDateUI}
            />
            <Column
              body={actionTemplate}
              style={{
                verticalAlign: "top",
                textAlign: "center",
                width: "4.5em",
              }}
            />
          </DataTable>
        </ScrollableTableHolder>
      );
    };

    const renderItemForm = (item, onHide) => {
      const task = item;
      return (
        <div>
          <FailedEmailTaskform ref={(ref) => (this.form = ref)} model={task} />
          <ProductFormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{
              marginLeft: "-1em",
              marginRight: "-1em",
              marginBottom: "-1em",
            }}
            onSave={() => this.onDeactivate(task, onHide)}
            saveBtnName="deactivateFailedEmailTaskBtn"
            saveBtnLabel="Deaktivieren"
            saveHidden={isCompany}
            onSaveAndClose={() => this.onSave(onHide)}
            saveAndCloseBtnName="saveFailedEmailTaskBtn"
            saveAndCloseBtnLabel="Speichern"
            onCancel={onHide}
          />
        </div>
      );
    };
    const getItemFormTitle = (task) => {
      const { origin } = task || {};
      const type = origin
        ? origin === "companyprofile"
          ? "Firmen"
          : "Benutzer"
        : "";
      return <h3 className="no-margin">{`${type}-E-Mail Adresse ändern`}</h3>;
    };
    const title = (
      <PageTitle>
        <h3 className="no-margin pad-btm">{`Unversandte E-Mails: ${
          isCompany ? "Firmenprofile" : "Benutzerprofile"
        }`}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={tasks}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`Benutzer`}
          itemPanelStyle={{ width: "40vw", maxWidth: "480px" }}
        />
        <ConfirmationDialog ref={(ref) => (this.confirmDialog = ref)} />
      </PageHeight40>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FailedEmailTaskList);
