import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadModemOrder, setDebitor, saveModemOrder } from "../../actions";
import TaskDebitorListContent from "../../components/task/TaskDebitorListContent";

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getModemOrder({ id, isTask: true });
};
const mapStateToProps = (state) => {
  return {
    modemOrder: state.modem.modemOrder,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getModemOrder: (...args) => dispatch(loadModemOrder(...args)),
  setDebitor: (...args) => dispatch(setDebitor(...args)),
  saveModemOrder: (...args) => dispatch(saveModemOrder(...args)),
});

class TaskDebitorList extends React.Component {
  static propTypes = {
    // mapped from state
    modemOrder: PropTypes.array,
    // actions
    getModemOrder: PropTypes.func.isRequired,
    setDebitor: PropTypes.func.isRequired,
    saveModemOrder: PropTypes.func.isRequired,
    // from parent
    isTask: PropTypes.bool,
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string,
  };
  componentDidUpdate() {
    const { modemOrder, match, setDebitor, history } = this.props;
    if (!modemOrder || modemOrder.length === 0) {
      return;
    }
    // conditional forwarding ...
    const id = match.params.id;
    const debitorId = match.params.debitorId;
    // console.log('TaskDebitorList.render()', modemOrder, debitorId);
    if (debitorId !== undefined) {
      const modem = modemOrder.find(
        (item) => `${item.debitor.id}` === `${debitorId}`
      );
      if (modem) {
        // console.log(`<<:: Debitor set (${debitorId}) => skipping to next page ...`);
        modem && setDebitor(modem.debitor);
        history.replace(
          `/wizard/aufgabe/bestellung/modem/${id}/debitor/${debitorId}/katalog`
        );
      }
    }
  }
  render() {
    const { modemOrder } = this.props;
    if (!modemOrder || modemOrder.length === 0) {
      return null;
    }
    return null;
    // return (<TaskDebitorListContent {...this.props}/>);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(TaskDebitorList)
);
