import React from "react";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadOpenTasks } from "../actions";

const frontload = async (props) => {
  await props.getOpenTasks(props.taskType);
};

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getOpenTasks: (...args) => dispatch(loadOpenTasks(...args)),
});

const TasksFetcher = ({}) => {
  return <></>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(TasksFetcher)
);
