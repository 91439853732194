import React from "react";
import PropTypes from "prop-types";
import { PageHeight40 } from "../../../components/Utils"

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  reset,
  loadDevices,
  cancelUserDevice,
  loadUserDeviceCompanies,
  loadUserDeviceWorkers,
} from "../actions";

import UserDeviceForm from "../components/UserDeviceForm";
import UserDeviceFormMenu from "../components/UserDeviceFormMenu";

const frontload = async (props) => {
  console.log("UserDeviceFormHolder.frontLoad() props:", props);
  const { currentWorkerCompany: company } = props;
  const deviceManagerCompanyId = company ? company.contractGiverId : undefined;
  await props.getDevices();
  await props.getCompanies({
    isManager: true,
    companyId: deviceManagerCompanyId,
  });
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.device.loading,
    devices: state.device.devices,
    deviceManagerCompanies: state.device.deviceManagerCompanies,
    deviceManagers: state.device.deviceManagers,
    companies: state.device.deviceUserCompanies,
    workers: state.device.deviceUsers,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDevices: (...args) => dispatch(loadDevices(...args)),
  cancelUserDevice: (...args) => dispatch(cancelUserDevice(...args)),
  getCompanies: (...args) => dispatch(loadUserDeviceCompanies(...args)),
  getWorkers: (...args) => dispatch(loadUserDeviceWorkers(...args)),
  resetAll: (...args) => dispatch(reset(...args)),
});

class UserDeviceFormHolder extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    userDevice: PropTypes.shape({}),
    currentWorker: PropTypes.shape({}),
    currentWorkerCompany: PropTypes.shape({}),
    disabled: PropTypes.bool,
    onSave: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    saveBtnLabel: PropTypes.string,
    updateBtnLabel: PropTypes.string,
    deleteBtnLabel: PropTypes.string,
    changeBtnLabel: PropTypes.string,
    isTask: PropTypes.bool,
    isUserTask: PropTypes.bool,

    isLoading: PropTypes.bool,
    devices: PropTypes.array,
    deviceManagerCompanies: PropTypes.array,
    deviceManagers: PropTypes.array,
    companies: PropTypes.array,
    workers: PropTypes.array,

    getDevices: PropTypes.func.isRequired,
    cancelUserDevice: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getWorkers: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
  };
  componentWillUnmount() {
    this.props.cancelUserDevice();
  }
  getModel = () => {
    return this.form.getModel();
  };
  onSave = (saveOnly) => {
    const { onSave } = this.props;
    onSave && onSave(this.getModel(), saveOnly);
  };
  onUpdate = () => {
    const { onUpdate } = this.props;
    onUpdate && onUpdate(this.getModel());
  };
  onDelete = () => {
    const { onDelete } = this.props;
    onDelete && onDelete(this.getModel());
  };
  onMacAddressChanged = (value) => {
    if (this.menu) this.menu.onMacAddressChanged(value);
  };
  render() {
    const {
      className,
      isLoading,
      userDevice,
      currentWorker,
      disabled,
      onCancel,
      devices,
      deviceManagerCompanies,
      deviceManagers,
      companies,
      workers,
      getWorkers,
      getCompanies,
      resetAll,
      isTask,
      isUserTask,
      saveBtnLabel,
      updateBtnLabel,
      deleteBtnLabel,
      changeBtnLabel,
    } = this.props;
    // console.log('UserDeviceFormHolder.render()', this.props);
    if (
      disabled &&
      (!deviceManagerCompanies || deviceManagerCompanies.length === 0)
    ) {
      return null;
    }
    if (!disabled && userDevice && userDevice.id) {
      if (deviceManagerCompanies && deviceManagerCompanies.length === 0) {
        deviceManagerCompanies.push({
          id: userDevice.userInChargeCompanyId,
          name: userDevice.userInChargeCompanyName,
        });
      }
      if (deviceManagers && deviceManagers.length === 0) {
        deviceManagers.push({
          id: userDevice.userInChargeId,
          name: userDevice.userInCharge,
        });
      }
      if (companies && companies.length === 0) {
        companies.push({
          id: userDevice.userPrimaryId,
          name: userDevice.userPrimary,
        });
        if (userDevice.userSecondaryId) {
          companies.push({
            id: userDevice.userSecondaryId,
            name: userDevice.userSecondary,
          });
        }
      }
    }
    return (
      <PageHeight40 className={`user-device-form-holder ${className}`}>
        <UserDeviceForm
          ref={(ref) => (this.form = ref)}
          isLoading={isLoading}
          model={userDevice}
          devices={devices}
          deviceManagerCompanies={deviceManagerCompanies}
          deviceManagers={deviceManagers}
          companies={companies}
          users={workers}
          disabled={disabled}
          currentWorker={currentWorker}
          getCompanies={getCompanies}
          getWorkers={getWorkers}
          reset={resetAll}
          onMacAddressChanged={this.onMacAddressChanged}
          isTask={isTask}
        />
        <UserDeviceFormMenu
          ref={(ref) => (this.menu = ref)}
          className="w3-border margin-big-top pad-big w3-light-grey"
          style={{
            marginLeft: "-1em",
            marginRight: "-1em",
            marginBottom: "-1em",
          }}
          onSave={() => this.onSave()}
          onUpdate={() => this.onUpdate()}
          onChange={() => this.onSave(true)}
          onDelete={() => this.onDelete()}
          onCancel={onCancel}
          saveBtnName="saveUserDeviceBtn"
          updateBtnName="updateUserDeviceBtn"
          changeBtnName="changeUserDeviceBtn"
          deleteBtnName="deleteUserDeviceBtn"
          changeDisabled={false}
          deleteDisabled={false}
          userDevice={userDevice}
          disabled={disabled}
          cancelBtnLabel={disabled ? "Schließen" : undefined}
          isTask={isTask}
          isUserTask={isUserTask}
          saveBtnLabel={saveBtnLabel}
          updateBtnLabel={updateBtnLabel}
          deleteBtnLabel={deleteBtnLabel}
          changeBtnLabel={changeBtnLabel}
        />
      </PageHeight40>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(UserDeviceFormHolder)
);
