import React from 'react';
import PropTypes from 'prop-types';

let ReactQuill = null;
import 'react-quill/dist/quill.snow.css';
let Quill = null;
let Font = null;
try {
  ReactQuill = require('react-quill').default; 
  Quill = ReactQuill.Quill;
  Font = Quill.import("formats/font");
  console.log("ReactQuill Fonts:", Font);
  Font.whitelist = [
    "Lato",
    "Montserrat",
    "Open Sans",
    "Raleway",
    "Roboto",
    "Rubik",
    "Sans Serif",
    "Ubuntu",
    "Verdana",
    "Vodafone"
  ];
  Quill.register(Font, true);
} catch(error) {
  console.error(error.message);
}
// if ((typeof window != 'undefined' && window.document)) {
// import ReactQuill from 'react-quill';

export default class RichTextEditor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    colors: PropTypes.array,
    showFonts: PropTypes.bool,
    fonts: PropTypes.arrayOf(PropTypes.string),
    toolbar: PropTypes.array,
    disabled: PropTypes.bool,
  }
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ value: props.value });
  }
  getValue = () => {
    return this.state.value;
  }
  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange({ target: { value }});
    } else {
      this.setState({ value });
    }
  }
  renderQuill = (restProps, parentId) => {
    if (!ReactQuill) {
      return (<textarea>{this.state.value}</textarea>);
    }
    const colors = this.props.colors || [];
    const modules = {
      toolbar: [
        [{ 'header': [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': colors }, { 'background': colors }],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
      clipboard: {
        matchVisual: false
      }
    };
    let formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'color', 'background',
      'list', 'bullet', 'indent', 'align',
      'link', 'image', 'video'
    ];
    if (this.props.showFonts) {
      const fonts = this.props.fonts || (Font ? Font.whitelist : []);
      modules.toolbar = [
        [{ font: fonts }]
      ].concat(modules.toolbar);
      formats = ['font'].concat(formats);
    }
    return (
      <ReactQuill
        {...restProps}
        modules={modules}
        formats={formats}
        value={this.state.value}
        onChange={this.onChange}
        // bounds={`#${parentId }`}
      />
    );
  }
  render() {
    const { className, ...restProps } = this.props;
    const parentId = (`rte-${Math.random()}`).split('.').join('-');
    return (
      <div id={parentId} className={`rich-text-editor pos-rel ${className}`}>
        {this.renderQuill(restProps, parentId)}
      </div>
    );
  }
}


export class SimpleTextEditor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    colors: PropTypes.array,
    showFonts: PropTypes.bool,
    fonts: PropTypes.arrayOf(PropTypes.string),
    toolbar: PropTypes.array
  }
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ value: props.value });
  }
  getValue = () => {
    return this.state.value;
  }
  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange({ target: { value }});
    } else {
      this.setState({ value });
    }
  }
  renderQuill = (restProps, parentId) => {
    if (!ReactQuill) {
      return (<textarea>{this.state.value}</textarea>);
    }
    const colors = this.props.colors || [];
    const modules = {
      toolbar: [
        [{ 'header': [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'blockquote'],
        [{ 'color': colors }, { 'background': colors }],
        [{'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['clean']
      ],
      clipboard: {
        matchVisual: false
      }
    };
    let formats = [
      'header',
      'bold', 'italic', 'underline', 'blockquote',
      'color', 'background',
      'list', 'bullet', 'indent', 'align'
    ];
    if (this.props.showFonts) {
      const fonts = this.props.fonts || (Font ? Font.whitelist : []);
      modules.toolbar = [
        [{ font: fonts }]
      ].concat(modules.toolbar);
      formats = ['font'].concat(formats);
    }
    return (
      <ReactQuill
        {...restProps}
        modules={modules}
        formats={formats}
        value={this.state.value}
        onChange={this.onChange}
        // bounds={`#${parentId }`}
      />
    );
  }
  render() {
    const { className, ...restProps } = this.props;
    const parentId = (`rte-${Math.random()}`).split('.').join('-');
    return (
      <div id={parentId} className={`rich-text-editor pos-rel ${className}`}>
        {this.renderQuill(restProps, parentId)}
      </div>
    );
  }
}


export class ImageEditor extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ value: props.value });
  }
  getValue = () => {
    return this.state.value;
  }
  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange({ target: { value }});
    } else {
      this.setState({ value });
    }
  }
  renderQuill = (restProps, parentId) => {
    if (!ReactQuill) {
      return (<textarea>{this.state.value}</textarea>);
    }
    const modules = {
      toolbar: [
        ['link', 'image']
      ],
      clipboard: {
        matchVisual: false
      }
    };
    const formats = [ 'link', 'image', 'video' ];
    return (
      <ReactQuill
        {...restProps}
        modules={modules}
        formats={formats}
        value={this.state.value}
        onChange={this.onChange}
        // bounds={`#${parentId }`}
      />
    );
  }
  render() {
    const { className, ...restProps } = this.props;
    const parentId = (`rte-${Math.random()}`).split('.').join('-');
    return (
      <div id={parentId} className={`rich-text-editor pos-rel ${className}`}>
        {this.renderQuill(restProps, parentId)}
      </div>
    );
  }
}