import React from "react";
import PropTypes from "prop-types";
import { PrimaryButtonLink, PrimaryButton } from "../../../components/Buttons";
import { connect } from "react-redux";
import { exportCompanies } from "../actions";
import { Organisation } from "../../Permissions/resources";
import {
  isResourceEditable,
  isResourceReadable,
} from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  permissions: state.permissions.permissionMap,
});
const mapDispatchToProps = (dispatch) => ({
  getCompaniesAsCSV: (...args) => dispatch(exportCompanies(...args)),
});

class CompanyListMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    permissions: PropTypes.shape({}),
    getCompaniesAsCSV: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
  };
  onExportCompanies = (event) => {
    if (event) event.preventDefault();
    this.props.getCompaniesAsCSV();
  };
  render() {
    const { className, permissions } = this.props;
    const isReadable = isResourceReadable(
      Organisation.CompanyWizard.BasicInfo,
      permissions
    );
    const isEditable = isResourceEditable(
      Organisation.CompanyWizard.BasicInfo,
      permissions
    );
    return (
      <div className={`company list-menu ${className}`}>
        {isReadable && isEditable && (
          <PrimaryButtonLink
            to={`/wizard/organisation/firma/neu`}
            name="addCompanyBtn"
          >
            {`Servicepartner hinzufügen`}
          </PrimaryButtonLink>
        )}
        <PrimaryButton
          className="margin-lft"
          name="exportCompanyBtn"
          onClick={this.onExportCompanies}
        >
          {"CSV Exportieren"}
        </PrimaryButton>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyListMenu);
