import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, ButtonLink } from '../../../../components/Buttons';
import { W3Dropdown, W3DropdownToggler, W3DropdownContent } from '../../../../components/W3Dropdown';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

export default class EmployeeCompanyChangeTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedTasks: PropTypes.array,
    closeTasks: PropTypes.func.isRequired,
    // exportTasks: PropTypes.func.isRequired,
    history: PropTypes.object,
  }
  static defaultProps = {
    className: '',
    actions: {},
  }
  onCloseTasks = (event) => {
    if (event) event.preventDefault();
    const { selectedTasks, closeTasks } = this.props;
    console.log(">> closing", selectedTasks)
    this.taskDialog.show(
      true,
      {
        title: 'Benutzeraufgaben schließen',
        // text: `
        //   Durch diese Aktion werden für die AD-Zugänge der zugehörigen Mitarbeiter aller ausgewählten Änderungen 
        //   Löschungen beantragt und die Aufgaben geschlossen. Wollen Sie wirlich fortfahren?
        // `,
        text: "Wollen Sie die ausgewählte Aufgaben wirklich schlißen"
      },
      () => closeTasks({ tasks: selectedTasks })
    );
  }
  onExport = (event) => {
    if (event) event.preventDefault();
    const { selectedTasks } = this.props;
    let counter = 0;
    if (selectedTasks && selectedTasks.length > 0) {
      counter += selectedTasks.length;
    }
    this.taskDialog.show(
      true,
      {
        title: `Kontakdatenänderungen exportieren`,
        text: `
          Alle ${counter > 0 ? 'ausgewählte ' : ''}Kontakdatenänderungen werden für die Weiterverarbeitung vorbereitet und als CSV exportiert.  
          Wollen Sie fortfahren?
        `
      },
      () => this.props.exportTasks({})
    );
  }
  render() {
    const { className, selectedTasks } = this.props;
    // const isExportable = true;
    const isCloseable = (selectedTasks.length > 0);

    return (
      <div className={className}>
        <W3Dropdown className="modems dropdown-list-menu">
          <W3DropdownToggler
            as={PrimaryButton}
            size="small-"
          >
            {`Optionen`}
          </W3DropdownToggler>
          <W3DropdownContent
            className="w3-bar-block w3-border"
            style={{right: '0', width: '280px'}}
          >
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onCloseTasks}
              disabled={!isCloseable}>
                {`Aufgaben schließen`}
            </ButtonLink>
            {/* <a href="#" className="w3-bar-item separator">
              <div className="w3-border-top" style={{margin: '0 -4px'}}/>
            </a>
            <ButtonLink to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={this.onExport}
              disabled={!isExportable}>
                {`Änderungen exportieren (CSV)`}
            </ButtonLink> */}
          </W3DropdownContent>
        </W3Dropdown>
        <ConfirmationDialog ref={ref => this.taskDialog = ref}/>
      </div>
    );
  }
}
