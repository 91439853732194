import * as actionTypes from './actions';
import isArray from 'lodash/isArray';
import { openFile, notifySuccess,/* , isMasterCompany */ 
notifyError} from '../../components/Utils';
import { LOAD_CURRENT_USER_SESSION_SUCCESS, LOGOUT_SUCCESS } from '../../redux/actions/auth';

const initialState = {
  companies: [],
  contractors: [],
  company: null,
  wizardCompany: null,
  loaded: false,
  header: {},
  techKeys: [
    // 'A04 (NE4)', 'HSSL (NE4)', 'B01 (NE3)', 'B02 (NE3)', 'Sonstige',
    // 'D/U (NE4) - Reg4', 'AX (NE3) - Reg1', 'DHS (NE3) - Reg4', 'HHS (NE3) - Reg7',
    // 'KHS (NE3) - Reg9', 'BX (NE3) - Reg2', 'KU (NE4) - Reg9', 'CHS (NE3) - Reg3'
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_COMPANY_TYPES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_COMPANY_TYPES_SUCCESS: {
      // console.log('CompanyType:', action);
      // const wizardTypes = action.result.filter(type => {
      //   let isValid = true;
      //   if (action.companyType) {
      //     isValid = action.isMasterCompany ? (type.id > action.companyType) : (type.id > 3);
      //   } else {
      //     isValid = action.isMasterCompany ? true : (type.id > 1);
      //   }
      //   return isValid;
      // });
      // console.log('REDUX REDUCER: company types', action.result, 'wizardTypes', wizardTypes);
      return {
        ...state,
        loading: false,
        types: [ ...action.result ],
        wizardTypes: action.result.filter(type => (type.id > 1)),
      };
    }
    case actionTypes.LOAD_COMPANY_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        types: null,
        error: action.result
      };
    // load companies
    case actionTypes.LOAD_COMPANIES:
      return {
        ...state,
        header: {},
        loading: true
      };
    case actionTypes.LOAD_COMPANIES_SUCCESS: {
      // const companyList = action.result;
      // const companies = [];
      // const map = {};
      // const addCompany = (company) => {
      //   if (!map[company.id]) {
      //     companies.push(company);
      //     map[company.id] = true;
      //   }
      // }
      // companyList.forEach(company => {
      //   // companies.push(company);
      //   addCompany(company);
      //   (company.contractors || []).forEach(contractor => {
      //     addCompany({ ...contractor, contractGiver: contractor.contractGiver || company.name });
      //     // companies.push({ ...contractor, contractGiver: company.name });
      //   })
      // });
      return {
        ...state,
        loading: false,
        header: action.header,
        companies: action.result // companies //
      };
    }
    case actionTypes.LOAD_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        header: {},
        companies: [],
        error: action.result
      };
    // load contractors
    case actionTypes.LOAD_CONTRACTORS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_CONTRACTORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        contractors: action.result
      };
    }
    case actionTypes.LOAD_CONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        contractors: [],
        error: action.result
      };
    // load company
    case actionTypes.LOAD_COMPANY: {
      const attributeName = action.wizard ? 'wizardCompany' : 'company';
      return {
        ...state,
        [attributeName]: null,
        loading: true
      };
    }
    case actionTypes.LOAD_COMPANY_SUCCESS:
      const attributeName = action.wizard ? 'wizardCompany' : 'company';
      console.log('<<', attributeName, 'loaded', action.result);
      return {
        ...state,
        loading: false,
        [attributeName]: isArray(action.result) ? action.result[0] : action.result
      };
    // company loaded by user session
    case LOAD_CURRENT_USER_SESSION_SUCCESS:
      console.log('<<::REDUX REDUCER - USER SESSION: Company Loaded', action.result.company);
      state.company = isArray(action.result.company) ? action.result.company[0] : action.result.company;
      return state;
      // return {
      //   ...state,
      //   company: isArray(action.result.company) ? action.result.company[0] : action.result.company
      // };
    case actionTypes.LOAD_COMPANY_FAILURE: {
      const attributeName = action.wizard ? 'wizardCompany' : 'company';
      return {
        ...state,
        loading: false,
        [attributeName] : null,
        error: action.result
      };
    }
    // change company
    case actionTypes.CHANGE_COMPANY:
      return {
        ...state,
        wizardCompany: action.result
      }
    // save company
    case actionTypes.SAVE_COMPANY:
        return {
          ...state,
          saving: true
        };
    case actionTypes.SAVE_COMPANY_SUCCESS:
      notifySuccess({
        summary: 'Firmenprofile',
        detail: `Die Firma ${action.company.name} wurde erfolgreich ${action.company.id ? 'aktualisiert' : 'angelegt'}!`
      });
      return {
        ...state,
        saving: false,
        wizardTypes: null,
        wizardCompany: null
      };
    case actionTypes.SAVE_COMPANY_FAILURE:
      notifyError({
        summary: 'Firmenprofile',
        detail: `Die Firma ${action.company.name} konnte nicht ${action.company.id ? 'aktualisiert' : 'angelegt'} werden!`
      });
      return {
        ...state,
        saving: false,
        error: action.result
      };
    // cancel company
    case actionTypes.CANCEL_COMPANY_WIZARD:
      return {
        ...state,
        wizardTypes: null,
        wizardCompany: null
      };
    // export companies
    case actionTypes.EXPORT_COMPANIES:
      return {
        ...state,
        exporting: true,
      }
    case actionTypes.EXPORT_COMPANIES_SUCCESS: {
      openFile(action.result.filePath);
      return {
        ...state,
        exporting: false
      }
    }
    case actionTypes.EXPORT_COMPANIES_FAILURE:
      return {
        ...state,
        exporting: false,
        error: action.error
      }
    // export subcompanies
    case actionTypes.EXPORT_SUBCOMPANIES:
      return {
        ...state,
        exporting: true,
      }
    case actionTypes.EXPORT_SUBCOMPANIES_SUCCESS: {
      openFile(action.result.filePath);
      return {
        ...state,
        exporting: false
      }
    }
    case actionTypes.EXPORT_SUBCOMPANIES_FAILURE:
      return {
        ...state,
        exporting: false,
        error: action.error
      };
    // (de)activate service partner
    case actionTypes.ACTIVATE_COMPANY:
    case actionTypes.DEACTIVATE_COMPANY:
      return {
        ...state,
        loading: true
      };
    case actionTypes.ACTIVATE_COMPANY_SUCCESS:
    case actionTypes.DEACTIVATE_COMPANY_SUCCESS:
      notifySuccess({
        summary: `Servicepartner ${action.activate ? '' : 'de'}aktivieren`,
        detail: `Der Servicepartner ${action.company.name} wurde erfolgreich ${action.activate ? '' : 'de'}aktiviert!`
      });
      return {
        ...state,
        loading: false
      }
    case actionTypes.ACTIVATE_COMPANY_FAILURE:
    case actionTypes.DEACTIVATE_COMPANY_FAILURE: {
      // const { message } = action.error;
      let suffix = "";
      // switch (message) {
      //   case "label.company.is.not.activated":
      //     suffix = " denn seine Firma ist deaktiviert";
      //     break;
      //   default:
      //     break;
      // }
      notifyError({
        summary: `Servicepartner ${action.activate ? '' : 'de'}aktivieren`,
        detail: `Der Servicepartner ${action.company.name} konnte nicht ${action.activate ? '' : 'de'}aktiviert werden${suffix}!`
      });
      return {
        ...state,
        loading: false
      }
    }
    // delete service partner
    case actionTypes.DELETE_COMPANY:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.DELETE_COMPANY_SUCCESS: {
      notifySuccess({
        summary: `Servicepartner löschen`,
        detail: `Der Servicepartner ${action.company.name} wurde erfolgreich gelöscht!`
      });
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.DELETE_COMPANY_FAILURE:
      notifyError({
        summary: `Servicepartner löschen`,
        detail: `Der Servicepartner ${action.company.name} konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        loading: false,
      };
    // log out
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}
