import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadCurrentOrderDebitorDetails,
  saveOrderDebitorChanges,
} from "../../actions";
import TaskModemCatalogContent from "../../components/task/TaskModemCatalogContent";

const frontload = async (props) => {
  // const id = props.match.params.id;
  // const debitorId = match.params.debitorId;
  const onFailure = () => {
    props.history.replace(`/aufgaben/modems`);
  };
  await props.getOrderDebitorDetails({ onFailure, isTask: true });
};
const mapStateToProps = (state) => {
  return {
    loadingDebitor: state.modem.loadingDebitor,
    selectedDebitor: state.modem.selectedDebitor,
    orderDebitorDetails: state.modem.orderDebitorDetails,
    newModems: state.modem.newModems,
    modems: state.modem.catalogModems,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrderDebitorDetails: (...args) =>
    dispatch(loadCurrentOrderDebitorDetails(...args)),
  saveOrderDebitorChanges: (...args) =>
    dispatch(saveOrderDebitorChanges(...args)),
});

class TaskModemCatalog extends React.Component {
  static propTypes = {
    // mapped from state
    loadingDebitor: PropTypes.bool,
    orderDebitorDetails: PropTypes.object,
    selectedDebitor: PropTypes.object,
    newModems: PropTypes.array,
    modems: PropTypes.array,
    // actions
    getOrderDebitorDetails: PropTypes.func.isRequired,
    saveOrderDebitorChanges: PropTypes.func.isRequired,
    // from parent
    match: PropTypes.object,
    history: PropTypes.object,
    cancelOrder: PropTypes.func,
    className: PropTypes.string,
  };
  render() {
    const { loadingDebitor, orderDebitorDetails, selectedDebitor, modems } =
      this.props;
    if (loadingDebitor || !orderDebitorDetails || !selectedDebitor || !modems) {
      return null;
    }
    return <TaskModemCatalogContent {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(TaskModemCatalog)
);
