import React from "react";
// import PropTypes from 'prop-types';
import { PrimaryButtonLink } from "../../../components/Buttons";
import {
  MODEM_ORDER_STATUS_IDS,
  MODEM_ORDER_STATUS_LABELS,
  notifyError,
} from "../../../components/Utils";

export const ModemMenu = ({ className, disabled }) => {
  if (disabled) {
    return null;
  }
  return (
    <div className={`modems list-menu ${className}`}>
      <PrimaryButtonLink
        name="newModemBtn"
        to={`/wizard/produkte/modem/neu`}
      >{`Modem hinzufügen`}</PrimaryButtonLink>
    </div>
  );
};

export const ModemOrderMenu = ({ className, disabled }) => {
  if (disabled) {
    return null;
  }
  return (
    <div className={`modems list-menu ${className}`}>
      <PrimaryButtonLink
        name="newModemOrderBtn"
        to={`/wizard/shop/bestellung/modem/neu`}
      >{`zum Modem Shop`}</PrimaryButtonLink>
    </div>
  );
};

export const formatFieldModemName = (value) =>
  value.split(".").join("").split(" ").join("-");

export const createDebitorTaskMap = (debitors = [], modems = []) => {
  console.log("##>> creating debitor task map ...", debitors, modems);
  try {
    const map = {};
    const inChangeList = [];
    debitors.forEach((item, rowIndex) => {
      // check if debitor exists
      if (!item.debitor) {
        const orderNumber = item.number || item.orderNumber || "";
        notifyError({
          summary: `Modembestellungsfreigabe`,
          detail: `Der Vorgang wurde abgebrochen, denn bei der Bestellung (${orderNumber}) fehlt ein Debitor.`,
        });
        throw {
          message: "DATA-ERROR - DEBITOR NOT FOUND:",
          ref: item,
        };
      }
      // check if debitor is being changed/updated
      if (item.debitor.inChange) {
        inChangeList.push({ ...item.debitor });
      }
      item.debitor.modems.forEach((modem, colIndex) => {
        const key = formatFieldModemName(modem.product_name);
        if (!map[key]) {
          map[key] = [];
        }
        let approved = modem.approved || 0;
        // if (modem.quantity !== 0 && (approved == null || approved == 0)) {
        //   approved = modem.quantity;
        // }
        const model = {
          rowIndex,
          colIndex,
          id: modem.id,
          number: item.number,
          debitorKey: item.debitor.debitorKey,
          product_name: modem.product_name,
          used: modem.used,
          stock: modem.stock,
          quantity: modem.quantity,
          approved: approved,
          model_id: modem.model_id || -1,
          distributions: modem.distributions || [],
          company_id: item.company_id,
          region_id: item.region_id,
          debitor_id: item.debitor_id,
          product_id: modem.product_id,
          description: item.description,
        };
        // // set default model_id
        // if (model.model_id === -1) {
        //   const currentModem = modems.find(item => formatFieldModemName(item.name) === key);
        //   if (currentModem && currentModem.models && currentModem.models.length > 0) {
        //     model.model_id = currentModem.models[0].id;
        //   }
        // }
        map[key].push(model);
      });
    });
    // check if there are debitors in change
    if (inChangeList.length > 0) {
      const debitors = inChangeList.map((item) => item.debitorKey).join(", ");
      notifyError({
        summary: `Modembestellungsfreigabe`,
        detail: `Der Vorgang wurde abgebrochen weil folgende Debitoren in Veränderung sind: ${debitors}`,
      });
      throw {
        message: "DATA-ERROR - DEBITORS in change:",
        ref: inChangeList,
      };
    }
    return map;
  } catch (error) {
    console.error(error.message, error.ref);
    return { abort: true };
  }
};

export const getDebitorOrderItemsFromDebitorTaskMap = (debitorTaskMap) => {
  const orderItems = [];
  if (debitorTaskMap) {
    for (const key in debitorTaskMap) {
      debitorTaskMap[key].forEach((item) => {
        const orderItem = {
          id: item.id,
          number: item.number,
          company_id: item.company_id,
          region_id: item.region_id,
          debitor_id: item.debitor_id,
          product_id: item.product_id,
          used: parseInt(item.used),
          stock: parseInt(item.stock),
          quantity: parseInt(item.quantity),
          approved: item.approved === "" ? 0 : parseInt(item.approved),
          model_id: item.model_id == "" ? -1 : parseInt(item.model_id),
          distributions: item.distributions.map((item) => ({
            approved: parseInt(item.approved),
            model_id: parseInt(item.model_id),
          })),
          description: item.description,
        };
        // if (item.model_id && item.model_id !== '') {
        //   orderItem.model_id = item.model_id;
        // }
        orderItems.push(orderItem);
      });
    }
  }
  return orderItems;
};

export const createModemOrderWeeklyOverview = (results) => {
  const modemOrderWeeklyOverview = results
    .map((item) => {
      const tokens =
        item.debitor.length > 0 ? item.debitor[0].debitorKey.split("-") : [];
      if (item.debitor.length > 0 && item.debitor_address) {
        try {
          item.debitor[0].debitorAddress = JSON.parse(item.debitor_address);
        } catch (error) {
          console.error(
            "<< ERROR: debitorAddress cannot be parsed",
            error,
            item
          );
        }
      }
      return {
        ...item,
        parentDebitorKey: tokens.length
          ? [tokens[0], tokens[1]].join("-")
          : undefined,
      };
    })
    .map((item) => {
      const newItem = {};
      for (const key in item) {
        newItem[formatFieldModemName(key)] = item[key];
      }
      return newItem;
    })
    .sort((a, b) => {
      if (!a.debitor.length || !b.debitor.length) {
        return 0;
      }
      if (a.debitor[0].debitorKey > b.debitor[0].debitorKey) return 1;
      else if (a.debitor[0].debitorKey < b.debitor[0].debitorKey) return -1;
      return 0;
    });
  return modemOrderWeeklyOverview;
};

export const createModemOrderWeeklyOverviewMap = (modemOrderWeeklyOverview) => {
  const modemOrderWeeklyOverviewMap = {};
  modemOrderWeeklyOverview.forEach((item) => {
    if (item.parentDebitorKey) {
      if (modemOrderWeeklyOverviewMap[item.parentDebitorKey] === undefined) {
        modemOrderWeeklyOverviewMap[item.parentDebitorKey] = [];
      }
      if (item.debitor.length >= 1) {
        modemOrderWeeklyOverviewMap[item.parentDebitorKey].push({
          ...item,
          debitorKey: item.debitor[0].debitorKey,
        });
      }
    }
  });
  return modemOrderWeeklyOverviewMap;
};

export const createModemOrderWeeklyGroupedOverview = (
  modemOrderWeeklyOverviewMap,
  time,
  catalogModems
) => {
  // console.log(
  //   "createModemOrderWeeklyGroupedOverview() map:",
  //   modemOrderWeeklyOverviewMap
  // );
  const modemOrderWeeklyGroupedOverview = [];
  for (const key in modemOrderWeeklyOverviewMap) {
    const group = { ...time, status_id: 0 };
    let isOneDebitorSent = false;
    modemOrderWeeklyOverviewMap[key].forEach((item) => {
      group.debitorKey = group.debitorKey || item.parentDebitorKey;
      group.orderNumber = group.orderNumber || item.orderNumber;
      group.parentCompanyName = group.parentCompanyName || item.parentCompanyName;
      group.requester = group.requester || item.requester;
      group.request_date = group.request_date || item.request_date;
      if (!isOneDebitorSent) {
        isOneDebitorSent = item.status_id === MODEM_ORDER_STATUS_IDS.SENT;
      }
      group.statusName =
        item.status_id && item.status_id > group.status_id
          ? item.statusName
          : group.statusName;
      group.status_id =
        item.status_id && item.status_id > group.status_id
          ? item.status_id
          : group.status_id;
      catalogModems.forEach((modem) => {
        const modemKey = formatFieldModemName(modem.name);
        if (!group[modemKey]) {
          group[modemKey] = 0;
        }
        group[modemKey] += item[modemKey];
      });
    });
    if (group.status_id === MODEM_ORDER_STATUS_IDS.CLOSED && isOneDebitorSent) {
      group.status_id = MODEM_ORDER_STATUS_IDS.SENT;
      group.statusName = MODEM_ORDER_STATUS_LABELS[group.status_id];
    }
    modemOrderWeeklyGroupedOverview.push(group);
  }
  return modemOrderWeeklyGroupedOverview;
};
