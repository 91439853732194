import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageFactory } from "../index";
import { connect } from "react-redux";
import { convertTreeToList } from "../../MainNav";
import BreadCrumbs, { SubLinks } from "../../BreadCrumbs";
import { buildMapLinks } from "../../MainNav/components";
import {
  getProductPageContent,
  isArchivePage,
  isProductPage,
} from "../components/Utils";
import ArchiveFactory from "./ArchiveFactory";
import { getLinkParentUrl, PageHeight60 } from "../../../components/Utils";
import { PermissionRoute, Resources } from "../../Permissions";
import { isResourceReadable } from "../../Permissions/actions";

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissionGroup.admindataaccess,
    tree: state.staticPage.menuTree.sales,
    labelMap: state.staticPage.labelMap.sales,
  };
};
const mapDispatchToProps = null;

const SalesRoutes = ({
  className = "",
  permissions = {},
  tree,
  labelMap,
  specialMap,
  // routeMap,
  editValidators,
}) => {
  const canReadAdminData = isResourceReadable(Resources.AdminDataAccess, permissions)
  const links = convertTreeToList(buildMapLinks(tree));
  let productTree = null;
  let productLinks = [];
  if (tree.children.length > 0 && tree.children[0].children.length > 0) {
    productTree = tree.children[0];
    productLinks = convertTreeToList(buildMapLinks(productTree));
  }
  console.log("SalesRoutes()", productTree);
  // build routes
  const normalRoutes = [];
  const specialRoutes = [];
  links.forEach((link, index) => {
    if (index > 0) {
      const { to, label, component, subLinks, resource } = link;
      if (component && specialMap[component] !== undefined) {
        const Component = specialMap[component];
        // const RouteComponent = routeMap[component] || Route;
        specialRoutes.push(
          // <RouteComponent
          <PermissionRoute
            resource={resource}
            key={index}
            exact
            path={to}
            component={Component}
          />
        );
        if (subLinks && subLinks.length > 0) {
          subLinks.forEach((subLink, subIndex) => {
            if (
              subLink.component &&
              specialMap[subLink.component] !== undefined
            ) {
              const SubComponent = specialMap[subLink.component];
              // const SubRouteComponent = routeMap[subLink.component] || Route;
              specialRoutes.push(
                // <SubRouteComponent
                <PermissionRoute
                  resource={subLink.resource}
                  key={`${index}${subIndex}`}
                  exact
                  path={subLink.to}
                  component={SubComponent}
                />
              );
            }
          });
        }
      } else {
        const data = link.data || {};
        const type = data ? data.type : link.type;
        let linkChildren = subLinks;
        if (isProductPage(type)) {
          const treeNode = tree.children.find((item) => item.to === link.to);
          linkChildren = getProductPageContent(treeNode, canReadAdminData);
        }
        if (data.noPage) {
          normalRoutes.push(
            <PermissionRoute
              resource={link.resource}
              key={index}
              exact
              path={to}
              render={() => <Redirect to={getLinkParentUrl(to)} />}
            />
          );
        } else {
          const Component = isArchivePage(type)
            ? ArchiveFactory("sales", to, "Archiv: Sales")
            : PageFactory(to, label, type, linkChildren, editValidators, link, {
                links: productLinks,
                tree: productTree,
              });
          normalRoutes.push(
            <PermissionRoute
              resource={link.resource}
              key={index}
              exact={!isArchivePage(type)}
              path={to}
              component={Component}
            />
          );
        }
      }
    }
  });
  // static area
  const StaticArea = () => {
    const Home = specialMap.home;
    return (
      <div>
        <BreadCrumbs className="margin-big-top" labelMap={labelMap} />
        <Switch>
          {normalRoutes}
          <Route path="/*" component={Home} />
        </Switch>
        <SubLinks exclude={["/sales"]} />
      </div>
    );
  };
  return (
    <PageHeight60 className={className}>
      <Switch>
        {specialRoutes}
        <Route path="/*" component={StaticArea} />
      </Switch>
    </PageHeight60>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesRoutes);
