import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, ButtonLink } from "../../../../components/Buttons";
import {
  W3Dropdown,
  W3DropdownToggler,
  W3DropdownContent,
} from "../../../../components/W3Dropdown";
import { ConnectionTypes } from "../Utils";
import ITTaskDialog from "./ITTaskDialog";
import ActionFilter from "../ActionFilter";

export default class PTConnectionTaskMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedPTConnections: PropTypes.array,
    connectionTaskType: PropTypes.string,
    exportPTConnections: PropTypes.func.isRequired,
    items: PropTypes.array,
    history: PropTypes.object,
  };
  static defaultProps = {
    className: "",
    actions: {},
    items: [],
  };
  onExport = (event) => {
    if (event) event.preventDefault();
    const type = "PT";
    const { selectedPTConnections } = this.props;
    let counter = 0;
    if (selectedPTConnections && selectedPTConnections.length > 0) {
      counter += selectedPTConnections.length;
    }
    this.taskDialog.show(
      true,
      {
        title: `${type}-Zugänge exportieren`,
        text: `
          Alle ${counter} ausgewählte ${type}-Zugänge werden für die Weiterverarbeitung vorbereitet und als CSV exportiert.  
          Wollen Sie fortfahren?
        `,
        type: type.toUpperCase(),
        showValidity: false,
        taskType: this.props.connectionTaskType,
        showTaskType: false,
      },
      (data) => this.props.exportPTConnections(data)
    );
  };
  render() {
    const {
      className,
      selectedPTConnections,
      filterConnections,
      connectionTaskType,
      items,
    } = this.props;
    const isPTExportable =
      selectedPTConnections && selectedPTConnections.length > 0;
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle pad-rht">
            <ActionFilter
              type="PT"
              taskType={connectionTaskType}
              setTaskType={filterConnections}
              itemsToCount={items}
              className="display-inline-block"
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-lft">
            <W3Dropdown className="modems dropdown-list-menu">
              <W3DropdownToggler
                as={PrimaryButton}
                size="small-"
              >{`Optionen`}</W3DropdownToggler>
              <W3DropdownContent
                className="w3-bar-block w3-border"
                style={{ right: "0", width: "280px" }}
              >
                <ButtonLink
                  to="#"
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  onClick={this.onExport}
                  disabled={!isPTExportable}
                >
                  {`PT-Zugänge exportieren (CSV)`}
                </ButtonLink>
                <ButtonLink
                  className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                  to={`/wizard/aufgabe/import/${ConnectionTypes.PT_CONNECTION.toLowerCase()}`}
                  disabled={false}
                >
                  {`PT-Zugänge importieren (CSV)`}
                </ButtonLink>
              </W3DropdownContent>
            </W3Dropdown>
          </div>
        </div>
        <ITTaskDialog ref={(ref) => (this.taskDialog = ref)} />
      </div>
    );
  }
}
