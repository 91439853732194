import React from 'react';
import PropTypes from 'prop-types';
import { WizardButtonPanel } from '../../../../../components/Buttons';

import { connect } from 'react-redux';
import {
  getOrderAddressData,
  saveOrderAddress
} from '../../../actions/cards';
import { PageHeight40, } from '../../../../../components/Utils';
import OrderAddressForm from '../../../components/OrderAddressForm';

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissionGroup.admindataaccess,
    company: state.company.company,
    isLoading: state.card.loading,
    cardOrder: state.card.cardOrder,
    addresses: state.card.addresses,
    companies: state.company.companies,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAddresses: (...args) => dispatch(getOrderAddressData(...args)),
  saveAddress: (...args) => dispatch(saveOrderAddress(...args)),
});

class OrderAddress extends React.Component {
  static propTypes = {
    // mapped from state
    permissions: PropTypes.shape({}),
    company: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    cardOrder: PropTypes.shape({}),
    cards: PropTypes.array,
    // actions
    getAddresses: PropTypes.func.isRequired,
    saveAddress: PropTypes.func.isRequired,
    // rest
    match: PropTypes.object,
    history: PropTypes.object,
    onPrevious: PropTypes.func,
    onNext: PropTypes.func,
    onCancel: PropTypes.func,
    className: PropTypes.string
  }
  static defaultProps = {
    className: ''
  }
  componentDidMount() {
    const { cardOrder } = this.props;
    if (!cardOrder || !cardOrder.items || cardOrder.items.length === 0) {
      console.log('<< Data Models not set => go to previous page ...');
      this.onPrevious();
    }
  }
  onPrevious = () => {
    const { history, match, onPrevious } = this.props;
    onPrevious && onPrevious({ history, match });
  }
  onNext = () => {
    const { history, match, onNext } = this.props;
    const address = this.form.getModel();
    if (!address) {
      return;
    }
    const onSuccess = () => onNext && onNext({ history, match });
    this.props.saveAddress({ address }, onSuccess)
  }
  onCancel = () => {
    const { history, match, onCancel } = this.props;
    onCancel && onCancel({ history, match });
  }
  renderNav = (className = 'margin-big-top w3-border pad-big w3-light-grey') => {
    return (
      <WizardButtonPanel
        className={className}
        onPrevious={this.onPrevious}
        onNext={this.onNext}
        onCancel={this.onCancel}
        nextLabel={`Weiter`}
        disableIfAbsent
      />
    );
  }
  render() {
    const {
      className,
      permissions,
      company,
      cardOrder,
      companies,
      addresses,
      getAddresses
    } = this.props;
    console.log('OrderAddress.render()', company, cardOrder, companies);
    if (!cardOrder || !companies) {
      return null;
    }
    // console.log('OrderAddress.render()', cardOrder, cards);
    return (
      <div className={`card-catalog`}>
        <PageHeight40 className={`w3-border ${className}`}>
          <div className="w3-border-bottom pad bg-secondary- w3-light-grey">
            <h3 className="no-margin pad-sm-top pad-lr">{`Lieferanschrift und Vermerk`}</h3>
          </div>
          <OrderAddressForm
            ref={ref => this.form = ref}
            className="no-border-top pad-big"
            permissions={permissions}
            model={cardOrder}
            getAddresses={getAddresses}
            companies={companies}
            addresses={addresses}
            company={company}
            isVertical
          />
        </PageHeight40>
        {this.renderNav()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderAddress);
