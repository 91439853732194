import React from "react";
import PropTypes from "prop-types";
// import { Dropdown } from 'primereact/dropdown';
import { Label, InputText, Dropdown } from "../../../components/Inputs";

export default class LinkForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
  };
  static defaultProps = {
    className: "",
    model: {
      body: '{title: "", url: "", type: "intern"}',
    },
  };
  constructor(props) {
    super(props);
    console.log("NewsArticleForm.constructor()", props.model);
    let model = {};
    try {
      model = JSON.parse((props.model || {}).body || "");
    } catch (error) {
      model = { title: "", url: "", type: "intern" };
    }
    this.state = {
      title: model.title || "",
      url: model.url || "",
      type: model.type || "intern",
      error: {
        title: null,
        url: null,
      },
    };
  }
  validate = () => {
    const { error, ...model } = this.state;
    // check if all values are set
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (error[key] !== undefined && model[key] === "") {
        error[key] = true;
        isValid = false;
      }
    });
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    return { body: JSON.stringify(model) };
  };
  getModel = () => {
    const model = this.validate();
    return model;
  };
  render() {
    const { className, disabled } = this.props;
    const { title, url, type, error } = this.state;
    // console.log('LinkForm.render()', this.state);
    const TypeOptions = [
      { label: "Intern", value: "intern" },
      { label: "Extern", value: "extern" },
    ];
    return (
      <div className={className}>
        <div className="neg-margin-lr w3-row">
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.title ? " error-group" : ""
            }`}
          >
            <Label htmlFor="title">{`Titel*`}</Label>
            <InputText
              id="titleId"
              className="w3-block w3-border"
              value={title}
              onChange={(event) => this.setState({ title: event.target.value })}
              disabled={disabled}
            />
          </div>
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.url ? " error-group" : ""
            }`}
          >
            <Label htmlFor="url">{`URL`}</Label>
            <InputText
              id="url"
              className="w3-block w3-border"
              value={url || ""}
              onChange={(event) => this.setState({ url: event.target.value })}
              disabled={disabled}
            />
          </div>
          <div
            className={`w3-col s4 pad-lr pad-top form-group-item ${
              error.type ? " error-group" : ""
            }`}
          >
            <Label htmlFor="type">{`Intern/Extern*`}</Label>
            <Dropdown
              id="type"
              className="w3-block w3-border"
              value={type}
              options={TypeOptions}
              onChange={(event) => this.setState({ type: event.value })}
              showClear
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
