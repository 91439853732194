import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

import ProfileIcon from "../../../components/media/my-vodafone.png";
import LocationChangeListener from "../../../components/LocationChangeListener";

class LoggedInProfile extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    link: PropTypes.node,
    subLinkList: PropTypes.node
  }

  static defaultProps = {
    className: null,
    link: null,
    subLinkList: null
  }

  constructor () {
    super();

    this.state = {
      isOpen: false,
    };

    this.hideFlyout = this.hideFlyout.bind(this);
    this.toggleFlyout = this.toggleFlyout.bind(this);
  }

  hideFlyout () {
    this.setState({
      isOpen: false
    });
  }

  toggleFlyout () {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }

  render () {
    const { className, subLinkList, link } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <LocationChangeListener onChange={this.hideFlyout} />
        <span id="profile-icon-btn" className={className} onClick={this.toggleFlyout}>{link}</span>
        <div
          className={classnames("profile-flyout", {
            "nav-display": isOpen
          })}
          onMouseLeave={this.hideFlyout}
        >
          <div className="vf-h5">Du bist eingeloggt</div>
          {subLinkList}
        </div>
      </>
    );
  }
}

export default LoggedInProfile;
