import React from "react";
import PropTypes from "prop-types";
import Dashboard from "../../../components/Dashboard";
import InPageNavMenu from "../../../components/InPageNavMenu";
import {
  COMPANY_STATUS_ACTIONS,
  COMPANY_STATUS_IDS,
  COMPANY_STATUS_LABELS,
  createSortByName,
  isCompanyActivated,
  isContractor,
  isSpecialKDL,
  notifyError,
  PageHeight80,
  PageHeight30,
  PageHeightAuto,
  WizardTitle,
} from "../../../components/Utils";
import { FormMenu } from "../../../components/Buttons";

import BasicDataForm from "../components/wizard/BasicDataForm";
import GroupForm from "../components/wizard/GroupForm";
import TechKeyForm from "../components/wizard/TechKeyForm";
import RegionListForm from "../components/wizard/RegionListForm";
import DebitorListForm from "../components/wizard/DebitorListForm";
import ContractGiverForm from "../components/wizard/ContractGiverForm";
import ContractorListForm from "../components/wizard/ContractorListForm";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import {
  loadCompanyTypes,
  loadContractors,
  loadCompany,
  changeCompany,
  saveCompany,
  cancelCompany,
  exportSubCompanies,
} from "../actions";
import { loadKeys } from "../../Product/actions/keys";
import AdHocNewsRecipentListForm from "../components/wizard/AdHocNewsRecipientListForm";
import StatusForm from "../../../components/StatusForm";
import {
  PermissionResource,
  PermissionInPageNavMenu,
  Resources,
  PermissionSection,
  PermissionGroupFetcher,
} from "../../Permissions";
import FootprintForm from "../../../components/FootprintForm";
import { loadFootprints } from "../../News/actions";
import BasicDataFormHeader from "../components/wizard/BasicDataFormHeader";
import { isResourceEditable, isResourceReadable } from "../../Permissions/actions";
import ITDataForm from "../components/wizard/ITDataForm";

const frontload = async (props) => {
  const id = props.match.params.id;
  if (id === undefined || id === null) {
    return;
  }
  await props.getFootprints();
  await props.getCompanyTypes();
  await props.getContractors();
  await props.getTechkeys();
  await props.getCompany({ id, wizard: true });
};
const mapStateToProps = (state) => {
  return {
    // isCEO: state.auth.isCEO,
    // isWorkerPlus: state.auth.isWorkerPlus,
    // isWorkerLogisticsAssistant: state.auth.isWorkerLogisticsAssistant,
    permissions:
      state.permissions.permissionGroup["organisation.companywizard"],
    types: state.company.wizardTypes,
    techKeys: state.key.catalogKeys,
    userCompany: state.company.company,
    company: state.company.wizardCompany,
    companyWorkers: state.worker.companyWorkers,
    companies: state.company.contractors,
    footPrints: state.news.companyFootPrints,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getFootprints: (...args) => dispatch(loadFootprints(...args)),
  getCompanyTypes: (...args) => dispatch(loadCompanyTypes(...args)),
  getContractors: (...args) => dispatch(loadContractors(...args)),
  getTechkeys: (...args) => dispatch(loadKeys(...args)),
  getCompany: (...args) => dispatch(loadCompany(...args)),
  changeCompany: (...args) => dispatch(changeCompany(...args)),
  saveCompany: (...args) => dispatch(saveCompany(...args)),
  cancelCompany: (...args) => dispatch(cancelCompany(...args)),
  exportSubCompanies: (...args) => dispatch(exportSubCompanies(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    permissions: PropTypes.shape({}),
    types: PropTypes.array,
    techKeys: PropTypes.array,
    userCompany: PropTypes.object,
    company: PropTypes.object,
    companyWorkers: PropTypes.array,
    companies: PropTypes.array,
    regions: PropTypes.array,
    footPrints: PropTypes.arrayOf(PropTypes.string),
    getCompanyTypes: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    changeCompany: PropTypes.func.isRequired,
    saveCompany: PropTypes.func.isRequired,
    cancelCompany: PropTypes.func.isRequired,
    exportSubCompanies: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
    regions: ["1", "2", "3", "4", "7", "9"],
    companies: [],
  };
  componentWillUnmount() {
    this.props.cancelCompany();
  }
  scrollIntoView = (element) => {
    if (!element) {
      return;
    }
    window.scrollTo(0, element.offsetTop);
  };
  getModel = (noValidate) => {
    const { company } = this.props;
    const statusData = !this.statusData ? null : this.statusData.getModel();
    const footPrintData = !this.footPrintData
      ? null
      : this.footPrintData.getModel();
    let basicData = !this.basicData
      ? null
      : this.basicData.getModel(noValidate);
    if (
      !noValidate &&
      ((!basicData && this.basicData) || (!footPrintData && this.footPrintData))
    ) {
      notifyError({
        summary: "Firmenprofil",
        detail: `Die Firma konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`,
      });
      return; // window.scrollTo(0, this.basicData.offsetTop);
    }

    basicData = basicData || company;
    // it data
    const itData = !this.itData
    ? {}
    : this.itData.getModel(noValidate);
    basicData = {
      ...basicData,
      ...itData,
    };
    // groups
    const groups = !this.groupDaten
      ? company.groups
      : this.groupDaten.getModel(noValidate);
    // techKeys
    const techKeys = !this.techKeys
      ? company.techKeys
      : this.techKeys.getModel(noValidate);
    // regions
    let regions =
      (!this.regions ? company.regions : this.regions.getModel(noValidate)) ||
      [];
    // debitors
    const debitors =
      (!this.debitors ? null : this.debitors.getModel(noValidate)) || [];
    if (regions && regions.length > 0 && debitors) {
      console.log("<< saving these debitors", debitors);
      debitors.forEach((debitor) => {
        for (let index = 0; index < regions.length; index++) {
          if (regions[index].name === debitor.region) {
            if (regions[index].debitors === undefined) {
              regions[index].debitors = [];
            }
            regions[index].debitors.push({ ...debitor });
          }
        }
      });
    }
    // contractGivers
    let contractGivers = basicData.contractGivers;
    if (this.contractGivers) {
      contractGivers = this.contractGivers.getModel();
    }
    // contractors
    let contractors = company.contractors;
    if (this.contractors) {
      contractors = this.contractors.getModel();
    }
    // ad-hoc news recipients
    let newsRecipients = company.newsRecipients;
    if (this.newsRecipients) {
      newsRecipients = this.newsRecipients.getModel();
      if (!newsRecipients) {
        notifyError({
          summary: "Firmenprofil",
          detail: `Die Firma konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`,
        });
        return this.scrollIntoView(
          document.getElementById("adhoc-news-verteiler")
        );
        // return this.scrollIntoView(this.newsRecipients);
      }
    }
    const model = {
      ...basicData,
      groups,
      techKeys,
      regions,
      contractGivers,
      contractors,
      newsRecipients,
      status_id: statusData ? statusData.status_id : company.status_id,
      footPrint: footPrintData ? footPrintData.footPrint : company.footPrint,
    };
    if (this.props.match.params.id !== "neu" && this.props.company.id) {
      model.id = this.props.company.id;
    }
    return model;
  };
  onSave = () => {
    const model = this.getModel();
    if (!model) return;
    // console.log("Saving Model:", model);
    const { history } = this.props;
    const onSuccess = () => history.replace(`/organisation/firmen`);
    this.props.saveCompany(model, onSuccess);
  };
  onCancel = () => {
    const { history } = this.props;
    this.props.cancelCompany();
    history.replace(`/organisation/firmen`);
  };
  onCompanyTypeChanged = (newType_id) => {
    const model = this.getModel(true);
    model.type_id = newType_id;
    this.props.changeCompany(model);
  };
  onRegionsChanged = (regions) => {
    this.debitors && this.debitors.setRegions(regions);
  };
  getRegionBaseData = () => {
    const { company } = this.props;
    const basicData = !this.basicData
      ? { type_id: company.type_id, address: null, }
      : this.basicData.getModel(true);
    return {
      getAddress: () => basicData, // this.basicData.getModel(true),
      type_id: basicData.type_id,
    };
  };
  onDebitorsChanged = (debitors) => {
    this.regions && this.regions.setDebitors(debitors);
  };
  getDebitorBaseData = () => {
    const { company } = this.props;
    const basicData = !this.basicData
      ? { type_id: company.type_id } // { type_id: null }
      : this.basicData.getModel(true);
    return {
      regions: this.regions ? this.regions.getModel() : [...company.regions],
      type_id: basicData.type_id,
    };
  };
  renderMenu = (isContractor, isContractGiverVisible) => {
    let links = [               
      {
        isVisible: true,
        to: "basisdaten",
        label: "Allgemeine Daten",
        resource: Resources.Organisation.CompanyWizard.BasicInfo,
      },
      {
        isVisible: true,
        to: "itdaten",
        label: "IT-Daten",
        resource: Resources.Organisation.CompanyWizard.ITData,
      },
      {
        isVisible: true,
        to: "gewerke",
        label: "Gewerke",
        resource: Resources.Organisation.CompanyWizard.Groups,
      },
    ];
    if (!isContractor) {
      links = links.concat([
        {
          isVisible: true,
          to: "technikerschlüssel",
          label: "Technikerschlüssel",
          resource: Resources.Organisation.CompanyWizard.TechKeys,
        },
        {
          isVisible: true,
          to: "regionen",
          label: "Regionen",
          resource: Resources.Organisation.CompanyWizard.Regions,
        },
        {
          isVisible: true,
          to: "debitoren",
          label: "Debitoren",
          resource: Resources.Organisation.CompanyWizard.Debitors,
        },
      ]);
    }
    // links.push({
    //   isVisible: isContractGiverVisible,
    //   to: "auftraggeber",
    //   label: "Auftraggeber",
    // });
    links.push({
      isVisible: true,
      to: "adhoc-news-verteiler",
      label: "Ad-hoc News Verteiler",
      resource: Resources.Organisation.CompanyWizard.AdHocNewsRecipents,
    });
    if (!isContractor) {
      // links.push({ isVisible: true, to: "auftragnehmer", label: "Auftragnehmer (NAN)" });
      links.push({
        isVisible: true,
        to: "subunternehmen",
        label: "Subunternehmen",
        resource: Resources.Organisation.CompanyWizard.Contractors,
      });
    }
    return (
      <PermissionInPageNavMenu className="under-wizard-title" links={links} />
    );
  };
  isCompanyEditable = () => {
    const resourceKeys = Object.keys(Resources.Organisation.CompanyWizard);
    for (const key of resourceKeys) {
      const isWriteable = isResourceEditable(
        Resources.Organisation.CompanyWizard[key],
        this.props.permissions
      );
      if (isWriteable) {
        return true;
      }
    }
    return false;
  };
  render() {
    const {
      className,
      types,
      techKeys,
      userCompany,
      company,
      companyWorkers,
      regions,
      companies,
      match,
      footPrints,
      permissions,
    } = this.props;
    const id = match.params.id;
    if (!company || (id === "neu" && company.id != null) || !types) {
      return null;
    }
    const isCompanyContractor = isContractor(company);
    // const isCompanySpecialKDL = isSpecialKDL(company);
    const isContractGiverVisible = id === "neu" || company.id == null;
    const isContractorsExportable = isResourceReadable(Resources.Organisation.CompanyWizard.ContractorsExport, permissions);
    const Menu = this.renderMenu(isCompanyContractor, isContractGiverVisible);
    console.log("CompanyWizard() permissions", permissions, "isContractorsExportable:", isContractorsExportable);

    // debitors
    let debitors = [];
    company.regions &&
      company.regions.forEach((region) => {
        if (region.debitors && region.debitors.length > 0) {
          debitors = debitors.concat(region.debitors);
        }
      });
    if (company.changedDebitors) {
      const newDebitors = [];
      debitors.forEach((item) => {
        const found = company.changedDebitors.find(
          (debitor) => debitor.id === item.id
        );
        if (!found) {
          newDebitors.push(item);
        }
      });
      debitors = company.changedDebitors
        .map((item) => ({
          ...item,
          readOnly: true,
        }))
        .concat(newDebitors);
    }

    // contractors
    const companyList = [...companies].sort(createSortByName());
    // remove company name from the list of possible contractors
    const contractorList = companyList.filter((item) => item.id !== company.id);

    return (
      <div className={`company-wizard clearfix ${className}`}>
        <PermissionGroupFetcher group="Organisation.CompanyWizard" />
        <WizardTitle
          type="company"
          model={company}
          menu={
            <FormMenu
              className="pad-big-top"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveCompanyBtn2"
              cancelBtnName="cancelCompanyBtn2"
              saveHidden={!this.isCompanyEditable()}
              cancelBtnLabel={
                !this.isCompanyEditable() ? "Schließen" : undefined
              }
            />
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight80 className="">
              <PermissionResource
                resource={Resources.Organisation.CompanyWizard.BasicInfo}
              >
                <PermissionSection
                  height="30"
                  id="basisdaten"
                  className="basicData w3-border margin-big-top pad-big"
                  isUnterWizardTitle
                >
                  <BasicDataFormHeader
                    title="Allgemeine Daten"
                    renderFootPrint={(disabled) => (
                      <FootprintForm
                        ref={(ref) => (this.footPrintData = ref)}
                        className="footprint-form"
                        label="Footprint*"
                        model={{ footPrint: company.footPrint || "" }}
                        footPrints={footPrints}
                        disabled={disabled}
                        required
                      />
                    )}
                    renderStatus={() => (
                      <PermissionResource
                        resource={Resources.Organisation.CompanyWizard.Status}
                      >
                        <StatusForm
                          ref={(ref) => (this.statusData = ref)}
                          className="status-form"
                          model={{ status_id: company.status_id }}
                          statusIds={COMPANY_STATUS_IDS}
                          statusLabels={COMPANY_STATUS_LABELS}
                          statusActions={COMPANY_STATUS_ACTIONS}
                          isDisabled={
                            company.activeEmployees > 0 &&
                            isCompanyActivated(company)
                          }
                        />
                      </PermissionResource>
                    )}
                  />
                  <BasicDataForm
                    ref={(ref) => (this.basicData = ref)}
                    model={company}
                    types={types}
                    userCompany={userCompany}
                    onCompanyTypeChanged={this.onCompanyTypeChanged}
                    onlyServicePartner
                  />
                </PermissionSection>
              </PermissionResource>
              <PermissionResource
                resource={Resources.Organisation.CompanyWizard.ITData}
              >
                <PermissionSection
                  height="30"
                  id="itdaten"
                  className="groups w3-border margin-big-top pad-big"
                  isUnterWizardTitle
                >
                  <h3 className="no-margin-top">IT-Daten</h3>
                  <ITDataForm
                    ref={(ref) => (this.itData = ref)}
                    model={company}
                  />
                </PermissionSection>
              </PermissionResource>
              <PermissionResource
                resource={Resources.Organisation.CompanyWizard.Groups}
              >
                <PermissionSection
                  height="30"
                  id="gewerke"
                  className="groups w3-border margin-big-top pad-big"
                  isUnterWizardTitle
                >
                  <h3 className="no-margin-top">Gewerke</h3>
                  <GroupForm
                    ref={(ref) => (this.groupDaten = ref)}
                    model={company.groups}
                  />
                </PermissionSection>
              </PermissionResource>
              {!isCompanyContractor && (
                <>
                  <PermissionResource
                    resource={Resources.Organisation.CompanyWizard.TechKeys}
                  >
                    <PermissionSection
                      height="30"
                      id="technikerschlüssel"
                      className="techKeys w3-border margin-big-top pad-big"
                      isUnterWizardTitle
                    >
                      <h3 className="no-margin-top">Technikerschlüssel</h3>
                      <TechKeyForm
                        ref={(ref) => (this.techKeys = ref)}
                        model={company.techKeys}
                        techKeys={techKeys}
                      />
                    </PermissionSection>
                  </PermissionResource>
                  <PermissionResource
                    resource={Resources.Organisation.CompanyWizard.Regions}
                  >
                    <PermissionSection
                      height="30"
                      id="regionen"
                      className="w3-border margin-big-top pad-big"
                      isUnterWizardTitle
                    >
                      <RegionListForm
                        className="regions"
                        ref={(ref) => (this.regions = ref)}
                        model={company.regions}
                        regions={regions}
                        debitors={debitors}
                        getBaseData={this.getRegionBaseData}
                        onChange={this.onRegionsChanged}
                        // isCompanySpecialKDL={isCompanySpecialKDL}
                      />
                    </PermissionSection>
                  </PermissionResource>
                  <PermissionResource
                    resource={Resources.Organisation.CompanyWizard.Debitors}
                  >
                    <PermissionSection
                      height="30"
                      id="debitoren"
                      className="w3-border margin-big-top pad-big"
                      isUnterWizardTitle
                    >
                      <DebitorListForm
                        className="debitors"
                        ref={(ref) => (this.debitors = ref)}
                        model={debitors}
                        regions={company.regions}
                        workers={companyWorkers}
                        getBaseData={this.getDebitorBaseData}
                        // isCompanySpecialKDL={isCompanySpecialKDL}
                        contractors={company.contractors}
                        company={company}
                        getCompany={() => this.basicData ? this.basicData.getModel(true) : company}
                        onChange={this.onDebitorsChanged}
                      />
                    </PermissionSection>
                  </PermissionResource>
                </>
              )}
              {/* <PermissionResource
                resource={Resources.Organisation.CompanyWizard.ContractGiver}
                isVisible={isContractGiverVisible}
              >
                <PermissionSection
                  id="auftraggeber"
                  className="contractGivers w3-border margin-big-top pad-big"
                  isUnterWizardTitle
                >
                  <h3 className="no-margin-top">Auftraggeber</h3>
                  <ContractGiverForm
                    ref={(ref) => (this.contractGivers = ref)}
                    model={company}
                    companies={companyList}
                    userCompany={userCompany}
                  />
                </PermissionSection>
              </PermissionResource> */}
              <PermissionResource
                resource={
                  Resources.Organisation.CompanyWizard.AdHocNewsRecipents
                }
              >
                <PermissionSection
                  height="30"
                  id="adhoc-news-verteiler"
                  className="w3-border margin-big-top pad-big"
                  isUnterWizardTitle
                >
                  <AdHocNewsRecipentListForm
                    className="newsRecipients"
                    ref={(ref) => (this.newsRecipients = ref)}
                    model={company.newsRecipients}
                  />
                </PermissionSection>
              </PermissionResource>
              {!isCompanyContractor && (
                <PermissionResource
                  resource={Resources.Organisation.CompanyWizard.Contractors}
                >
                  <PermissionSection
                    id="subunternehmen"
                    className="w3-border margin-big-top pad-big"
                    isUnterWizardTitle
                  >
                    <ContractorListForm
                      className="contractors"
                      ref={(ref) => (this.contractors = ref)}
                      model={company.contractors}
                      companies={contractorList}
                      getBaseData={() => ({
                        types,
                        getCompany: () => this.basicData.getModel(true),
                      })}
                      exportSubCompanies={this.props.exportSubCompanies}
                      isExportable={isContractorsExportable}
                    />
                  </PermissionSection>
                </PermissionResource>
              )}
              <FormMenu
                className="w3-border margin-big-top pad-big w3-light-grey"
                onSave={this.onSave}
                onCancel={this.onCancel}
                saveBtnName="saveCompanyBtn"
                cancelBtnName="cancelCompanyBtn"
                saveHidden={!this.isCompanyEditable()}
                cancelBtnLabel={
                  !this.isCompanyEditable() ? "Schließen" : undefined
                }
              />
            </PageHeight80>
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Wizard)
);
