import classnames from "classnames";
import ObjectUtils from "primereact/components/utils/ObjectUtils";
import { Column } from "primereact/column";
import React, { useCallback, useMemo, useState, useEffect, useReducer } from "react";

import BooleanFilter from "./filters/BooleanFilter";
import CalendarFilter from "./filters/CalendarFilter";
import InputTextFilter from "./filters/InputTextFilter";
import MultiSelectFilter, {
  filterFunction as multiSelectFilterFunction
} from "./filters/MultiSelectFilter";
import { toUIDate } from "../../../components/Utils";
import RangeFilter from "./filters/RangeFilter";

const booleanFilter = (value, filter) => {
  if (filter == null) {
    return true;
  }

  return Boolean(value) === filter;
};

const booleanTemplate = (rowData, column) => {
  const value = ObjectUtils.resolveFieldData(rowData, column.field);
  if (value) {
    return "ja";
  }
  return "nein";
};

const calendarWeekTemplate = (rowData, column) => {
  const value = ObjectUtils.resolveFieldData(rowData, column.field);
  if (!value) {
    return "";
  }
  const [year, week] = value.split("_");
  return `KW${week}, ${year}`;
};

const A_DAY = 24 * 60 * 60 * 1000;
const dateRangeFilter = (value, filter) => {
  if (filter === undefined || filter === null || filter.length !== 2) {
    return true;
  }

  const [fromDate, toDate] = filter;
  if (!fromDate || !toDate) {
    return true;
  }

  if (!value) {
    return false;
  }

  const date = new Date(value).getTime();
  if (!date) {
    return false;
  }

  return fromDate.getTime() <= date && date < toDate.getTime() + A_DAY;
};

const dateTemplate = (rowData, column) => {
  const value = ObjectUtils.resolveFieldData(rowData, column.field);
  return toUIDate(value, undefined, "DD.MM.YYYY HH:mm:ss");
};

const getInitialSortState = (rows) => ({
  sorting: false,
  sortedRows: rows,
  sortProps: {}
});
const sortReducer = (state, action) => {
  switch (action.type) {
    case "set-rows": {
      const { data } = action;
      return {
        ...state,
        sortedRows: data
      };
    }
    case "set-sort-props": {
      const { data } = action;
      return {
        ...state,
        sortProps: data
      };
    }
    case "sorting": {
      return {
        ...state,
        sorting: true
      };
    }
    case "sorting-done": {
      const { data } = action;
      return {
        ...state,
        sortedRows: data,
        sorting: false
      };
    }
    default: {
      return state;
    }
  }
};

const wrapValue = (value) => (<div className="pad-lr">{value}</div>);

class SalesTableUtil {
  static createColumn ({ className, ...props }) {
    const body = props.body
      ? (...args) => wrapValue(props.body(...args))
      : (data) => wrapValue(data[props.field]);
    return (
      <Column
        filter
        filterMatchMode="contains"
        {...props}
        body={body}
        className={classnames(className, {
          [`report-table-column-${props.field}`]: props.field
        })}
        style={{width: '200px'}}
      />
    );
  }
  
  static createBooleanColumn (props) {
    return SalesTableUtil.createColumn({
      body: booleanTemplate,
      ...props
    });
  }

  static createCalendarWeekColumn (props) {
    return SalesTableUtil.createColumn({
      body: calendarWeekTemplate,
      ...props
    });
  }

  static createDateColumn (props) {
    return SalesTableUtil.createColumn({
      body: dateTemplate,
      ...props
    });
  }

  static getRowClassName (rowData) {
    return {
      "report-table-row-missing-pm": !rowData.pm_weborderid,
      "report-table-row-missing-sale": !rowData.s_weborderid
    };
  }

  static createUseBooleanFilter (dt, onChange, value) {
    return (field, props = {}) => {
      const filterElement = (
        <BooleanFilter
          dt={dt}
          field={field}
          onChange={onChange}
          value={value}
          {...props}
        />
      );
    
      return { filterElement, filterFunction: booleanFilter };
    };
  }

  static createUseDateFilter (dt, onChange, value) {
    return (field, props = {}) => {
      const filterElement = (
        <CalendarFilter
          dt={dt}
          field={field}
          onChange={onChange}
          value={value}
          {...props}
        />
      );

      return { filterElement, filterFunction: dateRangeFilter };
    };
  }

  static createUseRangeFilter (dt, onChange, value) {
    return (field, props = {}) => {
      const filterElement = (
        <RangeFilter
          dt={dt}
          field={field}
          onChange={onChange}
          value={value}
          {...props}
        />
      );

      return { filterElement, filterFunction: dateRangeFilter };
    };
  }

  static createUseInputFilter (dt, filterDelay, onChange, value = '') {
    return (field, props = {}) => {
      const filterElement = (
        <InputTextFilter
          dt={dt}
          field={field}
          filterDelay={filterDelay}
          onChange={onChange}
          value={value}
          {...props}
        />
      );

      return { filterElement };
    };
  }

  static createUseMultiSelectFilter (dt, rows, optionMap, onChange, value = []) {
    return (field, props = {}) => {
      const filterElement = (
        <MultiSelectFilter
          dt={dt}
          field={field}
          rows={rows}
          options={optionMap[field]}
          onChange={onChange}
          value={value}
          {...props}
        />
      );

      return { filterElement, filterFunction: multiSelectFilterFunction };
    };
  };

  static useAsyncSort (dataTable, rows) {
    const [state, dispatch] = useReducer(sortReducer, rows, getInitialSortState)
    const [worker, setWorker] = useState(() => {
      const w = new Worker("/sortWorker.js");
      w.onmessage = (event) => {
        dispatch(event.data);
      };
      return w;
    });
    useEffect(() => {
      return () => {
        worker.terminate();
      };
    }, [worker]);
    
    useEffect(() => {
      if (worker) {
        worker.postMessage({ type: "set-rows", data: rows });
      }
    }, [rows, worker]);

    useEffect(() => {
      dispatch({ type: "set-rows", data: rows });
    }, [rows]);

    const handleSort = useCallback((event) => {
      dispatch({ type: "set-sort-props", data: event });
      worker.postMessage({ type: "set-sort-props", data: event });
    }, [worker]);

    const dataTableProps = {
      onSort: handleSort,
      value: state.sortedRows,
      ...state.sortProps
    };

    const noopSortFunction = useCallback(() => {
      // do nothing - just return what the data table is getting
      return dataTable.props.value;
    }, [dataTable]);

    const columnProps = {
      sortable: false,
      // sortFunction: noopSortFunction
    };

    return [dataTableProps, columnProps, state.sorting];
  }
}

export default SalesTableUtil;
