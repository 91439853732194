import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageFactory } from '../index';
import { connect } from "react-redux";
import BreadCrumbs, { SubLinks } from '../../BreadCrumbs';
import { isArchivePage } from '../components/Utils';
import ArchiveFactory from './ArchiveFactory';
import { PageHeight60 } from '../../../components/Utils';


const mapStateToProps = (state) => {
  return {
    links: state.staticPage.routes.technik,
    tree: state.staticPage.tree.technik,
    labelMap: state.staticPage.labelMap.technik,
  };
};
const mapDispatchToProps = null;


const TechnikRoutes = ({ className = '', links, labelMap, tree, isEditable }) => {
  console.log("TechnikRoutes() isEditable:", isEditable)
  let Home = null;
  const editValidators = [
    () => {
      console.log("Using Page Permissions - isEditable:", isEditable);
      return isEditable;
    }
  ];
  const routes = links.map((link, index) => {
    const { url, label } = link;
    if (index === 0) {
      const data = link.data || {};
      const type = data ? data.type : link.type;
      Home = PageFactory(url, label, type, null, editValidators, link);
      return null;
    }
    const data = link.data || {};
    const type = data ? data.type : link.type;
    const Component = isArchivePage(type)
      ? ArchiveFactory('technik', url, 'Archiv: Technik')
      : PageFactory(url, label, null, null, editValidators, link, { links, tree });
    return (
      <Route
        key={index}
        exact={!isArchivePage(type)}
        path={url}
        component={Component}
      />
    );
  });
  return (
    <PageHeight60 className={className}>
      <BreadCrumbs
        className="margin-big-top"
        labelMap={labelMap}
      />
      <Switch>
        {routes}
        <Route path="/*" component={Home}/>
      </Switch>
      <SubLinks isEditable={isEditable}/>
    </PageHeight60>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnikRoutes);
