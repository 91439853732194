import * as actionTypes from "../actions/employee";
import {
  openFile,
  notifySuccess,
  notifyError,
} from "../../../components/Utils";

export const initialState = {
  employeeTasks: [],
  selectedEmployeeTasks: [],
  failedEmailAddresses: [],
  employeeDeactivationTasks: [],
  employeeCompanyChangeTasks: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // setting selected card orders
    case actionTypes.SET_SELECTED_EMPLOYEE_TASKS:
      return {
        ...state,
        selectedEmployeeTasks: [...action.selected],
      };

    // close employee task
    case actionTypes.CLOSE_EMPLOYEE_TASK:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CLOSE_EMPLOYEE_TASK_SUCCESS:
      notifySuccess({
        summary: `Kontaktdatenänderung bestätigen`,
        detail: `Die gewählte Änderung wurde erfolgreich bestätigt.`,
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_EMPLOYEE_TASK_FAILURE:
      notifyError({
        summary: `Kontaktdatenänderung bestätigen`,
        detail: `Die gewählte Änderung konnte nicht bestätigt werden.`,
      });
      return {
        ...state,
        loading: false,
        error: action.error || action.result,
      };

    // export user profile changes
    case actionTypes.EXPORT_EMPLOYEE_TASKS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EXPORT_EMPLOYEE_TASKS_SUCCESS:
      openFile(action.result.filePath);
      notifySuccess({
        summary: `Kontaḱtdatenänderungen exportieren`,
        detail: `Die Änderungen wurden erfolgreich exportiert.`,
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EXPORT_EMPLOYEE_TASKS_FAILURE:
      notifyError({
        summary: `Kontaḱtdatenänderungen exportieren`,
        detail: `Die Änderungen konnte nicht bestätigt werden.`,
      });
      return {
        ...state,
        loading: false,
        error: action.error || action.result,
      };

    // deactivate user via failed email task
    case actionTypes.DEACTIVATE_USER_TASK:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DEACTIVATE_USER_TASK_SUCCESS:
      notifySuccess({
        summary: `Benutzer deaktivieren`,
        detail: `Die Deaktivierung von ${action.task.username} mitsamt allen Zugängen wurde erfolgreich beantragt.`,
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DEACTIVATE_USER_TASK_FAILURE: {
      console.log("Error:", action);
      let msg = ".";
      if (
        action.error.message &&
        action.error.message === "label.user.has.no.ad.account"
      ) {
        msg = ` - ${action.task.username} hat keinen AD-Zugang!`;
      }
      notifyError({
        summary: `Benutzer deaktivieren`,
        detail: `Die Deaktivierung von ${action.task.username} mitsamt allen Zugängen konnte nicht beantragt werden${msg}`,
      });
      return {
        ...state,
        loading: false,
        error: action.error || action.result,
      };
    }
    // close failed email task
    case actionTypes.CLOSE_FAILED_EMAIL_TASK:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CLOSE_FAILED_EMAIL_TASK_SUCCESS:
      notifySuccess({
        summary: `Benutzerverwaltungsaufgabe bestätigen`,
        detail: `Die gewählte Aufgabe wurde erfolgreich bestätigt.`,
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLOSE_FAILED_EMAIL_TASK_FAILURE:
      notifyError({
        summary: `Benutzerverwaltungsaufgabe bestätigen`,
        detail: `Die gewählte Aufgabe konnte nicht bestätigt werden.`,
      });
      return {
        ...state,
        loading: false,
        error: action.error || action.result,
      };

    // close employee company change tasks
    case actionTypes.CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CLOSE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS: {
      let summary = `Messgerät aktualisieren`;
      let detail = `Das Messgerät wurde infolge der Subunternehmensänderung erfolgreich aktualisiert.`;
      if (
        action.taskType === actionTypes.OperationType.CreateDeviceDeleteTask
      ) {
        summary = `Messgerät deprovisionieren`;
        detail = `Ein Löschauftrag für das Messgerät wurde erfolgreich angelegt.`;
      }
      notifySuccess({ summary, detail });
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.CLOSE_EMPLOYEE_COMPANY_CHANGE_TASKS_FAILURE: {
      let summary = `Messgerät aktualisieren`;
      let detail = `Das Messgerät konnte infolge der Subunternehmensänderung nicht aktualisiert werden.`;
      if (
        action.taskType === actionTypes.OperationType.CreateDeviceDeleteTask
      ) {
        summary = `Messgerät deprovisionieren`;
        detail = `Ein Löschauftrag für das Messgerät konnte nicht angelegt werden.`;
      }
      notifyError({ summary, detail });
      return {
        ...state,
        loading: false,
        error: action.error || action.result,
      };
    }

    // close employee dactivation tasks
    case actionTypes.CLOSE_EMPLOYEE_DEACTIVATION_TASKS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CLOSE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS: {
      const plural = action.tasks.length === 1 ? "" : "n";
      notifySuccess({
        summary: `Mitarbeiter-Deaktivierungsaufgabe bestätigen`,
        detail: `Die gewählte Aufgabe${plural} wurde${plural} erfolgreich bestätigt.`,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.CLOSE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE: {
      const plural = action.tasks.length === 1 ? "" : "n";
      notifyError({
        summary: `Mitarbeiter-Deaktivierungsaufgabe bestätigen`,
        detail: `Die gewählte Aufgabe${plural} konnte${plural} nicht bestätigt werden.`,
      });
      return {
        ...state,
        loading: false,
        error: action.error || action.result,
      };
    }

    // deactivate employee via employee deactivation task
    case actionTypes.DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS: {
      let prefix = `Die Mitarbeiter wurden`;
      if (action.tasks.length === 1) {
        prefix = `Der Mitarbeiter ${action.tasks[0].employee} wurde`;
      }
      notifySuccess({
        summary: `Mitarbeiter deaktivieren`,
        detail: `${prefix} erfolgreich deaktiviert.`,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.DEACTIVATE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE: {
      let prefix = `Die Mitarbeiter konnten`;
      if (action.tasks.length === 1) {
        prefix = `Der Mitarbeiter ${action.tasks[0].employee} konnte`;
      }
      notifyError({
        summary: `Mitarbeiter deaktivieren`,
        detail: `${prefix} nicht deaktiviert werden.`,
      });
      return {
        ...state,
        loading: false,
        error: action.error || action.result,
      };
    }

    // delete employee deactivation task
    case actionTypes.DELETE_EMPLOYEE_DEACTIVATION_TASKS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_EMPLOYEE_DEACTIVATION_TASKS_SUCCESS:
      notifySuccess({
        summary: `Mitarbeiter-Deaktivierungsaufgabe löschen`,
        detail: `Die gewählte Aufgabe wurde erfolgreich gelöscht.`,
      });
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_EMPLOYEE_DEACTIVATION_TASKS_FAILURE:
      notifyError({
        summary: `Mitarbeiter-Deaktivierungsaufgabe löschen`,
        detail: `Die gewählte Aufgabe konnte nicht gelöscht werden.`,
      });
      return {
        ...state,
        loading: false,
        error: action.result,
      };

    default:
      return null;
  }
};
