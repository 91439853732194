import React from 'react';
import PropTypes from 'prop-types';
// import Dashboard from '../../../../components/Dashboard';
// import KeyWizardTitle from './KeyWizardTitle';

// import KeyCatalog from './wizard/KeyCatalog';
import OrderOverviewUpdate from './wizard/OrderOverviewUpdate';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadKeyOrder, cancelKeyOrder } from '../../actions/keys';


const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getOrder({ id });
};
const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  getOrder: (...args) => dispatch(loadKeyOrder(...args)),
  cancelOrder: (...args) => dispatch(cancelKeyOrder(...args)),
});

class Panel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getOrder: PropTypes.func.isRequired,
    cancelOrder: PropTypes.func.isRequired,
  }
  static defaultProps = {
    className: ''
  }
  componentWillUnmount() {
    this.props.cancelOrder();
  }
  onCancel = () => {
    // console.log('KeyOrderArchivePanel.onCancel()');
    const { cancelOrder, history } = this.props;
    cancelOrder();
    history.replace('/shop/schliessmittel/uebersicht');
  }
  render() {
    const { className, disabled } = this.props;
    return (
      <div className={`key-order-overview-panel clearfix ${className}`}>
        <OrderOverviewUpdate
          isArchive
          canReadAdminData
          onCancel={this.onCancel}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Panel)
);
