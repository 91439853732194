import React from "react";
import PropTypes from "prop-types";
import { InputText, Label } from "../../../../components/Inputs";
import { validateEmail } from "../../../../components/Utils";

export default class ADConnectionTaskForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    isDeleting: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      user: "",
      adUsername: "",
      adPassword: "",
    },
    isDeleting: false,
  };
  constructor(props) {
    super(props);
    const model = props.model || {};
    const user = model.userData || {};
    this.state = {
      adUsername: model.adUsername || "",
      adPassword: model.adPassword || "",
      vf_email: user.vf_email || "",
      error: {
        adUsername: props.isDeleting ? undefined : null,
        adPassword: props.isDeleting ? undefined : null,
        vf_email: null,
      },
    };
  }
  validate = () => {
    const originalModel = this.props.model || {};
    const { error, ...model } = this.state;
    // check if all values are set
    // let isChanged = false;
    // let isValid = false;
    // Object.keys(model).forEach(key => {
    //   if (model[key] !== originalModel[key]) {
    //     isChanged = true;
    //   }
    //   if (error[key] !== undefined && error[key] === '') {
    //     error[key] = true;
    //     isValid = true;
    //   }
    // });
    // this.setState({ error });
    // if (!isValid && !isChanged) {
    //   return null;
    // }
    let isValid = true;
    Object.keys(model).forEach((key) => {
      if (error[key] !== undefined && model[key] === "") {
        error[key] = true;
        isValid = false;
      }
    });
    // email
    model.vf_email = model.vf_email.trim();
    if (!validateEmail(model.vf_email)) {
      error.vf_email = "ungültige E-Mail";
      isValid = false;
    }
    this.setState({ error });
    if (!isValid) {
      return null;
    }
    return { ...originalModel, ...model };
  };
  getModel = (initialize = false) => {
    const { model, isDeleting } = this.props;
    return isDeleting ? { ...model } : this.validate(initialize);
    // const model = this.validate(initialize);
    // return model;
  };
  render() {
    // console.log("ADConnectionTaskForm()", this.props.model);
    const { className, model, isDeleting } = this.props;
    const { adUsername, adPassword, vf_email, error } = this.state;
    const user = model.userData || {};
    return (
      <div className={`${className} w3-large`}>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label>{`Benutzer`}</Label>
            <InputText
              className="w3-block w3-border"
              value={model.user}
              disabled
            />
          </div>
          <div
            className={`w3-col s6 pad-lr pad-top form-group-item ${
              error.vf_email ? " error-group" : ""
            }`}
          >
            <Label htmlFor="vf_email">{`Vodafone E-Mail${
              isDeleting ? "" : "*"
            }`}</Label>
            <InputText
              id="vf_email"
              className="w3-block w3-border"
              value={vf_email}
              onChange={(event) =>
                this.setState({
                  vf_email: event.target.value,
                  error: { ...error, vf_email: null },
                })
              }
              disabled={isDeleting}
            />
          </div>
        </div>
        {!isDeleting && (
          <div className="w3-row neg-margin-lr">
            <div className="w3-col s6 pad-lr pad-top form-group-item">
              <Label>{`Telefon (dienstlich)`}</Label>
              <InputText
                className="w3-block w3-border"
                value={model.userData.companyTelephoneNumber}
                disabled
              />
            </div>
          </div>
        )}
        <div className="w3-row neg-margin-lr">
          <div
            className={`w3-col s6 pad-lr pad-top form-group-item ${
              error.adUsername ? " error-group" : ""
            }`}
          >
            <Label htmlFor="adUsername">{`Kennung${
              isDeleting ? "" : "*"
            }`}</Label>
            <InputText
              id="adUsername"
              className="w3-block w3-border"
              value={adUsername}
              onChange={(event) =>
                this.setState({
                  adUsername: event.target.value,
                  error: { ...error, adUsername: null },
                })
              }
              disabled={isDeleting}
            />
          </div>
          {!isDeleting && (
            <div
              className={`w3-col s6 pad-lr pad-top form-group-item ${
                error.adPassword ? " error-group" : ""
              }`}
            >
              <Label htmlFor="adPassword">{`Passwort*`}</Label>
              <InputText
                id="adPassword"
                className="w3-block w3-border"
                value={adPassword}
                onChange={(event) =>
                  this.setState({
                    adPassword: event.target.value,
                    error: { ...error, adPassword: null },
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
