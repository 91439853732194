import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormMenu } from '../../../../components/Buttons';
import ItemListManager from '../../../../components/ItemListManager';
import CertificateForm from './CertificateForm';
import SalesTablePageReport from '../../../Reporting/components/SalesTablePageReport';
import { TableEmptyMessage } from '../../../../components/Utils';


export default class CertificationListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    rows: PropTypes.number,
  }
  static defaultProps = {
    className: '',
    model: [],
    rows: 5
  }
  validate = () => {
    const certifications = this.certifications.getModel();
    if (!certifications) return;
    const model = [ ...certifications ];
    // console.log('CertificationList Model:', model);
    return model;
  }
  getModel = () => {
    const model = this.validate();
    return model;
  }
  render() {
    const { className, ...restProps } = this.props
    return (
      <div className={className}>
        <Certification
          ref={(ref) => this.certifications = ref}
          {...restProps}
        />
      </div>
    );
  }
}

class Certification extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.array,
    rows: PropTypes.number,
  }
  constructor(props) {
    super(props);
    this.state = {
      items: (props.model || []).map((item, index) => ({ ...item, index })),
      first: 0
    };
  }
  getModel = () => {
    const { items } = this.state;
    // return items;
    const model = items.map(item => {
      const { issuer, issuer_id, name, certificate_id, remark, intension_date } = item;
      const data = { issuer, issuer_id, name, certificate_id, remark, intension_date };
      return data;
    });
    return model;
  }
  onSave = (onHide) => {
    const model = this.form.getModel();
    console.log('>> saving certification ...', model);
    const{ items } = this.state;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    onHide && onHide();
  }
  onDelete = (selected) => {
    console.log('>> deleting certification from list', selected);
    const items = [];
    for (const index in this.state.items) {
      console.log('>> delete: comparing index', index, 'with selected index', selected.index);
      if (index != selected.index) {
        items.push(this.state.items[index]);
      }
    }
    this.setState({ items });
    this.props.onChange && this.props.onChange(items);
  }
  render() {
    const { className, rows, disabled } = this.props;
    const { first } = this.state;
    const onPage = (event) => this.setState({first: event.first});
    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => ({ ...item, index }));
      return (
        <div>
          <h4 className="no-margin alg-center pad-big-btm">{'Update für diesen Bereich erfolgt mit einer weiteren Version.'}</h4>
          <DataTable
            value={data}
            rows={rows}
            paginator={data.length > rows}
            first={first}
            onPage={onPage}
            paginatorLeft={
              <SalesTablePageReport
                className="pad-lft"
                totalRecords={data.length}
                filteredRecords={data.length}
                first={first}
                rows={rows}
                emptyText="Keine Zertifizierungen"
                itemName="Zertifizierung"
                itemNamePlural="Zertifizierungen"
              />
            }
            emptyMessage={(<TableEmptyMessage itemNamePlural="Zertifizierungen" min/>)}
          >
            <Column
              field="issuer"
              header="Art"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
            />
            <Column
              field="name"
              header="Zertifizierung"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
            />  
            {/* <Column field="remark" header="Nummer"/>  */}
            <Column
              field="intension_date"
              header="angemeldet"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
              style={{textAlign:'center', width: '10em'}}
            />
            <Column
              field="status"
              header="Status"
              sortable={data.length > 1}
              filter={data.length > rows}
              filterMatchMode="contains"
              style={{textAlign:'center', width: '10em'}}
            />
            <Column
              body={actionTemplate}
              style={{textAlign:'center', width: '7.4em'}}
            />
          </DataTable>
        </div>
      );
    };
    const renderItemForm = (item, onHide) => {
      const certification = item || {
        issuer: '',
        name: '',
        remark: '',
        intension_date: '',
        status: ''
      };
      return (
        <div>
          <CertificateForm
            key={Math.random()}
            ref={(ref) => this.form = ref}
            model={certification}
            disabled={disabled}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey"
            style={{marginLeft: '-1em', marginRight: '-1em', marginBottom: '-1em'}}
            onSave={() => this.onSave(onHide)} onCancel={onHide}
            saveBtnName="saveCertificateBtn"
            saveHidden
            cancelBtnLabel="Schließen"
          />
        </div>
      );
    };
    const getItemFormTitle = (certification) => {
      // console.log('CertificationListForm.getItemFormTitle()', certification);
      // if (!certification) return 'Neue Zertifizierung hinzufügen';
      // return 'Zertifizierung bearbeiten';
      // return (<h3 className="no-margin">{`Zertifizierung ${/* !isEditable ? 'ansehen' :  */(certification ? 'bearbeiten' : 'hinzufügen')}`}</h3>);
      return (<h3 className="no-margin">{`Zertifizierung ansehen`}</h3>);
    }
    return (
      <ItemListManager
        key={Math.random()}
        className={className}
        title={(<h3 className="no-margin-top">Zertifizierungen / Kompetenznachweise</h3>)}
        addBtnLabel="Hinzufügen"
        addBtnName="addWorkerCertificateBtn"
        addBtnHidden
        itemVisibleOnly
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="certificate"
      />
    );
  }
}
