
import { getTaskTypeByActionID, TASK_SECTION_TYPES } from '../components/Utils';
import { loadOpenTasks } from '.';
import { STATUS_IDS, PRODUCT_ORDER_STATUS_LABELS, PRODUCT_ORDER_STATUS_IDS } from '../../../components/Utils';

export const LOAD_CARD_ORDERS = 'task/cards/orders/load';
export const LOAD_CARD_ORDERS_SUCCESS = 'task/cards/orders/load/success';
export const LOAD_CARD_ORDERS_FAILURE = 'task/cards/orders/load/failure';

export const FILTER_CARDS = 'tasks/cards/orders/filter';

export const SET_SELECTED_CARD_ORDERS = 'task/cards/orders/set';

export const CHANGE_CARD_ORDER_STATUS = 'task/cards/orders/status';
export const CHANGE_CARD_ORDER_STATUS_SUCCESS = 'task/cards/orders/status/success';
export const CHANGE_CARD_ORDER_STATUS_FAILURE = 'task/cards/orders/status/failure';

export const EXPORT_CARD_ORDERS = 'task/card/export';
export const EXPORT_CARD_ORDERS_SUCCESS = 'task/card/export/success';
export const EXPORT_CARD_ORDERS_FAILURE = 'task/card/export/failure';

export const DELETE_CARD_ORDER = 'tasks/cards/orders/delete';
export const DELETE_CARD_ORDER_SUCCESS = 'tasks/cards/orders/delete/success';
export const DELETE_CARD_ORDER_FAILURE = 'tasks/cards/orders/delete/failure';

export const CLOSE_CARD_ORDER = 'tasks/cards/orders/close';
export const CLOSE_CARD_ORDER_SUCCESS = 'tasks/cards/orders/close/success';
export const CLOSE_CARD_ORDER_FAILURE = 'tasks/cards/orders/close/failure';

export const CANCEL_ORDER = 'tasks/cards/orders/cancel';


export const loadCardOrderTasks = () => {
  return {
    types: [
      LOAD_CARD_ORDERS,
      LOAD_CARD_ORDERS_SUCCESS,
      LOAD_CARD_ORDERS_FAILURE
    ],
    promise: (client) => client.get(`/cards/orders/tasks`)
  }
};

export const filterCards = (taskType) => {
  return {
    type: FILTER_CARDS,
    taskType
  };
};

export const setSelectedCardOrders = (selected) => {
  console.log(`REDUX-ACTION: setSelectedCardOrders()`, selected);
  return {
    type: SET_SELECTED_CARD_ORDERS,
    selected
  };
};

export const lockCardOrders = (selected) => {
  console.log(`REDUX-ACTION: lockCardOrders() selected`, selected);
  return (dispatch, getState) => {
    const orders = selected || getState().task.selectedCardOrders || [];
    const newOrders = orders.filter(item => (item.status_id === STATUS_IDS.NEW)).map(item => ({
      id: item.id,
      type_id: item.type_id,
      status: PRODUCT_ORDER_STATUS_LABELS[PRODUCT_ORDER_STATUS_IDS.PROCESSING],
      status_id: PRODUCT_ORDER_STATUS_IDS.PROCESSING
    }));
    console.log(`<< Changing the status of ${newOrders.length} orders`, newOrders);
    dispatch({
      types: [
        CHANGE_CARD_ORDER_STATUS,
        CHANGE_CARD_ORDER_STATUS_SUCCESS,
        CHANGE_CARD_ORDER_STATUS_FAILURE
      ],
      promise: (client) => client.post(`/fccShops/orders/changeStatus`, { data: newOrders }),
      onSuccess: () => dispatch(loadOpenTasks(TASK_SECTION_TYPES.FLYERS)),
      selected
    });
  };
};

export const exportCardOrders = () => {
  console.log(`REDUX-ACTION: exportCardOrders()`);
  return (dispatch, getState) => {
    const { selectedCardOrders } = getState().task;
    const data = selectedCardOrders.map(item => ({ id: item.id, orderNumber: item.orderNumber }));
    dispatch({
      types: [
        EXPORT_CARD_ORDERS,
        EXPORT_CARD_ORDERS_SUCCESS,
        EXPORT_CARD_ORDERS_FAILURE
      ],
      promise: client => client.post(`/fccShops/orders/load/productTypes/flyer/csv`, { data }),
      // onSuccess: dispatch => dispatch(loadOpenTasks(TASK_SECTION_TYPES.FLYERS)),
    });
  }
};

export const closeCardOrder = ({ cardOrder, type, saveOnly }, onSuccess) => {
  console.log(`REDUX-ACTION: closeCardOrder()`, cardOrder, saveOnly);
  const productType = type || 'flyer';
  const companyId = cardOrder.companyId || 0;
  return {
    types: [
      CLOSE_CARD_ORDER,
      CLOSE_CARD_ORDER_SUCCESS,
      CLOSE_CARD_ORDER_FAILURE
    ],
    promise: (client) => client.put(`/fccShops/companies/${companyId}/productTypes/${productType}/orders`, { data: cardOrder }),
    onSuccess: dispatch => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.FLYERS));
    },
    cardOrder,
    saveOnly
  };
};

export const deleteCardOrder = ({ cardOrder }, onSuccess) => {
  console.log(`REDUX-ACTION: deleteCardOrder()`, cardOrder);
  return {
    types: [
      DELETE_CARD_ORDER,
      DELETE_CARD_ORDER_SUCCESS,
      DELETE_CARD_ORDER_FAILURE
    ],
    promise: client => client.del(`/cards/orders`, { data: cardOrder }),
    onSuccess: dispatch => {
      onSuccess && onSuccess();
      dispatch(loadOpenTasks(TASK_SECTION_TYPES.FLYERS));
    },
    cardOrder
  };
};

export const cancelCardOrder = () => {
  return { type: CANCEL_ORDER };
};
