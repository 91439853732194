import React from "react";
import PropTypes from "prop-types";
import { InputText, Label } from "../../../../components/Inputs";

export default class ITDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      // contractNumber: PropTypes.string,
      nGumPartner: PropTypes.string,
      delphiGroup: PropTypes.string,
      delphiComments: PropTypes.string,
    }),
    namePrefix: PropTypes.string,
    disabled: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    model: {
      // contractNumber: "",
      nGumPartner: "",
      delphiGroup: "",
      delphiComments: "",
    },
    namePrefix: "",
  };
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  getState = (props) => {
    const { model } = props;
    return {
      // contractNumber: model.contractNumber || "",
      nGumPartner: model.nGumPartner || "",
      delphiGroup: model.delphiGroup || "",
      delphiComments: model.delphiComments || "",
    };
  };
  validate = () => {
    const { ...model } = this.state;
    return model;
  };
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  };
  renderOptionalFields = () => {
    return <></>;
  };
  render() {
    const { className, disabled, namePrefix } = this.props;
    const { /* contractNumber,  */nGumPartner, delphiGroup, delphiComments } =
      this.state;
    console.log("ITDataForm() props", this.props);

    return (
      <div className={className}>
        <div className="w3-row neg-margin-lr">
          {/* <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}contractNumber`}
            >{`Rahmenvertragsnummer`}</Label>
            <InputText
              id={`${namePrefix}contractNumber`}
              className={`w3-block w3-border`}
              value={contractNumber}
              onChange={(event) =>
                this.setState({ contractNumber: event.target.value })
              }
              disabled={disabled}
            />
          </div> */}
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}nGumPartner`}
            >{`nGum Partner Company`}</Label>
            <InputText
              id={`${namePrefix}nGumPartner`}
              className={`w3-block w3-border`}
              value={nGumPartner}
              onChange={(event) =>
                this.setState({ nGumPartner: event.target.value })
              }
              disabled={disabled}
            />
          </div>
        </div>
        <div className="w3-row neg-margin-lr">
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}delphiGroup`}
            >{`Delphi Kräftegruppe`}</Label>
            <InputText
              id={`${namePrefix}delphiGroup`}
              className={`w3-block w3-border`}
              value={delphiGroup}
              onChange={(event) =>
                this.setState({ delphiGroup: event.target.value })
              }
              disabled={disabled}
            />
          </div>
          <div className="w3-col s6 pad-lr pad-top form-group-item">
            <Label
              htmlFor={`${namePrefix}delphiComments`}
            >{`Delphi Kurzbezeichnung`}</Label>
            <InputText
              id={`${namePrefix}delphiComments`}
              className={`w3-block w3-border`}
              value={delphiComments}
              onChange={(event) =>
                this.setState({ delphiComments: event.target.value })
              }
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
