import { notifyError } from '../../../components/Utils';
import * as actionTypes from '../actions/success';
import { DisplayConfig, DisplayOptions, getChildrenKeys, getChildDisplay } from '../components/DisplayUtils';
// import isArray from 'lodash/isArray';
// import {
//   notifyError,
//   notifySuccess
// } from '../../../components/Utils';

const initialDelphiFilter = {
  delphiSales: [],
  delphiFilterConfig: {},
};

const initialRangeFilter = {
  display: 'all',

  topLevelDisplay: '',
  daySales: [],
  dayFilterConfig: {},
  weekSales: [],
  weekFilterConfig: {},
  monthSales: [],
  monthFilterConfig: {},
  allSales: [],
  allFilterConfig: {},

  productSales: null
};

export const initialState = {
  username: null,
  delphiNrOptions: [],
  saleStates: [],
  filterType: 'range',
  filterConfig: {},
  sales: [],
  interval: {
    fromDate: null,
    toDate: null
  },
  productGroups: [],
  // delphi filter
  ...initialDelphiFilter,
  // range filter
  ...initialRangeFilter
};

const getStateKey = (display) => {
  return (DisplayConfig[display] || {}).key || null;
};

const buildDisplayStateData = (display, filterConfig, sales) => {
  const state = {};
  const stateKey = getStateKey(display);
  if (stateKey) {
    if (filterConfig) {
      state[`${stateKey}FilterConfig`] = filterConfig;
    }
    if (sales) {
      state[`${stateKey}Sales`] = sales;
    }
  }
  return state;
};


const getEmptyStateChildren = (key) => {
  const state = {};
  if (key) {
    const keys = getChildrenKeys(key);
    keys.forEach(key => {
      state[`${key}Sales`] = [];
      state[`${key}FilterConfig`] = null;
    });
  }
  return state;
}; 


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // load form data
    case actionTypes.LOAD_FORM_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOAD_FORM_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...initialDelphiFilter,
        ...initialRangeFilter,
        ...action.result,
        username: action.username
      };
    }
    case actionTypes.LOAD_FORM_DATA_FAILURE: {
      notifyError({
        summary: "Meine Leistungen",
        detail: "Die Formulardaten konnten nicht geladen werden"
      })
      return {
        ...state,
        loading: false,
        error: action.result
      };}
    
    case actionTypes.UNSET_WORKER:
      return {
        ...state,
        username: null
      }
    
    // set filterType
    case actionTypes.SET_FILTER_TYPE: {
      const data = action.filterType === 'range' ? initialRangeFilter : initialDelphiFilter
      return {
        ...state,
        filterType: action.filterType,
        ...data
      };
    }

    // unset filterType
    case actionTypes.UNSET_FILTER_TYPE: {
      // console.log
      return {
        ...initialState
      };
    }

    // show display
    case actionTypes.SHOW_DISPLAY: {
      const topLevelDisplay = action.filterConfig.display;
      const displayState = buildDisplayStateData(topLevelDisplay, action.filterConfig);
      const childrenDisplayState = getEmptyStateChildren(getStateKey(topLevelDisplay));
      const username = action.worker.username || null;
      console.log('REDUX REDUCER showing display', displayState, childrenDisplayState);
      return {
        ...state,
        topLevelDisplay,
        ...displayState,
        ...childrenDisplayState,
        username: username || state.username
      }
    }
    
    // load user sales
    case actionTypes.LOAD_SALES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_SALES_SUCCESS: {
      // console.log('REDUX REDUCER sales loaded:', action.result);
      const topLevelDisplay = action.filterConfig ? action.filterConfig.display : state.topLevelDisplay;
      const display = action.item ? getChildDisplay(topLevelDisplay) : topLevelDisplay;
      const filterConfig = action.item ? { ...action.filterConfig, display } : null;
      const displayState = buildDisplayStateData(display, filterConfig, action.result);
      console.log('REDUX REDUCER sales loaded:', action.result, displayState);
      return {
        ...state,
        loading: false,
        sales: action.result,
        ...displayState
      };
    }
    case actionTypes.LOAD_SALES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    
    // // set display
    // case actionTypes.SET_DISPLAY:
    //   return {
    //     ...state,
    //     display: action.display
    //   };

    // load productGroups
    case actionTypes.LOAD_PRODUCT_GROUPS: 
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_PRODUCT_GROUPS_SUCCESS: 
      return {
        ...state,
        loading: false,
        productGroups: action.result
      };
    case actionTypes.LOAD_PRODUCT_GROUPS_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.result || action.error
      };

    // load product sales
    case actionTypes.LOAD_PRODUCT_SALES: 
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_PRODUCT_SALES_SUCCESS: 
      return {
        ...state,
        loading: false,
        productSales: action.result
      };
    case actionTypes.LOAD_PRODUCT_SALES_FAILURE: 
      return {
        ...state,
        loading: false,
        error: action.result || action.error
      };

    // clear product sales
    case actionTypes.CLEAR_PRODUCT_SALES:
      return {
        ...state,
        productSales: null
      };
    
    case actionTypes.SET_TIME_RANGE:
      return {
        ...state,
        interval: action.interval
      };

      default:
        return null;
    }
  }  
