import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter, NavLink } from 'react-router-dom';
import Dashboard from '../../../../components/Dashboard';
import { PageHeight30, WizardTitle, notifyError, isProductDeleted } from '../../../../components/Utils';
import { FormMenu } from '../../../../components/Buttons';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadKey, cancelKey, saveKey } from '../../actions/keys';

import KeyBasicDataForm from '../../components/KeyBasicDataForm';
// import MediaUploadForm from '../../../../components/MediaUploadForm';

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getProduct({ id });
};
const mapStateToProps = (state) => {
  return {
    product: state.key.key
  };
};
const mapDispatchToProps = (dispatch) => ({
  getProduct: (...args) => dispatch(loadKey(...args)),
  cancelProduct: (...args) => dispatch(cancelKey(...args)),
  saveProduct: (...args) => dispatch(saveKey(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    product: PropTypes.shape({}),
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getProduct: PropTypes.func.isRequired,
    cancelProduct: PropTypes.func.isRequired,
    saveProduct: PropTypes.func.isRequired,
    className: PropTypes.string,
    cancelUrl: PropTypes.string
  }
  static defaultProps = {
    className: '',
    cancelUrl: '/shop/schliessmittel/produkte'
  }
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentWillUnmount() {
    this.props.cancelProduct();
  }
  onCancel = () => {
    console.log('KeyWizard.onCancel()');
    const { cancelProduct, history, cancelUrl } = this.props;
    cancelProduct();
    history.replace(cancelUrl);
  }
  onSave = () => {
    console.log('KeyWizard.onSave()');
    const model = this.form.getModel();
    if (!model) {
      return notifyError({
        summary: 'Schließmittelverwaltung',
        detail: `Das Schließmittel konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`
      });
    }
    // // key media
    // let files = this.mediaForm.getModel();
    // if (files && model.id) {
    //   files = files.media.map(item => ({ ...item, product_id: model.id }));
    // }
    // model.files = files;

    console.log('Saving Model: ', model);
    const { history, saveProduct, cancelUrl } = this.props;
    const onSuccess = () => history.replace(cancelUrl);
    saveProduct(model, onSuccess);
  }
  renderMenu = () => {
    return (
      <div className="under-wizard-title">
        <HashRouter hashType="noslash">
          <div className="margin-big-top nav-menu-vertical">
            <ul className="w3-ul w3-border w3-round- w3-large">
              <li><NavLink to={`daten`} className="disabled-link-" activeClassName="active">{'Allgemeine Daten'}</NavLink></li>
              {/* <li><NavLink to={`medien`} className="disabled-link-" activeClassName="active">{'Bilder/Dateien'}</NavLink></li> */}
            </ul>
          </div>
        </HashRouter>
      </div>
    );
  }
  render() {
    const { className, product } = this.props;
    console.log('KeyWizard.render()', product);
    const Menu = this.renderMenu();
    if (!product) return null;
    const isDeleted = isProductDeleted(product);
    return (
      <div className={`key-wizard clearfix ${className}`} key={Math.random()}>
        <WizardTitle
          type="key"
          model={product}
          menu={
            <FormMenu
              className="pad-big-top"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveKeyBtn2"
              cancelBtnName="cancelKeyBtn2"
              cancelBtnLabel={isDeleted ? 'Schließen' : 'Abbrechen'}
              saveHidden={isDeleted}
            />
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight30 id="daten" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <h3 className="no-margin-top">Allgemeine Daten</h3>
              <KeyBasicDataForm
                ref={ref => this.form = ref}
                model={product}
              />
            </PageHeight30>
            {/* <PageHeight30 id="medien" className="w3-border margin-big-top pad-big" isUnterWizardTitle>
              <MediaUploadForm
                ref={ref => this.mediaForm = ref}
                title="Bilder/Dateien"
                model={key}
              />
            </PageHeight30> */}
            <FormMenu
              className="w3-border margin-big-top pad-big w3-light-grey"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveKeyBtn"
              cancelBtnName="cancelKeyBtn"
              cancelBtnLabel={isDeleted ? 'Schließen' : 'Abbrechen'}
              saveHidden={isDeleted}
            />
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Wizard)
);
