import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Dropdown, Label } from "../../../components/Inputs";
import { Checkbox } from "primereact/checkbox";
// import { SelectButton } from 'primereact/selectbutton';
import {
  Panel,
  DownloadPanel,
  replaceAll,
  isLinkVisible,
  getVisibilityConfig,
  sortByOptionalPosition,
  createSortById,
  createSortByAttr,
  sortProducts,
} from "../../../components/Utils";
import HtmlText from "../../../components/HtmlText";
import {
  EditIconButton,
  SaveIconButton,
  CancelIconButton,
  PlusIconButton,
  PrimaryButton,
  ButtonLink,
} from "../../../components/Buttons";
import {
  HtmlContentForm,
  ImageContentForm,
  DownloadListForm,
  PanelContentForm,
  TextContentForm,
  HtmlImageContentForm,
} from "./content";

import {
  W3Dropdown,
  W3DropdownToggler,
  W3DropdownContent,
} from "../../../components/W3Dropdown";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { isArray } from "lodash";

export const PAGE_TYPES = {
  ROOT: "root",
  PRODUCT: "products",
  ARCHIVE: "archive",
  DEFAULT: "default",
};

export const COLORS = [
  "#000000",
  "#e60000",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#888888",
  "#a10000",
];

export const CLASS_STYLE_MAP = [
  // { className: 'rte-bg-trans', style: 'background-color: transparent;' },
  // { className: 'rte-bg-black', style: 'background-color: rgb(0, 0, 0);' },
  // { className: 'rte-bg-red', style: 'background-color: rgb(230, 0, 0);' },
  // { className: 'rte-bg-yellow', style: 'background-color: rgb(255, 255, 0);' },
  // { className: 'rte-bg-green', style: 'background-color: rgb(0, 138, 0);' },
  // { className: 'rte-bg-blue', style: 'background-color: rgb(0, 102, 204);' },
  // { className: 'rte-bg-grey', style: 'background-color: rgb(136, 136, 136);' },
  // { className: 'rte-bg-darkred', style: 'background-color: rgb(161, 0, 0);' },
  // { className: 'rte-txt-black', style: 'color: rgb(0, 0, 0);' },
  // { className: 'rte-txt-red', style: 'color: rgb(230, 0, 0);' },
  // { className: 'rte-txt-yellow', style: 'color: rgb(255, 255, 0);' },
  // { className: 'rte-txt-green', style: 'color: rgb(0, 138, 0);' },
  // { className: 'rte-txt-blue', style: 'color: rgb(0, 102, 204);' },
  // { className: 'rte-txt-grey', style: 'color: rgb(136, 136, 136);' },
  // { className: 'rte-txt-darkred', style: 'color: rgb(161, 0, 0);' },
  /* Background */
  { className: "rte-bg-trans", style: "background-color: transparent;" },

  { className: "rte-bg-black1", style: "background-color: rgb(0, 0, 0);" },
  { className: "rte-bg-red1", style: "background-color: rgb(230, 0, 0);" },
  { className: "rte-bg-orange1", style: "background-color: rgb(255, 153, 0);" },
  { className: "rte-bg-yellow1", style: "background-color: rgb(255, 255, 0);" },
  { className: "rte-bg-green1", style: "background-color: rgb(0, 138, 0);" },
  { className: "rte-bg-blue1", style: "background-color: rgb(0, 102, 204);" },
  {
    className: "rte-bg-indigo1",
    style: "background-color: rgb(153, 51, 255);",
  },

  {
    className: "rte-bg-black2",
    style: "background-color: rgb(255, 255, 255);",
  },
  { className: "rte-bg-red2", style: "background-color: rgb(250, 204, 204);" },
  {
    className: "rte-bg-orange2",
    style: "background-color: rgb(255, 235, 204);",
  },
  {
    className: "rte-bg-yellow2",
    style: "background-color: rgb(255, 255, 204);",
  },
  {
    className: "rte-bg-green2",
    style: "background-color: rgb(204, 232, 204);",
  },
  { className: "rte-bg-blue", style: "background-color: rgb(204, 224, 245);" },
  {
    className: "rte-bg-indigo2",
    style: "background-color: rgb(235, 214, 255);",
  },

  {
    className: "rte-bg-black3",
    style: "background-color: rgb(187, 187, 187);",
  },
  { className: "rte-bg-red3", style: "background-color: rgb(240, 102, 102);" },
  {
    className: "rte-bg-orange3",
    style: "background-color: rgb(255, 194, 102);",
  },
  {
    className: "rte-bg-yellow3",
    style: "background-color: rgb(255, 255, 102);",
  },
  {
    className: "rte-bg-green3",
    style: "background-color: rgb(102, 185, 102);",
  },
  { className: "rte-bg-blue3", style: "background-color: rgb(102, 163, 224);" },
  {
    className: "rte-bg-indigo3",
    style: "background-color: rgb(194, 133, 255);",
  },

  {
    className: "rte-bg-black4",
    style: "background-color: rgb(136, 136, 136);",
  },
  { className: "rte-bg-red4", style: "background-color: rgb(161, 0, 0);" },
  { className: "rte-bg-orange4", style: "background-color: rgb(178, 107, 0);" },
  { className: "rte-bg-yellow4", style: "background-color: rgb(178, 178, 0);" },
  { className: "rte-bg-green4", style: "background-color: rgb(0, 97, 0);" },
  { className: "rte-bg-blue4", style: "background-color: rgb(0, 71, 178);" },
  {
    className: "rte-bg-indigo4",
    style: "background-color: rgb(107, 36, 178);",
  },

  { className: "rte-bg-black5", style: "background-color: rgb(68, 68, 68);" },
  { className: "rte-bg-red5", style: "background-color: rgb(92, 0, 0);" },
  { className: "rte-bg-orange5", style: "background-color: rgb(102, 61, 0);" },
  { className: "rte-bg-yellow5", style: "background-color: rgb(102, 102, 0);" },
  { className: "rte-bg-green5", style: "background-color: rgb(0, 55, 0);" },
  { className: "rte-bg-blue5", style: "background-color: rgb(0, 41, 102);" },
  { className: "rte-bg-indigo5", style: "background-color: rgb(61, 20, 102);" },

  /* Text */
  { className: "rte-txt-grey77", style: "color: rgb(77, 77, 77);" },

  { className: "rte-txt-black1", style: "color: rgb(0, 0, 0);" },
  { className: "rte-txt-red1", style: "color: rgb(230, 0, 0);" },
  { className: "rte-txt-orange1", style: "color: rgb(255, 153, 0);" },
  { className: "rte-txt-yellow1", style: "color: rgb(255, 255, 0);" },
  { className: "rte-txt-green1", style: "color: rgb(0, 138, 0);" },
  { className: "rte-txt-blue1", style: "color: rgb(0, 102, 204);" },
  { className: "rte-txt-indigo1", style: "color: rgb(153, 51, 255);" },

  { className: "rte-txt-black2", style: "color: rgb(255, 255, 255);" },
  { className: "rte-txt-red2", style: "color: rgb(250, 204, 204);" },
  { className: "rte-txt-orange2", style: "color: rgb(255, 235, 204);" },
  { className: "rte-txt-yellow2", style: "color: rgb(255, 255, 204);" },
  { className: "rte-txt-green2", style: "color: rgb(204, 232, 204);" },
  { className: "rte-txt-blue", style: "color: rgb(204, 224, 245);" },
  { className: "rte-txt-indigo2", style: "color: rgb(235, 214, 255);" },

  { className: "rte-txt-black3", style: "color: rgb(187, 187, 187);" },
  { className: "rte-txt-red3", style: "color: rgb(240, 102, 102);" },
  { className: "rte-txt-orange3", style: "color: rgb(255, 194, 102);" },
  { className: "rte-txt-yellow3", style: "color: rgb(255, 255, 102);" },
  { className: "rte-txt-green3", style: "color: rgb(102, 185, 102);" },
  { className: "rte-txt-blue3", style: "color: rgb(102, 163, 224);" },
  { className: "rte-txt-indigo3", style: "color: rgb(194, 133, 255);" },

  { className: "rte-txt-black4", style: "color: rgb(136, 136, 136);" },
  { className: "rte-txt-red4", style: "color: rgb(161, 0, 0);" },
  { className: "rte-txt-orange4", style: "color: rgb(178, 107, 0);" },
  { className: "rte-txt-yellow4", style: "color: rgb(178, 178, 0);" },
  { className: "rte-txt-green4", style: "color: rgb(0, 97, 0);" },
  { className: "rte-txt-blue4", style: "color: rgb(0, 71, 178);" },
  { className: "rte-txt-indigo4", style: "color: rgb(107, 36, 178);" },

  { className: "rte-txt-black5", style: "color: rgb(68, 68, 68);" },
  { className: "rte-txt-red5", style: "color: rgb(92, 0, 0);" },
  { className: "rte-txt-orange5", style: "color: rgb(102, 61, 0);" },
  { className: "rte-txt-yellow5", style: "color: rgb(102, 102, 0);" },
  { className: "rte-txt-green5", style: "color: rgb(0, 55, 0);" },
  { className: "rte-txt-blue5", style: "color: rgb(0, 41, 102);" },
  { className: "rte-txt-indigo5", style: "color: rgb(61, 20, 102);" },
];

export function toEditableHtml(text) {
  let result = text;
  // result = replaceAll(result, 'style=', `nonce="${window.CSP_NONCE}" style=`);
  /* result = replaceAll(result, 'class=', 'style=');
  CLASS_STYLE_MAP.forEach(item => {
    result = replaceAll(result, item.className, item.style);
  }); */
  return result;
}

export function fromEditableHtml(text) {
  let result = text;
  // result = replaceAll(result, 'style=', `nonce="${window.CSP_NONCE}" style=`);
  /* result = replaceAll(result, 'style=', 'class=');
  CLASS_STYLE_MAP.forEach(item => {
    result = replaceAll(result, item.style, item.className);
  }); */
  return result;
}

export const isRootPage = (type) =>
  type && type.toLowerCase() === PAGE_TYPES.ROOT;
export const isProductPage = (type) =>
  type && type.toLowerCase() === PAGE_TYPES.PRODUCT;
export const isArchivePage = (type) =>
  type && type.toLowerCase() === PAGE_TYPES.ARCHIVE;

export const PageMetaData = ({
  modifiedBy,
  modifiedDate,
  createdDate,
  contentsModified = [],
}) => {
  if (!modifiedBy) {
    return null;
  }
  console.log(">> checking date", modifiedDate || createdDate);
  let date = moment(modifiedDate || createdDate, "YYYY-MM-DDTHH:mm:ss:SSSZ");
  let dateStr = "";
  if (date.isValid()) {
    contentsModified.forEach((item) => {
      console.log(">> checking date", item);
      let testDate = moment(item, "YYYY-MM-DDTHH:mm:ss:SSSZ");
      if (testDate.isValid() && testDate.isAfter(date)) {
        date = testDate;
      }
    });
    dateStr = date.format("DD.MM.YYYY");
  }
  // const dateStr = date.isValid() ? `${date.format("DD.MM.YYYY")}` : "";
  return (
    <div className="w3-text-grey">
      <span>{`Letzte Änderung: `}</span>
      <span className="bold">{`${modifiedBy} `}</span>
      <span className="">{`(`}</span>
      <span className="">{dateStr}</span>
      <span className="">{`)`}</span>
    </div>
  );
};

export const PageMenu = ({
  contents,
  isVisible,
  type,
  linkData = {},
  onEditPageContent,
  onEditPageLink,
  onCreatePageSubLink,
  onMoveToArchive,
  onMoveSubTree,
}) => {
  if (!isVisible) {
    return null;
  }
  // no menu for archived pages

  const data = linkData.data || {};
  if (isArchivePage(type) || data.archiveUrl) {
    return null;
  }
  // console.log('PageMenu()', type, linkData)
  const dialogRef = React.useRef();
  const moveToArchive = React.useCallback(() => {
    dialogRef.current &&
      dialogRef.current.show(
        true,
        {
          title: `Archivierung`,
          text: (
            <div>
              <div>{`Wollen Sie die folgende Seite jetzt archivieren?`}</div>
              <div className="bold pad-top">{`${linkData.label}`}</div>
              <div className="bold pad-btm">{`${linkData.url}`}</div>
              <div>{`Bitte beachten Sie dass dieser Vorgang nicht rückgängig gemacht werden kann!`}</div>
            </div>
          ),
        },
        () => onMoveToArchive({ linkData, type })
      );
  });

  const firstSeparator = !isProductPage(type) && (
    <a href="#" className="w3-bar-item separator">
      <div className="w3-border-top" style={{ margin: "0 -4px" }} />
    </a>
  );

  return (
    <div className="w3-right">
      <W3Dropdown className="modems dropdown-list-menu">
        <W3DropdownToggler
          as={PrimaryButton}
          size="small-"
        >{`Optionen`}</W3DropdownToggler>
        <W3DropdownContent
          className="w3-bar-block w3-border"
          style={{ right: "0", width: "240px" }}
        >
          <ButtonLink
            to="#"
            className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
            onClick={onEditPageContent}
          >
            {`Seiteninhalt bearbeiten`}
          </ButtonLink>
          {firstSeparator}
          {!isProductPage(type) && (
            <ButtonLink
              to="#"
              className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
              onClick={onCreatePageSubLink}
            >
              {`Unterseite anlegen`}
            </ButtonLink>
          )}
          {!isRootPage(type) && !isProductPage(type) && (
            <div>
              <ButtonLink
                to="#"
                className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                onClick={onEditPageLink}
              >
                {`Seitenlink bearbeiten`}
              </ButtonLink>
              <a href="#" className="w3-bar-item separator">
                <div className="w3-border-top" style={{ margin: "0 -4px" }} />
              </a>
              <ButtonLink
                to="#"
                className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                onClick={onMoveSubTree}
                // disabled={!contents}
              >
                {`Seite verschieben`}
              </ButtonLink>
              <a href="#" className="w3-bar-item separator">
                <div className="w3-border-top" style={{ margin: "0 -4px" }} />
              </a>
              <ButtonLink
                to="#"
                className="w3-bar-item dropdown-list-menu-item bg-secondary-hover"
                onClick={moveToArchive}
                disabled={!contents}
              >
                {`Archivieren`}
              </ButtonLink>
            </div>
          )}
        </W3DropdownContent>
      </W3Dropdown>
      <ConfirmationDialog ref={dialogRef} />
    </div>
  );
};

export const TextContent = ({ model }) => {
  const style = { whiteSpace: "pre-line" };
  return (
    <p className="text-content" style={style}>
      {model.body}
    </p>
  );
};

export const HtmlContent = ({ model }) => {
  const style = { whiteSpace: "pre-line" };
  return (
    <HtmlText
      className="html-content ql-editor"
      style={style}
      text={fromEditableHtml(model.body)}
    />
  );
};

export const getPanelContentData = (model) => {
  let contents = [];
  const toHtmlContent = (body) => {
    if (!body || body === "") {
      return null;
    }
    return {
      content_type_id: getContentTypeIdFromType(CONTENT_HTML),
      body,
    };
  };
  try {
    const data = JSON.parse(model.body);
    if (isArray(data)) {
      contents = data;
    } else {
      contents = data.contents;
      const item = toHtmlContent(data.body);
      item && contents.splice(0, 0, item);
    }
  } catch (err) {
    const item = toHtmlContent(model.body);
    item && contents.splice(0, 0, item);
  }
  return contents;
};

export const PanelContent = (props) => {
  const contents = getPanelContentData(props.model);
  const markup =
    contents &&
    contents.map((item, index) => {
      const { content_type_id, ...model } = item;
      const type = getContentTypeFromId(content_type_id);
      const Component = getContentComponent(type);
      if (!Component) {
        console.error(
          "<< Content Type Error: unknown content type",
          type,
          item
        );
        return null;
      }
      return (
        <Component
          key={index}
          className="margin-big-top"
          model={model}
          pageCreated={props.pageCreated}
        />
      );
    });
  return (
    <Panel className="margin-top pad-btm" title={props.model.title}>
      {/* <HtmlText text={body} /> */}
      <div style={{ minHeight: "80px" }}>{markup}</div>
    </Panel>
  );
};

export const DownloadLinksContent = ({ model, pageCreated }) => {
  let files = [];
  try {
    files = JSON.parse(model.body || "[]");
  } catch (err) {
    console.error("DonwloadLinksContent Error:", model, err);
    files = [];
  }
  console.log("DownloadLinksContent()", files, pageCreated);
  return <DownloadPanel links={files} pageCreated={pageCreated} />;
};

export const ImageContent = ({ model }) => {
  console.log("ImageContent.render()", model);
  let images = null;
  if (model.body && model.body.length >= 2) {
    try {
      images = JSON.parse(model.body).map((item, index) => {
        const { align, ...style } = item.style;
        return (
          <div
            key={index}
            className={`image-holder${index > 0 ? " pad-big-top" : ""}`}
            style={{ textAlign: align }}
          >
            <img
              src={item.image.replace("/api/", "/data-api/")}
              style={style}
              alt={"Bild"}
            />
          </div>
        );
      });
    } catch (error) {
      console.error("ImageContent Error:", model, error);
    }
  }
  return <div className="image-content pad-big-btm">{images}</div>;
};

export const HtmlImageContent = ({ model }) => {
  console.log("HtmlImageContent.render()", model);
  let row = null;
  if (model.body && model.body.length >= 2) {
    try {
      row = JSON.parse(model.body);
      if (row[0].isLeft !== undefined && !row[0].isLeft) {
        const tmp = row[0];
        row[0] = row[1];
        row[1] = tmp;
      }
      row = row.map((item, index) => {
        const type = getContentTypeFromId(item.content_type_id);
        const Component = getContentComponent(type);
        if (!Component) {
          console.error("<< Content Type Error: unknown content type", type);
          return null;
        }
        const className = `pad-sm-${index === 0 ? "rht" : "lft"}`;
        return (
          <div
            key={index}
            className={`w3-cell w3-cell-top ${className}`}
            style={item.style}
          >
            <Component model={item} />
          </div>
        );
      });
    } catch (error) {
      console.error("HtmlImageContent Error:", model, error);
    }
  }
  return (
    <div className="html-image-content pad-big-btm">
      <div className="w3-cell-row">{row}</div>
    </div>
  );
};

export const ControlMenu = ({
  className,
  isVisible,
  isEditing,
  onEdit,
  onSave,
  onCancel,
}) => {
  if (!isVisible) return null;
  const edit = !isEditing && <EditIconButton onClick={onEdit} />;
  const save = isEditing && <SaveIconButton onClick={onSave} />;
  const cancel = isEditing && <CancelIconButton onClick={onCancel} />;
  return (
    <div className={className}>
      {edit}
      {save}
      {cancel}
    </div>
  );
};

export const CONTENT_TEXT = "TEXT";
export const CONTENT_HTML = "HTML";
export const CONTENT_IMAGE_HTML = "HTML_IMAGE";
export const CONTENT_IMAGE = "IMAGE";
export const CONTENT_DOWNLOAD_LIST = "DOWNLOAD_LIST";
export const CONTENT_PANEL = "PANEL";
export const CONTENT_CLIPBOARD = "CLIPBOARD";
export const ContentMenuOptions = [
  { label: "Text", value: CONTENT_TEXT },
  { label: "Bild", value: CONTENT_IMAGE },
  { label: "HTML-Inhalt", value: CONTENT_HTML },
  { label: "Bild und HTML-Inhalt", value: CONTENT_IMAGE_HTML },
  { label: "Download-Dateiliste", value: CONTENT_DOWNLOAD_LIST },
  { label: "Abschnitt mit Titel", value: CONTENT_PANEL },
  { label: "Zwischenablage", value: CONTENT_CLIPBOARD },
];

export const isContentFromClipboard = (type) => type === CONTENT_CLIPBOARD;

export const getContentTypeFromId = (id) => {
  if (id !== null && id > 0 && id <= ContentMenuOptions.length) {
    return ContentMenuOptions[id - 1].value;
  }
  return null;
};

export const getContentTypeIdFromType = (type) => {
  const foundIndex = ContentMenuOptions.findIndex(
    (item) => item.value === type
  );
  // console.log("getContentTypeIdFromType(", type, ")", foundIndex);
  if (foundIndex >= 0) {
    return foundIndex + 1;
  }
  return null;
};

export const getContentComponent = (type) => {
  switch (type) {
    case CONTENT_TEXT:
      return TextContent;
    case CONTENT_HTML:
      return HtmlContent;
    case CONTENT_IMAGE_HTML:
      return HtmlImageContent;
    case CONTENT_IMAGE:
      return ImageContent;
    case CONTENT_PANEL:
      return PanelContent;
    case CONTENT_DOWNLOAD_LIST:
      return DownloadLinksContent;
    default:
      return null;
  }
};

export const getContentForm = (type) => {
  switch (type) {
    case CONTENT_TEXT:
      return TextContentForm;
    case CONTENT_HTML:
      return HtmlContentForm;
    case CONTENT_IMAGE:
      return ImageContentForm;
    case CONTENT_IMAGE_HTML:
      return HtmlImageContentForm;
    case CONTENT_PANEL:
      return PanelContentForm;
    case CONTENT_DOWNLOAD_LIST:
      return DownloadListForm;
    default:
      return null;
  }
};

export const getContentDeleteConfirmationText = (type) => {
  const option = ContentMenuOptions.find((item) => item.value === type);
  if (option) {
    const title = `${option.label} löschen`;
    let artikel = "";
    switch (type) {
      case CONTENT_TEXT:
      case CONTENT_HTML:
      case CONTENT_PANEL:
        artikel = "den";
        break;
      case CONTENT_IMAGE:
      case CONTENT_IMAGE_HTML:
        artikel = "das";
        break;
      case CONTENT_DOWNLOAD_LIST:
        artikel = "die";
        break;
      default:
        break;
    }
    const text = `Wollen Sie ${artikel} ${option.label} wirklich löschen?`;
    return { title, text };
  }
  return null;
};

export const ContentMenuLabelMap = {};
ContentMenuOptions.forEach((item) => {
  ContentMenuLabelMap[item.value] = item.label;
});

export class ContentMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onAddContent: PropTypes.func,
    positions: PropTypes.number,
    draggable: PropTypes.bool,
    onChangeDraggable: PropTypes.func,
  };
  static defaultProps = {
    className: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      type: "TEXT",
      position: 1,
    };
  }
  onChangeDraggable = (value) => {
    const { onChangeDraggable } = this.props;
    onChangeDraggable && onChangeDraggable(value);
  };
  render() {
    // console.log("ContentMenu.render()", this.props);
    const { className, onAddContent, positions, draggable } = this.props;
    const { type, position } = this.state;
    const positionOptions = [];
    for (let pos = 1; pos <= positions; pos++) {
      positionOptions.push({
        label: `als ${
          pos == 1 ? "Erster" : pos === positions ? "Letzter" : pos + "."
        }`,
        value: pos,
      });
    }
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle">
            <div
              className="p-col-12- w3-border w3-round w3-white"
              style={{ padding: "0.429rem" }}
            >
              <Checkbox
                id="draggable"
                checked={draggable}
                onChange={(event) => this.onChangeDraggable(event.checked)}
              ></Checkbox>
              <label htmlFor={`draggable`} className="p-checkbox-label">
                <span className="display-inline-block pad-lft pad-big-rht">{`Verschieben`}</span>
              </label>
            </div>
          </div>
          <div
            className="w3-cell w3-cell-middle pad-lft"
            style={{ width: "240px" }}
          >
            <Dropdown
              className="w3-block w3-border"
              value={type}
              disabled={draggable}
              options={ContentMenuOptions}
              onChange={(event) => this.setState({ type: event.value })}
              showClear
            />
          </div>
          <div
            className="w3-cell w3-cell-middle pad-lft"
            style={{ width: "160px" }}
          >
            <Dropdown
              className="w3-block w3-border"
              value={position}
              options={positionOptions}
              disabled={draggable}
              onChange={(event) => this.setState({ position: event.value })}
              showClear
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-lft">
            <span className="display-inline-block w3-border">
              <PlusIconButton
                className="pad-sm"
                onClick={() =>
                  onAddContent(
                    getContentTypeIdFromType(this.state.type),
                    this.state.position,
                    this.state.type
                  )
                }
                disabled={draggable}
                title="hinzufügen"
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export class ContentMenuPanel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onAddContent: PropTypes.func,
    positions: PropTypes.number,
  };
  static defaultProps = {
    className: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      type: "TEXT",
      position: 1,
    };
  }
  render() {
    // console.log("ContentMenuPanel.render()", this.props);
    const { className, onAddContent, positions } = this.props;
    const { type, position } = this.state;
    const positionOptions = [];
    for (let pos = 1; pos <= positions; pos++) {
      positionOptions.push({
        label: `als ${
          pos == 1 ? "Erster" : pos === positions ? "Letzter" : pos + "."
        }`,
        value: pos,
      });
    }
    const Options = ContentMenuOptions.filter(
      ({ label }) => label !== "Abschnitt mit Titel"
    );
    return (
      <div className={className}>
        <div className="w3-cell-row">
          <div className="w3-cell w3-cell-middle" style={{ width: "240px" }}>
            <Dropdown
              className="w3-block w3-border"
              value={type}
              options={Options}
              onChange={(event) => this.setState({ type: event.value })}
              showClear
            />
          </div>
          <div
            className="w3-cell w3-cell-middle pad-lft"
            style={{ width: "160px" }}
          >
            <Dropdown
              className="w3-block w3-border"
              value={position}
              options={positionOptions}
              onChange={(event) => this.setState({ position: event.value })}
              showClear
            />
          </div>
          <div className="w3-cell w3-cell-middle pad-lft">
            <span className="display-inline-block w3-border">
              <PlusIconButton
                className="pad-sm"
                onClick={() =>
                  onAddContent(
                    getContentTypeIdFromType(this.state.type),
                    this.state.position,
                    this.state.type
                  )
                }
                title="hinzufügen"
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export const getFileTitleFromName = (fileName) => {
  if (fileName) {
    const tokens = fileName.split(".");
    tokens.splice(tokens.length - 1, 1);
    let str = tokens.join("");
    str = replaceAll(str, "_", " ");
    return str;
  }
  return null;
};

export const getFileTypeLabel = (fileName) => {
  if (fileName) {
    const tokens = fileName.split(".");
    const ext = tokens[tokens.length - 1];
    switch (ext.toLowerCase()) {
      case "jpg":
      case "jpeg":
        return "JPEG";
      case "xls":
      case "xlsx":
        return `EXCEL (.${ext})`;
      case "doc":
      case "docx":
        return `WORD (.${ext})`;
      case "csv":
        return `CSV`;
      case "pdf":
        return `PDF`;
      case "ppt":
      case "pptx":
        return "POWER POINT";
      case "exe":
        return "EXE";
      case "zip":
        return "ZIP";
      default:
        return ext.toUpperCase();
    }
  }
  return null;
};

export const formatFileSize = (size) => {
  let divisor = 1;
  let units = "B";
  if (size > 1000000) {
    divisor = 1000000;
    units = "MB";
  } else if (size >= 1000) {
    divisor = 1000;
    units = "kB";
  }
  return `${(size / divisor).toFixed(1)} ${units}`;
};

export const getProductPageContent = (tree, canReadAdminData) => {
  // console.log("getProductPageContent", tree);
  const result = [];
  if (tree && tree.children) {
    tree.children.forEach((item) => {
      const { label, to, url, data } = item;
      const visibility = isLinkVisible(item);
      const child = {
        title: label,
        url,
        to,
        data: {
          ...data,
          ...visibility,
        },
      };
      child.products = (item.children || [])
        .filter((item) => item.is_archived === 0)
        .map((product) => {
          // const { children, ...rest } = product;
          const productData = product.data || {};
          const visibilityConfig = getVisibilityConfig(product);
          visibilityConfig.hide =
            !visibilityConfig.visibility.isVisible && !canReadAdminData;
          return {
            ...product,
            data: {
              ...productData,
              ...visibilityConfig.visibility,
            },
            visibilityConfig,
          };
        });
      // old and new items
      // const newItems = sortByOptionalPosition(child.products.filter(item => (!item.data || (item.data && item.data.color === "black"))));
      // const oldItems = sortByOptionalPosition(child.products.filter(item => (item.data && item.data.color !== "black")));
      let newItems = [];
      let oldItems = [];
      child.products.forEach((item) => {
        if (item.data && item.data.color && item.data.color !== "black") {
          oldItems.push(item);
        } else {
          newItems.push(item);
        }
      });
      console.log("Products: newItems", JSON.parse(JSON.stringify(newItems)));
      newItems = sortProducts(newItems);
      console.log(
        "Products: newItems (sorted by id desc and position asc)",
        newItems
      );

      oldItems = sortProducts(oldItems);

      child.products = [...newItems, ...oldItems];
      child.newProducts = newItems.length;
      child.oldProducts = oldItems.length;
      result.push(child);
    });
  }
  return result;
};
