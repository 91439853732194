import {
  ACTION_IDS,
  USER_ACTION_IDS,
  USER_STATUS_IDS,
  encodeObjectToBase64,
  isWorkerPlus,
  toDBDateTime,
} from "../../components/Utils";
import * as Auth from "../../redux/actions/auth";
import {
  canReadAdministrativeData,
  canWriteAdministrativeData,
} from "../Permissions/actions";

export const LOAD_EXTENDED_ROLES = "workers/roles/load";
export const LOAD_EXTENDED_ROLES_SUCCESS = "workers/roles/load/success";
export const LOAD_EXTENDED_ROLES_FAILURE = "workers/roles/load/failure";

export const LOAD_WORKERS = "workers/load";
export const LOAD_WORKERS_SUCCESS = "workers/load/success";
export const LOAD_WORKERS_FAILURE = "workers/load/failure";

export const LOAD_FILTERED_WORKERS = "workers/filtered/load";
export const LOAD_FILTERED_WORKERS_SUCCESS = "workers/filtered/load/success";
export const LOAD_FILTERED_WORKERS_FAILURE = "workers/filtered/load/failure";

export const LOAD_WORKER_COMPANIES = "worker/companies/load";
export const LOAD_WORKER_COMPANIES_SUCCESS = "worker/companies/load/success";
export const LOAD_WORKER_COMPANIES_FAILURE = "worker/companies/load/failure";

export const LOAD_WORKER_KDLS = "worker/kdls/load";
export const LOAD_WORKER_KDLS_SUCCESS = "worker/kdls/load/success";
export const LOAD_WORKER_KDLS_FAILURE = "worker/kdls/load/failure";

export const LOAD_WORKER = "worker/load";
export const LOAD_WORKER_SUCCESS = "worker/load/success";
export const LOAD_WORKER_FAILURE = "worker/load/failure";

export const LOAD_CURRENT_WORKER = "worker/current/load";
export const LOAD_CURRENT_WORKER_SUCCESS = "worker/current/load/success";
export const LOAD_CURRENT_WORKER_FAILURE = "worker/current/load/failure";

export const INIT_WORKER_WIZARD = "worker/init";
export const CANCEL_WORKER_WIZARD = "worker/cancel";

export const CHECK_WORKER = "worker/check";
export const CHECK_WORKER_SUCCESS = "worker/check/success";
export const CHECK_WORKER_FAILURE = "worker/check/failure";

export const SAVE_WORKER = "worker/save";
export const SAVE_WORKER_SUCCESS = "worker/save/success";
export const SAVE_WORKER_FAILURE = "worker/save/failure";

export const ACTIVATE_WORKER = "worker/activate";
export const ACTIVATE_WORKER_SUCCESS = "worker/activate/success";
export const ACTIVATE_WORKER_FAILURE = "worker/activate/failure";

export const DEACTIVATE_WORKER = "worker/deactivate";
export const DEACTIVATE_WORKER_SUCCESS = "worker/deactivate/success";
export const DEACTIVATE_WORKER_FAILURE = "worker/deactivate/failure";

export const DELETE_WORKER = "worker/delete";
export const DELETE_WORKER_SUCCESS = "worker/delete/success";
export const DELETE_WORKER_FAILURE = "worker/delete/failure";

export const EXPORT_WORKERS = "workers/export";
export const EXPORT_WORKERS_SUCCESS = "workers/export/success";
export const EXPORT_WORKERS_FAILURE = "workers/export/failure";

export const LOAD_SALES_WORKERS = "worker/sales/load";

export const DUMMY_WORKER = {
  username: "",
  password: "",
  firstname: "",
  lastname: "",
  email: "",
  cellNumber: "",
  telephoneNumber: "",
  preferredContactMethod: "email",
  // status_id: 1,

  companies: [
    {
      name: "",
      contractGiver: "",
      telephoneNumber: "",
      roles: [],
      extendedRoles: [],
      delphiConnections: [],
      devices: [],
      techDevices: [],
      techKeys: [],
      certificates: [],
    },
  ],
  itConnectionData: {
    adConnection: "",
    adUsername: "",
    adPassword: "",
    adRemark: "",
    ptConnection: "",
    ptUsername: "",
    ptPassword: "",
    ptRemark: "",
  },
};

export const defaultFilter = {
  first: 0,
  rows: 10,
  username: null,
  role: null,
  companyName: null,
  modified: null,
  status_id: null,
  sortFields: [],
};

export const loadExtendedRoles = () => {
  console.log("REDUX-ACTION: loadExtendedRoles() ...");
  return {
    types: [
      LOAD_EXTENDED_ROLES,
      LOAD_EXTENDED_ROLES_SUCCESS,
      LOAD_EXTENDED_ROLES_FAILURE,
    ],
    promise: (client) => client.get(`/companies/roles/0`),
  };
};

export const loadWorkers = (onSuccess) => {
  console.log("REDUX-ACTION: loadWorkers() ...");
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : user.companyId;
    dispatch({
      types: [LOAD_WORKERS, LOAD_WORKERS_SUCCESS, LOAD_WORKERS_FAILURE],
      promise: (client) =>
        client.get(
          `/companies/${encodeURIComponent(
            companyId
          )}/employees/0/summaries?related=true`
        ),
      onSuccess: (dispatch) => onSuccess && onSuccess(dispatch),
    });
  };
};

export const loadFilteredWorkers = (onSuccess, filter = defaultFilter) => {
  console.log("REDUX-ACTION: loadWorkers() ...");
  return (dispatch, getState) => {
    // const user = getState().auth.user;
    let filterJsonStringBase64 = "";
    if (filter) {
      filterJsonStringBase64 = encodeObjectToBase64(filter);
    }
    // const companyId = canReadAdministrativeData(getState())
    //   ? 0
    //   : user.companyId;
    dispatch({
      types: [
        LOAD_FILTERED_WORKERS,
        LOAD_FILTERED_WORKERS_SUCCESS,
        LOAD_FILTERED_WORKERS_FAILURE,
      ],
      promise: (client) =>
        client.get(
          `/companies/0/employees/0/filters/summaries?related=true&filter=${filterJsonStringBase64}`
        ),
      onSuccess: (dispatch) => onSuccess && onSuccess(dispatch),
    });
  };
};

export const loadLogisticsAssistants = (pCompany) => {
  console.log("REDUX-ACTION: loadLogisticsAssistants() ...");
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canReadAdministrativeData(getState())
      ? pCompany
      : user.companyId;
    dispatch({
      types: [LOAD_WORKERS, LOAD_WORKERS_SUCCESS, LOAD_WORKERS_FAILURE],
      promise: (client) =>
        client.get(`/companies/${encodeURIComponent(companyId)}/employees/0`),
      onlyLogisticsAssistants: true,
    });
  };
};

export const loadWorkerKDLs = (onSuccess) => {
  console.log(`REDUX-ACTION: loadWorkerKDLs() ...`);
  return {
    types: [
      LOAD_WORKER_KDLS,
      LOAD_WORKER_KDLS_SUCCESS,
      LOAD_WORKER_KDLS_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `/companies/0/summaries?related=true&form=true&filter=&kdl=true`
      ),
    onSuccess: () => onSuccess && onSuccess(),
  };
};

export const loadWorkerCompanies = (companyId, onSuccess) => {
  console.log(`REDUX-ACTION: loadWorkerCompanies(${companyId || ""}) ...`);
  return {
    types: [
      LOAD_WORKER_COMPANIES,
      LOAD_WORKER_COMPANIES_SUCCESS,
      LOAD_WORKER_COMPANIES_FAILURE,
    ],
    promise: (client) =>
      client.get(
        `/companies${
          companyId != null ? "/" + companyId : ""
        }/contractors?kdl=true&form=true`
      ),
    onSuccess: () => onSuccess && onSuccess(),
    companyId,
  };
};

export const loadWorker = ({ id }) => {
  console.log(`REDUX-ACTION: loadWorker(${id}) ...`);
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const companyId = canReadAdministrativeData(getState())
      ? 0
      : user.companyId;
    // fetch companies
    dispatch(loadWorkerKDLs());
    dispatch(loadWorkerCompanies(companyId));
    // fetch employee profile
    if (id === "neu") {
      return dispatch({
        type: LOAD_WORKER_SUCCESS,
        result: { ...DUMMY_WORKER },
        workerId: id,
      });
    }
    dispatch({
      types: [LOAD_WORKER, LOAD_WORKER_SUCCESS, LOAD_WORKER_FAILURE],
      promise: (client) =>
        client.get(
          `/companies/${encodeURIComponent(
            companyId
          )}/employees/${id}?related=true`
        ),
    });
  };
};

export const loadCurrentWorker = () => {
  console.log(`REDUX-ACTION: loadCurrentWorker()...`);
  return {
    types: [
      LOAD_CURRENT_WORKER,
      LOAD_CURRENT_WORKER_SUCCESS,
      LOAD_CURRENT_WORKER_FAILURE,
    ],
    promise: (client) => client.get(`/companies/profiles`),
  };
};

export const loadCurrentUser = () => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (canReadAdministrativeData(getState())) {
      dispatch(loadUser(user));
    } else {
      dispatch(loadWorker(user));
    }
  };
};

export const checkWorker = (worker, onSuccess) => {
  console.log(`REDUX-ACTION: checkWorker()`, worker);
  return {
    types: [CHECK_WORKER, CHECK_WORKER_SUCCESS, CHECK_WORKER_FAILURE],
    promise: (client) => client.post(`/companies/check`, { data: worker }),
    onSuccess,
    worker,
  };
};

export const saveWorker = (worker, onSuccess) => {
  console.log(`REDUX-ACTION: saveWorker()`, worker);
  worker.password = "secret";
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const companyId = canWriteAdministrativeData(getState())
      ? 0
      : user.companyId;
    const updatingMyself = user.id === worker.id;
    const onSaveSucess = () => {
      onSuccess && onSuccess();
      if (updatingMyself) {
        console.log(
          "### user is updating their own worker profile => reload profile..."
        );
        dispatch(Auth.loadCurrentUser());
      }
      // refetch tasks if company was changed
      if (worker.companies[0].oldId !== worker.companies[0].id) {
        console.log(
          `company changed from ${worker.companies[0].oldName} to ${worker.companies[0].name} => reload tasks...`
        );
        dispatch(Auth.loadCurrentUserSession);
      }
    };
    dispatch({
      types: [SAVE_WORKER, SAVE_WORKER_SUCCESS, SAVE_WORKER_FAILURE],
      promise: (client) =>
        client.put(`/companies/${encodeURIComponent(companyId)}/employee`, {
          data: worker,
        }),
      onSuccess: onSaveSucess,
      worker,
    });
  };
};

export const activateWorker = (worker, onSuccess) => {
  console.log(`REDUX-ACTION: activateWorker()`, worker);
  return {
    types: [ACTIVATE_WORKER, ACTIVATE_WORKER_SUCCESS, ACTIVATE_WORKER_FAILURE],
    // promise: (client) => client.get(`/users/${worker.id}/changeStatus/2`),
    promise: (client) =>
      client.get(
        `/companies/${worker.companies[0].id}/employees/${worker.id}/changeStatus/2`
      ),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadFilteredWorkers());
    },
    worker,
    activate: true,
  };
};

export const deactivateWorker = ({ worker, canReadAdminData }, onSuccess) => {
  // console.log(`REDUX-ACTION: deactivateWorker()`, worker, canReadAdminData);
  const task = {
    ...worker,
    user_id: worker.id,
    action_id: USER_ACTION_IDS.DEACTIVATE,
    status_id: USER_STATUS_IDS.DEACTIVATION_PENDING,
  };
  return {
    types: [
      DEACTIVATE_WORKER,
      DEACTIVATE_WORKER_SUCCESS,
      DEACTIVATE_WORKER_FAILURE,
    ],
    promise: (client) =>
      client.post(`/companies/connections/DELETE_USER_AND_IT_CONNECTIONS`, {
        data: [task],
      }),
    onSuccess: (dispatch) => {
      onSuccess && onSuccess();
      dispatch(loadFilteredWorkers());
    },
    worker,
    activate: false,
  };
};

export const deleteWorker = (worker) => {
  console.log(`REDUX-ACTION: deleteWorker()`, worker);
  return {
    types: [DELETE_WORKER, DELETE_WORKER_SUCCESS, DELETE_WORKER_FAILURE],
    promise: (client) => client.del(`/users`, { data: worker }),
    onSuccess: (dispatch) => dispatch(loadWorkers()),
    worker,
  };
};

export const initWorker = (value) => ({
  type: INIT_WORKER_WIZARD,
  result: value,
});
export const cancelWorker = () => ({ type: CANCEL_WORKER_WIZARD });

export const exportWorkers = (props = {}) => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    console.log(`REDUX-ACTION: exportWorkers()`, props, user);
    const canReadAdminData = canReadAdministrativeData(getState());
    const { username, companyId, position, modified_date } = props;
    const params = [
      `username=${encodeURIComponent(
        !canReadAdminData ? user.username : username || "null"
      )}`,
      `companyId=${encodeURIComponent(
        !canReadAdminData ? user.companyId : companyId || 0
      )}`,
      `modified_date=${encodeURIComponent(
        toDBDateTime(modified_date) || "null"
      )}`,
      `position=${encodeURIComponent(position || "null")}`,
    ].join("&");
    dispatch({
      types: [EXPORT_WORKERS, EXPORT_WORKERS_SUCCESS, EXPORT_WORKERS_FAILURE],
      promise: (client) => client.get(`/companies/employees/csv?${params}`),
    });
  };
};
