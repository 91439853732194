import React from 'react';
import PropTypes from 'prop-types';
import CheckDataForm from './CheckDataForm';
import CompanyDataForm from './CompanyDataForm';

const defaultModel = {
  firstname: '',
  lastname: '',
  email: '',
  cellNumber: '',
  username: '',
  telephoneNumber: '',
  preferredContactMethod: 'cell_number',
  companies: [{
    name: '',
    role: '',
    telephoneNumber: '',
    extendedRoles: []
  }]
};

export default class BasicDataForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    workerKdl: PropTypes.number,
    getWorkerCompanies: PropTypes.func.isRequired,
    companyList: PropTypes.array,
    kdlCompanyList: PropTypes.array,
    contractGivers: PropTypes.array,
    userCompany: PropTypes.object,
    roleList: PropTypes.array,
    checkUser: PropTypes.func,
    extendedRoleList: PropTypes.array,
    userExists: PropTypes.bool,
    userChecked: PropTypes.bool,
    canReadAdminData: PropTypes.bool,
    disabled: PropTypes.bool,
    editingMyself: PropTypes.bool,
  }
  static defaultProps = {
    className: '',
    model: { ...defaultModel },
    companyList: [],
    kdlCompanyList: [],
    contractGivers: [],
    roleList: []
  }
  constructor(props) {
    super(props);
    this.state = this.getState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.getState(props));
  }
  getState = (props) => {
    const model = props.model || {};
    const checkData = {
      id: model.id,
      firstname: model.firstname || '',
      lastname: model.lastname || '',
      email: model.email || '',
      cellNumber: model.cellNumber || '',
      preferredContactMethod: model.preferredContactMethod || 'cell_number'
    };
    let mainCompany = null;
    const companies = [];
    if (props.userChecked) {
      // creating new person
      if (props.userExists) {
        companies.push({ ...model.companies[0] });
      } else {
        mainCompany = { ...model.companies[0] };
      }
    } else {
      for (const company of model.companies) {
        console.log('>> BasicDataForm: checking company', company, 'userChecked', props.userChecked, 'userExists', props.userExists);
        if (mainCompany === null && company.role) {
          mainCompany = { ...company };
        } else {
          companies.push({ ...company });
        }
      }
    }
    return {
      checkData,
      mainCompany,
      companies,
    };
  }
  validate = (noValidate) => {
    // const { companies } = this.state;
    // base data (required)
    const model = this.checkData.getModel(noValidate);
    if (!model) return;
    // companies
    model.companies = [];
    // main company (required if new company is being created)
    if (this.mainCompany) {
      const mainCompany = this.mainCompany.getModel(noValidate);
      if (!mainCompany) return null;
      console.log('>>  model of main company', mainCompany);
      model.companies.push(mainCompany);
    } else {
      console.log('<< MainCompany not set => first secondary company is required !');
    }

    if (model.companies.length === 0) {
      return null;
    }
    if (this.props.model && this.props.model.id) {
      model.id = this.props.model.id;
    }
    return model;
  }
  getModel = (noValidate) => {
    const model = this.validate(noValidate);
    return model;
  }
  generateUsername = (newName, newSurname) => {
    const { firstname, lastname } = this.state;
    const username = `${newName || firstname}.${newSurname || lastname}`;
    this.setState({
      firstname: (newName || firstname),
      lastname: (newSurname || lastname),
      username
    });
  }
  render() {
    const {
      className, workerKdl, companyList, kdlCompanyList, contractGivers, userCompany,
      roleList, extendedRoleList, checkUser, userChecked, userExists, canReadAdminData,
      disabled, editingMyself, model: { username, password },
      getWorkerCompanies,
    } = this.props
    const { checkData, mainCompany, companies} = this.state;

    const mergeCompanyLists = (cmp1, cmp2) => {
      let list = [];
      if (cmp1) { list = [ ...cmp1 ]; }
      (cmp2 || []).forEach(company => {
        const found = list.find(item => item.name === company.name);
        if (!found) list.push({ ...company });
      });
      return list;
    }
    const kdlCompanies = mergeCompanyLists(kdlCompanyList, contractGivers);
console.log("BasicDataForm()", mainCompany, companies, "userChecked:", userChecked, "userExists:", userExists);
    const mainCompanyMarkup = mainCompany && (
      <CompanyDataForm
        ref={ref => this.mainCompany = ref}
        namePrefix={`mainCompany-`}
        className="pad-top"
        model={mainCompany}
        workerKdl={workerKdl}
        getWorkerCompanies={getWorkerCompanies}
        companyList={companyList}
        kdlCompanyList={kdlCompanies}
        userCompany={userCompany}
        roleList={roleList}
        extendedRoleList={extendedRoleList}
        disabled={disabled}
        userExists={userExists}
        canReadAdminData={canReadAdminData}
        isMain
      />
    );
    return (
      <div className={className}>
        <CheckDataForm
          ref={ref => this.checkData = ref}
          model={{ ...checkData, username, password }}
          userChecked={userChecked}
          checkUser={checkUser}
          userExists={userExists}
          disabled={disabled}
        />
        {mainCompanyMarkup}
      </div>
    );
  }
}
