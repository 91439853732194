import React from "react";
import { connect } from "react-redux";
import { InpageNavLink } from "../../../components/InPageNavMenu";
// import { frontloadConnect } from "react-frontload";
import {
  isResourceReadable,
  // loadResourcePermissions,
} from "../actions";

// const frontload = async (props) => {
//   const resources = [];
//   (props.links || []).forEach(link => {
//     if (link.resource) {
//       resources.push(link.resource);
//     }
//   })
//   await props.getResourcePermissionList(resources);
// };

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissionMap,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getResourcePermission: (...args) =>
  //   dispatch(loadResourcePermissions(...args)),
});

const evaluatePermissions = (
  permissions,
  resource,
  resources,
  resourceEvaluation = "or"
) => {
  if (!resource && !resources) {
    return true;
  }
  const isORComparism = `${resourceEvaluation}`.toLowerCase() === "or";
  let result = isORComparism ? false : true;
  if (resource) {
    result = isResourceReadable(resource, permissions);
  } else {
    resources.forEach((item) => {
      if (isORComparism) {
        result = result || isResourceReadable(item, permissions);
      } else {
        result = result || isResourceReadable(item, permissions);
      }
    });
  }
  return result;
};

const PermissionInPageNavMenu = ({
  className = "under-wizard-title",
  contentClassName = "margin-big-top",
  linkClassName = "w3-border",
  links,
  initialActiveId = "basisdaten",
  permissions,
}) => {
  console.log("PermissionInPageNavMenu()", permissions);
  const [activeId, setActiveId] = React.useState(initialActiveId);
  const body = links.map((link, index) => {
    const {
      isVisible,
      to,
      label,
      resource,
      resources,
      resourceEvaluation,
      ...rest
    } = link;
    const show = isVisible !== undefined ? isVisible : true;
    const isReadable = evaluatePermissions(permissions, resource, resources, resourceEvaluation);
    return !isReadable || !show ? null : (
      <li key={index}>
        <InpageNavLink
          to={to}
          {...rest}
          activeClassName="active"
          currentTo={activeId}
          setCurrentTo={setActiveId}
        >
          {label}
        </InpageNavLink>
      </li>
    );
  });
  return (
    <div className={className}>
      <div className={`${contentClassName} nav-menu-vertical`}>
        <ul className={`w3-ul w3-large ${linkClassName}`}>{body}</ul>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  PermissionInPageNavMenu
  // frontloadConnect(frontload, {
  //   onMount: true,
  //   onUpdate: false,
  // })(PermissionInPageNavMenu)
);
